import React, { useEffect, useState } from "react";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import Cardboard from "components/Global/Cardboard";
import InfoArea from "components/Global/InfoArea";
import ListContainer from "components/Global/ListContainer";
import Pagination from "components/Global/Pagination";
import List from "components/Global/List";
import api from "store/api/actions";
import { Link } from "react-router-dom";
import RightIcon from "components/icons/RightIcon";
import styles from "./transactionreportssubdealersd.module.css";
import PriceLabel from "components/Global/PriceLabel";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import ExportCSV from "hoc/ExportCSV";
import SwitchButton from "components/Global/SwitchButton";
import Label from "components/Global/Label";
import TablePriceLabel from "components/Global/TablePriceLabel";
import Information from "components/Global/Info/Information";
import DateTimePicker from "components/Global/DateTimePickerv2";
import { useMediaQuery } from 'react-responsive'
import TransactionReportsResponsive from "components/Responsive/TransactionReports";
import ExportExcel from "hoc/ExportExcel";

const intialResponseState = {
  successTransactionQuantity: 0, //başarılı işlem adedi
  successTransactionAmount: "", //başarılı işlem tutarı
  cancellationAndRefundTransactionQuantity: 0, // iptal ve geri ödeme işlem adedi
  cancellationAndRefundTransactionAmount: "", // iptal ve geri ödeme işlem tutarı
  waitingTransactionQuantity: 0, //bekleyen işlem adedi
  waitingTransactionAmount: "", //bekleyen işlem tutarı
  failedTransactionQuantity: 0, //başarısız işlem adedi
  failedTransactionAmount: "", //başarısız işlem tutarı
  commissionToBePaidTransactionQuantity: 0, //ödenecek komisyon adedi
  commissionToBePaidTransactionAmount: "", //ödenecek komisyon tutarı
  marketPlaceToBePaidAmountTransactionQuantity: 0, //bmpye geçecek adet
  marketPlaceToBePaidAmountTransactionAmount: "", //bmpye geçecek tutar
  paymentList: [],
  paymentListTotalCount: 0,
};

const initialSearchDataState = {
  startDate: "",
  endDate: "",
  pobUserIdList: [],
  listingTypeId: 0,
  limit: 10,
  orderBy: 0,
  pageNo: 0
};

const SubDealerStandard = () => {
  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState(intialResponseState);
  const [searchData, setSearchData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("İşlem Raporları");
  const [exportData, setExportData] = useState([]);
  const [pageType, setPageType] = useState(1);

  const [allData, setAllData] = useState([])
  const [allSearchData, setAllSearchData] = useState({ limit: -1 });

  //#region Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  useEffect(() => {
    api
      .SubDealer()
      .GetReports(allSearchData)
      .then((response) => {
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setAllData(response.data.data.paymentList);
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  useEffect(() => {
    getPaymentDataFromApi();
  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(pageType)) {
      setSearchData({ ...searchData, listingTypeId: pageType });
    }
  }, [pageType]);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getPaymentDataFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(allData);
    }
  }, [allData]);

  // #region Pagination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row) => [
          <Label text={row.pobUserNameOrTransactionDate} />,
          <TablePriceLabel price={row.successTransactionAmount} />,
          <TablePriceLabel price={row.waitingTransactionAmount} />,
          <TablePriceLabel price={row.failedTransactionAmount} />,
          <TablePriceLabel price={row.cancellationAndRefundTransactionAmount} />,
          <TablePriceLabel price={row.marketPlaceToBePaidAmountTransactionAmount} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrUserName: row.pobUserNameOrTransactionDate,
                pobUserId: row.pobUserId,
                listingTypeId: pageType,
              }}
            >
              <span style={{ display: "flex", justifyContent: 'center', alignItems: 'center', textDecoration: "none", color: "#4f5386" }}>
                İşlemlere Git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );

      setCurrentItemsForResponsive(
        listData?.map((row) => [
          <Label text={row.pobUserNameOrTransactionDate} />,
          <TablePriceLabel price={row.successTransactionAmount} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrUserName: row.pobUserNameOrTransactionDate,
                pobUserId: row.pobUserId,
                listingTypeId: pageType,
              }}
            >
              <span style={{ display: "flex", justifyContent: 'center', alignItems: 'center', textDecoration: "none", color: "#4f5386" }}>
                İşlemlere Git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
    } else {
      setCurrentItems([]);
      setCurrentItemsForResponsive([]);
    }
  }, [listData, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };
  //#endregion

  const getPaymentDataFromApi = () => {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {
      api
        .SubDealer()
        .GetReports(searchData)
        .then((response) => {
          //console.log("responselist", response.data.data);
          setResponseData(response.data.data);
          if (checkNullOrEmptyArray(response.data.data.paymentList)) {
            setListData(response.data.data.paymentList);
          } else {
            setListData([]);
          }
          setresponseListTotalCount(
            response.data.data.paymentListTotalCount
          );
        })
        .catch((error) => {
          console.log("error", error.response.data);
          setListData([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateExportData = (listValue) => {
    setExportData(
      listValue.map((row) => {
        return {
          "": `${row.pobUserNameOrTransactionDate}`,
          Başarılı: `${row.successTransactionAmount}`,
          Bekleyen: `${row.waitingTransactionAmount}`,
          Başarısız: `${row.failedTransactionAmount}`,
          "İptal/İade": `${row.cancellationAndRefundTransactionAmount}`,
          Toplam: `${row.marketPlaceToBePaidAmountTransactionAmount}`,
        };
      })
    );
  };

  // Date range
  const setDateFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (newValue) => {
    if (newValue.endDate != null) {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      let endDate = new Date(newValue.endDate).toLocaleDateString();
      if (startDate !== endDate) {

        setSearchData({
          ...searchData,
          startDate: setDateFormat(startDate),
          endDate: setDateFormat(endDate),
        });
      }
    }
  };

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="93px"
    // />
    <ExportExcel
    excelData={exportData}
    fileName={fileName}
    text="İndir"
    />,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <TransactionReportsResponsive
        setPageType={setPageType}
        pageType={pageType}
        currentItems={currentItemsForResponsive}
        handleDateRangeChange={handleDateRangeChange}
        responseData={responseData}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea width="100%" style={{ flexBasis: '10%' }} items={filterItems} SwitchItem={() => <SwitchButton
          callback={setPageType}
          style={{ height: '3rem', width: "360px", marginLeft: "15%" }}
          items={[
            { id: 1, text: "Tarih Bazlı" },
            { id: 2, text: "Kullanıcı Bazlı" },
          ]}
          selectedValue={pageType}
        />}></FilterArea>
        <Container justifyContent='flex-start' flexDirection="column" minHeight="100%">
          <Cardboard>
            <InfoArea>
              <div className={styles.total}>
                <div className={styles.biglabel}>Başarılı İşlem Tutarı</div>
                <PriceLabel
                  price={responseData.successTransactionAmount}
                  count={responseData.successTransactionQuantity}
                  size="3.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>İptal / İade <Information text="İade işlemi belirlenen vade süresine göre hesaba geçecektir." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.cancellationAndRefundTransactionAmount}
                  count={responseData.cancellationAndRefundTransactionQuantity}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Bekleyen İşlemler</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.waitingTransactionAmount}
                  count={responseData.waitingTransactionQuantity}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Başarısız İşlemler</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.failedTransactionAmount}
                  count={responseData.failedTransactionQuantity}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Komisyon Tutarı</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.commissionToBePaidTransactionAmount}
                  count={responseData.commissionToBePaidTransactionQuantity}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Ana Firmaya Geçecek Tutar <Information text="Bu tutar ana firmanıza işlem tarihi ve vade süresine göre geçecektir." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.marketPlaceToBePaidAmountTransactionAmount}
                  count={
                    responseData.marketPlaceToBePaidAmountTransactionQuantity
                  }
                  size="2.5"
                />
              </div>
            </InfoArea>
          </Cardboard>
          <Cardboard>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={responseListTotalCount}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />
              <List
                columnNames={[
                  "",
                  "BAŞARILI",
                  "BEKLEYEN",
                  "BAŞARISIZ",
                  "İPTAL/İADE",
                  "Ana firmaya geçecek tutar",
                  "",
                  "",
                ]}
                data={currentItems}
              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};

export default SubDealerStandard;
