// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoarea_headinfo__j0tkP{\n    display : flex;\n    justify-content: space-between;\n    align-items: center;\n    width:100%;\n    padding: 20px;\n}\n\n.infoarea_headcontainer__a\\+Pl\\+{\n    display : flex;\n    justify-content: space-between;\n    align-items: center;\n    width:90%;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/InfoArea/infoarea.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".headinfo{\n    display : flex;\n    justify-content: space-between;\n    align-items: center;\n    width:100%;\n    padding: 20px;\n}\n\n.headcontainer{\n    display : flex;\n    justify-content: space-between;\n    align-items: center;\n    width:90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headinfo": "infoarea_headinfo__j0tkP",
	"headcontainer": "infoarea_headcontainer__a+Pl+"
};
export default ___CSS_LOADER_EXPORT___;
