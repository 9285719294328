import { Breadcrumbs, Link, Typography } from "@mui/material"
import RightIcon from 'components/icons/RightIcon'

const CustomBreadcrumbs = ({ breadcrumbs, onClick }) => {

    let i = 0;

    return (
        <>
            <Breadcrumbs separator={<RightIcon/>} aria-label="breadcrumb" sx={{marginRight:'auto'}}>
                {
                    breadcrumbs && breadcrumbs.map(crumb => {
                        i = i + 1;
                        if (i === 1) {
                            return (
                                <Link key={i} underline="hover" href="/transactions" color="text.secondary" style={{ textDecoration: "none", fontWeight: "bold",fontFamily:'Metropolis-Bold',color:'#000d89' }}>
                                    {crumb}
                                </Link>
                            )
                        }
                        else {
                            return (
                                <Typography key={i} color="text.secondary" sx={{fontFamily:'Metropolis-Medium',color:'##4f5386' }}>
                                    {crumb}
                                </Typography>
                            )
                        }
                    })
                }

            </Breadcrumbs>
        </>
    )
}

export default CustomBreadcrumbs
