import { AUTHORITY, CLIENT_ID, POST_LOGOUT_REDIRECT_URI, REDIRECT_URI } from 'environment';
import { Log, UserManager, WebStorageStateStore } from 'oidc-client';


//console.log("process.env", process.env);
//.env
const config = {
  userStore: new WebStorageStateStore(),
  authority: AUTHORITY,
  client_id: CLIENT_ID, //mahsupp.web.client.test
  redirect_uri: REDIRECT_URI, //https://test.mahsupp.com/signin-oidc
  response_type: "code",
  scope: "openid profile mahsuppserviceapi.fullaccess",
  post_logout_redirect_uri: POST_LOGOUT_REDIRECT_URI,
};

const userManager = new UserManager(config)

Log.logger = console;
Log.level = Log.INFO;

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  return userManager.signoutRedirectCallback()
}

export function getUser() {
  return userManager.getUser();
}

export function signinSilent() {
  return userManager.signinSilent()
}

export function remove() {
  userManager.clearStaleState()
  userManager.removeUser()
}

export default userManager