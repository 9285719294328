import React from 'react';

const LinkPaymentIcon = ({ color = "#000D89", size = "20" }) => {
    return <>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>

            <symbol id="link">
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.709 8.58c1.924-1.712 4.907-1.646 6.749.196.44.44.44 1.164 0 1.604-.44.44-1.164.44-1.603 0a2.66 2.66 0 0 0-3.751 0l-2.688 2.665-.177.189a3.91 3.91 0 0 0 .176 5.356 3.909 3.909 0 0 0 5.543 0c.44-.44 1.164-.44 1.604 0 .44.44.44 1.164 0 1.604A6.217 6.217 0 0 1 8.187 22a6.178 6.178 0 0 1-4.375-1.806 6.19 6.19 0 0 1-.22-8.52l.219-.23L6.5 8.776l.209-.197zm4.732-4.768a6.187 6.187 0 0 1 8.75 0 6.168 6.168 0 0 1-.022 8.75l-2.667 2.668-.222.209a4.866 4.866 0 0 1-3.257 1.23 4.87 4.87 0 0 1-3.478-1.44 1.14 1.14 0 0 1 0-1.603 1.14 1.14 0 0 1 1.603 0 2.62 2.62 0 0 0 3.754-.003l2.684-2.662.178-.19a3.91 3.91 0 0 0-.177-5.355 3.909 3.909 0 0 0-5.543 0 1.14 1.14 0 0 1-1.603 0 1.14 1.14 0 0 1 0-1.604z" fill={color} />
                </svg>
            </symbol>
        </div>
    </>
};

export default LinkPaymentIcon