import { useState, useEffect } from 'react';
import styles from './payment.module.css'
import Input from 'components/Global/Input';
import Dropdown from 'components/Global/Dropdown';
import Button from 'components/Global/Button';
import PriceLabel from 'components/Global/PriceLabel';
import AutoComplete from 'components/Global/AutoComplete';
import CheckBox from 'components/Global/CheckBox';
import BankList from 'components/Global/BankList';
import { useFormik } from 'formik';
import cx from 'classnames';
import api from 'store/api/actions'
import paymentValidator from './paymentValidator'
import axios from 'axios';
import useCreditCardItems from 'hoc/useCreditCardItems';
import InstallmentArea from 'components/Global/InstallmentArea';
import ReactInputMask from 'react-input-mask';
import Cookies from 'universal-cookie';
import { checkNullOrEmpty } from 'hoc/Conditions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useMediaQuery } from 'react-responsive';
import InputFlip from 'components/Global/InputFlip';

const DirectPayment = ({ isSuccessful, customerId, style, tableData }) => {
    const cookies = new Cookies();
    var userCommissionList = cookies.get('userCommissionList');

    const [autoCompleteCallback, setAutoCompleteCallback] = useState();
    const [monthSelected, setMonthSelected] = useState('12');
    const [yearSelected, setYearSelected] = useState('2022');
    const [installmentSelected, setInstallmentSelected] = useState();
    const [ipAddress, setIpAddress] = useState("");
    const [price, setPrice] = useState("00,00");
    const [disablePost, setDisablePost] = useState(true);
    const [customerData, setCustomerData] = useState();
    const [disableCustomerInputs, setDisableCustomerInputs] = useState();
    const [includeCommission, setIncludeCommission] = useState(false);
    const [pureAmount, setAmount] = useState(0);
    const [replacedPrice, setReplacedPrice] = useState(null)
    const [isInstallment, setIsInstallment] = useState(1)
    const [commissionRate, setCommissionRate] = useState()
    const [emptyGroupError, setEmptyGroupError] = useState("")
    const { months, years, installmentsAndCommissions } = useCreditCardItems();
    const [isInstalmentStatus, setIsInstalmentStatus] = useState(false) //taksit seçimi kaldırılırsa true
    const [beforeCommissisonRate, setBeforeCommissisonRate] = useState() //bir önceki rate değerini yakalamak için

    const [phoneFlipClosed, setPhoneFlipClosed] = useState(false);
    const [mailFlipClosed, setMailFlipClosed] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [showMail, setShowMail] = useState(1);
    const [showPhone, setShowPhone] = useState(1);

    useEffect(() => {
        const getLocation = axios.get('https://geolocation-db.com/json/');
        getLocation.then(res => {
            setIpAddress(res.data.IPv4);
        }).catch((err) => {
            setIpAddress("192.168.1.2");
            console.log("Error", err?.response?.data)
        });

        api.Customer().GetAll({}).then(response => {
            // console.log('response customer', response?.data.data)
            setCustomerData(response.data.data.customerList?.map(item => ({
                id: item.id,
                name: item.customerName,
                phone: item.phone,
                email: item.email
            })));
            // console.log(response.data)
        }).catch(err => {
            console.log(err)
        });

        handleReset({});

    }, []);

    useEffect(() => {
        if (autoCompleteCallback?.id) {
            setDisableCustomerInputs(true)
            values.pobDealerCustomerId = autoCompleteCallback?.id;
            values.customerFullName = "";
        }
        else if (autoCompleteCallback?.id === undefined) {
            setDisableCustomerInputs(false)
            values.customerFullName = autoCompleteCallback?.name
            values.pobDealerCustomerId = "";
        }
    }, [autoCompleteCallback])

    useEffect(() => {
        if (disableCustomerInputs) {
            const customerInfo = customerData?.find(a => a.id === autoCompleteCallback?.id)
            setFieldValue('phone', customerInfo.phone)
            setFieldValue('email', customerInfo.email)
        }
        else {
            setFieldValue('phone', '')
            setFieldValue('email', '')
        }

    }, [disableCustomerInputs])

    useEffect(() => {
        if (isInstallment == 0) {
            setIsInstalmentStatus(true)
            setIncludeCommission(false)
        }
    }, [isInstallment])

    useEffect(() => {
        if (isInstalmentStatus == true && includeCommission == false) {
            const commission = Number.parseFloat(pureAmount * beforeCommissisonRate / 100).toFixed(2);
            const price = Number.parseFloat(pureAmount - commission).toFixed(2);
            const splittedPrice = price.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            setFieldValue('amount', String(pureAmount).replace('.', ','))
        }
    }, [isInstallment, includeCommission, commissionRate, isInstalmentStatus])

    useEffect(() => {
        if (includeCommission == true && pureAmount > 0) {
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            const calculatedValue = Number.parseFloat(((100 + commissionRate) / 100) * pureAmount).toFixed(2).replace('.', ',');
            const splittedPrice = pureAmount.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            console.log(String(pureAmount).replace('.', ','), 'qweqweazxch')
            setFieldValue('amount', calculatedValue.replaceAll('.', ''))
            setReplacedPrice(null)
            setBeforeCommissisonRate(commissionRate)
        }
        if (includeCommission == false && isInstalmentStatus == false) {
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            const commission = Number.parseFloat(pureAmount * commissionRate / 100).toFixed(2);
            const price = Number.parseFloat(pureAmount - commission).toFixed(2);
            const splittedPrice = price.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            setFieldValue('amount', String(pureAmount).replace('.', ','))
            setBeforeCommissisonRate(commissionRate)
        }
        // else {
        //     const commission = Number.parseFloat(pureAmount * commissionRate / 100).toFixed(2);
        //     const price = Number.parseFloat(pureAmount - commission).toFixed(2);
        //     const splittedPrice = price.split(".");
        //     setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
        //     setFieldValue('amount', String(pureAmount).replace('.', ','))
        // }
    }, [includeCommission, commissionRate])

    useEffect(() => {
        values.expMonth = monthSelected;
        values.expYear = yearSelected;

    }, [monthSelected, yearSelected, installmentSelected])

    const { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue, setValues, handleReset } = useFormik({

        initialValues: {
            pobDealerCustomerId: "",
            clientIP: '',
            customerFullName: "",
            addCommissionRateToAmount: false,
            email: "",
            phone: "",
            cardHolderFullName: "",
            cardNumber: "4183441122223339",
            expMonth: "12",
            expYear: "2022",
            cvcNumber: "000",
            amount: "",
            currency: "",
            installmentNumber: 0,
            description: "",
        },
        onSubmit: values => {

            setDisablePost(true);
            setEmptyGroupError("")
            if (values.pobDealerCustomerId.length < 1) {
                delete values.pobDealerCustomerId
            }
            else {
                customerId(values.pobDealerCustomerId)
            }

            values.clientIP = ipAddress;
            values.amount = values?.amount?.replace(',', '.')
            if (replacedPrice != null) values.amount = replacedPrice

            api.PaymentTransaction().ThreeDSecurePayment(values).then(response => {
                if (response?.data?.success === true) {
                    console.log("Response Dönüşü card ödeme", response.data.data.companyName)

                    window.location.href = response.data.data.url;
                    tableData({
                        companyName: response.data.data.companyName
                    })
                }
                else {
                    isSuccessful(false)
                }
            }).catch((error) => {
                setEmptyGroupError(error?.response?.data.userMessage)

            }).finally(() => {
                setDisablePost(false);
            });

        },
        validationSchema: paymentValidator
    });

    const numberWithCommas = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    const customHandleChange = e => {
        if (e.target.name === "amount") {
            e.target.value.length > 0 ? setDisablePost(false) : setDisablePost(true)
            // if (installmentSelected !== undefined) {
            //     installment = parseInt(installmentSelected);
            // }
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            if (e.target.value !== undefined && e.target.value !== null && e.target.value !== "") {
                var amount = Number.parseFloat(e.target.value.replace(/\./g, "").replace(",", ".")).toFixed(2);
                setAmount(amount)
                const commission = Number.parseFloat(amount * commissionRate / 100).toFixed(2);
                const price = Number.parseFloat(amount - commission).toFixed(2);
                console.log(e.target.value.replaceAll('.', '').replace(',', '.'), "qasghasdh")
                const splittedPrice = price.split(".");
                setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
                setReplacedPrice(e.target.value.replaceAll('.', '').replace(',', '.'))
            }
            else {
                setPrice("00,00")
            }
        }
        setIncludeCommission(false)
        handleChange(e);
    }

    const handleInstallment = (id) => {
        setFieldValue("installmentNumber", id)
        if (checkNullOrEmpty(id))
            setIsInstallment(id)
    }
    const defaultMaskOptions = {
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false,
        prefix: '',
    }
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    return (
        isMobile ? <>
            <form className={styles.linkformresponsive} onSubmit={handleSubmit}>
                <section className={styles.linksection} >
                    <div className={styles.customerarea}></div>
                    <div className={styles.subheaders}>Müşteri Bilgisi</div>
                    <div className={styles.inputfieldresponsive}>
                        <AutoComplete
                            onBlur={handleBlur}
                            height='3rem'
                            items={customerData}
                            maxLength='70'
                            onChange={setAutoCompleteCallback}
                            name='customerFullName'
                            width='100%'
                            placeholder='Müşteri ismi / Grup ismi'
                            error={touched.customerFullName && errors.customerFullName}
                        />
                    </div>
                    <div className={styles.inputgroup}>
                        <div className={styles.groupitem}>
                            <InputFlip
                                placeholder='Telefon'
                                buttonText='Telefon'
                                closed={setPhoneFlipClosed}
                                disabled={disableInputs}
                                showInput={showPhone}
                                value={values.phone}
                                name='phone'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                length='10'
                            />
                        </div>
                        <div className={styles.groupitem}>
                            <InputFlip
                                placeholder='E-posta'
                                buttonText='E-posta'
                                closed={setMailFlipClosed}
                                disabled={disableInputs}
                                showInput={showMail}
                                value={values.email}
                                name='email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>
                    <div className={styles.subtitle2}>Telefon ve E-postadan yanlızca biri zorunludur.</div>
                    <div className={styles.customerarea}>
                        <div className={styles.subheaders}>Kart Bilgisi</div>

                        <Input onBlur={handleBlur} height='3rem' error={touched.cardHolderFullName && errors.cardHolderFullName} name='cardHolderFullName' width='100%' value={values.cardHolderFullName} placeholder='Kart üzerindeki isim' onChange={handleChange} />

                        <ReactInputMask
                            mask="9999 9999 9999 9999"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cardNumber}
                            maskChar=" "
                            error={touched.cardNumber && errors.cardNumber}
                            helperText={(errors.cardNumber && touched.cardNumber)}
                        >
                            {() => <Input id="cardNumber" name="cardNumber" placeholder="Kart Numarası" onBlur={handleBlur} error={errors.cardNumber && touched.cardNumber} helperText={(errors.cardNumber && touched.cardNumber)} />}
                        </ReactInputMask>

                        <div className={styles.triplearea}>
                            <Dropdown
                                width='30%'
                                text={values.expMonth}
                                items={months}
                                onSelected={setMonthSelected}
                                height='3rem'
                                selectItemId={monthSelected}
                                backgroundColor='#fff'
                                border='solid 1px #bfc3de'
                            />

                            <Dropdown text={values.expYear} width='30%' items={years}
                                onSelected={setYearSelected}
                                height='3rem'
                                selectItemId={yearSelected}
                                backgroundColor='#fff'
                                border='solid 1px #bfc3de'

                            />

                            <Input onBlur={handleBlur} maxLength='3' name='cvcNumber' width='30%' placeholder='CVC' value={values.cvcNumber} onChange={handleChange} height='3rem' />
                        </div>

                        <div className={styles.customerarea}>
                            <div className={styles.subheaders}>İşlem Bilgisi</div>
                            <Input
                                height='3rem'
                                onChange={customHandleChange}
                                placeholder='İşlem tutarı'
                                name='amount'
                                value={values.amount == 0 ? "" : values.amount}
                                onBlur={handleBlur}
                                error={touched.amount && errors.amount}
                                type='text'
                                isMasked={true}
                                maskedOptions={currencyMask}
                            />
                        </div>
                        <div className={styles.customerarea}>
                            <div className={styles.subheaders}>Taksit Miktarı</div>
                            <InstallmentArea
                                items={installmentsAndCommissions}
                                slidesShow={6}
                                onChange={handleInstallment}
                                itemStyle={{ width: '50px !important', height: '50px !important' }}
                            />
                            <div className={cx(styles.info, styles.primary)}>
                                {
                                    <>
                                        <CheckBox
                                            text='Komisyon oranını tutara ekle'
                                            height='3rem'
                                            isClicked={setIncludeCommission}
                                            checked={includeCommission}
                                            id="linkCheckBox"
                                            disabled={isInstallment > 1 ? false : true}
                                        />
                                        <div style={{ width: "100px" }}></div>
                                    </>
                                }
                            </div>
                            <div className={styles.info} >
                                <div className={styles.infolabelresponsive}>
                                    Hesaba Geçecek
                                    Net Tutar
                                </div>
                                <div className={styles.pricelabel}>
                                    <PriceLabel price={price ?? "00,00"} size='2' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span style={{
                        color: 'red'
                    }}>
                        {emptyGroupError}
                    </span>
                    <div className={styles.footer}>
                        <Button onClick={handleSubmit} type='submit' fontSize='1.3rem' width='100%' height='3.5rem' text='Ödeme Al' disabled={disablePost} />
                    </div>
                    <div className={styles.banklist}>
                        <BankList />
                    </div>
                </section>
            </form>

        </> :
            <form onSubmit={handleSubmit} className={styles.form} action="">
                <section className={styles.creditcardsection} style={style}>
                    <div className={styles.top}>
                        <div className={styles.inputarea}>
                            <span className={styles.subheaders}>Müşteri Bilgisi</span>
                            <AutoComplete onBlur={handleBlur} height='3rem' items={customerData} maxLength='70' onChange={setAutoCompleteCallback} name='customerFullName' width='100%' placeholder='Müşteri ismi / Grup ismi' error={touched.customerFullName && errors.customerFullName} />

                            <ReactInputMask
                                mask="999 999 99 99"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                maskChar=" "
                                error={errors.phone && touched.phone}
                                helperText={(errors.phone && touched.phone)}
                                disabled={disableCustomerInputs}
                            >
                                {() => <Input id="phone" name="phone" placeholder="Telefon" onBlur={handleBlur} error={errors.phone && touched.phone} helperText={(errors.phone && touched.phone)} />}
                            </ReactInputMask>

                            <Input onBlur={handleBlur} height='3rem' error={touched.email && errors.email} disabled={disableCustomerInputs} name='email' width='100%' value={values.email} placeholder='E-posta' onChange={handleChange} />
                        </div>
                        <div className={styles.inputarea}>
                            <span className={styles.subheaders}>Kart Bilgisi</span>

                            <Input onBlur={handleBlur} height='3rem' error={touched.cardHolderFullName && errors.cardHolderFullName} name='cardHolderFullName' width='100%' value={values.cardHolderFullName} placeholder='Kart üzerindeki isim' onChange={handleChange} />

                            <ReactInputMask
                                mask="9999 9999 9999 9999"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cardNumber}
                                maskChar=" "
                                error={touched.cardNumber && errors.cardNumber}
                                helperText={(errors.cardNumber && touched.cardNumber)}
                            >
                                {() => <Input id="cardNumber" name="cardNumber" placeholder="Kart Numarası" onBlur={handleBlur} error={errors.cardNumber && touched.cardNumber} helperText={(errors.cardNumber && touched.cardNumber)} />}
                            </ReactInputMask>

                            <div className={styles.triplearea}>
                                <Dropdown
                                    width='30%'
                                    text={values.expMonth}
                                    items={months}
                                    onSelected={setMonthSelected}
                                    height='3rem'
                                    selectItemId={monthSelected}
                                    backgroundColor='#fff'
                                    border='solid 1px #bfc3de'
                                />

                                <Dropdown text={values.expYear} width='30%' items={years}
                                    onSelected={setYearSelected}
                                    height='3rem'
                                    selectItemId={yearSelected}
                                    backgroundColor='#fff'
                                    border='solid 1px #bfc3de'

                                />

                                <Input onBlur={handleBlur} maxLength='3' name='cvcNumber' width='30%' placeholder='CVC' value={values.cvcNumber} onChange={handleChange} height='3rem' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.bottomcontent}>
                            <div className={styles.inputarea}>
                                <span className={styles.subheaders}>İşlem Bilgisi</span>
                                <Input
                                    height='3rem'
                                    onChange={customHandleChange}
                                    placeholder='İşlem tutarı'
                                    name='amount'
                                    value={values.amount == 0 ? "" : values.amount}
                                    onBlur={handleBlur}
                                    error={touched.amount && errors.amount}
                                    type='text'
                                    isMasked={true}
                                    maskedOptions={currencyMask}
                                />
                                <Input
                                    placeholder="İşlem açıklaması giriniz"
                                    onChange={handleChange}
                                    name='description'
                                    value={values.description}
                                    height='3rem'
                                />
                            </div>
                            <div className={styles.inputarea}>
                                <span className={styles.subheaders}>Taksit Miktarı</span>
                                <InstallmentArea items={installmentsAndCommissions} onChange={handleInstallment} />
                                <div className={cx(styles.info, styles.primary)}>
                                    {
                                        <>
                                            <CheckBox
                                                text='Komisyon oranını tutara ekle'
                                                height='3rem'
                                                isClicked={setIncludeCommission}
                                                checked={includeCommission}
                                                id="linkCheckBox"
                                                disabled={isInstallment > 1 ? false : true}
                                            />
                                            <div style={{ width: "100px" }}></div>
                                        </>
                                    }
                                    <div className={cx(styles.info, styles.primary)} >
                                        <div className={styles.infolabel}>
                                            Hesaba Geçecek Net Tutar
                                        </div>
                                        <div className={styles.pricelabel}>
                                            <PriceLabel price={price ?? "00,00"} size='2' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span style={{
                        color: 'red'
                    }}>
                        {emptyGroupError}
                    </span>
                    <div className={styles.footer}>
                        <Button onClick={handleSubmit} type='submit' fontSize='1.3rem' width='100%' height='3.5rem' text='Ödeme Al' disabled={disablePost} />
                    </div>
                    <div className={styles.banklist}>
                        <BankList />
                    </div>
                </section>
            </form>
    )
}

export default DirectPayment