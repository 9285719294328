// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountedforsmp_searcharea__bJe1E {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 25%;\n    height: 15px;\n}\n\n\n.accountedforsmp_biglabel__lmr-u {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.accountedforsmp_normallabel__ut4X6 {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n.accountedforsmp_chartcontainer__kfeVa {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.accountedforsmp_info__VEsqz {\n    display: flex;\n    flex-direction: row;\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/AccountedFor/SmallMarketPlace/Standard/accountedforsmp.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".searcharea {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 25%;\n    height: 15px;\n}\n\n\n.biglabel {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.normallabel {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n.chartcontainer {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.info {\n    display: flex;\n    flex-direction: row;\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searcharea": "accountedforsmp_searcharea__bJe1E",
	"biglabel": "accountedforsmp_biglabel__lmr-u",
	"normallabel": "accountedforsmp_normallabel__ut4X6",
	"chartcontainer": "accountedforsmp_chartcontainer__kfeVa",
	"info": "accountedforsmp_info__VEsqz"
};
export default ___CSS_LOADER_EXPORT___;
