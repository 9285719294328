import React, { useState, useEffect } from 'react';
import styles from './modal.module.css';
import cx from 'classnames';
import Cardboard from '../Cardboard';
import Button from '../Button';
import logo from '../../../assets/img/header_logo.svg';
import CloseIcon from 'components/icons/CloseIcon';

const Modal = ({ clickEvent, callbackEvent, body: Body, modalStyle }) => {
    const [dynamicModalStyle, setDynamicModalStyle] = useState(modalStyle)
    const { scrollHeight: height, clientWidth: width } = document.body;
    // useEffect(()=>{
    //     console.log('acildim')
    // },[])
    return (
        <>
            <section style={{ width: clickEvent && width, height: clickEvent && height }} className={cx(styles.modal, clickEvent && styles.active)}>
                <div className={styles.modalcontainer}>
                    <div className={styles.header}>
                        <img src={logo} alt="" />
                        <Button onClick={() => callbackEvent(false)} text={<CloseIcon color='#fff' />} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.body}>
                        <Cardboard style={{flexBasis:'0',boxShadow:'none'}}
                            >
                            <div className={styles.bodycontent}>
                                {React.cloneElement(Body, { setDynamicModalStyle }, null)}
                            </div>
                            <div className={styles.bannerarea}>REKLAM ALANI</div>
                        </Cardboard>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Modal;
