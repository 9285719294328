import { useState, useRef, useEffect } from 'react';
import styles from './inputrange.module.css';
import Input from 'components/Global/Input';
import RangeIcon from 'components/icons/RangeIcon';
import useOutside from 'hoc/CustomOutsideHook';
import CurrencyInput from './currencyInput';

const InputRange = ({ width, height, text, fontSize, firstPlaceHolder, secondPlaceHolder, callback }) => {
    
    const [rangeText, setRangeText] = useState("-");
    const [clicked, setClicked] = useState(false);
    const [firstVal, setFirstVal] = useState();
    const [secondVal, setSecondVal] = useState();
    const btnRef = useRef();
    const dropdownRef = useRef();
    const handleClick = () => {
        setClicked(!clicked);
    }
    useEffect(() => {
        setRangeText(firstVal + '-' + secondVal);
        callback({ firstVal, secondVal });
    }, [firstVal, secondVal])
    useOutside(btnRef, dropdownRef, () => {
        setClicked(false);
    })



 

    // const defaultMaskOptions = {
    //     suffix: '',
    //     includeThousandsSeparator: true,
    //     thousandsSeparatorSymbol: '.',
    //     allowDecimal: true,
    //     decimalSymbol: ',',
    //     decimalLimit: 2,
    //     integerLimit: 13,
    //     allowNegative: false,
    //     allowLeadingZeroes: false,
    //     prefix: '',
    // }
    // const currencyMask = createNumberMask({
    //     ...defaultMaskOptions,
    // })

    // for(var i = 0; i<firstVal.length; i++){
    //     if(firstVal[i] == Number){
    //         //Bir array içerisine değer son satıra atanacak. 
    //     }
    //     else{
    //         continue;
    //     }
    // }

    return (
        <>
            <button ref={btnRef} onClick={handleClick} className={styles.inputrange} style={{ width, height, fontSize }}>
                {firstVal != undefined && firstVal != 0 && secondVal != undefined && secondVal != 0 ? (rangeText != "-" ? rangeText : text) : "İşlem tutar aralığı gir"}
            </button>
            {clicked &&
                <div ref={dropdownRef} className={styles.rangedropdown} style={{
                    left: btnRef?.current?.offsetLeft,
                    top: btnRef?.current?.offsetTop + btnRef?.current?.offsetHeight + 5,
                    width: btnRef?.current?.clientWidth,
                    height: '2rem',
                }} >
                    <div className={styles.first}>
                        <CurrencyInput
                            placeholder={firstPlaceHolder}
                            decimalsLimit={2}
                            onChange={(e) => setFirstVal((e.target.value).replace(/,/g,""))}
                            value={firstVal != 0 ? firstVal : ""}
                        />
                    </div>
                    <RangeIcon width="20" height="20" />
                    <div className={styles.second}>
                        <CurrencyInput
                            placeholder={secondPlaceHolder}
                            decimalsLimit={2}
                            onChange={(e) => setSecondVal((e.target.value).replace(/,/g,""))}
                            value={secondVal != 0 ? secondVal : ""}
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default InputRange;
