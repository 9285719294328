import CopyIcon from 'components/icons/CopyIcon'
import { useState, useEffect, useRef } from 'react'
import styles from './inputshare.module.css'
import ToolTip from 'components/Global/ToolTip'
import WhatsappButton from '../WhatsappButton'

const InputShare = ({ value = "0",whatsappNumber, whatsappMessage }) => {

  const ref = useRef();
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [currentRef, setCurrentRef] = useState(ref);
  const [url, setUrl] = useState();

  useEffect(() => {
    setUrl(window.location.host + "/SharedPaymentLink?link=" + value)
  }, [])
  const handleCopy = _ => {
    console.log(ref)
    navigator.clipboard.writeText(url)
    setCurrentRef(ref)
    setTooltipVisibility(true)
    if (!tooltipVisibility) {
      setTimeout(() => {
        setTooltipVisibility(false)
      }, 1000)
    }
  }

  return (
    <>
      <div className={styles.sharecontainer}>
        <input className={styles.input} type="text" value={url} disabled='true' />
        <WhatsappButton message={whatsappMessage} phoneNumber={whatsappNumber} />
        <div ref={ref} onClick={handleCopy} className={styles.copy}>
          <CopyIcon />
        </div>
      </div>
      <ToolTip text='Kopyalandı!' visibility={tooltipVisibility} ref={currentRef} />
    </>
  )
}

export default InputShare