// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".willaccountedbmps_searcharea__TrDGL {\n    display: flex;\n    justify-content: space-between;\n    width: 32%;\n}\n\n\n.willaccountedbmps_biglabel__c960S {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.willaccountedbmps_normallabel__KY25x {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n\n.willaccountedbmps_chartcontainer__5CJV5 {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.willaccountedbmps_info__KDC5J {\n    display: flex;\n    flex-direction: row;\n    font-size: 16px;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/WillAccounted/SmallMarketPlace/Standard/willaccountedbmps.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;AACd;;;AAGA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".searcharea {\n    display: flex;\n    justify-content: space-between;\n    width: 32%;\n}\n\n\n.biglabel {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.normallabel {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n\n.chartcontainer {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.info {\n    display: flex;\n    flex-direction: row;\n    font-size: 16px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searcharea": "willaccountedbmps_searcharea__TrDGL",
	"biglabel": "willaccountedbmps_biglabel__c960S",
	"normallabel": "willaccountedbmps_normallabel__KY25x",
	"chartcontainer": "willaccountedbmps_chartcontainer__5CJV5",
	"info": "willaccountedbmps_info__KDC5J"
};
export default ___CSS_LOADER_EXPORT___;
