import WhatsappIcon from 'components/icons/WhatsappIcon';
import React from 'react'
import ReactWhatsapp from 'react-whatsapp';

const WhatsappButton = ({ phoneNumber, message }) => {
    return (
            <ReactWhatsapp style={{border:'none',backgroundColor:'inherit'}} number={'90'+ phoneNumber} message={message}>
                <WhatsappIcon/>
            </ReactWhatsapp>
    )
}

export default WhatsappButton