import Container from 'components/Global/Container'
import FilterArea from 'components/Global/FilterArea'
import Button from 'components/Global/Button'
import styles from './justpayment.module.css'
import Card from 'components/Global/Card'
import ListContainer from 'components/Global/ListContainer'
import Pagination from 'components/Global/Pagination'
import List from 'components/Global/List'
import RightIcon from 'components/icons/RightIcon'
import React, { useRef } from 'react'
import Cardboard from 'components/Global/Cardboard'
import SwitchButton from 'components/Global/SwitchButton'
import { useState } from 'react'
import Modal from 'components/Global/Modal'
import { useEffect } from 'react'
import api from 'store/api/actions'
import CloseIcon from 'components/icons/CloseIcon'
import Payment from 'components/Global/Forms/Payment'
import LinkPayment from 'components/Global/Forms/Payment/LinkPayment'
import PaymentSuccess from 'components/Global/Forms/Payment/PaymentSuccess'
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions'
import { Link, useSearchParams } from 'react-router-dom'
import CreditCardIcon from 'components/icons/CreditCardIcon'
import LinkPaymentIcon from 'components/icons/LinkPaymentIcon'
import DateLabel from 'components/Global/DateLabel'
import Label from 'components/Global/Label'
import TablePriceLabel from 'components/Global/TablePriceLabel'
import StatusLabel from 'components/Global/StatusLabel'
import GetPaymentInfo from 'hoc/GetPaymentInfo'
import CustomLoadingBox from 'hoc/CustomLoadingBox'
import DateTimePicker from 'components/Global/DateTimePickerv2'
import BannerArea from 'components/Global/BannerArea'
import TripleButton from 'components/Global/TripleButton'
import NewPaymentGroup from 'components/Global/Forms/Payment/NewPaymentGroup'
import PobUp from 'components/Global/PopUp/PobUp'
import DashboardCollapse from 'components/Global/List/CollapseForDashboard'
import { useMediaQuery } from "react-responsive";
import DashboardResponsive from "components/Responsive/Dashboard";

const GetDate = () => {
    var locale = "tr-TR";
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var monthName = today.toLocaleString(locale, { month: "long" });
    var yyyy = today.getFullYear();
    var time = today.getHours() + ":" + (String(today.getMinutes()).padStart(2, "0"));

    return { dd, mm, yyyy, monthName, time };
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

const JustPayment = () => {
    const now = GetDate();

    const [switchValue, setSwitchValue] = useState(1);
    const [modalClick, setModalClick] = useState(false);
    const [isLoading, setIsLoading] = useState();
    const [isLinkCreated, setLinkCreated] = useState(false); //link olusturuldu mu
    const [isSavedCustomer, setSavedCustomer] = useState(false); //kayitli kullaniciya link gonderildi mi
    const [startTodayDate, setStartTodayDate] = useState()
    const [endTodayDate, setEndTodayDate] = useState()
    const [listData, setListData] = useState()
    const [customerId, setCustomerId] = useState(0);
    const [tableData, setTableData] = useState({});
    const [linkOrDirect, setLinkOrDirect] = useState(0); //1 direct, 2 link
    const [linkUrl, setLinkUrl] = useState();
    const [endsDate, setEndsDate] = useState() //Vade Bitiş tarihi
    const [waitingTotalTransactionAmount, setWaitingTotalTransactionAmount] = useState()
    const [waitingTotalTransactionCount, setWaitingTotalTransactionCount] = useState()
    // const [cancelAndRefundTotalTransactionAmount, setCancelAndRefundTotalTransactionAmount] = useState()
    // const [cancelAndRefundTotalTransactionCount, setCancelAndRefundTotalTransactionCount] = useState()
    const [unSuccessfulTotalTransactionAmount, setUnSuccessfulTotalTransactionAmount] = useState()
    const [unSuccessfulTotalTransactionCount, setUnSuccessfulTotalTransactionCount] = useState()
    const [successfulTotalTransactionAmount, setSuccessfulTotalTransactionAmount] = useState()
    const [successfulTotalTransactionCount, setSuccessfulTotalTransactionCount] = useState()
    //#region Paggination State
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState([[]]);
    const [pageCount, setPageCount] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    const [pageNumber, setPageNumber] = useState(1)
    const [paymentResponseListTotalCount, setPaymentResponseListTotalCount] =
        useState(0);
    //#endregion

    const [paymentInfo, setPaymentInfo] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchData, setSearchData] = useState()
    const [cardSearchData, setCardSearchData] = useState();
    const [isGroupAdd, setIsGroupAdd] = useState(false);
    const [popUpClick, setPopUpClick] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const [sendText, setSendText] = useState("hatırlatma")
    const [isActive, setIsActive] = useState(false)
    const [currentRef, setCurrentRef] = useState()
    const [item, setItem] = useState()
    const [customerName, setCustomerName] = useState()
    const refs = useRef([])
    const [popupPosition, setPopupPosition] = useState("")
    const [cardResponseData, setCardResponseData] = useState()
    const [termDayData, setTermDayData] = useState()


    useEffect(() => {
        GetPaymentInfo(setPaymentInfo, searchParams, setModalClick);
        todaysDate();

        api
            .Helpers()
            .GetTermDay({})
            .then((response) => {
                if (checkNullOrEmpty(response.data.data.termDay)) {
                    // console.log('response Term Day', response.data.data.termDay)
                    setTermDayData(response.data.data.termDay);
                }
            })
            .catch((err) => {
                console.log("getDealer Hatası : ", err?.response?.data);
            });

        killLoading();
    }, [])

    const killLoading = () => {
        setTimeout(() => {
            setIsLoading(false);
            console.log("Kill Loading");
        }, 4000);
    }

    useEffect(() => {
        GetPaymentsFromApi();
    }, [endsDate])

    useEffect(() => {
        getCardData()
    }, [cardSearchData])

    useEffect(() => {
        setCardSearchData({ startTransactionDate: startTodayDate, endTransactionDate: endTodayDate })
        if (endTodayDate !== undefined && endTodayDate !== null) {
            // console.log("end Date:", endTodayDate)
            getCardData()
        }
    }, [endTodayDate])

    useEffect(() => {
        if (checkNullOrEmpty(pageNumber)) {
            setSearchData({ ...searchData, pageNo: pageNumber });
        }
    }, [pageNumber])

    useEffect(() => {
        if (checkNullOrEmpty(itemsPerPage)) {
            setSearchData({ ...searchData, limit: itemsPerPage });
        }
    }, [itemsPerPage])

    useEffect(() => {
        if (checkNullOrEmpty(searchData)) {
            GetPaymentsFromApi();
        }
    }, [searchData]);

    useEffect(() => {
        setPageCount(Math.ceil(paymentResponseListTotalCount / itemsPerPage));
    }, [paymentResponseListTotalCount, itemOffset, itemsPerPage]);

    //Pagination
    useEffect(() => {
        if (checkNullOrEmptyArray(listData)) {
            setCurrentItems(
                listData?.map((item, i) => [
                    console.log("item", item),
                    setCartAndPaymentLinkColor(item),
                    <DateLabel
                        time={setTimeFormat(item.transactionDate)}
                        date={setDateFormat(item.transactionDate)}
                    />,
                    <Label text={item.customerName} />,
                    <TablePriceLabel price={item.amountAndCurrencyCode} />,
                    setPaymentStatusDisplay(item),
                    item.details.paymentStatusId == 2 && item.details.paymentTypeId == 2 ? setLinkPaymentButtonStatus(item, i) : setLinkPaymentButtonStatus(item, i), //is refund olayını getireceksin başarılı işlemlerde
                    // her şekilde triple button gösteriyor.
                    {
                        details: item.details,
                    },
                ]));

        } else {
            setCurrentItems([[]]);
        }

    }, [listData, itemOffset, itemsPerPage]);

    useEffect(() => {
        // console.log(switchValue);
        if (switchValue == 2) setModalClick(value => !value)
        // console.log(modalClick);
    }, [switchValue])

    useEffect(() => {
        if (!modalClick) setSwitchValue(1)
    }, [modalClick])

    useEffect(() => {
        if (checkNullOrEmpty(deleteId)) {
            if (sendText == "hatırlatma") {
                ReminderCheck(deleteId)
            } else if (sendText == "yenileme") {
                RefreshCheck(deleteId)
            } else {
                DeleteCheck(deleteId)
            }
            setIsActive(false)
        }
        if (popUpClick) {
            console.log("popup false useeffect")
            setPopUpClick(false)
        }
    }, [popUpClick])

    useEffect(() => {
        // console.log("Link call back")            // CallBack Arıyorum !!
        GetPaymentsFromApi()
    }, [linkUrl])

    const GetPaymentsFromApi = () => {
        setIsLoading(true)

        api.PaymentAuthorizedUser().PaymentTransactionList(searchData).then((response) => {
            //console.log("SubDealerPaymentList Response : ", response.data.data)
            // console.log("Ödeme Deneme Get PaymentsFromApi")
            setPaymentResponseListTotalCount(response?.data?.data.paymentTransactionListTotalCount);
            if (checkNullOrEmptyArray(response.data.data.paymentList)) {
                setListData(response.data.data.paymentList)
            }
            else {
                setCurrentItems([[]]);
            }
        }).catch((error) => {
            setCurrentItems([[]]);
            console.log("error", error?.response?.data);
        }).finally(() => { setIsLoading(false) });
    }

    const willAccountedDateFormat = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate() + termDayData);
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const accountedForDateFormat = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate() - termDayData);
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const todaysDate = () => {
        var startDate = new Date();
        startDate.setDate(startDate.getDate());
        startDate.setUTCHours(0, 0, 0, 0);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        endDate.setUTCHours(23, 59, 58, 999);
        // console.log("Start : ", start)
        // console.log("End : ", end)
        if (isValidDate(startDate) && isValidDate(endDate)) {
            setStartTodayDate(startDate);
            setEndTodayDate(endDate);
        }
    }

    const getCardData = () => {
        if (checkNullOrEmpty(cardSearchData)) {
            api.Dashboard().GetValuesForUser({ startTransactionDate: startTodayDate, endTransactionDate: endTodayDate }).then((response) => {
                console.log("GetValuesForUser Response : ", response.data.data)

                // setCancelAndRefundTotalTransactionCount(response.data.data.cancelAndRefundTotalTransactionCount)
                // setCancelAndRefundTotalTransactionAmount(response.data.data.cancelAndRefundTotalTransactionAmount)
                setWaitingTotalTransactionCount(response.data.data.waitingTotalTransactionCount)
                setWaitingTotalTransactionAmount(response.data.data.waitingTotalTransactionAmount)
                setUnSuccessfulTotalTransactionAmount(response.data.data.unSuccessfulTotalTransactionAmount)
                setUnSuccessfulTotalTransactionCount(response.data.data.unSuccessfulTotalTransactionCount)
                setSuccessfulTotalTransactionAmount(response.data.data.successfulTotalTransactionAmount)
                setSuccessfulTotalTransactionCount(response.data.data.successfulTotalTransactionCount)
                setCardResponseData(response.data.data)

            }).catch((error) => {
                console.log("error", error?.response?.data);
            }).finally(() => {
                GetPaymentsFromApi()
            });
        }
    }

    const setDateFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var year = date.slice(0, 4);
            var month = date.slice(5, 7);
            var day = date.slice(8, 10);
            return day + "." + month + "." + year;
        }
    };

    const setTimeFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var time = date.slice(11, 16);
            return time;
        }
    };

    //#region RefreshLink-ReminderSend-Cancal handler
    const handleRefreshLinkButtonClick = (id) => {
        api
            .PaymentTransaction()
            .RefreshPaymentRequest({ paymentIds: id })
            .then((response) => {
                GetPaymentsFromApi();
            })
            .catch((error) => {
                console.log("error", error?.response?.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleReminderSendButtonClick = (id) => {
        api
            .PaymentTransaction()
            .PaymentRequestReminder({ paymentIds: id })
            .then((response) => {
                GetPaymentsFromApi();
            })
            .catch((error) => {
                console.log("error", error?.response?.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCancelButtonClick = (id) => {
        api
            .PaymentTransaction()
            .CancelPaymentLinkRequest({ paymentIds: id })
            .then((response) => {
                GetPaymentsFromApi();
            })
            .catch((error) => {
                console.log("error", error?.response?.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    //#endregion

    const handleCallBack = (item) => {
        console.log("item : ", item) // iteme bak geri dönüş olarak birşey geliyor mu eklenirse kapatacak by alanı 
        if (item.addSuccess) {
            setIsGroupAdd(false)
        }
        if (item.wantAdd) {

            setIsGroupAdd(true)
        }

    }

    const handleModalClick = (status) => {
        setModalClick(status);

        //modal kapandığında verileri yenile (apiden tekrar getir)
        if (status == false) {
            GetPaymentsFromApi();
        }
    }

    //Paggination
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % paymentResponseListTotalCount;
        setItemOffset(newOffset);
        setPageNumber(event.selected + 1);
    };

    const handleSwitch = (e) => {
        setSwitchValue(e)
    }

    const setLinkPaymentButtonStatus = (row, i) => {

        if (row.paymentTypeId === 2) {

            if (row.paymentStatusId === 1 || row.paymentStatusId === 9) {

                return <>
                    <TripleButton

                        secondOnClick={() => { ReminderCheck([row.paymentId], i) }}

                        thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
                    />
                </>
            }

            return <TripleButton

                firstOnClick={() => { RefreshCheck([row.paymentId], i) }}

                thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
            />

        }
    }

    //selectedDateRange
    const handleDateRangeChange = (value) => {
        let startDate = new Date(value.startDate).toLocaleDateString();
        let endDate = new Date(value.endDate).toLocaleDateString();

        if (value.endDate != null) {
            setSearchData({
                ...searchData,
                startTransactionDate: setDateTimeFormat(startDate),
                endTransactionDate: setDateTimeFormat(endDate),
            });
        }
    };

    const setDateTimeFormat = (dataTime) => {
        var day = dataTime.slice(0, 2);
        var month = dataTime.slice(3, 5);
        var year = dataTime.slice(6, 10);
        return year + "-" + month + "-" + day + "T00:00:00.000Z";
    };

    const cardText = (paymentStatusId) => {
        if (paymentStatusId === 1) {
            return (
                <>
                    <p>Zaman Aşımı + Reddedilen İşlemler</p>
                    <p>Bugün 00:00 itibariyle</p>
                </>
            );
        }
        else if (paymentStatusId === 2) {
            return (
                <>
                    <p>İptal iade işlemleri düşülen tutardır.</p>
                    <p>Bugün 00:00 itibariyle</p>
                </>
            );
        }
        return "Bugün 00:00 itibariyle";
    };

    const setCartAndPaymentLinkColor = (item) => {
        const statusList = [
            { id: 1, text: "Beklemede" },
            {
                id: 2,
                text: "Başarılı",
            },
            { id: 3, text: "Başarısız" },
            { id: 4, text: "Müşteri Reddi" },
            { id: 5, text: "Zaman Aşımı" },
            { id: 6, text: "Banka Reddi" },
            { id: 7, text: "İptal" },
            { id: 8, text: "İade" },
            { id: 9, text: "Beklemede" },
        ];
        const status = statusList.find((a) => a.id == item?.paymentStatusId);
        let color =
            status?.id == 2
                ? "#1ebcc8"
                : status?.id > 2 && status?.id < 9
                    ? "#ff5c5c"
                    : "#000D89";

        return item.paymentTypeId == 1 ? (
            <CreditCardIcon color={color} />
        ) : (
            <LinkPaymentIcon color={color} />
        );
    };

    const onHoldDateFormat = (dateTime) => {
        const date1 = Date.now();
        const date2 = Date.parse(dateTime);
        var hours = Math.abs(date1 - date2) / 36e5;
        if (hours < 25) {
            return Math.floor(hours) + " saat";
        }
        if (checkNullOrEmpty(dateTime)) {
            return setTimeFormat(dateTime) + ", " + setDateFormat(dateTime);
        }
    };

    const setPaymentStatusDisplay = (row) => {
        const statusList = [
            { id: 1, text: "Beklemede", detail: onHoldDateFormat(row.linkExpireDate) },
            {
                id: 2,
                text: "Başarılı",
                detail:
                    setTimeFormat(row.transactionDate) +
                    ", " +
                    setDateFormat(row.transactionDate),
            },
            { id: 3, text: "Başarısız", detail: row.name },
            { id: 4, text: "Müşteri Reddi", detail: row.name },
            { id: 5, text: "Zaman Aşımı", detail: row.name },
            { id: 6, text: "Banka Reddi", detail: row.name },
            { id: 7, text: "İptal", detail: row.name },
            { id: 8, text: "İade", detail: row.name },
            { id: 9, text: "Beklemede", detail: onHoldDateFormat(row.linkExpireDate) },
        ];

        const status = statusList.find((a) => a.id == row?.paymentStatusId);
        const success = status?.id == 2 ? true : false;
        const failure = status?.id > 2 && status?.id < 9 ? true : false;
        return (
            <StatusLabel
                text={status?.text}
                detail={status?.detail}
                success={success}
                failure={failure}
            />
        );
    };

    const handlePopUp = (status) => {
        setPopUpClick(status)
        // console.log("Handle popup Status : ", status)
        setIsActive(false)
    }

    const DeleteCheck = (id, listId) => {
        setSendText("iptal")
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        const ref = refs.current[listId]
        console.log("current ref : ", ref)
        setCurrentRef(ref)
        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        // console.log(" Delete :", popUpClick)

        if (popUpClick) {
            console.log("Delete Success")
            handleCancelButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            console.log("Delete Cancel ")
        }
    }

    const RefreshCheck = (id, listId) => {
        setSendText("yenileme")
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        const ref = refs.current[listId]
        setCurrentRef(ref)
        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        console.log("Refresh Delete :", popUpClick)

        if (popUpClick) {
            console.log("Refresh Success")
            handleRefreshLinkButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            console.log("Delete Cancel ")
        }
    }


    const ReminderCheck = (id, listId) => {
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        setSendText("hatırlatma")
        const ref = refs.current[listId]
        setCurrentRef(ref)

        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        console.log("Delete :", popUpClick)

        if (popUpClick) {
            handleReminderSendButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            // console.log("Delete Cancel ")
        }
    }

    const filterItems = [
        <div style={{ width: "5px" }}></div>,
        <DateTimePicker
            onSelected={handleDateRangeChange}
            width="235px"
            fontFamily="Metropolis-bold"
            fontSize="12px"
        />,
    ];

    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    return (
        isMobile ? <>

            <DashboardResponsive
                role="jp"
                cardResponseData={cardResponseData}
                willAccountedDateFormat={willAccountedDateFormat}
                accountedForDateFormat
            />
        </> :
            <>
                <CustomLoadingBox isLoading={isLoading} />
                <FilterArea items={filterItems} width="3%" />
                <Container minHeight="100%" columnGap='20px' marginTop='20px'>
                    <Modal
                        modalStyle={
                            {
                                boxShadow: 'none',
                                flexBasis: '70%',
                                width: '60%',
                                alignItems: 'stretch',
                            }
                        }
                        clickEvent={modalClick}
                        callbackEvent={handleModalClick}
                        body={<Payment paymentInfo={paymentInfo} modalClicked={modalClick} twice={false} paymentType={2} />}
                    />
                    <div className={styles.leftside}>
                        <div className={styles.cardarea}>
                            <div className={styles.leftcards}>
                                <div className={styles.card}>
                                    <Card
                                        style={{ height: '100%' }}
                                        headText='Başarılı İşlem Tutarı'
                                        subText={cardText(2)}
                                        count={successfulTotalTransactionCount + ' adet işlem'}
                                        price={successfulTotalTransactionAmount}
                                        color="#1ebcc8"
                                        btnBackground="#e8f8f9"
                                        href={`/transactions`}
                                        state={{
                                            dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                            paymentStatusId: 2,
                                        }}
                                    />
                                </div>
                                <div className={styles.card}>
                                    <Card
                                        style={{ height: '100%' }}
                                        headText='Başarısız İşlemler'
                                        subText={cardText(1)}
                                        count={unSuccessfulTotalTransactionCount + ' adet işlem'}
                                        price={unSuccessfulTotalTransactionAmount}
                                        color="#ff5c5c"
                                        btnBackground='#fee'
                                        href={"/transactions"}
                                        state={{
                                            dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                            paymentStatusIdsArray: [3, 4],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.rightcards}>
                                <div className={styles.card}>
                                    <Card
                                        style={{ height: '100%' }}
                                        headText='Bekleyen İşlemler'
                                        subText={cardText()}
                                        count={waitingTotalTransactionCount + ' adet işlem'}
                                        price={waitingTotalTransactionAmount}
                                        color='#000d89'
                                        btnBackground='#f5f7f9'
                                        href={"/transactions"}
                                        state={{
                                            dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                            paymentStatusId: 1,
                                        }}
                                    />
                                </div>
                                <div className={styles.card}>
                                    <BannerArea />
                                </div>
                            </div>
                        </div>

                        <div className={styles.listarea}>
                            <div className={styles.leftcontent}>
                                <div className={styles.headgroup}>
                                    <div className={styles.textarea}>
                                        <div className={styles.textsection}>
                                            <span className={styles.header}>İşlem Listesi</span>
                                            <span className={styles.headsubtext}>Bugün 00:00 itibariyle</span>
                                        </div>
                                        <a className={styles.headbtn} href="#">
                                            <RightIcon color="#000d89" />
                                        </a>
                                    </div>
                                </div>
                                <Cardboard style={{ width: '96%', minHeight: '500px', marginBottom: '2%' }}>
                                    <ListContainer>
                                        <Pagination
                                            width="20%"
                                            handlePageClick={handlePageClick}
                                            pageCount={pageCount}
                                            setItemsPerPage={setItemsPerPage}
                                            infoCount={paymentResponseListTotalCount}
                                            itemsPerPage={itemsPerPage}
                                            listDataLength={listData?.length}
                                        />

                                        <PobUp
                                            callbackButtonEvent={handlePopUp}
                                            clickEvent={popUpClick}
                                            isCustomer={true}
                                            isActive={isActive}
                                            position={popupPosition}
                                            ref={currentRef}
                                            headerName={customerName}
                                            content={item?.modifiedDate == null ? `isimli kişiye ${sendText} göndermek istermisiniz ?` : `isimli müşteriye en son ${setTimeFormat(item?.modifiedDate) + " " + setDateFormat(item?.modifiedDate)} zamanında ${sendText} gönderildi. Tekrar ${sendText} göndermek ister misiniz?`}
                                            dimensionsAdder="true"
                                        // dx={300}
                                        // dy={400}
                                        />

                                        <List
                                            collapsed
                                            isDashboard={true}
                                            CollapseComponent={<DashboardCollapse />}
                                            columnNames={[
                                                "",
                                                "",
                                                "İŞLEM SAATİ VE TARİHİ",
                                                "MÜŞTERİ İSMİ",
                                                "İŞLEM TUTARI",
                                                "İŞLEM DURUMU",
                                                "",
                                                "",
                                                "",

                                            ]}
                                            data={currentItems}
                                            ref={refs}
                                        />
                                    </ListContainer>
                                </Cardboard>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightside}>
                        <div className={styles.rightcontent}>
                            <div className={styles.formcontainer}>
                                {(() => {
                                    if (isLinkCreated) {
                                        return (

                                            <div className={styles.resultarea}>
                                                <div className={styles.resulthead}>
                                                    <Button
                                                        width="50px"
                                                        text={<CloseIcon color="#fff" />}
                                                        onClick={() => setLinkCreated(false)}
                                                    />
                                                </div>
                                                <PaymentSuccess
                                                    responsePath={linkOrDirect}
                                                    savedCustomer={isSavedCustomer}
                                                    customerId={customerId}
                                                    tableData={tableData}
                                                    link={linkUrl} />
                                            </div>
                                        )
                                    }
                                    else if (switchValue === 1 && !isLinkCreated) {
                                        return <>
                                            <div className={styles.paymentarea}>
                                                {

                                                    isGroupAdd ?
                                                        <NewPaymentGroup callback={handleCallBack} />
                                                        :
                                                        <>
                                                            <SwitchButton
                                                                style={{ height: '3rem' }}
                                                                selectedValue={switchValue}
                                                                callback={handleSwitch}
                                                                items={
                                                                    [
                                                                        {
                                                                            id: '1',
                                                                            text: 'Linkle Ödeme Al'
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            text: 'Kartla Ödeme Al'
                                                                        }
                                                                    ]
                                                                }
                                                                isPayment={true}
                                                            />
                                                            <LinkPayment
                                                                setPaymentSuccess={setLinkCreated}
                                                                savedCustomer={isSavedCustomer}
                                                                setSavedCustomer={setSavedCustomer}
                                                                setCustomerId={setCustomerId}
                                                                tableData={setTableData}
                                                                successPageCallback={setLinkOrDirect}
                                                                setLinkUrl={setLinkUrl}
                                                                callback={handleCallBack}
                                                            />
                                                        </>

                                                }
                                            </div>
                                        </>
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </Container>
            </>
    )
}

export default JustPayment
