import Layout from "../../components/Layout/Layout";
// import BMPJustPayment from "../../components/TransactionReports/BigMarketPlace/JustPayment";
import BMPDealerStandard from "../../components/TransactionReports/BigMarketPlace/Dealer/Standard";
// import BMPDealerJustPayment from "../../components/TransactionReports/BigMarketPlace/Dealer/JustPayment";
import BMPSubDealerStandard from "../../components/TransactionReports/BigMarketPlace/SubDealer/Standard";
// import BMPSubDealerJustPayment from "../../components/TransactionReports/BigMarketPlace/SubDealer/JustPayment";
// import SMPJustPayment from "components/TransactionReports/SmallMarketPlace/JustPayment";
import Cookies from "universal-cookie";
import UnAuthorized from "container/UnAuthorized/UnAuthorized";
import JustPaymentReports from "components/TransactionReports/JustPayment";

export default function TransactionReports() {
  const cookies = new Cookies();
  var userRole = cookies.get('roleNumber') //Get userRole

  //Get by user role
  switch (userRole) {
    // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
    case "1":
      return (
        <Layout>
           <UnAuthorized/>
        </Layout>
      )

    // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
    case "2":
      return (
        <Layout>
          {console.log("Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
          < JustPaymentReports />
        </Layout>
      )
      
    // Küçük Firma - Pazaryeri - Standart Kullanıcı
    case "3":
      return (
        <Layout>
          <UnAuthorized/>
        </Layout>
      )
      
    // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
    case "4":
      return (
        <Layout>
          {console.log("Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
          < JustPaymentReports />
        </Layout>
      )
      
    // Bayi - Standart Kullanıcı
    case "5":
      return (
        <Layout>
          {console.log("Bayi - Standart Kullanıcı")}
          < BMPDealerStandard />
        </Layout>
      )
      
    // Bayi - Ödeme Yetkili Kullanıcı
    case "6":
      return (
        <Layout>
          {console.log("Bayi - Ödeme Yetkili Kullanıcı")}
          <JustPaymentReports />
        </Layout>
      )
      
    // Alt Bayi - Standart Kullanıcı
    case "7":
      return (
        <Layout>
          {console.log("Alt Bayi - Standart Kullanıcı")}
          < BMPSubDealerStandard />
        </Layout>
      )
      
    // Alt Bayi - Ödeme Yetkili Kullanıcı
    case "8":
      return (
        <Layout>
          {console.log("Alt Bayi - Ödeme Yetkili Kullanıcı")}
          < JustPaymentReports />
        </Layout>
      )
      
    default:
      return (
        <Layout>
          ROLE COOKIE ERROR
        </Layout>
      )
      
  }
}
