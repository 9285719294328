import React from 'react';

const SearchIcon = ({width=24, height=24,color='#000d89'}) => {
    return <symbol id="search">
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.548 0c6.366 0 11.547 5.067 11.547 11.3 0 2.713-.986 5.28-2.742 7.305l-.062.067 3.346 3.265c.398.39.469.98.211 1.438l-.098.148-.11.123a1.237 1.237 0 0 1-1.58.128l-.148-.123-3.416-3.334-.131.1a11.63 11.63 0 0 1-5.83 2.144l-.491.03-.496.01C5.182 22.602 0 17.535 0 11.302 0 5.066 5.182 0 11.548 0zm0 2.412C6.53 2.412 2.45 6.4 2.45 11.3c0 4.9 4.08 8.89 9.098 8.89 5.018 0 9.097-3.99 9.097-8.89 0-4.899-4.08-8.888-9.097-8.888z" fill={color} />
        </svg>
    </symbol>;
};

export default SearchIcon;
