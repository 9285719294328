import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import AccountedFor from './container/AccountedFor/AccountedFor';
import WillAccounted from './container/WillAccounted/WillAccounted';
import Dashboard from './container/Dashboard/Dashboard';
import TransactionReports from './container/TransactionReports/TransactionReports';
import SignIn from './container/SignIn/SignIn';
import Customer from './container/Customer/Customer';
import SubDealerManagement from './container/SubDealerManagement/SubDealerManagement';
import DealerManagement from './container/DealerManagement/DealerManagement';
import SignOut from './container/SignOut/SignOut';
import Support from './container/Support/Support';
import Profile from './container/Profile/Profile';
import UnAuthorized from './container/UnAuthorized/UnAuthorized';
import PrivateRoute from './hoc/PrivateRoute/PrivateRoute';
import ForgotPassword from './container/ForgotPassword/ForgotPassword';
import Transactions from './container/Transactions/Transactions';
import Link3DSecure from './components/SharedPaymentLink/Link3DSecure';
import SignInOidc from 'container/SignIn/SignInOidc';
import SignOutOidc from 'container/SignOut/SignOutOidc';


function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path='/SignIn' element={<SignIn />} />
        <Route path='/SignOut' element={<SignOut />} />
        <Route path="/signout-oidc" element={<SignOutOidc />} />
        <Route path="/signin-oidc" element={<SignInOidc />} />
        <Route path='/ForgotPassword' element={<ForgotPassword />} />
        <Route path='/UnAuthorized' element={<UnAuthorized />} />
        <Route path='/SharedPaymentLink' element={<Link3DSecure />} />

        <Route path='/' element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />

        <Route path='/transactions' element={
          <PrivateRoute>
            <Transactions />
          </PrivateRoute>
        } />
        <Route path='/transactionReports' element={
          <PrivateRoute>
            <TransactionReports />
          </PrivateRoute>
        } />
        <Route path='/accountedFor' element={
          <PrivateRoute>
            <AccountedFor />
          </PrivateRoute>
        } />
        <Route path='/willAccounted' element={
          <PrivateRoute>
            <WillAccounted />
          </PrivateRoute>
        } />
        <Route path='/customers' element={
          <PrivateRoute>
            <Customer />
          </PrivateRoute>
        } />
        <Route path='/dealerManagement' element={
          <PrivateRoute>
            <DealerManagement />
          </PrivateRoute>
        } />
        <Route path='/subDealerManagement' element={
          <PrivateRoute>
            <SubDealerManagement />
          </PrivateRoute>
        } />
        <Route path='/support' element={
          <PrivateRoute>
            <Support />
          </PrivateRoute>
        } />
        <Route path='/profile' element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        } />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
