import styles from './Link3DSecure.module.css';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import api from '../../store/api/actions'
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from 'components/Global/Table';
import Input from 'components/Global/Input';
import Dropdown from 'components/Global/Dropdown';
import ButtonItem from 'components/Global/Dropdown/DropdownItem';
import Button from 'components/Global/Button';
import BankList from 'components/Global/BankList';
import bgSVG from 'assets/Backgrounds/bg_link.svg';
import logo from 'assets/img/header_logo.svg';
import cx from 'classnames';
import PaymentSuccess from 'components/Global/Forms/Payment/PaymentSuccess';
import validation from './validation'
import useCreditCardItems from 'hoc/useCreditCardItems';
import { checkNullOrEmpty } from 'hoc/Conditions';
import ReactInputMask from 'react-input-mask';

const initialResponseState = {
    amount: 0,
    currency: "TL",
    customerFullName: "",
    description: "",
    installmentNumber: 0,
    paymentRequestId: ""

}

const initiAlTableData = {
    amount: "",
    installment: "",
    customerName: "",
    description: "",
    pobDealerCustomerId: "",
    userNameOrPobDealerName: ""
}

const initialPaymentInfoState = {
    amount: "",
    installmentNumber: "",
    userNameOrPobDealerName: "",
    customerName: "",
    description: "",
};

export default function Link3DSecure(props) {

    const [clientIp, setClientIp] = useState()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [responseData, setResponseData] = useState(initialResponseState)
    const [rejectedDescription, setRejectedDescription] = useState("")
    const [monthSelected, setMonthSelected] = useState('12');
    const [yearSelected, setYearSelected] = useState('2022');
    const [tableData, setTableData] = useState();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [isLoading, setIsLoading] = useState();

    const { months, years } = useCreditCardItems();
    let tryCurrency = new Intl.NumberFormat('tr-TR', {
        currency: 'TRY',
    });
    useEffect(() => {

        //#region Get Payment Info

        const hash = searchParams.get("Hash");

        // console.log("hash",hash);
        if (checkNullOrEmpty(hash)) {
            //kart ile ödeme işleminden sonra geriye sayfaya dönülmüştür 
            api
                .RedirectUrl()
                .GetPaymentInformation({ hash: hash })
                .then((response) => {
                    // console.log("RedirectUrl GetPaymentInformation", response.data.data);
                    if (checkNullOrEmpty(response.data.data)) {
                        setTableData({
                            amount: response.data.data.amount,
                            installment: response.data.data.installmentNumber,
                            customerName: response.data.data.customerName,
                            description: response.data.data.description,
                            pobDealerCustomerId: response.data.data.pobDealerCustomerId,
                            companyName: response.data.data.userNameOrPobDealerName
                        });
                        setPaymentSuccess(true)

                    }
                })
                .catch((error) => {
                    console.log("error", error?.response?.data);
                });

        }

        //#endregion

        const getUrlLinkId = searchParams.get("link")
        console.log("test : ", getUrlLinkId)
        if (getUrlLinkId != undefined && getUrlLinkId != "") {

            api.PaymentTransaction().GetPaymentRequest({ paymentRequestId: getUrlLinkId }).then((response) => {
                if (response.data.success) {
                    setResponseData(response.data.data)
                    console.log("GetPaymentRequest : ", response.data)
                }
            }).catch((error) => {
                window.location.href = "/"
            }).finally(() => {

            });
            console.log(responseData)
        }

        // Get Client Ip
        fetch('https://api.ipify.org?format=json')
            .then(response => {
                return response.json();
            }, "jsonp").then(res => {
                setClientIp(res.ip)
                // console.log("IP : ", res.ip)
            }).catch(err => console.log("Ip Get Error ", err))

    }, [])

    const handleRejected = (e) => {
        console.log('requestid', responseData.paymentRequestId);

        api.PaymentTransaction().PaymentRequestDenied({ paymentRequestId: responseData.paymentRequestId, description: rejectedDescription, clientIp: clientIp }).then((response) => {
            console.log("denied  : ", response)
            setIsLoading(true)
            setTableData({
                amount: responseData?.amount,
                installment: responseData?.installmentNumber,
                customerName: responseData?.customerFullName,
                description: responseData?.description,
                companyName: responseData?.company
            })
            setResponseData(response.data.data)
            setRejected(true)
        }).catch((error) => {
            console.log('error', error.response.data);
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue, setValues, handleReset } = useFormik({

        initialValues: {

            paymentRequestId: "",
            cardHolderFullName: "",
            cardNumber: "4183441122223339",
            expMonth: "12",
            expYear: "2022",
            cvcNumber: "000",
            clientIP: "",
            installmentNumber: 0
        },
        onSubmit: values => {

            setIsLoading(true);

            values.clientIP = clientIp;
            values.paymentRequestId = responseData?.paymentRequestId;
            values.installmentNumber = parseInt(responseData?.installmentNumber);

            api.PaymentTransaction().PaymentRequestThreeDSecurePayment(values).then((response) => {
                console.log("Values : ", values)
                if (response.status === 200) {
                    window.location.href = response.data.data.url;
                }
            }).catch((error) => {
                console.log('error', error.response.data);
            }).finally(() => {
                setIsLoading(false)
            });
        },
        validationSchema: validation
    });

    useEffect(() => {
        setFieldValue('expMonth', monthSelected);
        setFieldValue('expYear', yearSelected);
    }, [monthSelected, yearSelected])

    return (
        <>
            <div style={{ backgroundImage: `url(${bgSVG})` }} className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.headlogo}>
                        <img src={logo} />
                    </div>
                    <div className={styles.formarea}>
                        <div className={styles.formcontainer}>
                            <div className={styles.form}>
                                <div className={styles.formcontent}>
                                    {
                                        paymentSuccess ?
                                            <PaymentSuccess
                                                responsePath='1'
                                                tableData={tableData}
                                                successPageType={2} />
                                            :
                                            rejected ?
                                                <PaymentSuccess
                                                    rejected={rejected}
                                                    responsePath='1'
                                                    tableData={tableData}
                                                    successPageType={2} />
                                                :
                                                <>
                                                    <div className={styles.infoarea}>
                                                        <div className={styles.infoname}>
                                                            Sayın&nbsp;
                                                            <span className={styles.bold}>
                                                                {responseData?.customerFullName}
                                                            </span>
                                                        </div>
                                                        <span>aşağıdaki işlem için ödemeniz bekleniyor</span>
                                                    </div>
                                                    <div className={styles.tablearea}>
                                                        <Table data={
                                                            [
                                                                { name: 'Ödeme Tutarı', value: tryCurrency.format(responseData?.amount) + ` ${responseData?.currency}` },
                                                                { name: 'Taksit Miktarı', value: responseData?.installmentNumber == 0 ? '0' : responseData?.installmentNumber },
                                                                { name: 'Firma Bilgisi', value: responseData?.company },
                                                                { name: 'Açıklama', value: responseData?.description == "" ? 'Yok' : responseData?.description }
                                                            ]
                                                        } />
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className={styles.inputarea}>
                                                            <div className={cx(styles.label, styles.bold)}>Kart Bilgisi</div>
                                                            <div className={styles.inputs}>
                                                                <Input
                                                                    name='cardHolderFullName'
                                                                    onChange={handleChange}
                                                                    placeholder='Müşteri İsmi'
                                                                    width='100%'
                                                                    height='3rem'
                                                                    value={values.cardHolderFullName}
                                                                    error={touched.cardHolderFullName && errors.cardHolderFullName}
                                                                    onBlur={handleBlur}

                                                                />

                                                                <ReactInputMask
                                                                    mask="9999 9999 9999 9999"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.cardNumber}
                                                                    maskChar=" "
                                                                    error={touched.cardNumber && errors.cardNumber}
                                                                    helperText={(errors.cardNumber && touched.cardNumber)}
                                                                >
                                                                    {() => <Input id="cardNumber" name="cardNumber" placeholder="Kart Numarası" error={errors.cardNumber && touched.cardNumber} helperText={(errors.cardNumber && touched.cardNumber)} />}
                                                                </ReactInputMask>

                                                            </div>
                                                            <div className={styles.dropdowns}>
                                                                <Dropdown
                                                                    name='expMonth'
                                                                    text='Ay'
                                                                    width='33%'
                                                                    height='3rem'
                                                                    onSelected={setMonthSelected}
                                                                    selectItemId={monthSelected}
                                                                    items={months}
                                                                    backgroundColor='#fff'
                                                                    onBlur={handleBlur}
                                                                    border='solid 1px #bfc3de'

                                                                />

                                                                <Dropdown
                                                                    name='expYear'
                                                                    text='Yıl'
                                                                    width='33%'
                                                                    height='3rem'
                                                                    selectItemId={yearSelected}
                                                                    onSelected={setYearSelected}
                                                                    items={years}
                                                                    onBlur={handleBlur}
                                                                    backgroundColor='#fff'
                                                                    border='solid 1px #bfc3de'
                                                                />

                                                                <Input name='cvcNumber'
                                                                    onChange={handleChange}
                                                                    placeholder='CVC'
                                                                    width='33%'
                                                                    height='3rem'
                                                                    value={values.cvcNumber}
                                                                    onBlur={handleBlur}

                                                                />
                                                            </div>
                                                            <span className={styles.error}>{errors.expMonth || errors.expYear || errors.cvcNumber && 'Lütfen Ay/Yıl/CVC alanlarını eksiksiz doldurunuz.'}</span>

                                                            <Button disabled={isLoading} type="submit" width='100%' text='Ödeme Yap' fontSize='1.2rem' />
                                                            <Button border='solid 1px #bfc3de' backgroundColor='white' color='#000d89' fontFamily='Metropolis-Bold' backgroundImage='none' disabled={isLoading} type="button" onClick={handleRejected} text='Ödemeyi Reddet' fontSize='1.2rem' />
                                                            <BankList style={{ width: '100%' }} />
                                                        </div>
                                                    </form>
                                                </>

                                    }

                                </div>
                            </div>
                            <div className={styles.banner}>
                                REKLAM ALANI
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



