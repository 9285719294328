import React from 'react'
import styles from './statuslabel.module.css'
import cx from 'classnames'

const StatusLabel = ({ text, detail, success=false, failure=false }) => {

    return (
        <div className={styles.label}>
            <div className={cx(success && styles.success, failure && styles.failure, styles.bold)}>{text}</div>
            <div className={styles.detail}>{detail}</div>
        </div>
    )
}

export default StatusLabel