// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_input__A0Hqi{\n    display:flex;\n    box-sizing: border-box;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    padding: 1rem;\n    color: #4f5386;\n}\n\n.input_error__DNoey{\n    border-color: red !important;\n}\n\n.input_errortext__1DZh6{\n    color:red !important;\n    font-size: 11px !important;\n    padding: 2px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Input/input.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,oBAAoB;IACpB,0BAA0B;IAC1B,YAAY;AAChB","sourcesContent":[".input{\n    display:flex;\n    box-sizing: border-box;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    padding: 1rem;\n    color: #4f5386;\n}\n\n.error{\n    border-color: red !important;\n}\n\n.errortext{\n    color:red !important;\n    font-size: 11px !important;\n    padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input_input__A0Hqi",
	"error": "input_error__DNoey",
	"errortext": "input_errortext__1DZh6"
};
export default ___CSS_LOADER_EXPORT___;
