// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resizeablecard_container__3Saco {\r\n    margin: 15px;\r\n    padding: 15px 10px;\r\n    border-radius: 12px;\r\n    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);\r\n    background-color: #fff;\r\n}\r\n\r\n.resizeablecard_button__r8PoA {\r\n    display: flex;\r\n    justify-content: center;\r\n    background-image: none;\r\n    background-color: #fff;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/global/resizeablecard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kDAAkD;IAClD,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,sBAAsB;AAC1B","sourcesContent":[".container {\r\n    margin: 15px;\r\n    padding: 15px 10px;\r\n    border-radius: 12px;\r\n    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);\r\n    background-color: #fff;\r\n}\r\n\r\n.button {\r\n    display: flex;\r\n    justify-content: center;\r\n    background-image: none;\r\n    background-color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "resizeablecard_container__3Saco",
	"button": "resizeablecard_button__r8PoA"
};
export default ___CSS_LOADER_EXPORT___;
