import React, { useEffect, useState } from 'react';
import styles from './customergroupadd.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import formValidator from './FormValidator';
import { checkNullOrEmptyArray } from "hoc/Conditions";
import MultipleSelect from 'components/Global/MultipleSelect/MultipleSelect';

const initialCustomers = {
    id: "",
    birthDate: "",
    customerGroups: [],
    customerName: "",
    email: "",
    gender: 0,
    phone: ""
};

const CustomerGroupAdd = ({ handleModalClick }) => {

    const [responseErrors, setResponseErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [customers, setCustomers] = useState([])
    const [multiselectCallBack, setMultiselectCallBack] = useState()
    const [isFull, setisFull] = useState(false);

    useEffect(() => {
        (
            values.groupName == ""
            
        ) ?
          setisFull(true)
          : setisFull(false)
      })
    useEffect(() => {

        api.Customer().GetAll({}).then((response) => {
            console.log("get all Cust :", response.data.data)

            setCustomers(response.data.data.customerList.map(item => ({
                id: item.id,
                name: item.customerName,
                phone: item.phone,
                email: item.email
            })))

        }).catch((error) => {
            console.log("error", error?.response?.data);
            // setResponseErrors(error?.response?.data)
            setResponseErrors(error?.response?.data.userMessage)

        });

    }, [])

    const { handleChange, handleBlur, touched, values, handleSubmit, errors, handleReset } = useFormik({

        initialValues: {
            groupName: "",
            pobDealerCustomerIDs: []
        },

        onSubmit: values => {

            setIsLoading(true);

            if (checkNullOrEmptyArray(multiselectCallBack)) {

                values.pobDealerCustomerIDs = multiselectCallBack.map(item => item.id);
            }

            console.log("push Values", values)
            api.CustomerGroup().Create(values).then((response) => {

                handleModalClick(false);
                handleReset({});

            }).catch((error) => {
                // handle error
                console.log('error', error?.response?.data);
                setResponseErrors(error?.response?.data.userMessage);
            }).finally(() => {
                setIsLoading(false);
            });
        },
        validationSchema: formValidator
    });


    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <div className={styles.formitems}>
                <div className={styles.forminfo}>
                    Yeni Grup Ekle
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div onChange={handleChange} className={styles.inputgroup}>

                        <Input
                            width='100%'
                            id="groupName"
                            height='3rem'
                            placeholder="Grup Adı"
                            name="groupName"
                            onChange={handleChange}
                            value={values.groupName}
                            onBlur={handleBlur}
                            error={touched.groupName && errors.groupName}
                        />

                        <MultipleSelect
                            items={customers}
                            error={touched.customerFullName && errors.customerFullName}
                            maxLength='70'
                            onChange={setMultiselectCallBack}
                            height='3rem'
                            name='customerName'
                            onBlur={handleBlur}
                            placeholder='Müşteri Ekle'
                        />


                        <Button type="submit" width='100%' text="Kaydet" fontSize="1.3rem" disabled={isFull} />
                    </div>
                </form>
            </div >
            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }
        </>
    )
};

export default CustomerGroupAdd;
