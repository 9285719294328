import React from 'react'
import styles from './container.module.css';

const Container = ({ children, flexDirection, minHeight,style,rowGap,columnGap,marginTop,justifyContent}) => {
    return (
        <div className={styles.content}>
            <div className={styles.container} style={{ flexDirection,minHeight, rowGap,columnGap,marginTop,justifyContent,style}}>
                {children}
            </div>
        </div>
    )
}

export default Container
