import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ResponsiveModal from '../Modal'
import HamburgerItem from './HamburgerItem';
import styles from './hamburgeritem.module.css'

const Hamburger = ({ setIsBurgerOpen, menuList }) => {
  const [modalClick, setModalClick] = useState(true);
  const [burgerList, setBurgerList] = useState()

  useEffect(() => {
    let burgerArr = [
      <Link className={styles.menuitem} to='/support'>Destek</Link>,
      <Link className={styles.menuitem} to='/profile'>Profil</Link>,
      <Link className={styles.menuitem} to='/SignOut'>Çıkış Yap</Link>
    ];
    if (!menuList.includes("/support")) {
      menuList = menuList.concat(burgerArr);
      console.log(menuList);
    }

    setBurgerList(
      menuList?.map((item) => {
        // return React.cloneElement(item, { style: { color: '#fff' } })
        const isLastItem = item === menuList[menuList.length - 1]
        return <HamburgerItem data={item} lastItem={isLastItem} />
      })
    )

  }, [])


  const handleModalClick = (status) => {
    setModalClick(status);
    setIsBurgerOpen(false)
  }

  return (
    <>
      <ResponsiveModal

        clickEvent={modalClick}
        callbackEvent={handleModalClick}

        body={burgerList}
      />

    </>
  )
}

export default Hamburger