// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hamburgeritem_seperator__SqhiA {\r\n    width: 100%;\r\n    height: 2px;\r\n    background-color: #edeef3;\r\n    border: none;\r\n}\r\n\r\n.hamburgeritem_menuitem__ghkOk {\r\n    display: flex;\r\n    text-align: center;\r\n    flex-direction: row;\r\n    text-decoration: none;\r\n    padding-right: 90px;\r\n    color: #4f5386;\r\n    font-family: 'Metropolis-SemiBold';\r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/Layout/hamburgeritem.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;IACnB,cAAc;IACd,kCAAkC;AACtC","sourcesContent":[".seperator {\r\n    width: 100%;\r\n    height: 2px;\r\n    background-color: #edeef3;\r\n    border: none;\r\n}\r\n\r\n.menuitem {\r\n    display: flex;\r\n    text-align: center;\r\n    flex-direction: row;\r\n    text-decoration: none;\r\n    padding-right: 90px;\r\n    color: #4f5386;\r\n    font-family: 'Metropolis-SemiBold';\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"seperator": "hamburgeritem_seperator__SqhiA",
	"menuitem": "hamburgeritem_menuitem__ghkOk"
};
export default ___CSS_LOADER_EXPORT___;
