import React from 'react'

const WhatsappIcon = () => {
    return (
        <symbol id="user" >
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.129 0c1.292 0 2.602.272 3.834.79a9.72 9.72 0 0 1 3.132 2.089 9.643 9.643 0 0 1 2.104 3.11 9.569 9.569 0 0 1 .795 3.806 9.746 9.746 0 0 1-.795 3.806 9.644 9.644 0 0 1-2.104 3.11 9.72 9.72 0 0 1-3.132 2.09 9.755 9.755 0 0 1-3.834.79c-1.533 0-3.007-.347-4.42-1.031l-.352-.178-.158-.014L0 20l1.685-4.973-.02-.172-.18-.31c-.817-1.45-1.221-3.02-1.221-4.75 0-1.282.274-2.582.795-3.806a9.644 9.644 0 0 1 2.103-3.11C3.941 2.063 4.95 1.407 6.301.787A9.748 9.748 0 0 1 10.129 0zm-.048 1.296c-1.133 0-2.206.225-3.323.678-1 .407-1.878.996-2.735 1.794-.76.709-1.359 1.617-1.816 2.726-.406.986-.63 1.938-.675 2.928l-.008.373.007.365a8.286 8.286 0 0 0 1.204 4.025l.194.308.147.214-1.146 3.365 3.54-1.086.198.123c1.322.787 2.826 1.186 4.413 1.186 1.133 0 2.206-.225 3.323-.679 1-.406 1.878-.995 2.735-1.793.761-.71 1.359-1.618 1.816-2.727.457-1.109.683-2.175.683-3.3 0-1.126-.226-2.193-.683-3.302-.41-.993-1.003-1.865-1.807-2.717-.713-.756-1.628-1.35-2.744-1.803-1.117-.453-2.19-.678-3.323-.678zm-2.768 3.57c.19 0 .286.048.334.095.048.047.143.19.191.38.048.189.239.568.43 1.184.238.616.334.948.334.995 0 .19-.144.427-.478.759-.286.331-.477.52-.477.616 0 .047 0 .142.048.19.286.663.763 1.232 1.336 1.8.477.474 1.146.9 2.005 1.327.095.048.19.095.286.095.19.048.43-.142.764-.569.334-.426.572-.663.716-.663.095 0 .524.19 1.288.569.764.379 1.146.616 1.193.71 0 .048.048.095.048.19 0 .285-.095.616-.239.995-.143.332-.477.617-.954.854a3.071 3.071 0 0 1-1.336.331c-.525 0-1.337-.284-2.53-.805-.86-.38-1.623-.9-2.243-1.564A19.482 19.482 0 0 1 6.072 9.89c-.62-.948-.954-1.801-.954-2.56v-.094c.047-.806.334-1.517 1.002-2.086.19-.19.43-.284.716-.284h.477z" fill="#000D89" />
            </svg>
        </symbol>
    )
}

export default WhatsappIcon