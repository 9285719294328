// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info_container__HeI\\+M {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.info_popup__-RGge {\n  display: flex;\n  position: absolute;\n  align-items: center;\n  justify-content: center;\n  min-width: 175px;\n  max-width: 350px;\n  min-height: 55px;\n  max-height: 75px;\n  text-align: center;\n  color: white;\n  background-color: #020d89;\n  border-radius: 5px;\n  font-size: 12px;\n  line-height: 14px;\n  padding: 0 5px 0 5px;\n}\n\n.info_infoicon__9fuSE {\n  padding-left: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Info/info.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.popup {\n  display: flex;\n  position: absolute;\n  align-items: center;\n  justify-content: center;\n  min-width: 175px;\n  max-width: 350px;\n  min-height: 55px;\n  max-height: 75px;\n  text-align: center;\n  color: white;\n  background-color: #020d89;\n  border-radius: 5px;\n  font-size: 12px;\n  line-height: 14px;\n  padding: 0 5px 0 5px;\n}\n\n.infoicon {\n  padding-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "info_container__HeI+M",
	"popup": "info_popup__-RGge",
	"infoicon": "info_infoicon__9fuSE"
};
export default ___CSS_LOADER_EXPORT___;
