import React from 'react'
import UserIcon from 'components/icons/UserIcon';
import styles from './autocompleteitem.module.css';
import UserGroupIcon from 'components/icons/UserGroupIcon';
const CompleteItem = ({ data, callback, isGroup }) => {
    const handleClick = () => {
        callback({
            id: data.id,
            name: data.name
        })
    }
    return (

        <div onClick={handleClick} id={data?.id} className={styles.listitem}>
            <div className={styles.itemicon}>
                {
                    isGroup ?  <UserGroupIcon/> : <UserIcon />
                }
            </div>
            <div className={styles.itemlabel}>
                {data?.name}
            </div>
        </div>
    )
}

export default CompleteItem