import React, { useEffect, useState } from 'react';
import styles from './customergroupupdate.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import formValidator from './FormValidator';
import { checkNullOrEmptyArray } from 'hoc/Conditions';
import CustomLoadingBox from 'hoc/CustomLoadingBox';
import MultipleSelect from 'components/Global/MultipleSelect/MultipleSelect';

const initialCreateGroupsRequest = {
    groupName: "",
    pobDealerCustomerIDs: [],
};

const initialGroup = {
    id: "",
    text: ""
}

const initialSearchData = {
    customerGroupIdList: [
        "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    ],
    customerName: "string",
    limit: 0,
    pageNo: 0,
    orderBy: 0
};

var initialCustomersResponse = {
    id: "",
    customerName: "",
    phone: "",
    email: "",
    gender: 0,
    birthDate: "",
    address: "",
    customerGroups: ""
};

const CustomerGroupUpdate = ({ group, handleModalClick }) => {

    const [responseErrors, setResponseErrors] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [customers, setCustomers] = useState([])
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [multiselectCallBack, setMultiselectCallBack] = useState()
    const [isFull, setisFull] = useState(false);

    useEffect(() => {
        (
            values.groupName == ""
            
        ) ?
          setisFull(true)
          : setisFull(false)
      })
    useEffect(() => {
        //bütün müşterileri çek
        getAllCustomerDataFromApi();
        //gruptaki müşterileri çek
        getAllSelectedCustomerDataFromApi();
    }, [])

    const { handleChange, handleBlur, touched, values, handleSubmit, errors, handleReset } = useFormik({

        initialValues: {
            groupName: group.text,
            pobDealerCustomerIDs: [],
            pobDealerCustomerGroupID: group.id
        },

        onSubmit: values => {

            setIsLoading(true);

            if (checkNullOrEmptyArray(multiselectCallBack)) {

                values.pobDealerCustomerIDs = multiselectCallBack.map(item => item.id);
            }

            console.log("onSubmit", values)
            api.CustomerGroup().Update(values).then((response) => {

                handleReset({});
                handleModalClick(false);

            }).catch((error) => {
                // handle error
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data?.userMessage)
                setResponseErrors(error?.response?.data.userMessage);

            }).finally(() => {
                setIsLoading(false);
            });
        },
        validationSchema: formValidator
    });

    const getAllSelectedCustomerDataFromApi = () => {
        api.Customer()
            .GetAll({ customerGroupIdList: [group?.id] })
            .then((response) => {
                console.log("get all Cust :", response.data.data)
                if (checkNullOrEmptyArray(response.data.data.customerList)) {
                    setSelectedCustomers(response.data.data.customerList.map(item => ({
                        id: item.id,
                        name: item.customerName,
                        phone: item.phone,
                        email: item.email
                    })));
                }
                else {
                    setSelectedCustomers([]);
                }

            }).catch((error) => {
                console.log("error", error?.response?.data);
                setResponseErrors(error?.response?.data.userMessage);
            });
    }

    const getAllCustomerDataFromApi = () => {
        api.Customer()
            .GetAll({})
            .then((response) => {
                // console.log("get all Cust :", response.data.data)
                if (checkNullOrEmptyArray(response.data.data.customerList)) {
                    setCustomers(response.data.data.customerList.map(item => ({
                        id: item.id,
                        name: item.customerName,
                        phone: item.phone,
                        email: item.email
                    })));
                }
                else {
                    setCustomers([]);
                }

            }).catch((error) => {
                console.log("error", error?.response?.data);
                setResponseErrors(error?.response?.data.userMessage)
            });
    }

    const handleDeleteClick = () => {
        setIsLoading(true);
        console.log("pobDealerCustomerIDs", group?.id)
        api.CustomerGroup().Delete({ pobDealerCustomerGroupID: group?.id })
            .then((response) => {
                console.log("delete group :", response?.data?.data)
                handleModalClick(false);
            }).catch((error) => {
                console.log("error", error?.response?.data);
                setResponseErrors(error?.response?.data.userMessage)
            }).finally(() => {
                setIsLoading(false);
            }
            );
    }

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <div className={styles.formitems}>
                <div className={styles.forminfo}>
                    Grubu Düzenle
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.inputgroup}>

                        <Input
                            width='100%'
                            id="groupName"
                            height='3rem'
                            placeholder="Grup Adı"
                            name="groupName"
                            onChange={handleChange}
                            value={values.groupName}
                            onBlur={handleBlur}
                            error={touched.groupName && errors.groupName}
                        />

                        <MultipleSelect
                            items={customers}
                            error={touched.customerFullName && errors.customerFullName}
                            maxLength='70'
                            onChange={setMultiselectCallBack}
                            height='3rem'
                            name='customerName'
                            onBlur={handleBlur}
                            placeholder='Müşteri Ekle'
                            selectedItems={selectedCustomers}
                        />

                        <Button type="submit" width='100%' text="Kaydet" fontSize="1.3rem" disabled={isFull}/>

                    </div>
                </form>
                <Button type="submit" width='100%' text="Grubu Sil" fontSize="1.3rem" backgroundColor="red" color="red" onClick={handleDeleteClick} />
            </div>
            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }
        </>
    )
};

export default CustomerGroupUpdate;
