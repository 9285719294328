import RightIcon from 'components/icons/RightIcon';
import styles from './slidefilter.module.css'
import Slider from "react-slick";
import { useEffect } from 'react';

const SlideFilter = ({ items }) => { // filter items 
    const splittedItems = items?.slice(0);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.1,
        slidesToScroll: 4,

    };

    // useEffect(() => {
    //     console.log("splittedItems", splittedItems)
    // }, [splittedItems])


    return (
        <>
            <div className={styles.container}>

            </div>
            <Slider
                {...settings}
                useCSS={false}

            >
                {splittedItems?.map((item) => {
                    return (
                        <>
                            <div id={item?.props?.value} className={styles.text}>{item}</div>
                        </>
                    )
                })}</Slider>
        </>


    )
}
export default SlideFilter