import React from 'react'
import styles from './bottomsticky.module.css'

const BottomSticky = ({ children,text }) => {
    return (
        <div className={styles.sticky}>
            <div className={styles.infolabel}>{text}</div>
            <div className={styles.buttongroup}>{children}</div>
        </div>
    )
}

export default BottomSticky