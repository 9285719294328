// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bottomsticky_sticky__hyMWl {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: #fff;\n    bottom: 20px;\n    width:60%;\n    min-height: 78px;\n    margin-bottom:50px;\n    border-radius: 16px;\n    background-image: linear-gradient(to right, #000d89 0%, #6608b7 100%);\n    align-self: center;\n    position: sticky;\n    margin-top:277px;\n  }\n\n  .bottomsticky_infolabel__VsvYr{\n      margin-left: 20px;\n  }\n\n  .bottomsticky_buttongroup__ilalQ{\n      display: flex;\n      align-items: center;\n      justify-content:space-between;\n      flex-basis: 70%;\n      margin: 0 50px;\n      font-size: 18px;\n  }", "",{"version":3,"sources":["webpack://./src/components/Global/BottomSticky/bottomsticky.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,qEAAqE;IACrE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;MACI,iBAAiB;EACrB;;EAEA;MACI,aAAa;MACb,mBAAmB;MACnB,6BAA6B;MAC7B,eAAe;MACf,cAAc;MACd,eAAe;EACnB","sourcesContent":[".sticky {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: #fff;\n    bottom: 20px;\n    width:60%;\n    min-height: 78px;\n    margin-bottom:50px;\n    border-radius: 16px;\n    background-image: linear-gradient(to right, #000d89 0%, #6608b7 100%);\n    align-self: center;\n    position: sticky;\n    margin-top:277px;\n  }\n\n  .infolabel{\n      margin-left: 20px;\n  }\n\n  .buttongroup{\n      display: flex;\n      align-items: center;\n      justify-content:space-between;\n      flex-basis: 70%;\n      margin: 0 50px;\n      font-size: 18px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sticky": "bottomsticky_sticky__hyMWl",
	"infolabel": "bottomsticky_infolabel__VsvYr",
	"buttongroup": "bottomsticky_buttongroup__ilalQ"
};
export default ___CSS_LOADER_EXPORT___;
