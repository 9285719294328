import * as yup from 'yup';

const linkValidator = yup.object().shape({
  customerFullName: yup.string().when('pobDealerCustomerId', {
    is: (pobDealerCustomerId) =>
    !pobDealerCustomerId || pobDealerCustomerId?.length === 0,
    then: yup.string().max(50).required("Müşteri ya da müşteri grubu zorunludur."),
    otherwise: yup.string()
  }),
  phone: yup.string().when(['email', 'isSendEmail'], {
    is: (email,isSendEmail) => !email || email.length === 0 || email === '' && isSendEmail || email === null ,
    then: yup.string().max(50).required("lütfen en az 1 iletişim bilgisi giriniz."),
    otherwise: yup.string()
  }),
  email: yup.string().email("Email formatı hatalı.").when(['phone','isSendPhone'], {
    is: (phone,isSendPhone) => !phone || phone.length === 0 || phone === '' || !isSendPhone,
    then: yup.string().max(50).required("lütfen en az 1 iletişim bilgisi giriniz."),
    otherwise: yup.string()
  }),
  twiceClosed: yup.bool().when(['isSendEmail', 'isSendPhone'], {
    is: (isSendEmail, isSendPhone) => !isSendEmail && !isSendPhone,
    then: yup.bool().required("lütfen en az 1 iletişim bilgisi giriniz."),
    otherwise: yup.bool()
  }),
  pobDealerCustomerId: yup.string(),
  
  amount: yup.string('Lütfen rakam giriniz.').typeError('Lütfen rakam giriniz').min(1, 'Lütfen tutar giriniz.').required('Tutar zorunludur.'),
}, [['phone', 'email','isSendEmail', 'isSendPhone']]);

export default linkValidator