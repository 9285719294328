import { useState, useEffect, useRef } from 'react';
import styles from './payment.module.css';
import SwitchButton from 'components/Global/SwitchButton';
import CustomLoadingBox from "hoc/CustomLoadingBox";
import DirectPayment from './DirectPayment'
import LinkPayment from './LinkPayment'
import PaymentSuccess from './PaymentSuccess'
import { checkNullOrEmpty } from 'hoc/Conditions';

const initialPaymentInfoState = {
    amount: "",
    installmentNumber: "",
    userNameOrPobDealerName: "",
    customerName: "",
    description: "",
    pobDealerCustomerId: ""
};

const Payment = ({ setDynamicModalStyle, modalClicked, twice = true, paymentInfo }) => {
    const [switchCallback, setSwitchCallback] = useState(2);
    const [isLoading, setIsLoading] = useState();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [savedCustomer, setSavedCustomer] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const [tableData, setTableData] = useState({});
    const [linkOrDirect, setLinkOrDirect] = useState(0); //1 direct, 2 link, 3 toplu link
    const [linkUrl, setLinkUrl] = useState();
    const [directPaymentError, setDirectPaymentError] = useState();
    const [isGroup, setIsGroup] = useState();


    useEffect(() => {


        if (checkNullOrEmpty(paymentInfo)) {
            setPaymentSuccess(true);
            if (paymentInfo.status) {
                setDirectPaymentError(false);
            }
            else {
                setDirectPaymentError(true);
            }
            setLinkOrDirect(1)
            setSwitchCallback(2)
            console.log("paymentInfo", paymentInfo)
            setTableData({
                amount: paymentInfo.amount,
                installment: paymentInfo.installmentNumber,
                customerName: paymentInfo.customerName,
                description: paymentInfo.description,
                pobDealerCustomerId: paymentInfo.pobDealerCustomerId,
                companyName: paymentInfo.companyName,
            })
            setSavedCustomer(checkNullOrEmpty(paymentInfo.pobDealerCustomerId))
        }

    }, [paymentInfo])

    useEffect(() => {
        if (!modalClicked) {
            setPaymentSuccess(false)
            setSavedCustomer(false)
            setCustomerId(0)
            setTableData({})
            setLinkOrDirect(0)
            setLinkUrl(0)
        }
    }, [modalClicked])

    // useEffect(()=>{
    //     if (isGroup){
    //         setLinkOrDirect(3)
    //     }

    // },[isGroup])
    const SetPaymentView = () => {
        //eğer ödeme işleminden sonrası ise direkt success ekranını açsın
        if (paymentSuccess) {
            return (
                <PaymentSuccess
                    responsePath={linkOrDirect}
                    savedCustomer={savedCustomer}
                    customerId={customerId}
                    tableData={tableData}
                    link={linkUrl}
                    directPaymentError={directPaymentError}
                    successPageType={1}
                    style={{ width: '30rem' }} />

            );
        }

        else {
            if (switchCallback == 1) {
                return (
                    <LinkPayment
                        setPaymentSuccess={setPaymentSuccess}
                        savedCustomer={savedCustomer}
                        setSavedCustomer={setSavedCustomer}
                        setCustomerId={setCustomerId}
                        tableData={setTableData}
                        successPageCallback={setLinkOrDirect}
                        setLinkUrl={setLinkUrl}
                        isGroupPayment={setIsGroup}
                        style={{ width: '30rem' }}
                        buttonStatus={false}
                    />
                );
            }
            else if (switchCallback == 2) {
                return (<DirectPayment
                    isSuccessful={setPaymentSuccess}
                    isSavedCustomer={setSavedCustomer}
                    customerId={setCustomerId}
                    tableData={setTableData}
                    successPageCallback={setLinkOrDirect}
                    style={{ width: '55rem' }}
                    buttonStatus={false}
                />);
            }
        }
        return (<>Payment ekranında bir sıkıntı var!</>)
    }

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            {!paymentSuccess &&
                twice &&
                <div className={styles.switcharea}>
                    <SwitchButton
                        items={[
                            {
                                id: 1,
                                text: 'Linkle Ödeme Al'
                            },
                            {
                                id: 2,
                                text: 'Kartla Ödeme Al'
                            }
                        ]}
                        isPayment={true}
                        style={{ width: '70%', height: '3rem' }}
                        callback={setSwitchCallback}
                        selectedValue={switchCallback}
                    />
                </div>
            }
            {/* % kullanmak icin modalin bodysi component seviyesine cekilebilir */}
            <SetPaymentView />
        </>
    )
};

export default Payment;
