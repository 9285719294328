import React from 'react';
import styles from './filterinfo.module.css';

const FilterInfoButton = ({ width, height, fontSize,text,count,onClick,id,value}) => {
  return (
    <button onClick={onClick} id={id} data-value ={value } className={styles.filterbtn} style={{ width, height, fontSize }}>
      <div id={id} data-value ={value} className={styles.btncontainer}>
        <span id={id} data-value ={value} className={styles.infotext}>{text}</span>
        <span id={id} data-value ={value} className={styles.infocount}>{count}</span>
      </div>
    </button>
  )
};

export default FilterInfoButton;
