import Cookies from 'universal-cookie';
import Layout from '../../components/Layout/Layout';
import WillAccountedSMP from '../../components/WillAccounted/SmallMarketPlace/Standard';
import WillAccountedBMP from '../../components/WillAccounted/BigMarketPlace/Standard';
import UnAuthorized from 'container/UnAuthorized/UnAuthorized';

export default function WillAccounted() {

    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole
    console.log('cookiegetinCustomer', userRole);

    //Get by user role
    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
        case "1":
            return (
                <Layout>
                    {console.log("Büyük Firma - Pazaryeri - Standart Kullanıcı ")}
                    < WillAccountedBMP />
                </Layout>
            )
            break;
        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    <WillAccountedSMP />
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;
        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;
        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;
        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;
        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            break;

        default:
            return (
                <Layout>
                    ROLE COOKIE ERROR
                </Layout>
            )
            break;

    }
}
