import React from 'react'

function ForgotPassword() {
    return (
        <div>
            ForgotPassword
        </div>
    )
}

export default ForgotPassword
