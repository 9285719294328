import React from 'react';

const HamburgerIcon = ({ width = 24, height = 24 }) => {
    return <>
        <div>
            <symbol id="hamburgericon">
                <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.375c0-.483.392-.875.875-.875h14.25a.875.875 0 0 1 0 1.75H4.875A.875.875 0 0 1 4 6.375zm0 5.5c0-.483.392-.875.875-.875h14.25a.875.875 0 0 1 0 1.75H4.875A.875.875 0 0 1 4 11.875zm.875 4.625a.875.875 0 0 0 0 1.75h14.25a.875.875 0 0 0 0-1.75H4.875z" fill="#000D89" />
                </svg>
            </symbol>
        </div>
    </>
};

export default HamburgerIcon;