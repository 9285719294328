import React, { useEffect, useState } from 'react'
import styles from './dashboardbmps.module.css';
import Container from 'components/Global/Container';
import RightIcon from 'components/icons/RightIcon';
import PriceLabel from 'components/Global/PriceLabel';
import ListContainer from 'components/Global/ListContainer';
import List from 'components/Global/List';
import Pagination from 'components/Global/Pagination';
import Dropdown from 'components/Global/Dropdown';
import Card from 'components/Global/Card';
import FilterArea from "components/Global/FilterArea";
import Button from 'components/Global/Button';
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions';
import api from '../../../../../store/api/actions'
import { Link, useSearchParams } from 'react-router-dom';
import Modal from 'components/Global/Modal';
import Payment from 'components/Global/Forms/Payment';
import BannerArea from 'components/Global/BannerArea';
import CreditCardIcon from 'components/icons/CreditCardIcon';
import LinkPaymentIcon from 'components/icons/LinkPaymentIcon';
import Label from 'components/Global/Label';
import TablePriceLabel from 'components/Global/TablePriceLabel';
import DateLabel from 'components/Global/DateLabel';
import GetPaymentInfo from 'hoc/GetPaymentInfo';
import CustomLoadingBox from 'hoc/CustomLoadingBox';
import DateTimePicker from 'components/Global/DateTimePickerv2';
import DashboardCollapse from 'components/Global/List/CollapseForDashboard';
import { useMediaQuery } from 'react-responsive';
import DashboardResponsive from 'components/Responsive/Dashboard';

const GetDate = () => {
    var locale = "tr-TR";
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var monthName = today.toLocaleString(locale, { month: "long" });
    var yyyy = today.getFullYear();
    var time = today.getHours() + ":" + (String(today.getMinutes()).padStart(2, "0"));

    return { dd, mm, yyyy, monthName, time };
}
// Date Time Check
function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

const DealerDashboard = () => {

    const now = GetDate();
    const [subDealerData, setSubDealerData] = useState(); // filtre için dealer listesi
    const [termDayData, setTermDayData] = useState() // banka vade bügünü (int)
    const [startDate, setStartDate] = useState() //Vade Başlangıç tarihi
    const [endDate, setEndDate] = useState() //Vade Bitiş tarihi
    const [selectedSubDealerValue, setSelectedSubDealerValue] = useState()
    const [payments, setPayments] = useState()
    const [listData, setListData] = useState()
    const [waitingTotalTransactionAmount, setWaitingTotalTransactionAmount] = useState()
    const [waitingTotalTransactionCount, setWaitingTotalTransactionCount] = useState()
    const [cancelAndRefundTotalTransactionAmount, setCancelAndRefundTotalTransactionAmount] = useState()
    const [cancelAndRefundTotalTransactionCount, setCancelAndRefundTotalTransactionCount] = useState()
    const [unSuccessfulTotalTransactionAmount, setUnSuccessfulTotalTransactionAmount] = useState()
    const [unSuccessfulTotalTransactionCount, setUnSuccessfulTotalTransactionCount] = useState()
    const [successfulTotalTransactionAmount, setSuccessfulTotalTransactionAmount] = useState()
    const [successfulTotalTransactionCount, setSuccessfulTotalTransactionCount] = useState()
    const [pobSubDealerId, setPobSubDealerId] = useState([])
    const [pobSubId, setPobSubId] = useState([])
    const [modalClick, setModalClick] = useState(false);
    const [startsDate, setStartsDate] = useState()
    const [endsDate, setEndsDate] = useState()
    const [dealersData, setDealersData] = useState([]);
    const [subAndDealersData, setSubAndDealersData] = useState([]);
    const [userList, setUserList] = useState([])
    const [pageNo, setPageNo] = useState()
    const [cardResponseData, setCardResponseData] = useState()

    //#region Paggination State
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState([[]]);
    const [pageCount, setPageCount] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    //#endregion
    const [paymentInfo, setPaymentInfo] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const [cardSearchData, setCardSearchData] = useState();
    const [startTodayDate, setStartTodayDate] = useState()
    const [endTodayDate, setEndTodayDate] = useState()
    const [paymentResponseListTotalCount, setPaymentResponseListTotalCount] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [listTotalCount, setListTotalCount] = useState(0);//veritabanında aslında bulunan tam veri saysısı

    useEffect(() => {
        setIsLoading(true)

        todayStartEnd();

        GetPaymentInfo(setPaymentInfo, searchParams, setModalClick);

        // Filtre Alanı için Dealer Getir
        api.Helpers().GetAllSubDealerForFilter({}).then(response => {
            console.log('response SubDealers', response?.data.data)
            if (checkNullOrEmptyArray(response.data.data)) {
                setSubDealerData(response.data.data.map(item => ({
                    id: item.pobSubDealerId,
                    text: item.pobSubDealerName
                })));
            }
        }).catch(err => {
            console.log("getSubDealer Hatası : ", err?.response?.data);
        })

        api.User().GetAllUser({}).then((response) => {
            // console.log("response dealer", response.data.data.userList);
            if (checkNullOrEmptyArray(response.data.data.userList)) {
                setDealersData(
                    response.data.data.userList.map((item) => ({
                        id: item.userId,
                        text: item.userFullName,
                    }))
                );
            }
        })
            .catch((error) => {
                console.log("error", error?.response?.data);
            })
            .finally(() => { });

        // Banka Vadesini getir
        api.Helpers().GetTermDay({}).then(response => {
            if (checkNullOrEmpty(response.data.data.termDay)) {
                // console.log('response Term Day', response.data.data.termDay)
                setTermDayData(response.data.data.termDay);
            }
        }).catch(err => {
            console.log("getDealer Hatası : ", err?.response?.data);
        })
        killLoading();
    }, [])

    const killLoading = () => {
        setTimeout(() => {
            setIsLoading(false);
            console.log("Kill Loading");
        }, 4000);
    }

    useEffect(() => {

        if (checkNullOrEmpty(startDate) && checkNullOrEmpty(endDate)) {
            GetPaymentsFromApi()
        }
    }, [startDate, endDate, endsDate, pobSubId, userList])

    //term day a göre başlangıç ve bitiş tarihi oluştur.
    useEffect(() => {
        var start = new Date();
        start.setDate(start.getDate() - termDayData);
        start.setUTCHours(0, 0, 0, 0);

        var end = new Date();
        end.setDate(end.getDate() - termDayData);
        end.setUTCHours(23, 59, 59, 999);

        if (isValidDate(start) && isValidDate(end)) {
            setStartDate(start)
            setEndDate(end)
        }
        // console.log(start.toUTCString() + ':' + end.toUTCString());

    }, [termDayData])

    //Pagination
    useEffect(() => {
        if (checkNullOrEmptyArray(listData)) {
            setCurrentItems(
                listData.slice(itemOffset, itemOffset + itemsPerPage)
            );
        } else {
            setCurrentItems([[]]);
        }
        setPageCount(Math.ceil(payments?.length / itemsPerPage));
    }, [listData, itemOffset, itemsPerPage]);

    useEffect(() => {
        setCardSearchData({ startTransactionDate: startTodayDate, endTransactionDate: endTodayDate })
        if (endTodayDate !== undefined && endTodayDate !== null) {
            // console.log("end Date:", endTodayDate)
            getCardData()
        }
    }, [endTodayDate])

    useEffect(() => {
        getCardData()
    }, [cardSearchData])

    useEffect(() => {
        console.log("Concatr test :", dealersData?.concat(subDealerData))                             // Bu alanı düzenleyeceksin !
        if (checkNullOrEmptyArray(dealersData) && checkNullOrEmptyArray(subDealerData)) {
            setSubAndDealersData(dealersData?.concat(subDealerData))
        }
    }, [subDealerData, dealersData])

    useEffect(() => {
        var filter = false
        dealersData.map((item) => {
            if (item.id == selectedSubDealerValue) {
                filter = true
            }
        })

        // console.log("Filter : ", filter)
        if (filter) {
            setUserList([])
            if (checkNullOrEmpty(selectedSubDealerValue) && selectedSubDealerValue !== 0) {
                console.log("DEaler Filter : ", selectedSubDealerValue)
                setUserList(userList => [...userList, selectedSubDealerValue]);
                setPobSubId([]);

            } else {
                setUserList([])
                setPobSubId([]);
                GetPaymentsFromApi()
            }
        } else {
            setPobSubId([]);
            if (checkNullOrEmpty(selectedSubDealerValue) && selectedSubDealerValue !== 0) {
                console.log("Sub Filter : ", selectedSubDealerValue)
                setUserList([])
                setPobSubId(pobSubId => [...pobSubId, selectedSubDealerValue]);

            } else {
                setPobSubId([]);
                setUserList([])
                GetPaymentsFromApi()
            }
        }

    }, [selectedSubDealerValue]);

    const getCardData = () => {
        if (checkNullOrEmpty(cardSearchData)) {
            api.Dashboard().GetValuesForDealer(cardSearchData).then((response) => {
                // console.log("GetValuesForDealer Response : ", response.data.data)

                setCancelAndRefundTotalTransactionCount(response.data.data.cancelAndRefundTotalTransactionCount)
                setCancelAndRefundTotalTransactionAmount(response.data.data.cancelAndRefundTotalTransactionAmount)
                setWaitingTotalTransactionCount(response.data.data.waitingTotalTransactionCount)
                setWaitingTotalTransactionAmount(response.data.data.waitingTotalTransactionAmount)
                setUnSuccessfulTotalTransactionAmount(response.data.data.unSuccessfulTotalTransactionAmount)
                setUnSuccessfulTotalTransactionCount(response.data.data.unSuccessfulTotalTransactionCount)
                setSuccessfulTotalTransactionAmount(response.data.data.successfulTotalTransactionAmount)
                setSuccessfulTotalTransactionCount(response.data.data.successfulTotalTransactionCount)
                setCardResponseData(response.data.data)

            }).catch((error) => {
                console.log("error", error?.response?.data);
            }).finally(() => {
            });
        }
    }
    const todayStartEnd = () => {
        var start = new Date();
        start.setDate(start.getDate());
        start.setUTCHours(0, 0, 0, 0);

        var end = new Date();
        end.setDate(end.getDate());
        end.setUTCHours(23, 59, 58, 999);
        // console.log("Start : ", start)
        // console.log("End : ", end)
        if (isValidDate(start) && isValidDate(end)) {
            setStartTodayDate(start);
            setEndTodayDate(end);
        }
    }

    const handleDealerSelect = (item) => {
        setSelectedSubDealerValue(item)
        const data = subDealerData.find((element) => element.id === item)
        // console.log("Data : ", data)
        setPobSubDealerId(data?.id)
        // filterData(data?.text)
    }

    //selectedDateRange
    const handleDateRangeChange = (newValue) => {

        let str = "";
        if (newValue.endDate != null) {
            let startDate = new Date(newValue.startDate).toLocaleDateString();
            let endDate = new Date(newValue.endDate).toLocaleDateString();
            if (startDate !== endDate) {
                str = startDate + " - " + endDate;
                let startTransactionDate = setDateTimeFormat(startDate)
                let endTransactionDate = setDateTimeFormat(endDate)
                setStartsDate(startTransactionDate)
                setEndsDate(endTransactionDate)

            } else {
                str = startDate;
            }

        } else {
            let startDate = new Date(newValue.startDate);
            str = startDate.toLocaleDateString();
        }

    };

    const setDateTimeFormat = (dataTime) => {
        var day = dataTime.slice(0, 2);
        var month = dataTime.slice(3, 5);
        var year = dataTime.slice(6, 10);
        return year + "-" + month + "-" + day + "T00:00:00.000Z";
    };

    const setDateFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var year = date.slice(0, 4);
            var month = date.slice(5, 7);
            var day = date.slice(8, 10);
            return day + "." + month + "." + year;
        }
    };
    const setTimeFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var time = date.slice(11, 16);
            return time;
        }
    };

    //Paggination
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % payments.length;
        setItemOffset(newOffset);
        setPageNo(event.selected + 1)
    };

    const handleModalClick = (status) => {
        setModalClick(status);

        //modal kapandığında verileri yenile (apiden tekrar getir)
        if (status == false) {
            GetPaymentsFromApi();
        }
    }

    const setCartAndPaymentLinkColor = (item) => {
        const statusList = [
            { id: 1, text: "Beklemede" },
            {
                id: 2,
                text: "Başarılı",
            },
            { id: 3, text: "Başarısız" },
            { id: 4, text: "Müşteri Reddi" },
            { id: 5, text: "Zaman Aşımı" },
            { id: 6, text: "Banka Reddi" },
            { id: 7, text: "İptal" },
            { id: 8, text: "İade" },
            { id: 9, text: "Beklemede" },
        ];
        const status = statusList.find((a) => a.id == item?.paymentStatusId);
        let color =
            status?.id == 2
                ? "#1ebcc8"
                : status?.id > 2 && status?.id < 9
                    ? "#ff5c5c"
                    : "#000D89";

        return item.paymentTypeId == 1 ? (
            <CreditCardIcon color={color} />
        ) : (
            <LinkPaymentIcon color={color} />
        );
    };


    const GetPaymentsFromApi = () => {
        // console.log("PobSubDealerId : ", pobSubId)
        // console.log("UserList : ", userList)
        api.Dealer().PaymentTransactionList({ startTransactionDate: startsDate, endTransactionDate: endsDate, pobSubDealerIdList: pobSubId, userList: userList }).then((response) => {
            // console.log("SubDealerPaymentList Response : ", response.data.data)

            if (checkNullOrEmptyArray(response.data.data.paymentList)) {
                setPayments(response.data.data.paymentList)
                setListTotalCount(response.data.data.paymentList.length)
                setListData(
                    response.data.data.paymentList.map((item, i) => [

                        <div key={i}>
                            {
                                setCartAndPaymentLinkColor(item)

                            }
                        </div>,
                        <DateLabel
                            time={setTimeFormat(item.transactionDate)}
                            date={setDateFormat(item.transactionDate)}
                        />,
                        <Label text={item.customerName} />,
                        <TablePriceLabel price={item.amountAndCurrencyCode} />,
                        <Label text={item.userName} />,
                        {
                            details: item.details,
                        },
                    ]))
                setPaymentResponseListTotalCount(response.data.data.paymentListTotalCount)
            }
            else {
                setListData({})
                setPayments({})
            }
        }).catch((error) => {
            console.log("error", error?.response?.data);
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const handlePaymentButtonClick = () => {
        handleModalClick(true);
    }

    const willAccountedDateFormat = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate() + termDayData);
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const accountedForDateFormatOre = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate());
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const filterItems = [
        <DateTimePicker
            onSelected={handleDateRangeChange}
            width="235px"
            fontFamily="Metropolis-bold"
            fontSize="12px"
        />,
        <Dropdown
            text='Bayim + Tüm Alt Bayiler'
            items={subAndDealersData}
            onSelected={handleDealerSelect}
            selectItemId={selectedSubDealerValue}
            width="235px"
            fontFamily="Metropolis-bold"
            fontSize="12px"
        />,
        <Button
            text="Ödeme Al"
            width="179px"
            onClick={handlePaymentButtonClick}
        ></Button>,
    ];

    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    return (
        isMobile ? <>
            <DashboardResponsive
                accountedForDate={accountedForDateFormatOre}
                willAccountedDateFormat={willAccountedDateFormat}
                handleDealerSelect={handleDealerSelect}
                subAndDealersData={subAndDealersData}
                selectedSubDealerValue={selectedSubDealerValue}
                cardResponseData={cardResponseData}
                termDay={termDayData}
                role="bayis"
            />
        </>
            : <>
                <CustomLoadingBox isLoading={isLoading} />
                <Modal
                    modalStyle={
                        {
                            boxShadow: 'none',
                            flexBasis: '40%',
                            width: '30%',
                            alignItems: 'stretch',
                        }
                    }
                    clickEvent={modalClick}
                    callbackEvent={handleModalClick}
                    body={<Payment paymentInfo={paymentInfo} modalClicked={modalClick} />}

                />
                <FilterArea width="40%" items={filterItems}></FilterArea>
                <Container minHeight='100%' columnGap='20px' marginTop='20px'>
                    <div className={styles.leftside}>
                        <div className={styles.headgroup}>
                            <div className={styles.textarea}>

                                <div className={styles.header}>Başarılı İşlemler</div>
                                <a className={styles.headbtn} href="#">
                                    <Link
                                        style={{ textDecoration: "none" }}
                                        to={`/transactions`}
                                        state={{
                                            dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                            paymentStatusId: 2,
                                        }}
                                    >
                                        <RightIcon size={"15px"} />
                                    </Link>                            </a>
                            </div>
                            <div className={styles.seperator}></div>
                        </div>

                        <div className={styles.infoarea}>
                            <div className={styles.cardbg}>
                                <div className={styles.bg}>

                                    <div className={styles.infoheader}>
                                        <span>İptal iade işlemi düşülerek hesaplanan tutarlardır.</span>
                                        <span>
                                            Bu işlemler ana firmanızın hesabına
                                            <span className={styles.bolddate}> {now.dd + ' ' + now.monthName + ' ' + now.yyyy}</span>'de
                                            gerçekleştirilecektir. Son güncelleme {now.time}, {now.dd + '.' + now.mm + '.' + now.yyyy}
                                        </span>
                                    </div>

                                    <div className={styles.infobottom}>
                                        <PriceLabel
                                            price={successfulTotalTransactionAmount}
                                            size="4"
                                        />
                                        <span className={styles.counttext}>
                                            {successfulTotalTransactionCount +
                                                " adet işlem"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.listarea}>
                            <ListContainer>
                                <Pagination
                                    width="20%"
                                    handlePageClick={handlePageClick}
                                    pageCount={pageCount}
                                    setItemsPerPage={setItemsPerPage}
                                    infoCount={listTotalCount}
                                    itemsPerPage={itemsPerPage}
                                    listDataLength={listData?.length} />
                                <List
                                    collapsed
                                    CollapseComponent={<DashboardCollapse />}
                                    columnNames={[
                                        "",
                                        "İŞLEM SAATİ VE TARİHİ",
                                        "MÜŞTERİ İSMİ",
                                        "İŞLEM TUTARI",
                                        "İŞLEM SAHİBİ",
                                        "",
                                        ""
                                    ]}
                                    data={currentItems}
                                />
                            </ListContainer>
                        </div>

                    </div>
                    <div className={styles.rightside}>
                        <div className={styles.section}>
                            <Card
                                headText='Bekleyen İşlemler'
                                subText='Bugün 00:00 itibariyle'
                                count={waitingTotalTransactionCount + " adet İşlem"}
                                price={waitingTotalTransactionAmount}
                                color='#000d89'
                                btnBackground='#f5f7f9'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusId: 1,
                                }}
                            />
                        </div>
                        <div className={styles.section}>
                            <Card
                                headText='İptal/İade Edilen İşlemler'
                                subText='Bugün 00:00 itibariyle'
                                count={cancelAndRefundTotalTransactionCount == undefined ? 0 + " adet işlem" : cancelAndRefundTotalTransactionCount + " adet işlem"}
                                price={cancelAndRefundTotalTransactionAmount}
                                color='#e99d36'
                                btnBackground='#fcf5ea'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusIdsArray: [5, 6],
                                }}
                            />
                        </div>

                        <div className={styles.section}>
                            <Card
                                headText='Başarısız İşlemler'
                                subText='Zaman aşımına düşen ve reddedilen işlemleri kapsar. Bugün 00:00 itibariyle'
                                count={unSuccessfulTotalTransactionCount + ' adet işlem'}
                                price={unSuccessfulTotalTransactionAmount}
                                color='#ff5c5c'
                                btnBackground='#ffeeee'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusIdsArray: [3, 4],
                                }}
                            />
                        </div>
                        <BannerArea style={{ minHeight: '250px' }} />
                    </div>
                </Container>
            </>
    )
}

export default DealerDashboard
