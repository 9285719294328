import React from "react";
import styles from "./card.module.css";
import RightIcon from "../../icons/RightIcon";
import PriceLabel from "../PriceLabel";
import { Link } from "react-router-dom";
import { checkNullOrEmpty } from "hoc/Conditions";

const Card = ({
  headText,
  subText,
  href,
  price,
  count,
  money,
  color,
  btnBackground,
  style,
  state,
  fontSize,
  children
}) => {

  return (
    <div style={style} className={styles.card}>
      <div className={styles.left}>
        <div className={styles.lefttop}>
          <div className={styles.headtext} style={{ color }}>
            {headText}
          </div>
          <div className={styles.subtext} style={{ fontSize }}>{subText}</div>
        </div>
        <div className={styles.leftbottom}>
          <PriceLabel color={color} price={price} money={money} />
          <div className={styles.counttext}>{count}</div>
          {children}
        </div>
      </div>

      <div className={styles.right}>
        <a
          style={{ backgroundColor: btnBackground }}
          className={styles.btnNavigate}
        >

          <Link style={{ textDecoration: "none" }} to={checkNullOrEmpty(href) ? href : "/"} state={state}>
            <RightIcon color={color} />
          </Link>

        </a>
      </div>
    </div>
  );
};

export default Card;
