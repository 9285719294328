import Label from "components/Global/Label";
import DownloadIcon from "components/icons/DownloadIcon";
import { createRef, useEffect, useState } from "react";
import styles from "./transactionscollapse.module.css";
import Pdf from "react-to-pdf";
import Button from "components/Global/Button";

const checkNullOrEmpty = (value) => {
  return value !== undefined && value !== "" && value !== " " && value !== null;
};

//olusturulacak her collapse componenti icin item propunun verilmesi zorunludur.
const DashboardCollapse = ({ item }) => {
  const [exportData, setExportData] = useState([]);
  const ref = createRef();

  useEffect(() => {
    if (checkNullOrEmpty(item?.details)) {
      // console.log("item.details", item.details);
      exportData.push({
        "Referans No": `=""${item?.details?.referanceNumber}""`,
        "Komisyon Tutarı": `${item?.details?.commissionAmount}`,
        "Ödeme Alan Kullanıcı": `${item?.details?.paymentRecipient}`,
        "İşlem Açıklaması": `${item?.details?.transactionDescription}`,
        "Banka Adı": `${item?.details?.bankName}`,
        "Kart Tipi": `${item?.details?.creditType}`,
        "Kart Türü": `${item?.details?.cardType}`,
        "Kart Üzerindeki İsim": `${item?.details?.nameOnTheCard}`,
        "Kart Numarası": `=""${item?.details?.cardNumber}""`,
        "3D Güvenlik": `${item?.details?.threeDSecurity}`,
        "Telefon": `=""${item?.details?.customerPhoneNumber}""`,
        "E-mail": `${item?.details?.customerEMail}`,
      });
    }
  }, [item]);

  return (
    <>
      <div>
        <div ref={ref}  className={styles.test}>

          <section className={styles.section}>
            <Label text='İşlem Bilgileri' bold fontSize='1.1rem' />
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <div className={styles.width}>
                  <Label text='Referans No' fontSize='.9rem' />
                  <Label text={item?.details?.referanceNumber != null ? ": " + item?.details?.referanceNumber : ""} bold />
                </div>
              </div>
              <div className={styles.twiceright}></div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <Label text='Komisyon Tutarı' fontSize='.9rem' />
                <Label text={item?.details?.commissionAmount != null ? ": " + item?.details?.commissionAmount + " TL" : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceright}>
                <div className={styles.width}>
                  <Label text='Ödeme Alan Kullanıcı' fontSize='.9rem' />
                  <Label text={item?.details?.paymentRecipient != null ? ": " + item?.details?.paymentRecipient : ""} bold />
                </div>
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <div className={styles.width}>
                  <Label text='İşlem Açıklaması' fontSize='.9rem' />
                  <Label text={item?.details?.transactionDescription != null ? ": " + item?.details?.transactionDescription : ""} bold />
                </div>
              </div>

              <div className={styles.twiceright}></div>
            </div>
          </section>
          <span className={styles.verticalseparator}></span>
          <section className={styles.secondsection}>
            <Label text='Kart Bilgileri' bold fontSize='1.1rem' />
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <Label text='Banka Adı' fontSize='.9rem' />
                <Label text={item?.details?.bankName != null ? ": " + item?.details?.bankName : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>

              <div className={styles.twiceright}>
                <Label text='Kart Tipi' fontSize='.9rem' />
                <Label text={item?.details?.creditType != null ? ": " + item?.details?.creditType : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <Label text='Kart Türü' fontSize='.9rem' />
                <Label text={item?.details?.cardType != null ? ": " + item?.details?.cardType : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceright}>
                <Label text='Kart Üzerindeki İsim' fontSize='.9rem' />
                <Label text={item?.details?.nameOnTheCard != null ? ": " + item?.details?.nameOnTheCard : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <Label text='Kart Numarası' fontSize='.9rem' />
                <Label text={item?.details?.cardNumber != null ? ": " + item?.details?.cardNumber : ""} bold />
              </div>
            </div>
            <div className={styles.twicerow}>

              <div className={styles.twiceright}>
                <Label text='3D Güvenlik' fontSize='.9rem' />
                <Label text={item?.details?.threeDSecurity != null ? ": " + item?.details?.threeDSecurity : ""} bold />
              </div>
            </div>
          </section>
          <span className={styles.verticalseparator}></span>
          <section className={styles.lastsection}>
            <Label text='Müşteri Bilgileri' bold fontSize='1.1rem' />
            <div className={styles.sectioncontainer}>
              <div className={styles.twicerow}>
                <div className={styles.twiceleft}>
                  <Label text='Telefon' fontSize='.9rem' />
                  <Label text={item?.details?.customerPhoneNumber != null ? ": " + item?.details?.customerPhoneNumber : ""} bold />
                </div>
              </div>
              <div className={styles.twicerow}>
                <div className={styles.twiceright}>
                  <div className={styles.height}>
                    <div className={styles.top}>
                    <Label text='E-mail' fontSize='.9rem' />
                    <Label text={item?.details?.customerEMail != null ? ": " + item?.details?.customerEMail : ""} bold />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
        <div className={styles.twicerow}>
          <div className={styles.twiceleft}></div>
          <div className={styles.twiceright}>
            <div className={styles.indir}>
              <Pdf targetRef={ref} filename="detay.pdf">
                {({ toPdf }) =>
                  <Button
                    width="100%"
                    height="3rem"
                    icon={<DownloadIcon width="30" height="20" />}
                    text="Detayı İndir"
                    onClick={toPdf}
                  />
                }
              </Pdf>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default DashboardCollapse;
