import React, { useEffect, useState } from 'react';
import styles from './dealerupdate.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import Dropdown from 'components/Global/Dropdown';
import formValidator from './FormValidator'
import { checkNullOrEmpty } from "hoc/Conditions";
import ReactInputMask from 'react-input-mask';

var initialDealerData = {
    pobDealerId: "",
    pobDealerName: "",
    phoneNumber: "",
    address: "",
    cityCode: 0,
    countryCode: 0,
    districtId: "",
    monthlyAmountLimit: 0,
    customCode: "",
};

var initialCities = {
    cityCode: 0,
    cityName: ""
};

var initialDistricts = {
    countryCode: 0,
    cityCode: 0,
    districtId: "",
    districtName: ""
};

var initialDistrictSearch = {
    cityCode: 0,
    countryCode: 0
};

const DealerUpdate = ({ dealer, handleModalClick, style }) => {

    const [responseErrors, setResponseErrors] = useState();//api den gelen hatalar
    const [isLoading, setIsLoading] = useState()
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [emptyDealerError, setEmptyDealerError] = useState("");
    const [isFull,setisFull] = useState(false)
    useEffect(() => {

        getAllCitiesDataFromApi();

        getAllDistrictsDataFromApi({ cityCode: dealer.cityCode, countryCode: dealer.countryCode });

    }, []);

    useEffect(() => {
        if (handleModalClick = true) {
            setEmptyDealerError("");
        }
    }, [handleModalClick])

    useEffect(() => {
        console.log("Get Dealer Data : ", dealer)
        setValues(dealer)
    }, [dealer])

    useEffect(() => {
        (
            values.pobDealerId == null
            || values.pobDealerName == ""
            || values.districtId == null
            || values.address == ""
            || values.phoneNumber.length < 10
            ) ?
            setisFull(true)
            : setisFull(false)

    })
    const { handleChange, handleBlur, touched, values, handleSubmit, errors, setValues } = useFormik({

        initialValues: {

            pobDealerId: dealer.pobDealerId,
            pobDealerName: dealer.pobDealerName,
            phoneNumber: dealer.phoneNumber,
            monthlyAmountLimit: dealer.monthlyAmountLimit,
            districtId: dealer.districtId,
            cityCode: dealer.cityCode,
            countryCode: dealer.countryCode,
            address: dealer.address,
            customCode: dealer.customCode
        },

        onSubmit: values => {

            setIsLoading(true);
            values.phoneNumber = values.phoneNumber + "";

            if (checkNullOrEmpty(selectedCity)) {
                values.cityCode = parseInt(selectedCity)
            }
            if (checkNullOrEmpty(selectedDistrict)) {
                values.districtId = selectedDistrict
            }
            if (checkNullOrEmpty(values.monthlyAmountLimit)) {
                values.monthlyAmountLimit = parseInt(values.monthlyAmountLimit)
            }
            console.log("Update Values : ", values)
            api.DealerManagement().Update(values).then((response) => {
                handleModalClick(false);
            }).catch((error) => {
                setEmptyDealerError("Bayi güncelleme işleminde bir sorun meydana geldi.")
                // console.log('error', error?.response?.data);
                setResponseErrors(error?.response?.data?.userMessage);
            }).finally(() => {
                setIsLoading(false);
            });

        },
        validationSchema: formValidator
    });

    const getAllCitiesDataFromApi = () => {
        api.Helpers().GetAllCity({ isAll: true })
            .then((response) => {
                setCities(response.data.data.map((item) => (
                    {
                        id: item.cityCode,
                        text: item.cityName
                    }
                )))
                // console.log("Get Cities response : ", response.data.data)
            })
            .catch((error) => {
                console.log('error', error?.response.data);
                setResponseErrors(error?.response?.data?.userMessage);
            });
    }

    const getAllDistrictsDataFromApi = (searchData = initialDistrictSearch) => {
        api.Helpers().GetAllDistrict(searchData)
            .then((response) => {
                setDistricts(response.data.data.map((item) => (
                    {
                        id: item.districtId,
                        text: item.districtName
                    }
                )))
            })
            .catch((error) => {
                console.log('error', error?.response?.data);
                setResponseErrors(error?.response?.data?.message);
            });
    }

    const handleSelectCityDropdown = (cityCode) => {
        setSelectedCity(cityCode);
        getAllDistrictsDataFromApi({ cityCode: cityCode, countryCode: dealer.countryCode });
    }

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />

            <form onSubmit={handleSubmit} className={styles.form} style={style}>
                <section>
                    <span className={styles.infoheader}>Bayiyi Düzenle</span>
                    <div className={styles.inputarea}>

                        <Input
                            error={touched.pobDealerName && errors.pobDealerName}
                            name='pobDealerName'
                            width='100%'
                            maxLength='50'
                            value={values.pobDealerName}
                            placeholder='Bayi Adı'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <ReactInputMask
                            mask="999 999 99 99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            maskChar=""
                        >
                            {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                        </ReactInputMask>

                        <Input
                            error={touched.address && errors.address}
                            name='address'
                            width='100%'
                            maxLength='500'
                            value={values.address}
                            placeholder='Adres'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <Dropdown
                            error={touched.cityCode && errors.cityCode}
                            width='100%'
                            items={cities}
                            text='İl Seçiniz..'
                            selectItemId={values.cityCode}
                            backgroundColor='#fff'
                            onSelected={handleSelectCityDropdown} />

                        <Dropdown
                            error={errors.districtId}
                            width='100%'
                            items={districts}
                            text='İlçe Seçiniz..'
                            selectItemId={values.districtId + "" === "00000000-0000-0000-0000-000000000000" ? undefined : values.districtId}
                            backgroundColor='#fff'
                            onSelected={setSelectedDistrict} />

                        <Input
                            error={touched.monthlyAmountLimit && errors.monthlyAmountLimit}
                            name='monthlyAmountLimit'
                            width='100%'
                            value={values.monthlyAmountLimit == 0 ? "" : values.monthlyAmountLimit}
                            placeholder='Limit (Opsiyonel)'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <Input
                            error={touched.customCode && errors.customCode}
                            name='customCode'
                            width='100%'
                            height='3rem'
                            value={values.customCode === 0 ? "" : values.customCode}
                            placeholder='Bayi Kodu (Opsiyonel)'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span style={{
                            color: 'red'
                        }}>
                            {emptyDealerError}
                        </span>

                        <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull} />
                    </div>
                </section>
            </form>

            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }

        </>
    )
};

export default DealerUpdate;
