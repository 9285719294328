// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".installmentitem_item__pegSy {\n    display: flex !important;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 90% !important;\n    min-width: 48px !important;\n    height: 52px !important;\n    background-color: white;\n    border: 1px  solid  #bfc3de;\n    color:#505386;\n    border-radius: 12px;\n    box-sizing: border-box;\n    flex-shrink: 0;\n    row-gap: 5px;\n    cursor: grab;\n}\n\n.installmentitem_text__A7dqS{\n    font-size: 16px;\n    font-family: 'Metropolis-Bold';\n}\n\n.installmentitem_rate__9xpo2{\n    font-size: 10px;\n}\n\n.installmentitem_clicked__rS0qS{\n    background-image: linear-gradient(to left, #6608b7 100%, #000d89 0%);\n    color:white;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/InstallmentArea/InstallmentItem/installmentitem.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,0BAA0B;IAC1B,uBAAuB;IACvB,uBAAuB;IACvB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oEAAoE;IACpE,WAAW;AACf","sourcesContent":[".item {\n    display: flex !important;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 90% !important;\n    min-width: 48px !important;\n    height: 52px !important;\n    background-color: white;\n    border: 1px  solid  #bfc3de;\n    color:#505386;\n    border-radius: 12px;\n    box-sizing: border-box;\n    flex-shrink: 0;\n    row-gap: 5px;\n    cursor: grab;\n}\n\n.text{\n    font-size: 16px;\n    font-family: 'Metropolis-Bold';\n}\n\n.rate{\n    font-size: 10px;\n}\n\n.clicked{\n    background-image: linear-gradient(to left, #6608b7 100%, #000d89 0%);\n    color:white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "installmentitem_item__pegSy",
	"text": "installmentitem_text__A7dqS",
	"rate": "installmentitem_rate__9xpo2",
	"clicked": "installmentitem_clicked__rS0qS"
};
export default ___CSS_LOADER_EXPORT___;
