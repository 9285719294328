import React from 'react';
import styles from './cardarea.module.css';

const Cardboard = ({ style, children}) => {
    // console.log('style', style);
    return <div style={style} className={styles.cardarea}>
        {children}
    </div>;
};

export default Cardboard;
