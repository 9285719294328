// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customerupdate_formitems__ppv5W{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n    row-gap: 20px;\n}\n\n.customerupdate_forminfo__uOpUb{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n}\n\n.customerupdate_inputgroup__HLrLS{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.customerupdate_form__lmEKO{\n    width: 100%;\n    height: 100%;\n    \n}\n\n.customerupdate_buttontop__N3mfT{\n    margin-top: 15px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Forms/Customer/Update/customerupdate.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,8BAA8B;IAC9B,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".formitems{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n    row-gap: 20px;\n}\n\n.forminfo{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n}\n\n.inputgroup{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.form{\n    width: 100%;\n    height: 100%;\n    \n}\n\n.buttontop{\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formitems": "customerupdate_formitems__ppv5W",
	"forminfo": "customerupdate_forminfo__uOpUb",
	"inputgroup": "customerupdate_inputgroup__HLrLS",
	"form": "customerupdate_form__lmEKO",
	"buttontop": "customerupdate_buttontop__N3mfT"
};
export default ___CSS_LOADER_EXPORT___;
