import RightIcon from 'components/icons/RightIcon';
import { useState, useEffect } from 'react'
import styles from './installment.module.css'
import Slider from "react-slick";
import InstallmentItem from './InstallmentItem';
import './override.css';
const InstallmentArea = ({ items, onChange = Function(), itemStyle = {}, slidesShow = 8 }) => {
    const splittedItems = items?.slice(1);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesShow,
        slidesToScroll: 5,
    };

    function Arrow({ onClick }) {
        return (
            <div className={styles.rightarrow} onClick={onClick}>
                <RightIcon size='10' />
            </div>
        );
    }
    const [callback, setCallback] = useState();
    useEffect(() => {
        onChange(callback);
    }, [callback])
    return (
        <div style={{ width: '100%' }}>
            <Slider
                {...settings}
                useCSS={true}
                style={{ display: 'flex' }}
                nextArrow={<Arrow />}
                prevArrow={<></>}
            >
                {
                    splittedItems?.map((item) => {
                        return (
                            <InstallmentItem setCallback={setCallback} callback={callback} item={item} style={itemStyle} />
                        )
                    })
                }
            </Slider>
        </div>
    )
}
export default InstallmentArea