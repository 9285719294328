import { useEffect } from "react";

const useOutClick = (firstRef,secondRef, callback) => {
  //firstref ve secondref useRef hook tipindedir. Bu hookun amaci click event alanin disinda mi icinde mi diye kontrol eder. eventlistener click esnasinda handleclick fonksiyonuna clickle alakali object doner biz de bu objecti kontrol ederek referansimiz click event targetinde var mi yok mu diye kontrol ederiz.
  const handleClick = e => {
    if(secondRef != null){
      if (firstRef.current && !firstRef.current.contains(e.target) && secondRef.current && !secondRef.current.contains(e.target)) {
        callback();
      }
    }
    else{
      if (firstRef.current && !firstRef.current.contains(e.target)) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const useOutPopUp = (ref, callback) => {
  const handleClick = e => {
    if (ref && !ref.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
}

export default useOutClick;