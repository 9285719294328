import React, { useState } from 'react';
import styles from './pagination.module.css';
import UpIcon from 'components/icons/UpIcon';
import DownIcon from 'components/icons/DownIcon';
import RightIcon from 'components/icons/RightIcon';
import LeftIcon from 'components/icons/LeftIcon';
import ReactPaginate from 'react-paginate';

/**
 * Pagination
 * @param {handlePageClick} handlePageClick status callback
 * @param {pageCount} pageCount hesaplanmis olan sayfa sayisi.
 * @param {setItemsPerPage} setItemsPerPage sayfa basina dusen item sayisi
 * @param {width} width genislik :)
 * @param {infoCount} infoCount listenin total countu
 * @param {infodataRange} infodataRange gozuken datalarin total datalar icerisindeki konumu orn. 100 data var 2. sayfadayiz 11-20 olmali.
 * item sayısına göre column olmalıdır. Boş alanlar için ''
 */
const Pagination = ({ pageViewStatus = false, desciription = "işlem içinden", handlePageClick, pageCount, itemsPerPage = 10, listDataLength = 10, setItemsPerPage, width, infoCount = 0, MultiSelect }) => {

    const [page, setPage] = useState(1)

    const pageUp = () => {
        if (pageCount !== 1) {
            setItemsPerPage(number => number + 1);
        }
    }
    const pageDown = () => {
        if (itemsPerPage > 1) {
            setItemsPerPage(number => number - 1);
        }
    }

    const handlePageChange = (event) => {
        setPage(event.selected + 1);
        handlePageClick(event)
    }

    return (

        <div className={styles.searcharea}>
            <div className={styles.multiselectarea}>
                <div className={styles.multiselect}>
                    {MultiSelect}
                </div>
                {pageViewStatus ? (
                    <span className={styles.searchinfo}>
                        {infoCount} {desciription}
                    </span>) : (
                    <span className={styles.searchinfo}>
                        {infoCount} {desciription} {page}-{listDataLength}
                    </span>)
                }

            </div>
            <div style={{ width }} className={styles.paginationsection}>
                <ReactPaginate
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    nextLabel={<RightIcon size='15' />}
                    previousLabel={<LeftIcon size='15' />}
                    onPageChange={handlePageChange}
                    breakLabel="..."
                    pageClassName={styles.btncount}
                    pageLinkClassName={styles.btncount}
                    previousClassName={styles.btnmove}
                    previousLinkClassName={styles.btnmove}
                    nextClassName={styles.btnmove}
                    nextLinkClassName={styles.btnmove}
                    breakClassName={styles.btndecoration}
                    breakLinkClassName={styles.btndecoration}
                    containerClassName={styles.pagination}
                    activeClassName={styles.countselected}
                />

                <div className={styles.selectgroup}>
                    <div className={styles.inputgroup}>
                        <input disabled className={styles.input} value={itemsPerPage} type="text" />
                    </div>
                    <div className={styles.counterbtngroup}>
                        <button onClick={pageUp} className={styles.upbtn}>
                            <UpIcon size='12' />
                        </button>
                        <button onClick={pageDown} className={styles.downbtn}>
                            <DownIcon size='12' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Pagination;
