// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customerJp_filtercontainer__BWues {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.customerJp_firstarea__q0Iaz {\n    display: flex;\n    width: 30%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.customerJp_searcharea__M6MqG {\n    z-index: 15;\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    overflow: 'auto';\n    background-color: white;\n    border-radius: 10px;\n    border: solid 1px #bfc3de;\n    padding: .5rem;\n    box-sizing: border-box;\n}\n\n\n.customerJp_lastarea__uvl-0 {\n    display: flex;\n    width: 310px;\n    justify-content: space-between;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/Customer/CustomerGlobal/customerJp.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;;AAGA;IACI,aAAa;IACb,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".filtercontainer {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.firstarea {\n    display: flex;\n    width: 30%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.searcharea {\n    z-index: 15;\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    overflow: 'auto';\n    background-color: white;\n    border-radius: 10px;\n    border: solid 1px #bfc3de;\n    padding: .5rem;\n    box-sizing: border-box;\n}\n\n\n.lastarea {\n    display: flex;\n    width: 310px;\n    justify-content: space-between;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtercontainer": "customerJp_filtercontainer__BWues",
	"firstarea": "customerJp_firstarea__q0Iaz",
	"searcharea": "customerJp_searcharea__M6MqG",
	"lastarea": "customerJp_lastarea__uvl-0"
};
export default ___CSS_LOADER_EXPORT___;
