import React, { forwardRef } from 'react';

const CloseIcon = forwardRef(({ color = '#000D89', size = '24' }, ref) => {
    return <>
        <symbol ref={ref} id="close" >
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.418 2.419a1.429 1.429 0 0 1 1.843-.151l.178.15L12 9.981l7.56-7.557a1.429 1.429 0 0 1 2.171 1.843l-.15.177-7.56 7.557 7.56 7.555a1.429 1.429 0 0 1-1.844 2.17l-.177-.15-7.56-7.556-7.562 7.562a1.429 1.429 0 0 1-2.171-1.844l.15-.177 7.564-7.56L2.42 4.44a1.429 1.429 0 0 1 0-2.02z" fill={color} />
            </svg>
        </symbol>
    </>;
});

export default CloseIcon;
