import { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker";
import customcss from './datetimepicker.module.css'
import overridedcss from './datetimepickeroverrided.css'
import useOutside from 'hoc/CustomOutsideHook';
import cx from 'classnames'
import moment from 'moment'
import CalendarIcon from 'components/icons/CalendarIcon'
import RightIcon from 'components/icons/RightIcon';
import LeftIcon from 'components/icons/LeftIcon';

// import CloseIcon from 'components/icons/CloseIcon';

/**
 * Returns x raised to the n-th power.
 *
 * @param {width} width uzunluk degeri.
 * @param {height} height yukseklik degeri. default 3rem
 * @param {onSelected} onSelected callback function, range araligini state e set eder
 * @param {currentRange} currentRange vermis oldugunuz string ifadeyi input value olarak kullanir string deger alir. 
 * @param {isTermDay} (boolean) isTermDay true ise vade sayisi buttonunu aktif eder.
 * @param {termRange} (int) termRange cache uzerinden vade gununu verdiginizde range i geriye getirir.
 * @param {dateTimePickerTypeId} (int) default değeri 0, 1 ise Hesaba geçen, 2 ise Hesaba geçecek tarih türü
 *   
 */
const DateTimePicker = ({ fontSize, fontFamily, dateTimePickerTypeId = 0, width, height = "3rem", onSelected = Function(), currentRange, isTermDay = false, termRange = 0, stringVal = "Lütfen tarih seçiniz." }) => {
  // console.log(stringVal)
  //response model

  const responseModel = {
    startDate: dateTimePickerTypeId === 2 ? addDays(new Date(), -1) : new Date(),
    endDate: null,
  }

  //response state
  const [response, setResponse] = useState({ startDate: null, endDate: null })
  //range text
  const [range, setRange] = useState()
  //opened-closed status
  const [opened, setOpened] = useState(false)
  //ref for open-closed positioning
  const ref = useRef()
  const inlineRef = useRef()
  //response data state
  const [date, setDate] = useState(responseModel);
  //button id handler state
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [selectedButtonNumber, setSelectedButtonNumber] = useState();

  //change event function
  const onChange = (dates) => {
    console.log("dates", dates)
    setSelectedButtonNumber(undefined);
    setSelectedButtonId(null)
    const [startDate, endDate] = dates
    const todayEnd = new Date(startDate)
    todayEnd.setHours(23, 59, 59, 59)
    startDate.setHours(0, 0, 0, 0)
    endDate?.setHours(23, 59, 59, 59)

    //setDate and setResponse seperated twice because of the bug in datepicker
    setDate({ startDate, endDate })
    setResponse({ startDate, endDate: endDate === null ? todayEnd : endDate })
  };

  useEffect(() => {
    if (currentRange != undefined) {
      setRange(currentRange)
    }
  }, [currentRange])

  function addDays(theDate, days) {
    return new Date(theDate?.getTime() + days * 24 * 60 * 60 * 1000);
  }

  //button click event
  const handleClick = (e) => {
    const id = Number(e.target.id)
    //console.log("id", id)
    //if do uncomment, buttons can be deselected too
    // if (id == selectedButtonId) {
    //   setSelectedButtonId(null)
    //   return;
    // }
    setSelectedButtonId(id)
    //clear datepicker selection
    setDate({ startDate: null, endDate: null })
    //switch case initializing for button ids
    var result = Array()
    var buttonNumber = 0;
    const currentDay = moment().toDate();
    const currentStartDay = moment(currentDay).startOf('day').toDate();
    const startOfWeek = moment().startOf('week').toDate();
    const startOfMonth = moment().startOf('month').toDate();

    var termDate = addDays(new Date(), termRange);
    var startDate = new Date();
    var endDate = new Date();

    if (dateTimePickerTypeId === 2) {
      startDate = addDays(startDate, -1)
      endDate = addDays(endDate, -1)
      endDate.setUTCHours(23, 59, 58, 999);
    } else {
      startDate.setDate(startDate.getDate());
      startDate.setUTCHours(0, 0, 0, 0);

      endDate.setDate(endDate.getDate());
      endDate.setUTCHours(23, 59, 58, 999);
    }

    switch (id) {
      case 1:
        result = { startDate: startDate, endDate: endDate };
        buttonNumber = 1;
        break;
      case 2:
        result = { startDate: startOfWeek, endDate: endDate };
        buttonNumber = 2;
        break;
      case 3:
        if (isTermDay) {
          result = { startDate: currentStartDay, endDate: termDate };
          buttonNumber = 3;
        }
        else {
          result = { startDate: startOfMonth, endDate: dateTimePickerTypeId === 2 ? addDays(endDate, -1) : endDate };
          buttonNumber = 4;
        }
        break;
      default:
        result = { startDate: currentDay, endDate: currentDay };
        break;
    }
    setResponse(result)
    setSelectedButtonNumber(buttonNumber);
  }

  //inline open-closed
  const handleOpen = () => {
    //if have closing, clear all of selections
    if (opened) {
      setSelectedButtonId(null)
      setDate({ startDate: null, endDate: null })
    }
    //always reverse selected status
    setOpened(status => !status)
  }

  //handling outside click effects
  useOutside(ref, inlineRef, () => {
    setOpened(false);
  });

  //response
  useEffect(() => {
    const { startDate, endDate } = response

    if (startDate == null) {
      setRange(stringVal)
    }
    else {
      onSelected({ startDate, endDate })
      if (endDate == undefined || endDate == null || startDate == undefined || startDate == null) {
        const stringStartDate = startDate?.toLocaleDateString()
        setRange(stringStartDate + " - " + stringStartDate)
      }
      else {
        if (selectedButtonNumber === 1) {
          setRange("Bugün");
        }
        else if (selectedButtonNumber === 2) {
          setRange("Bu Hafta");
        }
        else if (selectedButtonNumber === 3) {
          setRange(`Önümüzdeki ${termRange} gün`);
        }
        else if (selectedButtonNumber === 4) {
          setRange("Bu Ay");
        }
        else {
          setRange(startDate?.toLocaleDateString() + " - " + endDate?.toLocaleDateString())
        }

      }
    }
  }, [response, selectedButtonNumber])

  // const handleClose = () => {
  //   console.log("Close clicked")

  // }

  return (
    <>
      <div className={customcss.datecontainer} ref={ref} style={{ width, height }} onClick={handleOpen}>
        <div className={customcss.inputarea}>
          <input value={range} className={customcss.input} style={{ fontSize, fontFamily }} readOnly type="text" />
        </div>
        <div className={customcss.logoarea}>
          <CalendarIcon />
        </div>
      </div>
      {/* <div className={customcss.closelogo} onClick={handleClose}>
        <CloseIcon />
      </div> */}
      {opened &&
        <div ref={inlineRef} className={customcss.inlinecontainer} style={{
          left: ref.current.offsetLeft - 7, top: ref?.current?.offsetTop + ref?.current?.offsetHeight + 5
        }}>
          <DatePicker
            className={overridedcss}
            selected={date.startDate}
            onChange={onChange}
            startDate={date.startDate}
            endDate={date.endDate}
            selectsRange
            minDate={dateTimePickerTypeId === 1 && new Date()}
            maxDate={dateTimePickerTypeId === 2 && addDays(new Date(), -1)}
            inline
            renderCustomHeader={({
              monthDate,
              decreaseMonth,
              increaseMonth,
            }) => (
              <>
                <div className={customcss.btngroup}>
                  <div className={customcss.dayweek}>
                    <div id="1" onClick={handleClick} className={cx(customcss.btn, selectedButtonId == 1 && customcss.selected)}>Bugün</div>
                    <div id="2" onClick={handleClick} className={cx(customcss.btn, selectedButtonId == 2 && customcss.selected)}>Bu Hafta</div>
                  </div>
                  <div id="3" onClick={handleClick} className={cx(customcss.btn, selectedButtonId == 3 && customcss.selected)}>{isTermDay ? `Önümüzdeki ${termRange} gün (Vade Günü)` : 'Bu Ay'}</div>
                </div>
                <div className={customcss.selectarea}>
                  <button
                    className={customcss.navbtn}
                    onClick={decreaseMonth}
                  >
                    {<LeftIcon size='15' />}
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("tr-TR", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    className={customcss.navbtn}
                    onClick={increaseMonth}
                  >
                    {<RightIcon size='15' />}
                  </button>
                </div>

              </>

            )}
          />
        </div>
      }
    </>
  );
}

export default DateTimePicker