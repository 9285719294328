import React from 'react';

const TickIcon = ({size ='24'}) => {
    return <>
        <symbol id="tick" >
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M19.717 3.736 8.512 14.94l-4.229-4.228a2.508 2.508 0 0 0-3.549 0 2.511 2.511 0 0 0 0 3.55l6.004 6.001a2.506 2.506 0 0 0 3.547 0l12.98-12.98c.98-.98.98-2.569 0-3.548a2.507 2.507 0 0 0-3.548 0z" fill="url(#n7mlxbn2fa)" />
                <defs>
                    <linearGradient id="n7mlxbn2fa" x1=".377" y1="12" x2="23.508" y2="12" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#020D88" />
                        <stop offset="1" stop-color="#6608B7" />
                    </linearGradient>
                </defs>
            </svg>



        </symbol>
    </>;
};

export default TickIcon;
