import Button from "components/Global/Button";
import Cardboard from "components/Global/Cardboard";
import Container from "components/Global/Container";
import FilterArea from "components/Global/FilterArea";
import List from "components/Global/List";
import SwitchButton from "components/Global/SwitchButton";
import EditIcon from "components/icons/EditIcon";
import React, { useEffect, useState } from "react";
import styles from "./profilebmpjp.module.css";
import api from "store/api/actions";
import Modal from "components/Global/Modal";
import ProfileEdit from "components/Global/Forms/Profile/ProfileEdit";
import InfoArea from "components/Global/InfoArea";
import UserInformationLabel from "components/Global/UserInformationLabel";
import Table from "components/Global/Table";
import Label from "components/Global/Label";
import BankList from "components/Global/BankList";
import BannerArea from "components/Global/BannerArea";
import CreditCardIcon from "components/icons/CreditCardIcon";
import CommissionIcon from "components/icons/CommissionIcon";
import SuitcaseIcon from "components/icons/SuitcaseIcon";
import { useMediaQuery } from 'react-responsive'
import ProfileResponsive from "components/Responsive/Profile";

const initialCompanyInformationDataState = {
  companyName: "",
  companyType: "",
  contactName: "",
  address: "",
  cityName: "",
  districtName: "",
  taxOfficeName: "",
  countryName: "",
  phone: "",
  email: "",
  iban: "",
  taxNumber: "",
  bankOfTerm: "",
  comissionList: {
    commissionRate: "",
    commissionRate2: "",
    commissionRate3: "",
    commissionRate4: "",
    commissionRate5: "",
    commissionRate6: "",
    commissionRate7: "",
    commissionRate8: "",
    commissionRate9: "",
    commissionRate10: "",
    commissionRate11: "",
    commissionRate12: "",
  },
};

const initialUserProfilDataState = {
  userFullName: "",
  phoneNumber: "",
  email: "",
};

const BMPJustPayment = () => {
  const [getCompanyInformationData, setGetCompanyInformationData] = useState([
    [initialCompanyInformationDataState],
  ]);
  const [userProfileData, setUserProfileData] = useState(
    initialUserProfilDataState
  );
  const [pageType, setPageType] = useState(1);
  const [modalClick, setModalClick] = useState(false);

  useEffect(() => {
    //GetInformation
    getProfilDataFromApi();

    //GetCompanyInformation
    api
      .User()
      .GetCompanyInformationForBigMarketPlace({})
      .then((response) => {
        setGetCompanyInformationData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error.message);
      })
      .finally(() => { });
  }, []);

  const getProfilDataFromApi = () => {
    api
      .User()
      .GetInformation({})
      .then((response) => {
        setUserProfileData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
  };

  const handleModalClick = (status) => {
    setModalClick(status);
    if (status == false) {
      getProfilDataFromApi();
    }
  };

  const handleProfileEditButtonClick = () => {
    handleModalClick(true);
  };

  const setPhoneNumberFormat = (phoneNumber) => {
    return (
      "+90 (" +
      phoneNumber.slice(0, 3) +
      ") " +
      phoneNumber.slice(3, 6) +
      " " +
      phoneNumber.slice(6, 8) +
      " " +
      phoneNumber.slice(8, 10)
    );
  };

  const filterItems = [
    <SwitchButton
      items={[
        { id: 1, text: "Kullanıcı Bilgileri" },
        { id: 2, text: "Firma Bilgileri" },
      ]}
      callback={setPageType}
      style={{ width: "25%", height: "3rem" }}
      selectedValue={pageType}
    />,
    <div style={{ width: "40%" }}></div>,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <ProfileResponsive
        setPageType={setPageType}
        pageType={pageType}
        userProfileData={userProfileData}
        getCompanyInformationData={getCompanyInformationData}
      />
    </>
      :
      <>
        <FilterArea width="100%" items={filterItems} />
        {pageType === 1 ? (
          <Container flexDirection="column" >
            <Modal
              modalStyle={{
                boxShadow: "none",
                flexBasis: "70%",
                width: "30%",
                alignItems: "stretch",
              }}
              clickEvent={modalClick}
              callbackEvent={handleModalClick}
              body={
                <ProfileEdit
                  userProfileData={userProfileData}
                  handleModalClick={handleModalClick}
                />
              }
            />
            <Cardboard style={{ padding: "20px" }}>
              <InfoArea
                style={{ height: "1rem", width: "100%" }}
                containerStyle={{ width: "100%", marginRight: "20px" }}
              >
                <UserInformationLabel
                  color="#000d89"
                  fontFamily="Metropolis-Bold"
                  text={userProfileData?.userFullName}
                  size="2rem"
                />
                <Button
                  width="150px"
                  text="Düzenle"
                  onClick={() => {
                    handleProfileEditButtonClick(userProfileData);
                  }}
                  icon={<EditIcon color="#fff" />}
                />
              </InfoArea>
              <InfoArea>
                <div className={styles.normallabel}>
                  <Label text="Telefon" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={
                      userProfileData?.phoneNumber === undefined
                        ? ""
                        : setPhoneNumberFormat(userProfileData.phoneNumber)
                    }
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="E-posta" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.email}
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="Kullanıcı İsmi" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.userFullName}
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="Şifre" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={"*********"}
                  />
                </div>
              </InfoArea>
            </Cardboard>
          </Container>
        ) : (
          <>
            <Container marginTop="20px" columnGap="20px">
              <div className={styles.leftside}>
                <Table
                  data={[
                    {
                      name: "Firma",
                      value: getCompanyInformationData.companyName,
                    },
                    {
                      name: "Firma Tipi",
                      value: getCompanyInformationData.companyType,
                    },
                    {
                      name: "Yetkili Kişi",
                      value: getCompanyInformationData.contactName,
                    }, //BackEnd sorunu giderilecek
                    { name: "Vergi Dairesi", value: "Vergi Dairesi" }, //muallak olup olmaması
                    {
                      name: "Vergi No",
                      value: getCompanyInformationData.taxNumber,
                    },
                    { name: "Adres", value: getCompanyInformationData.address },
                    {
                      name: "İl / İlçe",
                      value:
                        getCompanyInformationData.cityName +
                        " / " +
                        getCompanyInformationData.districtName,
                    },
                    { name: "Telefon", value: getCompanyInformationData.phone },
                    { name: "E - posta", value: getCompanyInformationData.email },
                    { name: "Müşteri Key", value: "Müşteri Key" }, //ERP ile gelicek
                    { name: "IBAN", value: getCompanyInformationData.iban },
                    { name: "ERP Bayi Kodu", value: "0" },
                    { name: "ERP Kullanıcı Adı", value: "0" },
                    { name: "ERP Şifre", value: "0" },
                  ]}
                />
              </div>
              <div className={styles.rightside}>
                <div className={styles.righttable}>
                  <Table
                    data={[
                      {
                        name: <SuitcaseIcon text="Çalışma Koşulları" />,
                        value: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <Label
                              bold
                              fontSize="1.2rem"
                              text={
                                getCompanyInformationData.bankOfTerm +
                                " gün vadeli çalışmaktasınız."
                              }
                            />
                            <Label
                              fontSize=".8rem"
                              text="Bağlı olduğunuz ana firma tarafından anlaşma sağlanmıştır."
                            />
                          </div>
                        ),
                      },
                      {
                        name: <CommissionIcon text="Komisyon Oranları" />,
                        value: (
                          <List
                            columnNames={[
                              "Taksit Sayısı",
                              "Peşin",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                              "9",
                              "10",
                              "11",
                              "12",
                            ]}
                            data={[
                              [
                                <Label fontSize=".8rem" text="Komisyon Oranı" />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate2
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate3
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate4
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate5
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate6
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate7
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate8
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate9
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate10
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate11
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate12
                                  }
                                />,
                              ],
                            ]}
                          />
                        ),
                      },
                      {
                        name: <CreditCardIcon color="#000D89" text="Geçerli Kartlar" />,
                        value: <BankList style={{ width: "100%" }} />,
                      },
                    ]}
                  />
                </div>
                <BannerArea style={{ backgroundColor: "gray" }} />
              </div>
            </Container>
          </>
        )}
      </>
  );
};

export default BMPJustPayment;

