// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterinfo_filterbtn__-RWdR{\n    display:flex;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    cursor: pointer;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    width: 150px;\n    font-size: 0.92rem;\n}\n\n.filterinfo_btncontainer__puNME{\n    display: flex;\n    justify-content:space-between;\n    align-items: center;\n    flex-grow: 1;\n    padding: 0.8rem;\n\n}\n\n.filterinfo_infotext__vFgF-{\n    color: #4f5386;\n}\n\n.filterinfo_infocount__mdWrG{\n    font-weight: bold;\n    color: #4f5386;\n    font-size: 1.3rem;\n    padding-left: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/FilterInfoButton/filterinfo.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,eAAe;;AAEnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".filterbtn{\n    display:flex;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    cursor: pointer;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    width: 150px;\n    font-size: 0.92rem;\n}\n\n.btncontainer{\n    display: flex;\n    justify-content:space-between;\n    align-items: center;\n    flex-grow: 1;\n    padding: 0.8rem;\n\n}\n\n.infotext{\n    color: #4f5386;\n}\n\n.infocount{\n    font-weight: bold;\n    color: #4f5386;\n    font-size: 1.3rem;\n    padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterbtn": "filterinfo_filterbtn__-RWdR",
	"btncontainer": "filterinfo_btncontainer__puNME",
	"infotext": "filterinfo_infotext__vFgF-",
	"infocount": "filterinfo_infocount__mdWrG"
};
export default ___CSS_LOADER_EXPORT___;
