import styles from './switch.module.css';
import cx from 'classnames';
import LinkPaymentIcon from 'components/icons/LinkPaymentIcon';
import CreditCardIcon from 'components/icons/CreditCardIcon';

/**
 * SwitchButton.
 *
 * @param callback Geriye button numarası döndürür.
 * @param items object array içinde gelmesi gerekiyor, object => {id:'',text:''} şeklinde olmalıdır
 */
const SwitchButton = ({ callback, items, style, selectedValue, isPayment = false }) => {

    const handleSwitch = (e) => {
        const value = parseInt(e.target.id)
        // console.log(value);
        callback(value);
    }

    // console.log("SwitchButton", selectedValue)

    return (
        <>
            <div className={styles.switcher} style={style}>

                {
                    items?.map((item, i) => {
                        var linkcolor
                        selectedValue == 1 ? linkcolor = "#FFFFFF" : linkcolor = "#000D89"
                        var cardcolor
                        selectedValue == 2 ? cardcolor = "#FFFFFF" : cardcolor = "#000D89"


                        if (i == 0) {
                            return (<div id={item.id} onClick={handleSwitch} className={cx(styles.leftswitch, selectedValue === parseInt(item.id) ? styles.active : '')}>
                                <a id={item.id} onClick={handleSwitch} className={styles.switchitem} >
                                    <div className={styles.linkicon}>

                                        <div className={styles.icon}>
                                            {
                                                isPayment == true ?
                                                    <LinkPaymentIcon size={18} color={linkcolor} />
                                                    :
                                                    ""
                                            }
                                        </div>
                                        {item.text}
                                    </div>
                                    <div className={styles.smalltext}>
                                        {
                                            item.smallText && (<>
                                                <br />
                                                <small id={item.id} onClick={handleSwitch} style={{ fontSize: "x-small" }}>{item.smallText}</small>
                                            </>)
                                        }
                                    </div>
                                </a>

                            </div>)
                        }
                        if (i == items.length - 1) {
                            var color = "#FFFFFF"

                            return (<div id={item.id} onClick={handleSwitch} className={cx(styles.rightswitch, selectedValue === parseInt(item.id) ? styles.active : '')}>
                                <a id={item.id} onClick={handleSwitch} className={styles.switchitem} >
                                    <div className={styles.cardicon}>
                                        <div className={styles.icon}>
                                            {
                                                isPayment == true ?
                                                    <CreditCardIcon size={18} color={cardcolor} />
                                                    :
                                                    ""
                                            }
                                        </div>
                                        {item.text}
                                    </div>
                                    <div className={styles.smalltext}>
                                        {
                                            item.smallText && (<>
                                                <br />
                                                <small id={item.id} onClick={handleSwitch} style={{ fontSize: "x-small" }}>{item.smallText}</small>
                                            </>)
                                        }
                                    </div>
                                </a>
                            </div>)
                        }

                        return (<div id={item.id} onClick={handleSwitch} className={cx(styles.middleswitchDealer, selectedValue === parseInt(item.id) ? styles.active : '')}>
                            <a id={item.id} onClick={handleSwitch} className={styles.switchitem} >
                                {item.text}
                                {
                                    item.smallText && (<>
                                        <br />
                                        <small id={item.id} onClick={handleSwitch} style={{ fontSize: "x-small" }}>{item.smallText}</small>
                                    </>)
                                }
                            </a>
                        </div>)
                    })
                }

            </div>

        </>
    )
}

export default SwitchButton;
