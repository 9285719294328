import DoubleButton from 'components/Global/DoubleButton';
import PobUp from 'components/Global/PopUp/PobUp';
import React, { createRef, useEffect, useState } from 'react'
import { useRef } from 'react';
import styles from "./responsiveprofilecollapse.module.css";

const ResponsiveProfileCollapse = ({ item, handleUserEditButtonClick, userName,deleteSelector, currentRef, popUpClick, isActive, handlePopUp, func }) => {

  const ref = createRef();
  const refs = useRef([])

  const [mainData, setMainData] = useState()

  useEffect(() => {
    setMainData(func(item?.details?.userId))
  }, [])    

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };

  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };



  return (
    <div ref={ref} className={styles.collapsecontainer}>

      <div className={styles.detail}>
        <div className={styles.headerandvalue}>
          <div className={styles.header}>Kullanıcı Yetki Türü</div>
          <div className={styles.value}>
            <div className={styles.item}>{item?.details?.roleName}</div>
          </div>
        </div>

        <div className={styles.headerandvalue}>
          <div className={styles.header}>E-posta</div>
          <div className={styles.value}>
            <div className={styles.item}>{item?.details?.email}</div>
          </div>
        </div>
        <div className={styles.headerandvalue}>
          <div className={styles.header}>Son erişim zamanı</div>
          <div className={styles.item}>
            {
              item.details.lastAccessDate === "0001-01-01T00:00:00" ? (
                "-"
              ) : (
                <>
                  <div className={styles.item}>{setTimeFormat(item?.details?.lastAccessDate) + " " + setDateFormat(item?.details?.lastAccessDate)}</div>
                </>
              )
            }
          </div>
        </div>

        <div className={styles.buttons}>
          <DoubleButton
            firstOnClick={() => { handleUserEditButtonClick(item?.details?.userId) }}
            secondOnClick={() => { deleteSelector(item?.details?.userId, item?.i) }}
            ref={refs}
          ></DoubleButton>
        </div>
      </div>

    </div>
  )
}

export default ResponsiveProfileCollapse