import InfoIcon from 'components/icons/InfoIcon'
import React, { useEffect, useRef, useState } from 'react'
import styles from './info.module.css'
import useOutside from 'hoc/CustomOutsideHook';

function Information({ text, width = "14px", height = "14px",profileInformation=false }) {

    const inputRef = useRef()
    const areaRef = useRef()
    const [ref, setRef] = useState(inputRef)
    const [clicked, setClicked] = useState(false)
    const [isPopUp, setIsPopUp] = useState(false)

    useEffect(() => {
        setRef(ref)
    }, [clicked, inputRef])

    useOutside(inputRef, areaRef, () => {
        setClicked(false);
    })

    return (
        <div className={styles.container}>

            {isPopUp &&
                <div ref={areaRef} className={styles.popup}
                    style={{
                        top: profileInformation ? "420px" : (ref?.current?.offsetTop - ref?.current?.offsetHeight - 45),
                        width: ref?.current?.clientWidth + 2,
                    }}
                >
                    {text}
                </div>
            }

            <div ref={ref} className={styles.infoicon}>
                <InfoIcon color='#4f5386' width={width} height={height}
                    onMouseEnter={() => setIsPopUp(true)}
                    onMouseLeave={() => setIsPopUp(false)} />
            </div>

        </div>

    )
}

export default Information