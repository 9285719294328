import { useState, useEffect, useRef } from 'react'
import styles from './multipleselect.module.css';
import cx from 'classnames';
import useOutside from 'hoc/CustomOutsideHook';
import SelectItem from './SelectItem';
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions';
import AddIcon from 'components/icons/AddIcon';
import Button from '../Button';
import CloseIcon from 'components/icons/CloseIcon';

const MultipleSelect = ({ littleText = "Kişi", selectedItems, width: inputWidth, height = '2.4rem', placeholder, fontSize, onChange, name, value, id, type = 'text', maxLength, error, items, onBlur }) => {

    const inputRef = useRef();
    const areaRef = useRef();
    const [ref, setRef] = useState(inputRef);
    const [clicked, setClicked] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState('');
    const [isError, setIsError] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [isDeselectAll, setIsDeselectAll] = useState(false)

    const handleRef = () => {
        if (inputRef != undefined) {
            return setRef(inputRef);
        }
    }
    // useEffect(() => {
    //     console.log("items itemsw : ", items)
    // }, [items])


    useEffect(() => {
        setRef(ref)
    }, [clicked, inputRef])

    useOutside(inputRef, areaRef, () => {
        setClicked(false);
    })

    useEffect(() => {
        if (error != undefined) {
            setIsError(true)
        }
        else {
            setIsError(false)
        }
    }, [error])

    useEffect(() => {
        // console.log("selectedItems",selectedItems);

        if (checkNullOrEmptyArray(selectedItems)) {
            setSelectedCustomer(selectedItems);
        }
        else {
            setSelectedCustomer([]);
        }

    }, [selectedItems])

    useEffect(() => {
        onChange(selectedCustomer);

        if (checkNullOrEmptyArray(selectedCustomer)) {
            setInputValue(selectedCustomer.map(item => item?.name).join(","))
        } else {
            setInputValue("")
        }

    }, [selectedCustomer])

    const handleChange = (e) => {

        if (isDeselectAll) {
            setIsDeselectAll(false)

            setSelectedCustomer([])
            customerSelector("")

            // console.log("Deselect ", selectedCustomer)

        } else {

            if (e.deselect) {

                // console.log("Deselect : ", e.deselect)
                setSelectedCustomer(selectedCustomer.filter(item => item.id != e.deselectId))
                // console.log("newFilter", selectedCustomer)
                customerSelector(e.name)

            } else {

                if (e.target) {
                    customerSelector(e.target.value)
                }
                else {
                    customerSelector(e.name)
                }
            }
        }
    }

    const handleRemoveAll = () => {
        setIsDeselectAll(true)
        setSelectedCustomer([])
    }

    const handleSearch = () => {
        setFilteredData(items)
        setClicked(!clicked);
    }

    const customerSelector = (input) => {

        if (checkNullOrEmptyArray(items) && checkNullOrEmpty(input)) {

            const filtered = items?.filter((item) => {
                return Object.keys(item)
                    .some((key) => {
                        return (
                            item[key]?.toString()
                                .toLowerCase()
                                .includes(input?.toLowerCase())
                        )
                    })
            });

            if (!selectedCustomer?.some(item => item?.id === filtered[0]?.id)) {

                setSelectedCustomer([...selectedCustomer, filtered[0]])
            }
        }
    }

    return (
        <>
            <div ref={ref}
                className={cx(styles.inputcontainer, isError && styles.error)} style={{ width: inputWidth, height, fontSize }}>
                <div className={styles.inputarea}>
                    <input onBlur={onBlur} onClick={handleRef} className={cx(error && styles.righterror, styles.input)} value={inputValue} maxLength={maxLength} type={type} name={name} id={id} placeholder={placeholder} />
                </div>
                <div onClick={handleSearch} className={styles.iconarea}>
                    <AddIcon />
                </div>
            </div>
            {
                isError && (<span className={styles.errortext}>{error}</span>)
            }
            {
                clicked &&
                <div ref={areaRef} className={styles.searcharea}
                    style={{
                        left: ref?.current?.offsetLeft,
                        top: ref?.current?.offsetTop + ref?.current?.offsetHeight + 5,
                        width: ref?.current?.clientWidth + 2,
                    }}
                >
                    <div className={styles.itemstop}>
                        <div className={styles.selectingperson}> {selectedCustomer?.length} {littleText} Seçildi</div>
                        <div className={styles.removeallbutton} onClick={handleRemoveAll}>Tümünü Kaldır <CloseIcon size='15px' /> </div>
                    </div>
                    <div className={styles.inside}>

                        {filteredData?.length > 0 ? filteredData?.map((data) => {

                            return (
                                < SelectItem data={data} callback={handleChange} checked={selectedCustomer.some(item => item.id == data.id)} deselectAll={isDeselectAll} />
                            )

                        }) : <div className={styles.emptyalert}>Müşteri Bulunamadı</div>}
                    </div>
                </div>
            }
        </>
    )
}

export default MultipleSelect