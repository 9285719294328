import React from 'react'
import styles from './checkbox.module.css'
import cx from 'classnames';
const CheckBox = ({ text = "", secondText = "", style,isClicked,checked,id='checkbox',disabled }) => {

  const handleClick = e =>{
    console.log('triggered')
    isClicked(e.target.checked);
  }
  return (
    <div style={style} class={cx( disabled && styles.disabled,styles.checkcontainer)}>
      <input checked={checked == true ? true :false} onClick={handleClick} type="checkbox" id={id} />
      <label className={cx(styles.label)} htmlFor={id}></label>
      <div>
        {
          secondText.length > 0 ?
            <>
              <div>{text}</div>
              <div>{secondText}</div>
            </>
            :
            <div>{text}</div>
        }
      </div>
    </div>
  )
}

export default CheckBox