import Button from "components/Global/Button";
import DoubleButton from "components/Global/DoubleButton";
import PobUp from "components/Global/PopUp/PobUp";
import CloseIcon from "components/icons/CloseIcon";
import EditIcon from "components/icons/EditIcon";
import { checkNullOrEmpty } from "hoc/Conditions";
import React, { useEffect } from "react";
import { useRef } from "react";
import styles from "./dealercollapse.module.css";

const DealerCollapse = ({ item, handleUpdateClick, handleDeleteClick, deleteSelector, userName, currentRef, popUpClick, isActive, handlePopUp, userRole }) => {

    const refs = useRef([])


    useEffect(() => {
        console.log("item5", item)
        console.log("Role", userRole)

    }, [item])

    return (
        <div className={styles.collapsecontainer}>
            <div className={styles.row1}>
                {
                    userRole == 5 ? "" :
                        <div className={styles.col1}>
                            <span className={styles.rowtexts}>Alt Bayi sayısı</span>
                            <span className={styles.datas}> {item.details?.subDealerCount}</span>
                        </div>
                }
                <div className={styles.col1}>
                    <span className={styles.rowtexts}>Kullanıcı sayısı</span>
                    <span className={styles.datas}> {item.details?.userCount}</span>
                </div>
                <div className={styles.col1}>
                    <span className={styles.rowtexts}>{item.details?.transactionLimitAmountAndCurreny == undefined ? "" : "Limit Bilgisi"}</span>
                    {
                        item.details?.transactionLimitAmountAndCurreny != undefined ?
                            <span className={styles.datas}> {checkNullOrEmpty(item.details?.transactionLimitAmountAndCurreny) ? 0 : item.details?.transactionLimitAmountAndCurreny}</span> : <span style={{ marginLeft: "300px" }}></span>
                    }
                </div>
            </div>
            <div className={styles.row2}>
                <div className={styles.col2}>
                    <span className={styles.rowtexts}>Adres</span>
                    <span className={styles.datas}> {item.details?.address}</span>
                </div>
            </div>
            <div className={styles.details}>

                <div className={styles.buttons}>
                    <DoubleButton
                        firstOnClick={() => { handleUpdateClick(item?.details?.pobDealerId) }}
                        secondOnClick={() => { deleteSelector(item?.details?.pobDealerId, item?.i) }}
                        ref={refs}
                    ></DoubleButton>

                </div>
            </div>

        </div>
    );
};

export default DealerCollapse;