import React, { useEffect, useState } from 'react';
import styles from './subdealerupdate.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import Dropdown from 'components/Global/Dropdown';
import formRealPersonValidator from './FormValidator'
import { checkNullOrEmpty } from "hoc/Conditions";
import ReactInputMask from 'react-input-mask';

var initialUpdateDealer = {
    pobSubDealerId: "",
    cityCode: 0,
    countryCode: 212,
    districtId: "",
    address: "",
    phoneNumber: "",
    pobSubDealerName: "",
    companyName: "",
};

var initialDistrictSearch = {
    cityCode: 0,
    countryCode: 0
};

const SubDealerUpdate = ({ dealer = initialUpdateDealer, handleModalClick, style }) => {

    const [responseErrors, setResponseErrors] = useState();//api den gelen hatalar
    const [isLoading, setIsLoading] = useState()
    const [cities, setCities] = useState();
    const [districts, setDistricts] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [isFull, setisFull] = useState(false);

    useEffect(() => {

        getAllCitiesDataFromApi();
        getAllDistrictsDataFromApi();

    }, []);

    useEffect(() => {
        (
                 values.pobSubDealerId == null
                || values.pobSubDealerName == ""
                || values.phoneNumber.length < 10
                || values.districtId == null
                || values.address == null
                || values.cityCode == null
        ) ?
            setisFull(true)
            : setisFull(false)
        })

    useEffect(() => {
        console.log("Get Dealer Data : ", dealer)
        setValues(dealer)
    }, [dealer])

    const { handleChange, handleBlur, touched, values, handleSubmit, errors, setValues, handleReset } = useFormik({

        initialValues: {

            pobSubDealerId: dealer.pobSubDealerId,
            pobSubDealerName: dealer.pobSubDealerName,
            phoneNumber: dealer.phoneNumber,
            districtId: dealer.districtId,
            cityCode: dealer.cityCode,
            countryCode: dealer.countryCode,
            address: dealer.address,
            companyName: dealer.companyName,
            customCode: dealer.customCode
        },
        
        onSubmit: values => {

            setIsLoading(true);

            if (checkNullOrEmpty(selectedCity)) {
                values.cityCode = parseInt(selectedCity)
            }
            if (checkNullOrEmpty(selectedDistrict)) {
                values.districtId = selectedDistrict
            }

            // console.log("Update Values : ", values)
            api.SubDealerManagement().Update(values).then((response) => {

                setSelectedDistrict();
                setSelectedCity();
                handleReset({})
                handleModalClick(false)

                // console.log("Update response : ", response)
            }).catch((error) => {
                // handle error
                console.log('error', error.response.data);
                // setResponseErrors(error?.response.data?.message);
                setResponseErrors(error?.response?.data.userMessage)

            }).finally(() => {
                setIsLoading(false);
            });

        },
        validationSchema: formRealPersonValidator
    });

    const getAllCitiesDataFromApi = () => {
        api.Helpers().GetAllCity({ isAll: true })
            .then((response) => {
                setCities(response.data.data.map((item) => (
                    {
                        id: item.cityCode,
                        text: item.cityName
                    }
                )))
                // console.log("Get Cities response : ", response.data.data)
            })
            .catch((error) => {
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data.message);
                setResponseErrors("Şehirler getirilirken Bir hata oluştu lütfen tekrar deneyiniz.")

            });
    }

    const getAllDistrictsDataFromApi = (searchData = initialDistrictSearch) => {
        api.Helpers().GetAllDistrict(searchData)
            .then((response) => {
                setDistricts(response.data.data.map((item) => (
                    {
                        id: item.districtId,
                        text: item.districtName
                    }
                )))
            })
            .catch((error) => {
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data.message);
                setResponseErrors("ilçeler getirilirken Bir hata oluştu lütfen tekrar deneyiniz.")

            });
    }

    const handleSelectCityDropdown = (cityCode) => {
        setSelectedCity(cityCode);
        getAllDistrictsDataFromApi({ cityCode: cityCode });
    }

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />

            <form onSubmit={handleSubmit} className={styles.form} style={style}>
                <section>
                    <span className={styles.infoheader}>Alt Bayiyi Düzenle</span>
                    <div className={styles.inputarea}>

                        <Input
                            error={touched.pobSubDealerName && errors.pobSubDealerName}
                            name='pobSubDealerName'
                            width='100%'
                            value={values.pobSubDealerName}
                            placeholder='Bayi Adı'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <Input
                            error={touched.companyName && errors.companyName}
                            name='companyName'
                            width='100%'
                            value={values.companyName}
                            placeholder='Firma Adı'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <Input
                            error={touched.customCode && errors.customCode}
                            name='customCode'
                            width='100%'
                            height='3rem'
                            value={values.customCode === 0 ? "" : values.customCode}
                            placeholder='Bayi Kodu (Opsiyonel)'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <ReactInputMask
                            mask="999 999 99 99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            maskChar=""
                        >
                            {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                        </ReactInputMask>

                        <Input
                            error={touched.address && errors.address}
                            name='address'
                            width='100%'
                            value={values.address}
                            placeholder='Adres'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <Dropdown
                            error={touched.districtId && errors.districtId}
                            width='100%'
                            items={districts}
                            text='İlçe'
                            selectItemId={values.districtId + "" === "00000000-0000-0000-0000-000000000000" ? undefined : values.districtId}
                            onSelected={setSelectedDistrict}
                            border="2px solid #e6e6e6"
                            backgroundColor="#fafafa"
                        />

                        <Dropdown
                            error={touched.cityCode && errors.cityCode}
                            width='100%'
                            items={cities}
                            text='Şehir'
                            selectItemId={values.cityCode}
                            onSelected={handleSelectCityDropdown}
                            border="2px solid #e6e6e6"
                            backgroundColor="#fafafa"
                        />

                    </div>
                    <div className={styles.footer}>
                        <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull}  />
                    </div>
                </section>
            </form>

            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }

        </>
    )
};

export default SubDealerUpdate;
