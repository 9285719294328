import api from "../store/api/actions";
import { checkNullOrEmpty } from "./Conditions";


const GetPaymentInfo = (setPaymentInfo, searchParams, setModalClick) => {

    //Eğer QueryParams da Hash parametresi varsa; http://localhost:3000/?Hash=MjUwYTBlY2QtMWRiNy00MTYyLWE0YWMtYTVhOTMyYmUyZGYz
    const hash = searchParams.get("Hash");

    // console.log("hash",hash);
    if (checkNullOrEmpty(hash)) {
        //kart ile ödeme işleminden sonra geriye sayfaya dönülmüştür 
        api
            .RedirectUrl()
            .GetPaymentInformation({ hash: hash })
            .then((response) => {
                console.log("RedirectUrl GetPaymentInformation", response.data);
                if (checkNullOrEmpty(response.data.data)) {
                    response.data.data.status = response.data.success
                        setPaymentInfo(response.data.data);
                        if (checkNullOrEmpty(setModalClick)) {
                            setModalClick(true);
                        }
                }
            })
            .catch((error) => {
                console.log("error", error?.response?.data);
            });

    }

    return;
}

export default GetPaymentInfo;
