import React from 'react'

const UserIcon = () => {
    return (
        <symbol id="user" >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 14.675c4.7 0 7.407.939 7.407 3.603 0 2.661-2.692 3.58-7.406 3.58-4.702 0-7.408-.937-7.408-3.6 0-2.66 2.699-3.583 7.408-3.583zm0 1.715c-3.778 0-5.693.654-5.693 1.869 0 1.221 1.917 1.884 5.694 1.884 3.783 0 5.692-.651 5.692-1.865 0-1.224-1.917-1.888-5.692-1.888zM12 3c2.796 0 5.056 2.305 5.056 5.139 0 2.835-2.26 5.138-5.055 5.138l-.33-.01c-2.65-.165-4.736-2.406-4.726-5.128C6.945 5.305 9.205 3 12.001 3zm0 1.714c-1.84 0-3.34 1.53-3.34 3.428-.007 1.887 1.48 3.415 3.31 3.421l.28-.01c1.726-.13 3.092-1.605 3.092-3.414 0-1.895-1.5-3.425-3.341-3.425z" fill="#000D89" />
                </svg>
            </svg>
        </symbol>
    )
}

export default UserIcon





