import Layout from '../../components/Layout/Layout';
import React from 'react';
import SubDealerManagementForDealer from 'components/SubDealerManagement/Standard';
import UnAuthorized from 'container/UnAuthorized/UnAuthorized';
import Cookies from 'universal-cookie';


export default function SubDealerManagement() {
    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole

    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
        case "1":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    <SubDealerManagementForDealer />
                </Layout>
            )

        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )

        default:
            return (
                <Layout>
                    ROLE COOKIE ERROR
                </Layout>
            )

    }
}
