import React from 'react';

const AddIcon = ({ color = "#000D89" }) => {
    return <>
        <symbol id="add" >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M10.333 20.333a1.667 1.667 0 1 0 3.334 0v-6.666h6.666a1.667 1.667 0 1 0 0-3.334h-6.666V3.667a1.667 1.667 0 0 0-3.334 0v6.666H3.667a1.667 1.667 0 0 0 0 3.334h6.666v6.666z" fill={color} />
            </svg>


        </symbol>
    </>;
};

export default AddIcon;
