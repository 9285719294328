import Button from 'components/Global/Button'
import Cardboard from 'components/Global/Cardboard'
import Dropdown from 'components/Global/Dropdown'
import List from 'components/Global/List'
import ListContainer from 'components/Global/ListContainer'
import TextArea from 'components/Global/TextArea'
import AddIcon from 'components/icons/AddIcon'
import React, { useState } from 'react'
import { useEffect } from 'react'
import ResponsiveFilterArea from '../FilterArea'
import ResponsiveSupportCollapse from '../global/List/ResponsiveCollepseForSupport'
import ResponsiveModal from '../Modal'
import styles from './support.module.css'

const SupportResponsive = ({ currentItems, getSupportDataFromApi, topicsData, topicFilter, filter, setTopicFilter, setFilter, formik, onSubmitSupport, isSelectedTopic, setIsSelectedTopic, isSelectedFilterTopic, setIsSelectedFilterTopic, handleSubmitSelectedDropdown }) => {
  const [isClosed, setIsClosed] = useState(true) //isClosed true ise açılmıyo /kapanıyor false ise açılıyo
  const [modalClick, setModalClick] = useState(true); //çarpı butonuna basılınca false oluyo
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    isClosed && setModalClick(false)
    isClosed == false && setModalClick(true)
  }, [isClosed])

  useEffect(() => {
    (formik.values.description == "" || formik.values.supportTopicTypeId == "") ? setButtonDisable(true) : setButtonDisable(false)
  })

  const handleOnSubmit = (value) => {
    formik.handleSubmit(value)
    setIsClosed(true)
  }

  const handleModalClick = (status) => {
    setModalClick(status);
    setIsClosed(true);
    getSupportDataFromApi()
    formik.values.description = ""
    formik.values.supportTopicTypeId = ""
  }

  useEffect(() => {
    setIsSelectedTopic(isSelectedTopic)
  }, [isSelectedTopic])

  useEffect(() => {
    setIsSelectedFilterTopic(isSelectedFilterTopic)
  }, [isSelectedFilterTopic])

  return (
    <div className={styles.container}>
      <div className={styles.filtercontainer}>
        <ResponsiveModal

          isClosed={isClosed}
          body={
            <div className={styles.modalform}>
              <div className={styles.forminfo}>Destek al</div>
              <form className={styles.form} onSubmit={handleOnSubmit}>
                <div className={styles.formitems}>
                  <Dropdown
                    width="100%"
                    color="black"
                    items={topicsData}
                    onSelected={handleSubmitSelectedDropdown}
                    selectItemId={topicFilter}
                    text="Destek talebinizin konusunu seçiniz"
                    error={formik.touched.supportTopicTypeId && Boolean(formik.errors.supportTopicTypeId)}
                    helperText={formik.touched.supportTopicTypeId && formik.errors.supportTopicTypeId}
                    onSubmit={onSubmitSupport}
                  />
                  <TextArea
                    width="100%"
                    id="description"
                    placeholder="Destek talebinizin konusunu yazınız."
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />

                  <Button
                    type="submit"
                    width="100%"
                    text="Gönder"
                    fontSize="1.3rem"
                    disabled={buttonDisable}
                  />
                </div>
              </form>
            </div>
          }
          callbackEvent={handleModalClick}
        />
        <ResponsiveFilterArea firstElement={
          <div className={styles.filter}>
            <Dropdown width={"100%"} text={"Destek Konusu"} color={"black"} items={topicsData} selectItemId={filter} onSelected={(item) => setFilter(item)} onSubmit={onSubmitSupport} />
            <div style={{ width: "10px" }} />
            <Button icon={<AddIcon width={"24px"} height={"16px"} fill={"#000d89"} />} backgroundImage="none" width={"50px"} height={"50px"} onClick={() => setIsClosed(false)} />
          </div>
        } isButtonActive={false} modalClosed={isClosed} />
      </div>

      <Cardboard style={{ flex: "1", margin: "15px" }}>
        <ListContainer>
          <List
            fontFamily="Metropolis"
            collapsed
            CollapseComponent={<ResponsiveSupportCollapse />}
            columnNames={[
              "DESTEK KONUSU",
              "CEVAP DURUMU",
              "",
              "",
              "",
            ]}
            data={currentItems}
          />
        </ListContainer>
      </Cardboard>
    </div>
  )
}

export default SupportResponsive