import React, { useState, useEffect, useRef } from "react";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import List from "components/Global/List";
import Cardboard from "components/Global/Cardboard";
import Button from "components/Global/Button";
import FilterInfoButton from "components/Global/FilterInfoButton";
import InputSearch from "components/Global/InputSearch";
import InputRange from "components/Global/InputRange";
import Dropdown from "components/Global/Dropdown";
import ListContainer from "components/Global/ListContainer";
import Pagination from "components/Global/Pagination";
import api from "store/api/actions";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import CreditCardIcon from "components/icons/CreditCardIcon";
import LinkPaymentIcon from "components/icons/LinkPaymentIcon";
import TransactionCollapse from "components/Global/List/CollapseForTransactions";
import CustomBreadcrumbs from "hoc/CustomBreadcrumbs";
import DeleteIcon from "components/icons/CloseIcon";
import ExportCSV from "hoc/ExportCSV";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import { useLocation } from "react-router";
import Check from "components/Global/Check";
import BottomSticky from "components/Global/BottomSticky";
import DateLabel from "components/Global/DateLabel";
import TablePriceLabel from "components/Global/TablePriceLabel";
import Label from "components/Global/Label";
import StatusLabel from "components/Global/StatusLabel";
import TripleButton from "components/Global/TripleButton";
import { Chip, FormControlLabel } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RefreshIcon from "@mui/icons-material/Refresh";
import DateTimePicker from "components/Global/DateTimePickerv2";
import Cookies from 'universal-cookie';
import PobUp from "components/Global/PopUp/PobUp";
import { useMediaQuery } from "react-responsive";
import TransactionResponsive from "components/Responsive/Transactions";
import ExportExcel from "hoc/ExportExcel";

//#region initialState
const initialSearchDataState = {
  inputSearch: "",
  startAmount: 0,
  endAmount: 0,
  paymentTypeId: 0,
  startTransactionDate: "",
  endTransactionDate: "",
  paymentStatusIdList: [],
  paymentGroupStatusIdList: [],
  erpStatusList: [],
  userList: [],
  limit: 10,
  orderBy: 0,
  pageNo: 0
};

const initialResponseState = {
  paymentList: [
    {
      paymentId: "",
      transactionDate: "",
      customerName: "",
      amountAndCurrencyCode: "",
      userName: "",
      installment: 0,
      paymentStatus: "",
      paymentTypeId: 0,
      paymentStatusId: 0,
      linkExpireDate: "",
      erpStatus: "",
      details: {
        referanceNumber: "",
        commissionAmount: "",
        paymentRecipient: "",
        transactionDescription: "",
        bankName: "",
        cardType: "",
        creditType: "",
        nameOnTheCard: "",
        cardNumber: "",
        threeDSecurity: "",
        customerPhoneNumber: "",
        customerEMail: ""
      }
    }
  ],
  paymentTransactionListTotalCount: 0,
  waitingPaymentTransactionTotalCount: 0,
  timeOutPaymentTransactionTotalCount: 0,
  rejectedPaymentTransactionTotalCount: 0,
  cannotBeSentToErpTransactionTotalCount: 0
};

//#endregion

const TransactionSMPStandard = () => {
  const cookies = new Cookies();
  var userErpStatus = cookies.get('isHaveErp');

  const { state } = useLocation(); // accountedFor/willAccounted tarih filtresi(01.03.2022)

  //#region Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [paymentResponseListTotalCount, setPaymentResponseListTotalCount] =
    useState(0);
  //#endregion

  const [breadcrumbs, setBreadcrumbs] = useState(["Tüm Liste"]);
  const [isLoading, setIsLoading] = useState(false); //kuyruğa alınınca true oluyor
  const [disable, setDisable] = useState(false); //ButtonGroupStatus
  const [listData, setListData] = useState([]); //değişken data
  const [responseData, setResponseData] = useState({}); //sbt data
  const [searchData, setSearchData] = useState();
  const [currentItemsResponsive, setCurrentItemsResponsive] = useState([[]]);

  //#region Filter
  const [paymentTypeFilter, setPaymentTypeFilter] = useState([]);

  const [paymentStatusData, setPaymentStatusData] = useState([]); // e.g. banka reddi - müşteri reddi
  const [paymentGroupStatusData, setPaymentGroupStatusData] = useState([]); //e.g. reddedilenler
  const [paymentStatusFilter, setPaymentStatusFilter] = useState([]);

  const [erpData, setErpData] = useState([]);
  const [erpFilter, setErpFilter] = useState("");
  //#endregion

  const [fileName, setFileName] = useState("İşlemler");
  const [exportData, setExportData] = useState([]);

  const [selectedButtonData, setSelectedButtonData] = useState([]); //Bakleyen/Zaman aşımı.. butonlarının içerdiği datalar
  const [filterButtonStatus, setFilterButtonStatus] = useState(true); //Filter butonlarının durumu

  const [multipleSelection, setMultipleSelection] = useState([]);
  const [isMultipleSelection, setIsMultipleSelection] = useState(false);

  const [selectedButton, setSelectedButton] = useState();

  const [selectedButtonStatus, setSelectedButtonStatus] = useState(false);

  const [multiFilterData, setMultiFilterData] = useState([]);
  const refs = useRef([])
  const [currentRef, setCurrentRef] = useState()
  const [popUpClick, setPopUpClick] = useState(false);
  const [isActive, setIsActive] = useState(false)
  const [customerName, setCustomerName] = useState()
  const [deleteId, setDeleteId] = useState()
  const [popupPosition, setPopupPosition] = useState("")
  const [item, setItem] = useState()
  const [sendText, setSendText] = useState("hatırlatma")

  const [allData, setAllData] = useState([])
  const [allSearchData, setAllSearchData] = useState({ limit: -1 });

  useEffect(() => {
    if (checkNullOrEmpty(state)) {
      console.log("state", state)
      setFilterButtonStatus(true);

      if (checkNullOrEmpty(state?.dateNow)) {

        dashboardCartFilterData();

      } else {

        if (state?.listingTypeId === 1) {
          accountedForAndWillAccountedDateFilterData();
        }
        else if (state?.listingTypeId === 2) {
          setTimeout(() => {
            setSearchData({ ...searchData, userList: [state?.pobUserId] });
          }, 1000);
        }
      }
    } else {
      setSearchData({ ...searchData, limit: 10 });
    }

    //#region GetAllPaymentStatus
    api
      .Helpers()
      .GetAllPaymentStatus({})
      .then((response) => {
        //console.log("GetAllPaymentStatus", response.data.data);
        setPaymentStatusData(
          response.data.data.map((item) => ({
            id: item.paymentStatusId,
            text: item.paymentStatusName,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
    //#endregion

    //#region GetAllPaymentGroupStatus
    api
      .Helpers()
      .GetAllPaymentGroupStatus({})
      .then((response) => {
        //console.log("GetAllPaymentGroupStatus", response.data.data);
        setPaymentGroupStatusData(
          response.data.data.map((item) => ({
            id: item.paymentGroupStatusId,
            text: item.paymentGroupStatusName,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
    //#endregion

    //#region GetAllERP
    api
      .Helpers()
      .GetAllERPStatus({})
      .then((response) => {
        //console.log("GetAllERPStatus", response.data.data);
        setErpData(
          response.data.data.map((item) => ({
            id: item.erpStatusId,
            text: item.erpStatusName,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
    //#endregion

    api
      .SmallMarketPlace()
      .PaymentTransactionList(allSearchData)
      .then((response) => {
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setAllData(response.data.data.paymentList);
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getAllTransactionsDataFromApi();
    }
  }, [searchData]);

  //#region export
  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(selectedButtonData)) {
      updateExportData(selectedButtonData);
    }
  }, [selectedButtonData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(multiFilterData)) {
      updateExportData(multiFilterData);
    }
  }, [multiFilterData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(
        allData.filter((item) =>
          multipleSelection.includes(item.paymentId)
        )
      );
    }
  }, [multipleSelection]);
  //#endregion

  //#region Pagination
  useEffect(() => {
    setPageCount(Math.ceil(paymentResponseListTotalCount / itemsPerPage));
  }, [paymentResponseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row, i) => [
          <>
            {isMultipleSelection ? (
              <Check
                key={i}
                id={row.paymentId}
                onChange={setMultiSelectRow}
                checked={multipleSelection.indexOf(row.paymentId) > -1}
                disabled={row.paymentTypeId === 1 ? true : false}
              />
            ) : (
              setCartAndPaymentLinkColor(row)
            )}
          </>,
          <DateLabel
            time={setTimeFormat(row.transactionDate)}
            date={setDateFormat(row.transactionDate)}
          />,
          <TablePriceLabel price={row.amountAndCurrencyCode} />,
          <Label text={row.userName} />,
          <Label text={row.customerName} />,
          <Label bold text={row.installment} />,
          setPaymentStatusDisplay(row),
          <>{userErpStatus == "true" ? setERPStatusDisplay(row) : ""}</>,
          (row.paymentStatusId == 2 || row.paymentStatusId == 1) && row.paymentTypeId == 2 ? setLinkPaymentButtonStatus(row, i, true, false) : setLinkPaymentButtonStatus(row, i, false, true), //is refund olayını getireceksin başarılı işlemlerde
          row.paymentStatusId == 2 && (row.paymentTypeId == 2 || row.paymentTypeId == 1) ? setLinkPaymentButtonStatus(row, i, true, true) : "",
          {
            details: row.details,
          }
        ])
      );

      setCurrentItemsResponsive(
        listData?.map((row, i) => [
          <>
            {isMultipleSelection ? (
              <Check
                key={i}
                id={row.paymentId}
                onChange={setMultiSelectRow}
                checked={multipleSelection.indexOf(row.paymentId) > -1}
                disabled={row.paymentTypeId === 1 ? true : false}
              />
            ) : (
              setCartAndPaymentLinkColor(row)
            )}
          </>,
          <DateLabel
            time={setTimeFormat(row.transactionDate)}
            date={setDateFormat(row.transactionDate)}
          />,
          <TablePriceLabel price={row.amountAndCurrencyCode} />,
          setPaymentStatusDisplay(row),
          {
            details: row.details,
            data: row,
            index: i,
            setLinkPaymentButtonStatus
          },
        ])
      );

    } else {
      setCurrentItems([[]]);
      setCurrentItemsResponsive([[]]);
    }
  }, [
    listData,
    itemOffset,
    itemsPerPage,
    isMultipleSelection,
    multipleSelection,
    selectedButtonData,
    multiFilterData,
  ]);

  useEffect(() => {
    if (checkNullOrEmpty(deleteId)) {
      if (sendText == "hatırlatma") {
        ReminderCheck(deleteId)
      } else if (sendText == "yenileme") {
        RefreshCheck(deleteId)
      } else {
        DeleteCheck(deleteId)
      }
      setIsActive(false)
    }
    if (popUpClick) {
      console.log("popup false useeffect")
      setPopUpClick(false)
    }
  }, [popUpClick])

  //#endregion
  const handlePopUp = (status) => {
    setPopUpClick(status)
    // console.log("Handle popup Status : ", status)
    setIsActive(false)
  }

  const DeleteCheck = (id, listId) => {
    setSendText("iptal")
    //listId => ilgili rowun refini arraya appendladigi indis numarasi
    const ref = refs.current[listId]
    setCurrentRef(ref)
    //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
    setDeleteId(id)
    setIsActive(true)
    // console.log("id : ", id)
    // console.log("listId : ", listId)
    listData?.map((item) => {
      if (item?.paymentId == id) {
        // console.log("customer Name : ", item)
        setItem(item)
        setCustomerName(item?.customerName)
        // console.log("Name :", item.)
      }
    })
    // console.log(" Delete :", popUpClick)

    if (popUpClick) {
      console.log("Delete Success")
      handleCancelButtonClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
      console.log("Delete Cancel ")
    }
  }

  const RefreshCheck = (id, listId) => {
    setSendText("yenileme")
    //listId => ilgili rowun refini arraya appendladigi indis numarasi
    const ref = refs.current[listId]
    setCurrentRef(ref)
    //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
    setDeleteId(id)
    setIsActive(true)
    // console.log("id : ", id)
    // console.log("listId : ", listId)
    listData?.map((item) => {
      if (item?.paymentId == id) {
        // console.log("customer Name : ", item)
        setItem(item)
        setCustomerName(item?.customerName)
        // console.log("Name :", item.)
      }
    })
    console.log("Refresh Delete :", popUpClick)

    if (popUpClick) {
      console.log("Refresh Success")
      handleRefreshLinkButtonClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
      console.log("Delete Cancel ")
    }
  }

  const ReminderCheck = (id, listId) => {
    setSendText("hatırlatma")
    //listId => ilgili rowun refini arraya appendladigi indis numarasi
    const ref = refs.current[listId]
    setCurrentRef(ref)

    //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
    setDeleteId(id)
    setIsActive(true)
    console.log("id : ", id)
    console.log("listId : ", listId)
    listData?.map((item) => {
      if (item?.paymentId == id) {
        console.log("customer Name : ", item)
        setItem(item)
        setCustomerName(item?.customerName)
        // console.log("Name :", item.)
      }
    })
    console.log("Delete :", popUpClick)

    if (popUpClick) {
      handleReminderSendButtonClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
      console.log("Delete Cancel ")
    }
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % paymentResponseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };
  //#endregion

  const getAllTransactionsDataFromApi = () => {
    // console.log("searchData", searchData);
    setIsLoading(true);
    api
      .SmallMarketPlace()
      .PaymentTransactionList(searchData)
      .then((response) => {
        //console.log("response.data.data",response.data.data);
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListData(response.data.data.paymentList);
        } else {
          setListData([]);
        }
        if (checkNullOrEmpty(response.data.data)) {
          setResponseData(response.data.data);
        } else {
          setResponseData();
        }
        setPaymentResponseListTotalCount(
          response.data.data.paymentTransactionListTotalCount
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //#region RefreshLink-ReminderSend-Cancal handler
  const handleRefreshLinkButtonClick = (id) => {
    setIsLoading(true);
    api
      .PaymentTransaction()
      .RefreshPaymentRequest({ paymentIds: id })
      .then((response) => {
        getAllTransactionsDataFromApi();
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReminderSendButtonClick = (id) => {
    setIsLoading(true);
    api
      .PaymentTransaction()
      .PaymentRequestReminder({ paymentIds: id })
      .then((response) => {
        getAllTransactionsDataFromApi();
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelButtonClick = (id) => {
    console.log(id);
    api
      .PaymentTransaction()
      .CancelPaymentLinkRequest({ paymentIds: id })
      .then((response) => {
        console.log(response);
        getAllTransactionsDataFromApi();
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion

  const updateExportData = (listValue) => {
    setExportData(
      listValue.map((row) => {
        if (userErpStatus == "true") {
          return {
            "İşlem Saati": `${setTimeFormat(row.transactionDate)}`,
            "TARİH": `${setDateFormat(row.transactionDate)}`,
            "İşlem Tutarı": `${row.amountAndCurrencyCode}`,
            "Kullanıcı Bilgisi": `${row.userName}`,
            "Müşteri İsmi": `${row.customerName}`,
            Taksit: `${row.installment}`,
            "İşlem Durumu": `${row.paymentStatus}`,
            "ERP Durumu": `${row.erpStatus}`,
          };
        } else {
          return {
            "İşlem Saati": `${setTimeFormat(row.transactionDate)}`,
            "TARİH": `${setDateFormat(row.transactionDate)}`,
            "İşlem Tutarı": `${row.amountAndCurrencyCode}`,
            "Kullanıcı Bilgisi": `${row.userName}`,
            "Müşteri İsmi": `${row.customerName}`,
            Taksit: `${row.installment}`,
            "İşlem Durumu": `${row.paymentStatus}`,
          };
        }
      })
    );
  };

  // Bekleyen - Zaman Aşımı - Reddedilen Butonları
  const handleSelectedButtonChange = (e) => {
    setSelectedButtonStatus(true);
    const value = e.target.id;
    setSelectedButton(value);
    setBreadcrumbs(["Tüm Listeler", e.target.dataset.value]);
    if (value === "onHoldButton") {
      setSearchData({ ...searchData, paymentGroupStatusIdList: [1] });
    } else if (value === "timeOutButton") {
      setSearchData({ ...searchData, paymentGroupStatusIdList: [4] });
    } else if (value === "rejectedButton") {
      setSearchData({ ...searchData, paymentGroupStatusIdList: [3] });
    } else if (value === "erpButton") {
      setSearchData({ ...searchData, erpStatusList: [3] });
    }
  };

  const handleRefundButtonClick = (id) => {
    setIsLoading(true);
    console.log("PaymentId for refund : ", id)
    api
      .PaymentTransaction()
      .RefundPaymentRequest({ paymentIds: id })
      .then((response) => {
        console.log("response for refund : ", response.data)

        getAllTransactionsDataFromApi();
      })
      .catch((error) => {
        console.log("error refund", error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelPaymentButtonClick = (id) => {
    setIsLoading(true);
    console.log("PaymentId for CancelPayment : ", id)
    api
      .PaymentTransaction()
      .CancelPaymentRequest({ paymentIds: id })
      .then((response) => {
        console.log("response for CancelPayment : ", response.data)

        getAllTransactionsDataFromApi();
      })
      .catch((error) => {
        console.log("error CancelPayment", error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setLinkPaymentButtonStatus = (row, i, status, isRefund) => {

    if (row.paymentTypeId === 2) {

      if (row.paymentStatusId === 1 || row.paymentStatusId === 9) {

        return <>
          <TripleButton

            secondOnClick={() => { ReminderCheck([row.paymentId], i) }}

            thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
          />
        </>
      }

      return <TripleButton
        isRefund={isRefund}
        status={status}

        firstOnClick={() => { RefreshCheck([row.paymentId], i) }}

        thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
      />

    }

    return <TripleButton
      isRefund={isRefund}
      status={status}
      firstOnClick={() => { handleRefundButtonClick([row.paymentId], i) }}

      thirdOnClick={() => { handleCancelPaymentButtonClick([row.paymentId], i) }}
    />

  }

  const handleSearchChange = (e) => {
    const value = e?.target?.value?.trim();
    // console.log("value", value)
    if (checkNullOrEmpty(value)) {
      setSearchData({ ...searchData, inputSearch: value });
    } else {
      setSearchData({ ...searchData, inputSearch: "" });
    }
  };

  //#region DashBoard-AccountedFor-WillAcoounted Filter
  const setNextDateTimeFormat = (dateTime) => {
    let day = dateTime.slice(0, 2);
    let month = dateTime.slice(3, 5);
    let year = dateTime.slice(6, 10);

    return month + "/" + day + "/" + year;
  };

  const accountedForAndWillAccountedDateFilterData = () => {
    if (checkNullOrEmpty(state) && state?.listingTypeId === 1) {
      setTimeout(() => {
        let nextDay = new Date(
          setNextDateTimeFormat(state.transactionDateOrUserName)
        );
        nextDay.setDate(
          new Date(
            setNextDateTimeFormat(state.transactionDateOrUserName)
          ).getDate() + 1
        );

        let startDate = state.transactionDateOrUserName;
        let endDate = new Date(nextDay).toLocaleDateString();

        setSearchData({
          ...searchData,
          startTransactionDate: setDateTimeFormat(startDate),
          endTransactionDate: setDateTimeFormat(endDate),
        });
      }, 1000);
    }
  };

  const dashboardCartFilterData = () => {
    if (checkNullOrEmpty(state.paymentStatusId)) {
      setTimeout(() => {
        let nextDay = new Date(setNextDateTimeFormat(state.dateNow));
        nextDay.setDate(
          new Date(setNextDateTimeFormat(state.dateNow)).getDate() + 1
        );

        let startDate = state.dateNow;
        let endDate = new Date(nextDay).toLocaleDateString();

        setSearchData({
          ...searchData,
          startTransactionDate: setDateTimeFormat(startDate),
          endTransactionDate: setDateTimeFormat(endDate),
          paymentGroupStatusIdList: [state?.paymentStatusId]
        });
        console.log("state :", [state?.paymentStatusId])

      }, 1000);
    }
    if (checkNullOrEmptyArray(state.paymentStatusIdsArray)) {
      setTimeout(() => {
        let nextDay = new Date(setNextDateTimeFormat(state.dateNow));
        nextDay.setDate(
          new Date(setNextDateTimeFormat(state.dateNow)).getDate() + 1
        );

        let startDate = state.dateNow;
        let endDate = new Date(nextDay).toLocaleDateString();

        setSearchData({
          ...searchData,
          startTransactionDate: setDateTimeFormat(startDate),
          endTransactionDate: setDateTimeFormat(endDate),
          paymentGroupStatusIdList: [state?.paymentStatusIdsArray[0], state?.paymentStatusIdsArray[1]]
        });
      }, 1000);
    }
  };
  //#endregion

  const setPaymentStatusDisplay = (row) => {
    const statusList = [
      { id: 1, text: "Beklemede", detail: dateTimeDiff(row.linkExpireDate) },
      {
        id: 2,
        text: "Başarılı",
        detail:
          setTimeFormat(row.transactionDate) +
          ", " +
          setDateFormat(row.transactionDate),
      },
      { id: 3, text: "Başarısız", detail: "Başarısız" },
      { id: 4, text: "Başarısız", detail: "Müşteri Reddi" },
      { id: 5, text: "Başarısız", detail: "Zaman Aşımı" },
      { id: 6, text: "Başarısız", detail: "Banka Reddi" },
      { id: 7, text: "İptal", detail: row.name },
      { id: 8, text: "İade", detail: row.name },
      { id: 9, text: "Beklemede", detail: dateTimeDiff(row.linkExpireDate) },
    ];

    const status = statusList.find((a) => a.id == row?.paymentStatusId);
    const success = status?.id == 2 ? true : false;
    const failure = status?.id > 2 && status?.id < 9 ? true : false;
    return (
      <StatusLabel
        text={status?.text}
        detail={status?.detail}
        success={success}
        failure={failure}
      />
    );
  };

  const setERPStatusDisplay = (row) => {
    const statusList = [
      { id: 1, text: "Beklemede" },
      { id: 2, text: "Başarılı" },
      { id: 3, text: "Hata", detail: row.erpErrorMessage },
    ];

    const status = statusList.find((a) => a.id == row?.erpStatusId);
    const success = status?.id == 2 ? true : false;
    const failure = status?.id == 3 ? true : false;
    return (
      <StatusLabel
        text={status?.text}
        detail={status?.detail}
        success={success}
        failure={failure}
      />
    );
  };

  const setCartAndPaymentLinkColor = (item) => {
    const statusList = [
      { id: 1, text: "Beklemede" },
      {
        id: 2,
        text: "Başarılı",
      },
      { id: 3, text: "Başarısız" },
      { id: 4, text: "Müşteri Reddi" },
      { id: 5, text: "Zaman Aşımı" },
      { id: 6, text: "Banka Reddi" },
      { id: 7, text: "İptal" },
      { id: 8, text: "İade" },
      { id: 9, text: "Beklemede" },
    ];
    const status = statusList.find((a) => a.id == item?.paymentStatusId);
    let color =
      status?.id == 2
        ? "#1ebcc8"
        : status?.id > 2 && status?.id < 9
          ? "#ff5c5c"
          : "#000D89";

    return item.paymentTypeId == 1 ? (
      <CreditCardIcon color={color} />
    ) : (
      <LinkPaymentIcon color={color} />
    );
  };

  const dateTimeDiff = (dateTime) => {
    const date1 = Date.now();
    const date2 = Date.parse(dateTime);
    var hours = Math.abs(date1 - date2) / 36e5;
    if (hours < 25) {
      return Math.floor(hours) + " saat";
    }
    if (checkNullOrEmpty(dateTime)) {
      return setTimeFormat(dateTime) + ", " + setDateFormat(dateTime);
    }
  };

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };

  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };

  //hangi sayfada ne var, durum kontrolu icin olusturulmus bir state
  const [pageSelection, setPageSelection] = useState([])
  //page selectionda bir aksiyon oldugunda multipleselectionu guncellemek icin kullaniliyor
  useEffect(() => {
    //arraylari cekiyoruz
    const arrays = pageSelection.map(item => item.selectedItems)
    //arraylerin tamamini tek bir array haline getiriyoruz
    const newArray = arrays.flat()
    //multipleselectiona ekliyoruz
    setMultipleSelection(newArray)
  }, [pageSelection])

  useEffect(() => {
    //page numarasina gore isAllSelected mi diye bakiyoruz
    const checkIsAllSelected = pageSelection.find(item => {
      if (item.pageNumber == pageNumber && item.isAllSelected) {
        return true
      }
      return false
    })
    //duruma gore tumu checkboxunu force ediyoruz ki tumu seciliyse tumu checked gelsin.
    if (checkIsAllSelected) {
      setForceAllCheck(true)
    } else {
      //allcheck yoksa mevcuttaki selectionlari set ediyoruz.
      setForceAllCheck(false)
    }
  }, [pageNumber])
  //sayfada gosterilecek veri sayisi degisirse hersey sifirlanir
  useEffect(() => {
    //useEffecti triggerlatir orasi da temizlenir
    setPageSelection([])
    //tumu secenekleri varsa temizlenir
    setForceAllCheck(false)
  }, [itemsPerPage])

  //bu Chipi force etmek icin olusturulmus bir state -- yukariya tasinabilir.
  const [forceAllCheck, setForceAllCheck] = useState(false)

  function setMultiSelectRow(e) {
    if (e.target.id === "multiSelect") {
      if (e.target.checked) {
        setForceAllCheck(true)
        const data = listData.filter((row) => row.paymentTypeId !== 1);
        if (checkNullOrEmptyArray(data)) {
          //ne olursa olsun filtreden gecirerek pageNumberi esit olan datayi siliyoruz.(select all oldugu icin eksiklikleri bulup eklemek yerine elimizdeki daha guvenilir veriyi kullaniyoruz)

          //silme islemini gerceklestirdik
          const filtered = pageSelection.filter((row) => row.pageNumber != pageNumber);
          //datadaki paymentId bilgisini mapliyoruz
          const idList = data.map((item) => item.paymentId)
          //yeni verilerimizi yeniden create ediyoruz.
          //asagidaki state guncellemesi effecti tetikleyecek ve biz orada setMultipleSelection guncellemesi gerceklestiriyoruz.
          setPageSelection([...filtered, {
            pageNumber: pageNumber,
            selectedItems: idList,
            isAllSelected: true,
          }])
        }
      } else {
        //unchecked durumu
        setForceAllCheck(false)
        setPageSelection(pageSelection.filter((item) => item.pageNumber !== pageNumber))
        //ayni seklilde useEffectine gidiyor ve yeniden bir guncelleme yapiyor
      }
    } else {
      const { id } = e.target;
      if (e.target.checked) {
        //checked durumda ise
        //multiple selectiona ekliyoruz
        //mevcut sayfa daha onceden selection olmus mu diye bakiyoruz.
        const searchCurrentPage = pageSelection.find((item) => item.pageNumber === pageNumber)
        if (searchCurrentPage) {
          //eger varsa
          //eski idlerle yeni id bilgisini merge ediyoruz.
          const newSelectedItems = [...searchCurrentPage.selectedItems, id]
          //modelimizi yeniden olusturalim
          const newPageSelection = pageSelection.map((item) => {
            if (item.pageNumber === pageNumber) {
              return {
                //propertylerin hepsi gecerli
                ...item,
                //bu alttaki iki arkadas haric
                selectedItems: newSelectedItems,
                isAllSelected: false
              }
            }
            return item
          }
          )
          //yeni modeli set edelim -- ve yine useEffecti tetiklenir
          setPageSelection(newPageSelection)
        } else {
          //onceden selection yoksa elimizdeki mevcut id bilgisiyle olusturalim. Anlayabilmeniz icin ternary if yapilarini kullanmadim.
          //i love effects
          setPageSelection([...pageSelection, {
            pageNumber: pageNumber,
            selectedItems: [id],
            isAllSelected: false,
          }])
        }
      } else {
        //unchecked durumu
        setForceAllCheck(false)
        setPageSelection(pageSelection.map((item) => {
          if (item.pageNumber === pageNumber) {
            return {
              ...item,
              //filter yaparak idyi mevcut listeden cikartiyoruz.
              selectedItems: item.selectedItems.filter((e) => e != id),
              isAllSelected: false,
            }
          }
          return item
        }))


        // //aradaki 3 farki bulmaniz icin yorum satirina aldim
        // const selectedIndex = multipleSelection.indexOf(id);
        // let newSelected = [];
        // if (selectedIndex === -1) {
        //   newSelected = newSelected.concat(multipleSelection, id);
        // } else if (selectedIndex === 0) {
        //   newSelected = newSelected.concat(multipleSelection.slice(1));
        // } else if (selectedIndex === multipleSelection.length - 1) {
        //   newSelected = newSelected.concat(multipleSelection.slice(0, -1));
        // } else if (selectedIndex > 0) {
        //   newSelected = newSelected.concat(
        //     multipleSelection.slice(0, selectedIndex),
        //     multipleSelection.slice(selectedIndex + 1)
        //   );
        // }
        // setMultipleSelection(newSelected);
      }
    }
  }

  //#region Filter
  const setDateTimeFormat = (dateTime) => {
    let day = dateTime.slice(0, 2);
    let month = dateTime.slice(3, 5);
    let year = dateTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (value) => {
    let startDate = new Date(value.startDate).toLocaleDateString();
    let endDate = new Date(value.endDate).toLocaleDateString();

    if (value.endDate != null) {
      setSearchData({
        ...searchData,
        startTransactionDate: setDateTimeFormat(startDate),
        endTransactionDate: setDateTimeFormat(endDate),
      });
    }
  };

  const handleAmountRangeChange = (value) => {
    if (checkNullOrEmpty(value) && value !== 0 && isNaN(value.endAmount) == true) {
      setSearchData({
        ...searchData,
        startAmount: parseFloat(value?.firstVal?.replace(",", "")),
        endAmount: parseFloat(value?.secondVal?.replace(",", "")),
      });
    } else {
      setSearchData({ ...searchData, startAmount: 0, endAmount: 0 });
    }
  };

  const handlePaymentTypeChange = (value) => {
    if (checkNullOrEmpty(value)) {
      setSearchData({ ...searchData, paymentTypeId: value });
    } else {
      setSearchData({ ...searchData, paymentTypeId: "" });
    }
  };

  const handleTransactionStatusChange = (value) => {
    console.log("value", value);
    const data = paymentGroupStatusData.find(
      (item) => item.id.toString() === value
    );

    if (checkNullOrEmpty(data) && data !== 0) {
      setSearchData({ ...searchData, paymentGroupStatusIdList: [data?.id] });
    } else {
      setSearchData({ ...searchData, paymentGroupStatusIdList: [] });
    }
  };

  const handleERPChange = (value) => {
    if (checkNullOrEmpty(value) && value !== 0) {
      setSearchData({ ...searchData, erpStatusList: [value] });
    } else {
      setSearchData({ ...searchData, erpStatusList: [] });
    }
  };

  //#endregion

  const pull_data = (refNo) => {
    return listData.find(item => item?.details?.referanceNumber == refNo);
  }

  const filterItems = [
    <InputSearch
      width="15%"
      placeholder={"Müşteri,kart,işlem no,kullanıcı"}
      onChange={handleSearchChange}
    />,
    <div style={{ width: "15%" }}></div>,
    <FilterInfoButton
      width="15%"
      height="3rem"
      id="onHoldButton"
      text="Bekleyen İşlemler"
      value="Bekleyen İşlemler"
      count={responseData?.waitingPaymentTransactionTotalCount}
      onClick={handleSelectedButtonChange}
    />,
    <FilterInfoButton
      width="15%"
      height="3rem"
      id="timeOutButton"
      text="Zaman Aşımındaki İşlemler"
      value="Zaman Aşımındaki İşlemler"
      onClick={handleSelectedButtonChange}
      count={responseData?.timeOutPaymentTransactionTotalCount}
    />,
    <FilterInfoButton
      width="15%"
      height="3rem"
      id="rejectedButton"
      text="Reddedilen İşlemler"
      value="Reddedilen İşlemler"
      onClick={handleSelectedButtonChange}
      count={responseData?.rejectedPaymentTransactionTotalCount}
    />,
    <>
      {userErpStatus == "true" ? (
        <FilterInfoButton
          width="15%"
          height="3rem"
          id="erpButton"
          text="ERP'ye Gönderilemeyenler"
          onClick={handleSelectedButtonChange}
          count={responseData?.cannotBeSentToErpTransactionTotalCount}
        />
      ) : (
        ""
      )}
    </>,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="100px"
    //   height="3rem"
    // />,
    <ExportExcel
      excelData={exportData}
      fileName={fileName}
      text="İndir"
    />,
  ];

  const selectedButtonFilter = [
    <CustomBreadcrumbs breadcrumbs={breadcrumbs} />,
    <InputSearch
      width="15%"
      placeholder={"Müşteri,kart,işlem no,kullanıcı"}
      onChange={handleSearchChange}
    />,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="100px"
    // />,
    <ExportExcel
      excelData={exportData}
      fileName={fileName}
      text="İndir"
    />,
  ];

  const dropdownPaymentTypeItems = [
    //id-text required
    { id: 1, text: "Kart ile ödeme" },
    { id: 2, text: "Link ile ödeme" },
  ];

  const collapsedItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="15%"
      stringVal={state?.transactionDateOrUserName == "NaN.NaN.NaN"  ? "Lütfen tarih seçiniz.": state?.transactionDateOrUserName}
    />,
    <InputRange
      callback={handleAmountRangeChange}
      width="15%"
      height="3rem"
      text="İşlem tutar aralığı gir"
      firstPlaceHolder="minimum"
      secondPlaceHolder="maksimum"
    />,
    <Dropdown
      width="15%"
      height="3rem"
      items={dropdownPaymentTypeItems}
      onSelected={handlePaymentTypeChange}
      selectItemId={paymentTypeFilter}
      text="Linkle / Kart Bilgisiyle"
    />,
    <Dropdown
      width="15%"
      height="3rem"
      items={paymentGroupStatusData}
      disabled={selectedButtonStatus}
      onSelected={handleTransactionStatusChange}
      selectItemId={paymentStatusFilter}
      text="İşlem durumunu seç"
    />,
    <>
      {
        userErpStatus == "true" ? (
          <Dropdown
            width="15%"
            height="3rem"
            items={erpData}
            onSelected={handleERPChange}
            selectItemId={erpFilter}
            text="ERP durumunu seç"
          />
        ) : ("")
      }
    </>,
    <div style={{ width: "15%" }}></div>,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <TransactionResponsive
        setIsMultipleSelection={setIsMultipleSelection}
        handleSelectedButtonChange={handleSelectedButtonChange}
        responseData={responseData}
        userErpStatus={userErpStatus}
        handleSearchChange={handleSearchChange}
        handleDateRangeChange={handleDateRangeChange}
        handleAmountRangeChange={handleAmountRangeChange}
        paymentGroupStatusData={paymentGroupStatusData}
        selectedButtonStatus={selectedButtonStatus}
        handleTransactionStatusChange={handleTransactionStatusChange}
        handleCancelPaymentButtonClick={handleCancelPaymentButtonClick}
        handleRefundButtonClick={handleRefundButtonClick}
        paymentStatusFilter={paymentStatusFilter}
        state={state}
        erpData={erpData}
        handleERPChange={handleERPChange}
        erpFilter={erpFilter}
        currentItemsResponsive={currentItemsResponsive}
        isMultipleSelection={isMultipleSelection}
        handleRefreshLinkButtonClick={handleRefreshLinkButtonClick}
        handleReminderSendButtonClick={handleReminderSendButtonClick}
        handleCancelButtonClick={handleCancelButtonClick}
        multipleSelection={multipleSelection}
        setMultiSelectRow={setMultiSelectRow}
        forceAllCheck={forceAllCheck}
      />
    </> :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea
          width="100%"
          items={
            selectedButtonStatus === false ? filterItems : selectedButtonFilter
          }
          collapsed
          collapseItems={collapsedItems}
          filterButtonStatus={filterButtonStatus}
        />

        <Container minHeight="100%" flexDirection="column">
          <Cardboard>
            <ListContainer>
              <Pagination
                MultiSelect={
                  <>
                    {isMultipleSelection && (
                      <FormControlLabel
                        control={
                          <Check id="multiSelect" checked={forceAllCheck} onChange={setMultiSelectRow} />
                        }
                        label={<span style={{ fontSize: "12px" }}>Tümü</span>}
                        sx={{ color: "#000d89", fontFamily: "metropolis" }}
                      />
                    )}
                    <Chip
                      sx={{
                        backgroundImage:
                          isMultipleSelection &&
                          "linear-gradient(to right, #000d89 0%, #6608b7 100%)",
                        color: isMultipleSelection ? "white" : "#000d89",
                        borderRadius: 2,
                        fontFamily: "metropolis",
                      }}
                      variant="outlined"
                      label="Çoklu seçim"
                      disaleRipple
                      onClick={() => setIsMultipleSelection(!isMultipleSelection)}
                    />
                  </>
                }
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={paymentResponseListTotalCount}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />

              <PobUp
                callbackButtonEvent={handlePopUp}
                clickEvent={popUpClick}
                isCustomer={true}
                isActive={isActive}
                position={popupPosition}
                ref={currentRef}
                headerName={customerName}
                content={item?.modifiedDate == null ? `isimli kişiye ${sendText} göndermek istermisiniz ?` : `isimli müşteriye en son ${setTimeFormat(item?.modifiedDate) + " " + setDateFormat(item?.modifiedDate)} zamanında ${sendText} gönderildi. Tekrar ${sendText} göndermek ister misiniz?`}
              />

              <List
                collapsed
                CollapseComponent={<TransactionCollapse func={pull_data} />}
                columnNames={[
                  "",
                  "İŞLEM SAATİ VE TARİHİ",
                  "İŞLEM TUTARI",
                  "KULLANICI BİLGİSİ",
                  "MÜŞTERİ İSMİ",
                  "TAKSİT",
                  "İŞLEM DURUM",
                  (userErpStatus == "true" ? "ERP DURUM" : ""),
                  "",
                  "",
                  "",
                ]}
                data={currentItems}
                ref={refs}

              />
            </ListContainer>
          </Cardboard>
          {/* Seçilenleri indir */}
          {isMultipleSelection && (
            <BottomSticky text={"" + multipleSelection.length + " Öğe Seçildi"}>
              <Button
                width="20%"
                fontSize="12px"
                backgroundColor="rgba(255, 255, 255, 0.22)"
                backgroundImage="none"
                height="2.5rem"
                icon={<RefreshIcon width="30" height="20" />}
                text="Link Yenile"
                onClick={() => RefreshCheck(multipleSelection, 6)}
              />
              <Button
                width="20%"
                fontSize="12px"
                backgroundColor="rgba(255, 255, 255, 0.22)"
                backgroundImage="none"
                height="2.5rem"
                icon={<DeleteIcon width="30" height="20" color="white" />}
                text="İptal et"
                onClick={() => DeleteCheck(multipleSelection, 6)} />
              <Button
                width="25%"
                fontSize="12px"
                backgroundColor="rgba(255, 255, 255, 0.22)"
                backgroundImage="none"
                height="2.5rem"
                icon={<AccessTimeIcon width="30" height="20" />}
                text="Hatırlatma gönder"
                onClick={() => ReminderCheck(multipleSelection, 6)}
              />
              {/* <ExportCSV
                width="100%"
                height="2.5rem"
                backgroundColor="rgba(255, 255, 255, 0.22)"
                backgroundImage="none"
                csvData={exportData}
                fileName={fileName}
                text="Seçilenleri İndir"
              /> */}
              <ExportExcel
                excelData={exportData}
                fileName={fileName}
                text="Seçilenleri İndir"
                backgroundColor="rgba(255, 255, 255, 0.22)"
                backgroundImage="none"
                width="25%"
                fontSize="12px"
                height="2.5rem"
              />
            </BottomSticky>
          )}
        </Container>
      </>
  );
};

export default TransactionSMPStandard;
