import React, { useEffect, useState } from "react";
import styles from "./bmpstandart.module.css";
import Container from "components/Global/Container";
import RightIcon from "components/icons/RightIcon";
import PriceLabel from "components/Global/PriceLabel";
import ListContainer from "components/Global/ListContainer";
import List from "components/Global/List";
import Pagination from "components/Global/Pagination";
import Dropdown from "components/Global/Dropdown";
import api from "../../../../store/api/actions";
import {
  checkNullOrEmpty,
  checkNullOrEmptyArray,
  isValidDate,
} from "hoc/Conditions";
import { Link } from "react-router-dom";
import BannerArea from "components/Global/BannerArea";
import Cardboard from "components/Global/Cardboard";
import FilterArea from "components/Global/FilterArea";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import Label from "components/Global/Label";
import TablePriceLabel from "components/Global/TablePriceLabel";
import DateTimePicker from "components/Global/DateTimePickerv2";
import { useMediaQuery } from 'react-responsive'
import DashboardResponsive from "components/Responsive/Dashboard";



const GetDate = () => {
  var locale = "tr-TR";
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var monthName = today.toLocaleString(locale, { month: "long" });
  var yyyy = today.getFullYear();
  var time =
    today.getHours() + ":" + String(today.getMinutes()).padStart(2, "0");

  return { dd, mm, yyyy, monthName, time };
};

const initialWillAccountedState = {
  totalNetAccountAmountAndCurrency: "",
  totalCancellationAndRefundAmountAndCurrency: "",
  totalSuccessTransactionAmountAndCurrency: "",
  totalComissionAmountAndCurrency: "",
  paymentList: [
    {
      transactionDateOrDealerName: "",
      netAccountAmountAndCurrency: "",
      successTransactionAmountAndCurrency: "",
      cancellationAndRefundAmountAndCurrency: "",
      comissionAmountAndCurrency: "",
    },
  ],
  paymentListTotalCount: 0,
};

const initialTodayPaymentsState = {
  todayTotalPaymentTransactionAmount: "",
  todayTotalPaymentTransactionCount: 0,
};

const BMPDashboard = () => {
  const now = GetDate();

  const [dealerData, setDealerData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [paymentListTotalCount, setPaymentListTotalCount] = useState(0);
  const [
    paymentResponseListTotaltotalSuccessTransaction,
    setPaymentResponseListTotaltotalSuccessTransaction,
  ] = useState(0);
  const [willAccounted, setWillAccounted] = useState(initialWillAccountedState);
  const [cities, setCities] = useState([]);
  const [todayPayments, setTodayPayments] = useState(initialTodayPaymentsState);
  const [listData, setListData] = useState([]); //listelenecek ve filtrelenecek veri
  const [listDataResponsive, setListDataResponsive] = useState([])
  const [selectedDealerValue, setSelectedDealerValue] = useState("");
  const [selectedCityValue, setSelectedCityValue] = useState(0);
  const [termDayData, setTermDayData] = useState();

  const [startTodayDate, setStartTodayDate] = useState()
  const [endTodayDate, setEndTodayDate] = useState()
  const [startsDate, setStartsDate] = useState();
  const [endsDate, setEndsDate] = useState();

  const [searchAccountedForData, setSearchAccountedForData] = useState({ listingTypeId: 2 })
  const [listTotalCount, setListTotalCount] = useState(0);//veritabanında aslında bulunan tam veri saysısı
  const [dateForToday, setDateForToday] = useState("")

  //#region Paggination State
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]])
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  //#endregion

  useEffect(() => {
    todayStartEnd() // get today 00-24

    api.Helpers().GetAllDealerForFilter({}).then((response) => {
      // console.log('response Dealers', response?.data.data)
      if (checkNullOrEmptyArray(response.data.data)) {
        setDealerData(
          response.data.data.map((item) => ({
            id: item.pobDealerId,
            text: item.pobDealerName,
          }))
        );
      }
    })
      .catch((err) => {
        console.log("getDealer Hatası : ", err?.response?.data);
      });

    api
      .Helpers()
      .GetAllCity({})
      .then((response) => {
        if (checkNullOrEmpty(response.data.data)) {
          // console.log('response Cities Day', response.data.data)
          setCities(
            response.data.data.map((item) => ({
              id: item.cityCode,
              text: item.cityName,
            }))
          );
        }
      })
      .catch((err) => {
        console.log("getDealer Hatası : ", err?.response?.data);
      });

    // getAccountedForValuesFromApi();

    api
      .Dashboard()
      .GetValuesForBigMarketPlace({})
      .then((response) => {
        if (checkNullOrEmpty(response.data.data)) {
          console.log('response DashBoard Today Day', response.data.data)
          setTodayPayments(response.data.data);
        }
      })
      .catch((err) => {
        console.log("getDealer Hatası : ", err?.response?.data);
      });



    getWillAccountedData();

    killLoading();

  }, []);

  api
    .Helpers()
    .GetTermDay({})
    .then((response) => {
      console.log('response Term Day', response)
      if (checkNullOrEmpty(response?.data?.data)) {
        setTermDayData(response?.data?.data?.termDay);
      }
      var date = addDays((new Date()), termDayData)
      console.log("date", formatDate(date))
      setDateForToday(formatDate(date))
      setTimeout(() => {
        console.log("dateforToday", dateForToday)
      }, 1000);
    })
    .catch((err) => {
      console.log("termDay Hatası : ", err?.response?.data);
    });


  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }

  const killLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
      console.log("Kill Loading");
    }, 4000);
  }

  //Pagination
  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(listData.slice(itemOffset, itemOffset + itemsPerPage));
      setCurrentItemsForResponsive(listDataResponsive.slice(itemOffset, itemOffset + itemsPerPage));
    } else {
      setCurrentItems([[]]);
    }
    setPageCount(Math.ceil(listData.length / itemsPerPage));
  }, [listData, itemOffset, itemsPerPage]);

  useEffect(() => {
    getAccountedForValuesFromApi()
  }, [searchAccountedForData, endTodayDate])

  useEffect(() => {
    getAccountedForValuesFromApi();
  }, [endsDate, selectedCityValue]);

  const willAccountedDateFormat = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate() + termDayData);
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }

  const accountedForDateFormat = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate() - termDayData);
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }
  const accountedForDateFormatOre = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate());
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }

  const getAccountedForValuesFromApi = () => {
    api
      .BigMarketPlace()
      .AccountedForPaymentList(searchAccountedForData)
      .then((response) => {
        console.log("AccountedForPaymentList Response : ", response.data.data);
        setListTotalCount(response.data.data.paymentListTotalCount)
        setPaymentListTotalCount(response.data.data.paymentListTotalCount);
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListData(
            response.data.data.paymentList.map((item) => [
              <Label text={item.transactionDateOrDealerName} />,
              <TablePriceLabel
                price={item.successTransactionAmountAndCurrency}
              />,
              <div>
                {((parseFloat(item.successTransactionAmountAndCurrency)) * 100) / parseFloat(response.data.data.totalSuccessTransactionAmountAndCurrency.replaceAll('.', '').replace(',', '.')) == 0 ? "0%" : (((parseFloat(item.successTransactionAmountAndCurrency.replaceAll('.', '').replace(',', '.'))) * 100) / parseFloat(response.data.data.totalSuccessTransactionAmountAndCurrency.replaceAll('.', '').replace(',', '.'))).toFixed(2) + "%"}
              </div>,
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/accountedfor`}
                  state={{
                    transactionDateOrDealerName: item.transactionDateOrDealerName,
                    startTransactionDate: setDateFormat(startsDate),
                    endTransactionDate: setDateFormat(endsDate),
                  }}
                >
                  <RightIcon size={"15px"} />
                </Link>
              </div>,
            ]),
          );

          setListDataResponsive(
            response.data.data.paymentList.map((item) => [
              <Label text={item.transactionDateOrDealerName} />,
              <TablePriceLabel
                price={item.successTransactionAmountAndCurrency}
              />,

            ]),
          );
        } else {
          setListData([]);
          setListDataResponsive([]);

        }

        setPaymentResponseListTotaltotalSuccessTransaction(
          response.data.data.totalSuccessTransactionAmountAndCurrency
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { })
  };

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };

  const getWillAccountedData = () => {

    if (checkNullOrEmpty(startTodayDate) && checkNullOrEmpty(endTodayDate)) {
      api.BigMarketPlace().WillAccountedPaymentList({ startTransactionDate: startTodayDate, endTransactionDate: endTodayDate })
        .then((response) => {
          if (checkNullOrEmpty(response.data.data)) {
            // console.log("WillAccountedPaymentList",response.data.data)
            setWillAccounted(response.data.data);
          }
        }).catch((err) => {
          console.log("WillAccountedPaymentList Hatası : ", err?.response?.data);
        });
    }
  };

  const handleDealerSelect = (item) => {
    // setSelectedDealerValue(item)
    const data = dealerData.find((element) => element.id === item);
    // console.log("Data : ",data)

    if (checkNullOrEmpty(data)) {
      setSelectedDealerValue(parseInt(data?.id));
    } else {
      setSelectedDealerValue("");
    }
  };

  const handleCitySelect = (item) => {
    // setSelectedCityValue(item)
    const data = cities.find((element) => element.id === parseInt(item));
    if (checkNullOrEmpty(data)) {
      setSelectedCityValue(parseInt(data?.id));
    } else {
      setSelectedCityValue(0);
    }
  };

  //selectedDateRange
  const handleDateRangeChange = (newValue) => {

    let str = "";
    if (newValue.endDate != null) {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      let endDate = new Date(newValue.endDate).toLocaleDateString();
      if (startDate !== endDate) {
        str = startDate + " - " + endDate;
        let startTransactionDate = setDateTimeFormat(startDate);
        let endTransactionDate = setDateTimeFormat(endDate);
        setStartsDate(startTransactionDate);
        setEndsDate(endTransactionDate);

        startTransactionDate = new Date(startTransactionDate)
        endTransactionDate = new Date(endTransactionDate)


        console.log("START :", typeof startTransactionDate)
        setSearchAccountedForData({ startTransactionDate: startTransactionDate, endTransactionDate: endTransactionDate, listingTypeId: 2 })
      } else {
        str = startDate;
      }
    } else {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      str = startDate;
    }

  };

  //Paggination
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % listData.length;
    setItemOffset(newOffset);
  };

  const todayStartEnd = () => {
    var start = new Date();
    start.setDate(start.getDate());
    start.setUTCHours(0, 0, 0, 0);

    var end = new Date();
    end.setDate(end.getDate());
    end.setUTCHours(23, 59, 58, 999);
    // console.log("Start : ", start)
    // console.log("End : ", end)
    if (isValidDate(start) && isValidDate(end)) {
      setStartTodayDate(start);
      setEndTodayDate(end);
    }
  };

  function addDays(theDate, days) {
    console.log("Date data from term day ", days)
    return new Date(theDate?.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const setDateTimeFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      text="Tüm Bölgeler"
      items={cities}
      onSelected={handleCitySelect}
      selectItemId={selectedCityValue}
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      text="Tüm Bayiler"
      items={dealerData}
      onSelected={handleDealerSelect}
      selectItemId={selectedDealerValue}
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })


  // responsive olmayan kısım
  return (

    isMobile ? <>
      <DashboardResponsive
        allCities={cities}
        dealers={dealerData}
        accountedForDate={accountedForDateFormatOre}
        paymentResponseListTotaltotalSuccessTransaction={paymentResponseListTotaltotalSuccessTransaction}
        paymentListTotalCount={willAccounted.paymentListTotalCount}
        currentItems={currentItemsForResponsive}
        willAccountedDateFormat={willAccountedDateFormat}
        handleCitySelect={handleCitySelect}
        selectedCityValue={selectedCityValue}
        handleDealerSelect={handleDealerSelect}
        selectedDealerValue={selectedDealerValue}
        willAccounted={willAccounted}
        termDay={termDayData}
        role="bmp"
      />
    </>
      : <>
        <CustomLoadingBox isLoading={isLoading} />
        <Container minHeight="100%" marginTop="20px" columnGap="20px">
          <div className={styles.leftside}>

            <div className={styles.headgroup}>
              <div className={styles.textarea}>
                <h4 className={styles.header}>Hesaba Geçen Toplam Tutar</h4>
                <a className={styles.headbtn}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/accountedFor`}
                    state={{
                      date: accountedForDateFormat(new Date()),
                    }}
                  >
                    <RightIcon color="#000d89" />
                  </Link>
                </a>
              </div>
            </div>
            <div className={styles.filterarea}>
              <FilterArea width="100%" items={filterItems}></FilterArea>
            </div>

            <div className={styles.infoarea}>
              <div className={styles.cardbg}>
                <div className={styles.infoheader}>
                  <span>
                    <span className={styles.normaldate}>
                      {" "}
                      {now.dd + " " + now.monthName + " " + now.yyyy}
                      , 00.00 da hesabınıza geçen tutar ve bu tutarı oluşturan işlem adedi.
                    </span>
                  </span>
                </div>
                <div className={styles.infobottom}>
                  <PriceLabel
                    price={
                      checkNullOrEmpty(
                        paymentResponseListTotaltotalSuccessTransaction
                      )
                        ? paymentResponseListTotaltotalSuccessTransaction
                        : 0
                    }
                    size="4"
                  />
                  <span className={styles.counttext}>
                    {paymentListTotalCount + " adet işlem"}
                  </span>
                </div>
              </div>
            </div>

            <Cardboard
              style={{ width: "100%", minHeight: "500px", marginTop: "0" }}
            >
              <ListContainer>
                <Pagination
                  width="20%"
                  handlePageClick={handlePageClick}
                  pageCount={pageCount}
                  setItemsPerPage={setItemsPerPage}
                  infoCount={listTotalCount}
                  itemsPerPage={itemsPerPage}
                  listDataLength={listData?.length}
                />
                <List
                  columnNames={["BAYİ İSMİ", "HESABA GEÇEN", "BAYİ KATKISI", ""]}
                  data={currentItems}
                />
              </ListContainer>
            </Cardboard>
          </div>
          <div className={styles.rightside}>
            <div className={styles.section}>
              <div className={styles.headgroup}>
                <div className={styles.textarea}>
                  <h4 className={styles.header}>Hesaba Geçecek Toplam Tutar</h4>
                  <a className={styles.headbtn}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/willaccounted`}
                      state={{
                        dateNow: willAccountedDateFormat(new Date()),
                      }}
                    >
                      <RightIcon color="#000d89" />
                    </Link>
                  </a>
                </div>
                <div className={styles.seperator}></div>
              </div>
              <div className={styles.infoheader}>
                <span className={styles.normaldate}>

                  <span className={styles.normaldate}>
                    {" "}
                    {now.dd + " " + now.monthName + " " + now.yyyy}
                    , 00.00
                  </span>
                  {" "} itibariyle, {checkNullOrEmpty(dateForToday) ? dateForToday : ""} {" "} tarihine kadar hesabınıza geçecek toplam tutar:
                </span>
              </div>
              <div className={styles.infobottom}>
                <PriceLabel
                  price={willAccounted?.totalSuccessTransactionAmountAndCurrency}
                  size="4"
                />
                <span className={styles.counttext}>
                  {willAccounted?.paymentListTotalCount + " adet işlem"}
                </span>
              </div>
            </div>

            <BannerArea style={{ minHeight: "250px" }} />

            <div className={styles.section}>
              <div className={styles.headgroup}>
                <div className={styles.textarea}>
                  <h4 className={styles.header}>Bugünkü İşlemler</h4>
                  <a className={styles.headbtn} href="#">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/transactions`}
                      state={{
                        dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                      }}
                    >
                      <RightIcon color="#000d89" />
                    </Link>
                  </a>
                </div>
                <div className={styles.seperator}></div>
              </div>
              <div className={styles.infoheader}>
                <span className={styles.normaldate}>
                  {termDayData} gün vadeli çalıştığınız için bu işlemler

                  <span className={styles.bolddate}>
                    {" "}
                    {willAccountedDateFormat()}
                  </span>
                  'de hesabınıza geçecek. Son güncelleme {now.time},{" "}
                  {now.dd + "." + now.mm + "." + now.yyyy}
                </span>
              </div>
              <div className={styles.infobottom}>
                <PriceLabel
                  price={todayPayments?.todayTotalPaymentTransactionAmount}
                  size="4"
                />
                <span className={styles.counttext}>
                  {todayPayments?.todayTotalPaymentTransactionCount +
                    " adet işlem"}
                </span>
              </div>
            </div>
          </div>
        </Container>
      </>
  );
};

export default BMPDashboard;
