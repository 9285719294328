import Button from 'components/Global/Button';
import Cardboard from 'components/Global/Cardboard';
import DateTimePicker from 'components/Global/DateTimePickerv2';
import Dropdown from 'components/Global/Dropdown';
import List from 'components/Global/List';
import ListContainer from 'components/Global/ListContainer';
import PriceLabel from 'components/Global/PriceLabel';
import SwitchButton from 'components/Global/SwitchButton';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import ResponsiveFilterArea from '../FilterArea';
import ResizeableCard from '../global/ResizeableCard';
import styles from './accountedforwillaccounted.module.css'

const AccountedForAndWillAccountedResponsive = ({
  responsivePaginationType, //1:WillAccounted ,2:AccountedFor
  setPageType,
  pageType,
  totalNetAccountAmountAndCurrency,
  totalComissionAmountAndCurrency,
  totalSuccessTransactionAmountAndCurrency,
  totalCancellationAndRefundAmountAndCurrency,
  currentItems,
  citiesData,
  cityFilter,
  dealersData,
  dealerFilter,
  usersData,
  setSelectedValue,
  termDay,
  handleDateRangeChange,
  searchData,
  setSearchData,
  setDealerFilter,
  setCityFilter

}) => {
  const cookies = new Cookies();
  var userRole = cookies.get('roleNumber');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [buttonStatus, setButtonStatus] = useState(false);//filtreleye tıklanınca true oluyo
  const [dealerValue, setDealerValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [userValue, setUserValue] = useState("");
  const [date, setDate] = useState();
  const [isOpen, setIsOpen] = useState(false) //isClosed true ise açılmıyo / kapanıyor false ise açılıyo

  useEffect(() => {
    if (buttonStatus == true) {
      if (userRole == "1") {
        setDealerFilter(dealerValue)
        setCityFilter(cityValue)
      } else {
        setSelectedValue(userValue)
      }
      if (date?.endDate != null) {
        let startDate = new Date(date.startDate).toLocaleDateString();
        let endDate = new Date(date.endDate).toLocaleDateString();
        if (startDate !== endDate) {
          setSearchData({
            ...searchData,
            startTransactionDate: setDateFormat(startDate),
            endTransactionDate: setDateFormat(endDate),
          });
        }
      }
      setIsOpen(true);
    }
  }, [buttonStatus])


  useEffect(() => {
    isOpen && setButtonStatus(false)
  }, [isOpen])


  const setDateFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.defaultPrevented && setButtonStatus(true)
  }

  const pull_data = (status) => status == false && setIsOpen(false);

  return (
    <div className={styles.container}>
      <div className={styles.filtercontainer}>
        <div className={styles.switchbutton}>
          <SwitchButton
            callback={setPageType}
            style={{ height: "2.8rem", width: "100%" }}
            items={[
              { id: 1, text: "Tarih Bazlı" },
              { id: 2, text: userRole == "1" ? "Bayi Bazlı" : "Kullanıcı Bazlı" },
            ]}
            selectedValue={pageType}
          />
        </div>
        <ResponsiveFilterArea firstElement={responsivePaginationType == "1" ? <DateTimePicker dateTimePickerTypeId={1} termRange={termDay}
          isTermDay="true" onSelected={handleDateRangeChange} /> : <DateTimePicker dateTimePickerTypeId={2} onSelected={handleDateRangeChange} />} isButtonActive={true} modalClosed={isOpen} func={pull_data} >
          <form onSubmit={handleFormSubmit} className={styles.form}>
            <div className={styles.formitems}>
              {responsivePaginationType == "1" ? <DateTimePicker dateTimePickerTypeId={1} termRange={termDay}
                isTermDay="true" onSelected={(d) => setDate(d)} /> : <DateTimePicker dateTimePickerTypeId={2} onSelected={(d) => setDate(d)} />}
              {
                userRole == "1" ?
                  <>
                    <Dropdown width={"100%"} text={"Tüm Bölgeler"} items={citiesData} color={"black"} selectItemId={cityFilter} onSelected={(id) => setCityValue(id)} />
                    <Dropdown width={"100%"} text={"Tüm Bayiler"} items={dealersData} color={"black"} selectItemId={dealerFilter} onSelected={(id) => setDealerValue(id)} />
                  </> :
                  <Dropdown width={"100%"} text={"Tüm Kullanıcılar"} items={usersData} color={"black"} selectItemId={setSelectedValue} onSelected={(value) => setUserValue(value)} />

              }

              <Button type={"submit"} text={"Filtrele"} width={"100%"}></Button>
            </div>
          </form>
        </ResponsiveFilterArea>
      </div>

      <ResizeableCard showContent={
        !isCollapsed &&
        <div className={styles.total}>
          <div className={styles.biglabel}> {responsivePaginationType == "2" ? "Hesaba Geçen Toplam Tutar" : "Hesaba Geçecek Toplam Tutar"}</div>
          <PriceLabel
            price={totalNetAccountAmountAndCurrency}
            size="3"
            margin="15px"
          />
        </div>
      }
        setIsCollapsed={setIsCollapsed}
        collapsibleContent={
          < div className={styles.infoarea}>
            <div className={styles.cardbg}>
              <div className={styles.total}>
                <div className={styles.biglabel}>{responsivePaginationType == "2" ? "Hesaba Geçen Toplam Tutar" : "Hesaba Geçecek Toplam Tutar"}</div>
                <PriceLabel
                  price={totalNetAccountAmountAndCurrency}
                  size="3"
                  margin="15px"
                />
              </div>

              <div>
                <table className={styles.table}>
                  <tr className={styles.tableitem}>
                    <td>Komisyon Tutarı</td>
                    <td>{totalComissionAmountAndCurrency}</td>
                  </tr>
                  <tr className={styles.tableitem}>
                    <td>Başarılı Tutar</td>
                    <td>{totalSuccessTransactionAmountAndCurrency}</td>
                  </tr>
                  <tr className={styles.tableitem}>
                    <td>İptal / İade</td>
                    <td>{totalCancellationAndRefundAmountAndCurrency}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        } />

      <Cardboard style={{ margin: "15px", flex: 1 }}>
        <ListContainer>
          <List
            fontFamily="Metropolis"
            columnNames={["", (responsivePaginationType == "2" ? "NET HESABA GEÇEN" : "NET HESABA GEÇECEK"), "", ""]}
            data={currentItems}
          />
        </ListContainer>
      </Cardboard>


    </div>
  )
}

export default AccountedForAndWillAccountedResponsive