import React from 'react'
import styles from "./Layout.module.css";
import { Link } from 'react-router-dom';

//burada direkt olarak array vermemizin sebebi array prototyplarini kullanabilmektir.
//uyari : fonksiyon seklinde degistirilmesi halinde layout icerisindeki array prototypleri calismayacaktir.
export default [
        <Link to='/accountedFor'        id='2' orderId='1' className={styles.menuitem}>Hesaba Geçenler</Link>,
        <Link to='/willAccounted'       id='1' orderId='2' className={styles.menuitem}>Hesaba Geçecekler</Link>,
        <Link to='/transactions'        id='3' orderId='3' className={styles.menuitem}>İşlemler</Link>,
        <Link to='/transactionReports'  id='6' orderId='4' className={styles.menuitem}>İşlem Raporları</Link>,
        <Link to='/customers'           id='5' orderId='5' className={styles.menuitem}>Müşteriler</Link>,
        <Link to='/dealerManagement'    id='4' orderId='6' className={styles.menuitem}>Bayi Yönetimi</Link>,
        <Link to='/subDealerManagement' id='7' orderId='7' className={styles.menuitem}>Alt Bayi Yönetimi</Link>,

]