import React, { useEffect, useRef, useState } from 'react';
import styles from './dropdown.module.css';
import DropdownItem from './DropdownItem';
import useOutside from 'hoc/CustomOutsideHook';
import DownIcon from 'components/icons/DownIcon';
import UpIcon from 'components/icons/UpIcon';
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";

const Dropdown = ({ onSubmit=false,fontFamily, fontSize, width, height = '3rem', items, onSelected, text = "Seçiniz", error, selectItemId, selectAllItem, DeselectAllItem, disabled, backgroundColor, border = 'none', color }) => {

    const btnRef = useRef();
    const dropRef = useRef();

    const [clicked, setClicked] = useState(false);
    const [clickCallback, setClickCallback] = useState(0);
    const [selectedText, setSelectedText] = useState(text);

    useEffect(() => {
        //console.log("selectItemId",selectItemId)
        //console.log("items",items)
        if (checkNullOrEmpty(selectItemId) && checkNullOrEmptyArray(items)) {
            const data = items?.find(element => element.id + "" === selectItemId + "");
            if (checkNullOrEmpty(data)) {
                handleSelectedText(data.text);
            }
        }
        else {
            handleSelectedText(text);
        }

    }, [items, selectItemId])

    const handleClick = () => {
        setClicked(!clicked)
    } 

    useEffect(() => {
        if (onSubmit == false)
            setSelectedText(selectedText);
        else
            setSelectedText(text);
    },[onSubmit])
    
    const handleSelectedText = (selectedText) => {
        if (checkNullOrEmpty(selectedText)) {
            setSelectedText(selectedText);
        }
        else {
            setSelectedText(text);
        }
    }

    const handleSelected = (e) => {
        // console.log("Drop E : ", e.text)
        onSelected(e.id);
        setClickCallback(e.id);
        handleSelectedText(e.text)
    }

    useOutside(dropRef, btnRef, () => {
        setClicked(false);
    });

    return (
        <>
            <button type="button" ref={btnRef} disabled={disabled} onClick={handleClick} style={{ width, height, backgroundColor, border, fontFamily, fontSize }} className={styles.dropdown}>
                <div className={styles.dropdownarea}>
                    <span>{selectedText}</span>
                    <span>
                        {!clicked ? <DownIcon id="down" onClick={() => handleClick} /> : <UpIcon id="up" onClick={() => handleClick} />}
                    </span>
                </div>
            </button>
            {
                clicked &&
                <div ref={dropRef}
                    style={{
                        left: btnRef?.current?.offsetLeft,
                        top: btnRef?.current?.offsetTop + btnRef?.current?.offsetHeight - 10,
                        width: btnRef?.current?.clientWidth * 0.96,
                        marginLeft: "0.4%",
                        color: color,
                    }}
                    className={styles.dropdowncontent}>
                    {
                        selectAllItem && (<DropdownItem
                            id={selectAllItem.id}
                            text={selectAllItem.text}
                            onClick={handleSelected}
                            first
                            isActive={selectAllItem.id + "" === selectItemId + ""}
                            selectedCallback={clickCallback}
                        />)
                    }
                    {
                        DeselectAllItem && (<DropdownItem
                            id={DeselectAllItem?.id}
                            text={DeselectAllItem?.text}
                            onClick={handleSelected}
                            first
                            isActive={DeselectAllItem?.id + "" === selectItemId + ""}
                            selectedCallback={clickCallback}
                        />)
                    }
                    {
                        checkNullOrEmptyArray(items) === true ? (
                            items?.map((item, i) => {

                                if (i == 0) {
                                    return <DropdownItem
                                        key={i}
                                        id={item.id}
                                        text={item.text}
                                        onClick={handleSelected}
                                        first
                                        isActive={item.id + "" === selectItemId + ""}
                                        selectedCallback={clickCallback}
                                    />
                                }

                                if (i == items.length - 1) {
                                    return <DropdownItem
                                        key={i}
                                        id={item.id}
                                        text={item.text}
                                        onClick={handleSelected}
                                        last
                                        isActive={item.id + "" === selectItemId + ""}
                                        selectedCallback={clickCallback}
                                    />
                                }

                                return <DropdownItem
                                    key={i}
                                    id={item.id}
                                    text={item.text}
                                    onClick={handleSelected}
                                    isActive={item.id + "" === selectItemId + ""}
                                    selectedCallback={clickCallback}
                                />
                            })
                        ) : "Veri Bulunamadı"
                    }
                </div>
            }
            {
                error && (<span>{error}</span>)
            }
        </>
    )
};

export default Dropdown;
