import React, { useEffect, useState } from 'react'
import styles from './filterarea.module.css';
import Button from 'components/Global/Button';
import FilterIcon from 'components/icons/FilterIcon';
import cx from 'classnames';
const FilterArea = ({ marginTop="1.1rem",width,height="3rem", items, collapseItems, collapsed, filterButtonStatus, style, SwitchItem }) => {
    // console.log('items', items);
    const lastWidth = items && items[items.length - 1]?.props.width+75;
    const [collapseStatus, setCollapseStatus] = useState(false);

    useEffect(() => {
        if (filterButtonStatus === true) {
            setCollapseStatus(true)
        }
    }, [filterButtonStatus])

    const handleCollapse = () => {
        setCollapseStatus(!collapseStatus);
    }
    useEffect(() => {

    },[])
    return (
        <div className={styles.filtercontainer}>
            <div className={styles.filterarea} style={{ width }}>
                <div className={styles.customcontainer}>
                    <div className={styles.first}>
                        <div className={styles.filtergroup} style={style}>
                            {
                                items?.map((e) => {
                                    return React.cloneElement(e);
                                })
                            }
                            {collapsed && <Button onClick={handleCollapse} width={lastWidth} height={height}
                                icon={<FilterIcon fill={"white"} width="30" height="20" />}
                                text="Filtrele"
                            />}
                        </div>
                        {SwitchItem &&
                            <div className={styles.switchitem}>
                                <SwitchItem />
                            </div>
                        }
                    </div>
                        <div style={{ marginTop }} className={cx(styles.second,collapseStatus && styles.disabled)}>
                            <div className={styles.filtergroup}>
                                {
                                    collapseItems?.map((e) => {
                                        return React.cloneElement(e);
                                    })
                                }
                                <div style={{ width: lastWidth}}></div>
                                <div style={{ width: lastWidth}}></div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default FilterArea
