import React from 'react';

const CommissioIcon = ({ size = '20', text, width, color, fontSize, fontWeight,alignItems,justifyContent= "space-around" }) => {
    return <>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: justifyContent, alignItems:alignItems, color:color, fontSize:fontSize, fontWeight:fontWeight }}>

            <symbol id="copy">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clipRule="evenodd" d="M7.857 12.766a.624.624 0 0 1-.442-1.067L11.7 7.417a.625.625 0 1 1 .884.884l-4.284 4.283a.62.62 0 0 1-.442.183z" fill="#4F5386" />
                    <path fill-rule="evenodd" clipRule="evenodd" d="M9.12 16.729a1.26 1.26 0 0 0 1.772-.017l.601-.6a2.52 2.52 0 0 1 1.77-.731h.858c.695 0 1.26-.566 1.26-1.26v-.857c0-.668.258-1.295.73-1.768l.6-.6a1.258 1.258 0 0 0 .001-1.79l-.6-.601a2.493 2.493 0 0 1-.731-1.77v-.856c0-.695-.565-1.26-1.26-1.26h-.857c-.667 0-1.295-.26-1.767-.728l-.602-.603c-.5-.496-1.3-.493-1.79 0l-.598.6a2.503 2.503 0 0 1-1.771.73h-.858c-.694.002-1.259.567-1.259 1.26v.855c0 .668-.259 1.295-.729 1.768l-.592.593a.175.175 0 0 1-.011.012l-.006.005a1.261 1.261 0 0 0 .006 1.781l.601.602c.472.473.731 1.1.731 1.768v.86c0 .694.564 1.258 1.26 1.258h.855c.668.001 1.296.26 1.768.731l.601.6a.187.187 0 0 0 .005.005l.008.009.004.004zm.878 1.605c-.639 0-1.278-.242-1.767-.727a.445.445 0 0 1-.02-.02l-.592-.591a1.254 1.254 0 0 0-.886-.365h-.855a2.512 2.512 0 0 1-2.509-2.51v-.859c0-.335-.13-.649-.366-.886l-.599-.598a2.513 2.513 0 0 1-.029-3.528l.022-.023.607-.608c.234-.236.365-.552.365-.886v-.854a2.513 2.513 0 0 1 2.508-2.51h.859c.334 0 .649-.131.888-.367l.596-.597a2.512 2.512 0 0 1 3.55-.01l.608.61c.236.234.551.364.886.364h.857a2.513 2.513 0 0 1 2.51 2.51v.856c0 .334.13.65.366.887l.598.6c.474.47.738 1.1.74 1.77a2.493 2.493 0 0 1-.73 1.778l-.61.609c-.234.236-.364.55-.364.885v.857a2.512 2.512 0 0 1-2.51 2.51h-.859c-.33 0-.652.133-.886.365l-.598.599a2.5 2.5 0 0 1-1.78.739zm2.087-5.418a.836.836 0 0 1-.837-.833c0-.461.37-.834.83-.834h.007a.833.833 0 1 1 0 1.667zm-5.004-5a.836.836 0 0 0 1.671 0 .833.833 0 0 0-.833-.833H7.91a.83.83 0 0 0-.83.833z" fill="#4F5386" />
                </svg>
            </symbol>
            <div style={{width:width}}/>
            {text}
        </div>

    </>
};

export default CommissioIcon;