import React from 'react';

const CancelIcon = ({size = '32', color = "#FF5C5C" }) => {
    return <>
        <symbol id="cancel" >
            <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m29.738 6.331.026-.03.024-.034a2.905 2.905 0 0 0-4.414-3.748l-9.376 9.377L6.625 2.52l-.028-.028-.031-.027-.236-.2-.031-.027-.033-.023a2.905 2.905 0 0 0-3.75 4.412l9.376 9.376-9.368 9.374-.029.029-.026.03-.2.237-.027.03-.024.033a2.905 2.905 0 0 0 4.414 3.748l9.366-9.372 9.368 9.372.029.029.03.026.237.201.03.026.034.024a2.905 2.905 0 0 0 3.749-4.413l-9.37-9.374 9.377-9.375.029-.029.026-.03.2-.237z" fill={color} stroke={color} strokeWidth="2" />
            </svg>

        </symbol>
    </>;
};

export default CancelIcon;
