import React, { useEffect, useState } from 'react';
import styles from './customergroupadd.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { checkNullOrEmptyArray } from "hoc/Conditions";
import MultipleSelect from 'components/Global/MultipleSelect/MultipleSelect';
import AddGroupValidator from './AddGroupValidator';

const initialCustomers = {
    id: "",
    birthDate: "",
    customerGroups: [],
    customerName: "",
    email: "",
    gender: 0,
    phone: ""
};

const NewPaymentGroup = ({ handleModalClick, callback }) => {

    const [responseErrors, setResponseErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [customers, setCustomers] = useState([])
    const [multiselectCallBack, setMultiselectCallBack] = useState()
    const [buttonText, setButtonText] = useState("Grup Ekle")
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        api.Customer().GetAll({}).then((response) => {
            console.log("get all Cust :", response.data.data)

            setCustomers(response.data.data.customerList.map(item => ({
                id: item.id,
                name: item.customerName,
                phone: item.phone,
                email: item.email
            })))

        }).catch((error) => {
            console.log("error", error?.response?.data);
            // setResponseErrors(error?.response?.data)
            setResponseErrors(error?.response?.data.userMessage)
        });
    }, [])

    const handleCB = () => {
        callback({
            addSuccess: true,
        })
    }

    const { handleChange, handleBlur, touched, values, handleSubmit, errors, handleReset } = useFormik({

        initialValues: {
            groupName: "",
            pobDealerCustomerIDs: []
        },

        onSubmit: values => {

            // setIsLoading(true);

            if (checkNullOrEmptyArray(multiselectCallBack)) {

                values.pobDealerCustomerIDs = multiselectCallBack.map(item => item.id);
            }

            console.log("push Values", values)
            setIsLoading(false);
            setButtonText("Grup Ekleme Başarılı")
            setDisabled(true)

            setTimeout(() => {
                handleCB()

                api.CustomerGroup().Create(values).then((response) => {

                    handleModalClick(false);
                    handleReset({});

                }).catch((error) => {
                    // handle error
                    console.log('error', error?.response?.data);
                    setResponseErrors(error?.response?.data.userMessage);
                }).finally(() => {
                    setIsLoading(false);
                });

            }, 1500);

        },
        validationSchema: AddGroupValidator
    });

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <div className={styles.formitems}>
                <div className={styles.forminfo}>
                    Yeni Grup Ekle
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div onChange={handleChange} className={styles.inputgroup}>

                        <Input
                            width='100%'
                            id="groupName"
                            height='3rem'
                            placeholder="Grup Adı"
                            name="groupName"
                            onChange={handleChange}
                            value={values.groupName}
                            onBlur={handleBlur}
                            error={touched.groupName && errors.groupName}
                        />

                        <MultipleSelect
                            items={customers}
                            error={touched.customerFullName && errors.customerFullName}
                            maxLength='70'
                            onChange={setMultiselectCallBack}
                            height='3rem'
                            name='customerName'
                            onBlur={handleBlur}
                            placeholder='Müşteri Ekle'
                        />


                        <Button disabled={disabled} type="submit" width='100%' text={buttonText} fontSize="1.3rem" />
                    </div>
                </form>
            </div >
            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }
        </>
    )
};

export default NewPaymentGroup;
