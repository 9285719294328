// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_container__tNY3c {\r\n    height: 100%;\r\n}\r\n\r\n.layout_navbar__viRPc {\r\n    /* background-color: red; */\r\n    height: 10%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 0 15px;\r\n    border-bottom: solid 3px transparent;\r\n    background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);\r\n    background-origin: border-box;\r\n    background-clip: border-box;\r\n    box-shadow: 2px 1000px 1px #fff inset;\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/Layout/layout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,oCAAoC;IACpC,kFAAkF;IAClF,6BAA6B;IAC7B,2BAA2B;IAC3B,qCAAqC;;AAEzC","sourcesContent":[".container {\r\n    height: 100%;\r\n}\r\n\r\n.navbar {\r\n    /* background-color: red; */\r\n    height: 10%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 0 15px;\r\n    border-bottom: solid 3px transparent;\r\n    background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);\r\n    background-origin: border-box;\r\n    background-clip: border-box;\r\n    box-shadow: 2px 1000px 1px #fff inset;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "layout_container__tNY3c",
	"navbar": "layout_navbar__viRPc"
};
export default ___CSS_LOADER_EXPORT___;
