import RightIcon from 'components/icons/RightIcon';
import styles from './commissionrate.module.css'
import Slider from "react-slick";

const CommissionRate = ({ items }) => {
    const splittedItems = items?.slice(0);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
    };

    function Arrow({ onClick }) {
        return (
            <div className={styles.rightarrow} onClick={onClick}>
                <RightIcon size='10' />
            </div>
        );
    }

    return (
        <>
            {
                <div
                    className={styles.tablehead}>
                    <div className={styles.uphead}>Taksit Sayısı</div>
                    <div className={styles.underhead}>Komisyon Oranı</div>
                </div>
            }
            {
                <Slider
                    {...settings}
                    useCSS={false}
                    nextArrow={<Arrow />}
                    prevArrow={<></>}
                >
                    {splittedItems?.map((item) => {
                        return (
                            <>
                                <div id={item.text} className={styles.text}>{item.text}</div>
                                <div id={item.text} className={styles.rate}>{item.rate}</div>
                            </>
                        )
                    })}</Slider>
            }
        </>


    )
}
export default CommissionRate