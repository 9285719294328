import { useState, useEffect } from 'react'
import styles from './payment.module.css'
import Input from 'components/Global/Input';
import Button from 'components/Global/Button';
import PriceLabel from 'components/Global/PriceLabel';
import AutoComplete from 'components/Global/AutoComplete';
import CheckBox from 'components/Global/CheckBox';
import TextArea from 'components/Global/TextArea';
import BankList from 'components/Global/BankList';
import InputFlip from 'components/Global/InputFlip';
import { useFormik } from 'formik';
import linkValidator from './linkValidator'
import api from 'store/api/actions';
import useCreditCardItems from 'hoc/useCreditCardItems';
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions';
import InstallmentArea from 'components/Global/InstallmentArea';
import cx from 'classnames';
import groupLinkValidator from './GroupLinkValidator';
import Cookies from 'universal-cookie';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useMediaQuery } from 'react-responsive';

const LinkPayment = ({ setPaymentSuccess, setSavedCustomer, setCustomerId, tableData, successPageCallback, setLinkUrl, style, callback, buttonStatus = true }) => {

    const cookies = new Cookies();
    var userCommissionList = cookies.get('userCommissionList');

    const [customerData, setCustomerData] = useState()
    const [customerGroupData, setCustomerGroupData] = useState()
    const [customerAndGroupsData, setCustomerAndGroupsData] = useState()
    const [autoCompleteCallback, setAutoCompleteCallback] = useState(0);
    const [phoneFlipClosed, setPhoneFlipClosed] = useState(false);
    const [mailFlipClosed, setMailFlipClosed] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [showMail, setShowMail] = useState(1);
    const [showPhone, setShowPhone] = useState(1);
    const [includeCommission, setIncludeCommission] = useState(false);
    const [pureAmount, setAmount] = useState(0);
    const [price, setPrice] = useState("00,00");
    const [disablePost, setDisablePost] = useState(true);
    const [isInstallment, setIsInstallment] = useState(1)
    const [isCustomer, setIsCustomer] = useState(true)
    const [emptyGroupError, setEmptyGroupError] = useState("")
    const [replacedPrice, setReplacedPrice] = useState(null)

    const [isInstalmentStatus, setIsInstalmentStatus] = useState(false) //taksit seçimi kaldırılırsa true
    const [beforeCommissisonRate, setBeforeCommissisonRate] = useState() //bir önceki rate değerini yakalamak için

    const [commissionRate, setCommissionRate] = useState()

    const { installmentsAndCommissions } = useCreditCardItems();

    const { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue, setValues, handleReset } = useFormik({

        initialValues: {
            isSendEmail: true,
            isSendPhone: true,
            pobDealerCustomerId: "",
            customerFullName: "",
            email: "",
            phone: "",
            addCommissionRateAmount: false,
            amount: "",
            currency: "",
            installmentNumber: 0,
            description: "",
            pobDealerCustomerGroupId: ""
        },

        onSubmit: values => {
            setDisablePost(true);
            if (values?.pobDealerCustomerId?.length < 1) {
                delete values?.pobDealerCustomerId
            }
            else {
                setCustomerId(values?.pobDealerCustomerId)
            }

            if (values?.pobDealerCustomerGroupId?.length < 1) {
                delete values?.pobDealerCustomerGroupId
            }
            values.amount = values?.amount?.replace(',', '.')
            if (replacedPrice != null) values.amount = replacedPrice
            api.PaymentTransaction()
                .CreatePaymentRequest(values)
                .then(response => {
                    console.log(response, 'respooooo')
                    if (response.data?.success === true) {
                        if (response?.data.messageCode == 'BSK40') {
                            console.log('mail service doesnt work.');
                        }
                        tableData({
                            amount: values?.amount,
                            installment: values?.installmentNumber,
                            customerName: autoCompleteCallback?.name,
                            description: values?.description,
                            pobDealerCustomerId: response?.data?.data?.pobDealerCustomerId,
                            gsmNumber: values?.phone,
                            email: values?.email,
                            companyName: response?.data?.data?.companyName
                        })
                        console.log("Response Lİnk page", response.data)

                        setLinkUrl(response.data.data?.paymentRequestId)

                        if (checkNullOrEmpty(response?.data?.data?.pobDealerCustomerId)) {
                            setSavedCustomer(true)
                            setCustomerId(response?.data?.data?.pobDealerCustomerId)
                        }

                        successPageCallback(2)
                        if (!isCustomer) {
                            successPageCallback(3)
                        }
                        setPaymentSuccess(true)
                    }
                    else {
                        setPaymentSuccess(false)
                    }
                }).catch(err => {
                    console.log("linkError", err)
                    if (!isCustomer) {
                        setEmptyGroupError("Link gönderme işleminde bir sorun meydana geldi. Müşteri grubunuzda müşteri olduğundan emin olunuz.")
                        setDisablePost(false);
                    }
                    else {
                        setEmptyGroupError(err?.response?.data.userMessage)
                        setDisablePost(false);
                    }
                    console.log("error detail", err)
                }).finally(() => {
                    setDisablePost(false);
                })
        },
        validationSchema: isCustomer ? linkValidator : groupLinkValidator,
    });

    useEffect(() => {
        api.Customer().GetAll({}).then(response => {
            console.log('customerData', response.data.data)
            setCustomerData(response.data.data.customerList?.map(item => ({
                id: item.id,
                name: item.customerName,
                phone: item.phone,
                email: item.email
            })))
        })

    }, [])

    useEffect(() => {
        api.CustomerGroup().GetAll({}).then(response => {
            console.log('customerGroupData', response.data.data)
            setCustomerGroupData(response.data.data?.map(item => ({
                id: item.pobDealerCustomerGroupId,
                name: item.customerGroupName,
            })))
        })

    }, [customerData])

    useEffect(() => {
        if (checkNullOrEmptyArray(customerData) || checkNullOrEmptyArray(customerGroupData)) {
            console.log("test", customerData)
            setCustomerAndGroupsData(customerData?.concat(customerGroupData))
        }

    }, [customerData, customerGroupData])

    useEffect(() => {
        console.table("customerAndGroupsData", customerAndGroupsData)
    }, [customerAndGroupsData])

    useEffect(() => {
        setEmptyGroupError("")
        // some function is every time looking for the equality of the values and returns true or false
        if (customerGroupData?.some(val => val.id == autoCompleteCallback.id)) {
            setFieldValue('pobDealerCustomerGroupId', autoCompleteCallback?.id)
            setIsCustomer(false)
        } else {
            setIsCustomer(true)
            if (autoCompleteCallback?.id?.length > 0 || autoCompleteCallback?.name?.trim().length < 1) {
                setShowPhone(e => (++e));
                setShowMail(e => (++e));
                if (autoCompleteCallback?.id?.length > 0) {
                    const customerInfo = customerData?.find(a => a.id === autoCompleteCallback.id)
                    setFieldValue('phone', customerInfo.phone)
                    setFieldValue('email', customerInfo.email)
                    setFieldValue('pobDealerCustomerId', autoCompleteCallback.id)
                    setDisableInputs(true)
                    setFieldValue('isSendEmail', true)
                    setFieldValue('isSendPhone', true)
                }
            }
            if (autoCompleteCallback?.id === undefined) {
                setDisableInputs(false)
                setFieldValue('phone', '')
                setFieldValue('email', '')
                setFieldValue('pobDealerCustomerId', '')
                if (autoCompleteCallback?.name != undefined) {
                    setFieldValue('customerFullName', autoCompleteCallback.name)
                }
                if (values.customerFullName.trim().length === 0) {
                    setFieldValue('isSendEmail', true)
                    setFieldValue('isSendPhone', true)
                }
            }
        }
    }, [autoCompleteCallback])

    useEffect(() => {
        if (mailFlipClosed) {
            setFieldValue('isSendEmail', false)
        }
        else {
            setFieldValue('isSendEmail', true)
        }
    }, [mailFlipClosed])

    useEffect(() => {
        if (phoneFlipClosed) {
            setFieldValue('isSendPhone', false)
        }
        else {
            setFieldValue('isSendPhone', true)
        }
    }, [phoneFlipClosed])

    useEffect(() => {
        if (isInstallment == 0) {
            setIsInstalmentStatus(true)
            setIncludeCommission(false)
        }
    }, [isInstallment])

    useEffect(() => {
        if (isInstalmentStatus == true && includeCommission == false) {
            const commission = Number.parseFloat(pureAmount * beforeCommissisonRate / 100).toFixed(2);
            const qwe = Number.parseFloat(pureAmount - commission).toFixed(2);
            const splittedPrice = qwe.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            setFieldValue('amount', String(pureAmount).replace('.', ','))

            setFieldValue('addCommissionRateAmount', false)
        }
    }, [isInstallment, includeCommission, commissionRate, isInstalmentStatus])

    useEffect(() => {
        if (includeCommission == true && pureAmount > 0) {
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            const calculatedValue = Number.parseFloat(((100 + commissionRate) / 100) * pureAmount).toFixed(2).replace('.', ',');
            const splittedPrice = pureAmount.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            console.log(String(pureAmount).replace('.', ','), 'qweqweazxch')
            setFieldValue('amount', calculatedValue.replaceAll('.', ''))
            setFieldValue('addCommissionRateAmount', true)
            setReplacedPrice(null)
            setBeforeCommissisonRate(commissionRate)
        }
        if (includeCommission == false && isInstalmentStatus == false) {
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            const commission = Number.parseFloat(pureAmount * commissionRate / 100).toFixed(2);
            const qwe = Number.parseFloat(pureAmount - commission).toFixed(2);
            const splittedPrice = qwe.split(".");
            setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
            setFieldValue('amount', String(pureAmount).replace('.', ','))
            setFieldValue('addCommissionRateAmount', false)
            setBeforeCommissisonRate(commissionRate)
        }

        // else {
        //     const commission = Number.parseFloat(pureAmount * commissionRate / 100).toFixed(2);
        //     const qwe = Number.parseFloat(pureAmount - commission).toFixed(2);
        //     const splittedPrice = qwe.split(".");
        //     setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
        //     setFieldValue('amount', String(pureAmount).replace('.', ','))
        //     console.log(pureAmount, 'qweqwe', typeof String(pureAmount).replace('.', ','))
        //     setFieldValue('addCommissionRateAmount', false)
        // }
    }, [includeCommission, commissionRate])

    const numberWithCommas = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    const handleCB = () => {
        callback({
            wantAdd: true,
        })
    }
    const defaultMaskOptions = {
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false,
        prefix: '',
    }
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    })

    const customHandleChange = e => {
        if (e.target.name === "amount") {
            // if (installmentSelected !== undefined) {
            //     installment = parseInt(installmentSelected);
            // }
            e.target.value.length > 0 ? setDisablePost(false) : setDisablePost(true)
            setCommissionRate(userCommissionList.filter(item => item.installmentNumber == isInstallment)[0].rate)
            if (e.target.value !== undefined && e.target.value !== null && e.target.value !== "") {
                console.log(e.target.value, 'pureval')
                console.log(String(e.target.value).replace('.', ','), 'qweqweqweqwe')
                var amount = Number.parseFloat(e.target.value.replace(/\./g, "").replace(",", ".")).toFixed(2);
                setAmount(amount)
                const commission = Number.parseFloat(amount * commissionRate / 100).toFixed(2);
                const price = Number.parseFloat(amount - commission).toFixed(2);
                console.log(e.target.value, "qasghasdh")
                const splittedPrice = price.split(".");
                setPrice(String(numberWithCommas(splittedPrice[0]) + "," + splittedPrice[1]))
                setReplacedPrice(e.target.value.replaceAll('.', '').replace(',', '.'))
                // setFieldValue('amount', price.replace('.', '').replace(',', '.'));
            }
            else {
                setPrice("00,00")
            }
        }
        setIncludeCommission(false)
        handleChange(e);
    }

    const handleInstallment = (id) => {
        setFieldValue("installmentNumber", id)
        if (checkNullOrEmpty(id))
            setIsInstallment(id)

    }

    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    return (
        isMobile ? <>
            <form className={styles.linkformresponsive} onSubmit={handleSubmit}>
                <section className={styles.linksection} >
                    <div className={styles.customerarea}>
                        <div className={styles.subheaders}>Müşteri Bilgisi</div>
                        <div className={styles.inputfieldresponsive}>
                            <AutoComplete
                                error={touched.customerFullName && errors.customerFullName}
                                items={customerAndGroupsData}
                                maxLength='70'
                                onChange={setAutoCompleteCallback}
                                height='3rem'
                                name='customerFullName'
                                onBlur={handleBlur}
                                placeholder='Müşteri ismi / Grup ismi'
                                callback={handleCB}
                                isLink={true}
                                customerList={customerData}
                                customerGroupList={customerGroupData}
                                buttonStatus={buttonStatus}
                            />
                        </div>
                        <div className={styles.inputgroup}>
                            <div className={styles.groupitem}>
                                <InputFlip
                                    placeholder='Telefon'
                                    buttonText='Telefon'
                                    closed={setPhoneFlipClosed}
                                    disabled={disableInputs}
                                    showInput={showPhone}
                                    value={values.phone}
                                    name='phone'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    length='10'
                                />
                            </div>
                            <div className={styles.groupitem}>
                                <InputFlip
                                    placeholder='E-posta'
                                    buttonText='E-posta'
                                    closed={setMailFlipClosed}
                                    disabled={disableInputs}
                                    showInput={showMail}
                                    value={values.email}
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className={styles.subtitle}>Telefon ve E-postadan yanlızca biri zorunludur.</div>
                        <div className={styles.transactionarea}>
                            <div className={styles.subheaders}>İşlem Bilgisi</div>
                            <Input width='100%'
                                height='3rem'
                                onChange={customHandleChange}
                                placeholder='İşlem tutarı'
                                name='amount'
                                value={values.amount == 0 ? "" : values.amount}
                                onBlur={handleBlur}
                                error={touched.amount && errors.amount}
                                type='text'
                                isMasked={true}
                                maskedOptions={currencyMask}
                            />
                        </div>
                        <div className={styles.installmentarea}>
                            <div className={styles.subheaders}>Taksit Miktarı</div>
                            <div className={styles.groupitem}>

                                <InstallmentArea
                                    items={installmentsAndCommissions}
                                    slidesShow={5}
                                    onChange={handleInstallment}
                                    itemStyle={{ width: '50px !important', height: '50px !important' }}
                                />
                            </div>
                        </div>
                        <div className={cx(styles.info, styles.primary)}>
                            {
                                <>
                                    <CheckBox
                                        text='Komisyon oranını tutara ekle'
                                        height='3rem'
                                        isClicked={setIncludeCommission}
                                        checked={includeCommission}
                                        id="linkCheckBox"
                                        disabled={isInstallment > 1 ? false : true}
                                    />
                                    <div style={{ width: "100px" }}></div>
                                </>
                            }

                        </div>
                        <div className={styles.info} >
                            <div className={styles.infolabelresponsive}>
                                Hesaba Geçecek
                                Net Tutar
                            </div>
                            <div className={styles.pricelabel}>
                                <PriceLabel price={price ?? "00,00"} size='2' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.descriptionsubmit}>
                        <Input
                            value={values.description}
                            name='description'
                            onChange={handleChange}
                            placeholder="İşlem açıklaması giriniz."
                        />
                        <span style={{
                            color: 'red'
                        }}>
                            {emptyGroupError}
                        </span>
                        <Button disabled={disablePost} height='3.5rem' type='submit' text='Link Gönder' fontSize='1.3rem' />
                    </div>
                    <div className={styles.banklist}>
                        <BankList />
                    </div>
                </section>
            </form>
        </> :

            <form className={styles.linkform} style={style} onSubmit={handleSubmit}>
                <section className={styles.linksection} >
                    <div className={styles.customerarea}>
                        <div className={styles.subheaders}>Müşteri Bilgisi</div>
                        <div className={styles.inputfield}>
                            <AutoComplete
                                error={touched.customerFullName && errors.customerFullName}
                                items={customerAndGroupsData}
                                maxLength='70'
                                onChange={setAutoCompleteCallback}
                                height='3rem'
                                name='customerFullName'
                                onBlur={handleBlur}
                                placeholder='Müşteri ismi / Grup ismi'
                                callback={handleCB}
                                isLink={true}
                                customerList={customerData}
                                customerGroupList={customerGroupData}
                                buttonStatus={buttonStatus}
                            />
                        </div>
                        <div className={styles.inputgroup}>
                            <div className={styles.groupitem}>
                                <InputFlip
                                    placeholder='Telefon'
                                    buttonText='Telefon'
                                    closed={setPhoneFlipClosed}
                                    disabled={disableInputs}
                                    showInput={showPhone}
                                    value={values.phone}
                                    name='phone'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    length='10'
                                />
                            </div>
                            <div className={styles.groupitem}>
                                <InputFlip
                                    placeholder='E-posta'
                                    buttonText='E-posta'
                                    closed={setMailFlipClosed}
                                    disabled={disableInputs}
                                    showInput={showMail}
                                    value={values.email}
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        {!(touched.email && errors.email || touched.phone && errors.phone || errors.twiceClosed) ? <span className={styles.chooselabel}>Telefon ve E-postadan yalnızca biri zorunludur.</span> :
                            <span className={styles.error}>{touched.email && errors.email || touched.phone && errors.phone || errors.twiceClosed}</span>
                        }
                    </div>
                    <div className={styles.transactionarea}>
                        <div className={styles.subheaders}>İşlem Bilgisi</div>
                        <Input width='100%'
                            height='3rem'
                            onChange={customHandleChange}
                            placeholder='İşlem tutarı'
                            name='amount'
                            value={values.amount == 0 ? "" : values.amount}
                            onBlur={handleBlur}
                            error={touched.amount && errors.amount}
                            type='text'
                            isMasked={true}
                            maskedOptions={currencyMask}
                        />
                    </div>
                    <div className={styles.installmentarea}>
                        <div className={styles.subheaders}>Taksit Miktarı</div>
                        <div className={styles.groupitem}>
                            {/* <Dropdown width='100%'
                            height='3rem'
                            items={installmentsAndCommissions}
                            onSelected={setInstallmentSelected}
                            selectItemId={installmentSelected}
                            backgroundColor='#fff'
                        /> */}
                            <InstallmentArea
                                items={installmentsAndCommissions}
                                onChange={handleInstallment}
                                itemStyle={{ width: '60px !important', height: '60px !important' }}
                            />
                        </div>
                        <div className={cx(styles.info, styles.primary)}>
                            {
                                <>
                                    <CheckBox
                                        text='Komisyon oranını tutara ekle'
                                        height='3rem'
                                        isClicked={setIncludeCommission}
                                        checked={includeCommission}
                                        id="linkCheckBox"
                                        disabled={isInstallment > 1 ? false : true}
                                    />
                                    <div style={{ width: "100px" }}></div>
                                </>
                            }

                            <div className={cx(styles.info, styles.primary)} >
                                <div className={styles.infolabel}>
                                    Hesaba Geçecek Net Tutar
                                </div>
                                <div className={styles.pricelabel}>
                                    <PriceLabel price={price ?? "00,00"} size='2' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.descriptionsubmit}>
                        <TextArea
                            value={values.description}
                            name='description'
                            onChange={handleChange}
                            placeholder="İşlem açıklaması giriniz."
                        />
                        <span style={{
                            color: 'red'
                        }}>
                            {emptyGroupError}
                        </span>
                        <Button disabled={disablePost} height='3.5rem' type='submit' text='Link Gönder' fontSize='1.3rem' />
                    </div>
                    <div className={styles.banklist}>
                        <BankList />
                    </div>
                </section>
            </form >
    )
}

export default LinkPayment 