import CloseIcon from 'components/icons/CloseIcon'
import RefreshIcon from 'components/icons/RefreshIcon'
import RemindIcon from 'components/icons/RemindIcon'
import React from 'react'
import styles from './triplebutton.module.css'
import cx from 'classnames'

const TripleButton = ({ firstOnClick, secondOnClick, thirdOnClick, status, isRefund = false, text1 = "İade Et", text2 = "İptal Et" }) => {
    if (isRefund) {

        if (status) {

            return (

                <div className={styles.triplecontainer}>
                    <div className={styles.section}>
                        <a onClick={firstOnClick} className={styles.button}>{text1}</a>
                    </div>
                    <div className={cx(styles.section, styles.bottom)}>
                        <a onClick={thirdOnClick} className={styles.button}>{text2}</a>
                    </div>

                </div>

            )
        } else {
            return ""
        }


    } else {

        return (
            <div className={styles.triplecontainer}>
                <div className={styles.section}>
                    <a onClick={firstOnClick} className={styles.button}><RefreshIcon size='15' /></a>
                </div>
                <div className={cx(styles.section, styles.bottom)}>
                    <a onClick={secondOnClick} className={styles.button}><RemindIcon size='15' /></a>
                </div>
                {
                    !status ?
                        <div className={styles.section}>
                            <a onClick={thirdOnClick} className={styles.button} ><CloseIcon size='15' /></a>
                        </div>
                        :
                        <div className={styles.section}>
                            <a className={styles.button} ><CloseIcon size='15' /></a>
                        </div>}


            </div>
        )
    }
}

export default TripleButton