import React, { useEffect, useState } from 'react'
import styles from './multipleselectitem.module.css';
import CheckBox from 'components/Global/CheckBox';
import CloseIcon from 'components/icons/CloseIcon';

const SelectItem = ({ data, callback, checked, deselectAll }) => {

    var deselect = false;
    var deselectId = "";

    // console.log("Data: ", data)
    const handleClick = () => {
        // console.log("handleDeselect : ", deselect)
        // console.log("Name: ", data.name)
        callback({
            id: data.id,
            name: data.name,
        })
    }

    const handleDeselect = () => {
        callback({
            deselect: true,
            deselectId: data.id
        })
    }

    useEffect(() => {
        if (deselectAll) {
            callback({

            })
        }
    }, [deselectAll])

    return (

        <div onClick={handleClick} id={data?.id} className={styles.listitem}>
            <div className={styles.itemicon}>
                <CheckBox
                    height="2rem"
                    isClicked={handleClick}
                    checked={checked}
                    id="linkCheckBox"
                />
            </div>
            <div className={styles.itemlabel}>
                <div className={styles.namearea}>
                    {data?.name}
                </div>
                <div className={styles.closeicon} onClick={handleDeselect}>
                    <CloseIcon size='15px' />
                </div>
            </div>
        </div>
    )
}

export default SelectItem