import Label from "components/Global/Label";
import { createRef, useEffect, useState } from "react";
import styles from "./transactionscollapse.module.css";
import Pdf from "react-to-pdf";
import Button from "components/Global/Button";
import DownloadIcon from "components/icons/DownloadIcon";
import logo from 'assets/img/header_logo.svg';
import creditCard from 'assets/ic_credit_card.png';

//olusturulacak her collapse componenti icin item propunun verilmesi zorunludur.
const TransactionsCollapse = ({ item, func }) => {
  const ref = createRef();
  const [mainData, setMainData] = useState()

  useEffect(() => {
    setMainData(func(item?.details?.referanceNumber))
  }, [])
  

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };

  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
      return '+90 ' + match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4]
    }
    return null
  }


  return (
    <>
      <div className={styles.isactive}>

        <div ref={ref} className={styles.general} >
          <img src={logo} alt="" />
          <div className={styles.borderLine}>
            <div className={styles.leftside}>
              <div className={styles.leftsideleft}>
                <div className={styles.transaction} style={{ margin: "0 0 30px" }}>İŞLEM TARİHİ VE SAATİ:</div>
                <div className={styles.transaction} style={{ margin: "30px 41px 30px 0" }}>İŞLEM TUTARI:</div>
                <div className={styles.transaction} style={{ margin: "30px 22px 30px 0" }}>KULLANICI BİLGİSİ:</div>
                <div className={styles.transaction} style={{ margin: "30px 43px 30px 0" }}>MÜŞTERİ İSMİ:</div>
                <div className={styles.transaction} style={{ margin: "30px 72px 30px 0" }}>TAKSİT:</div>
                <div className={styles.transaction} style={{ margin: "30px 40px 0 0" }}>İŞLEM DURUMU:</div>
              </div>
              <div style={styles.leftsideright}>
                <div className={styles.values} style={{ width: "146px", margin: "-1px 70px 0 0" }}>{setTimeFormat(mainData?.transactionDate) + "  " + setDateFormat(mainData?.transactionDate)}</div>
                <div className={styles.values} style={{ width: "146px", margin: "30px 70px 0 0" }}>{mainData?.amountAndCurrencyCode}</div>
                <div className={styles.values} style={{ width: "146px", margin: "30px 70px 0 0" }}>{mainData?.userName}</div>
                <div className={styles.values} style={{ width: "146px", margin: "30px 70px 0 0" }}>{mainData?.customerName}</div>
                <div className={styles.values} style={{ width: "146px", margin: "30px 70px 0 0" }}>{mainData?.installment}</div>
                <div className={styles.values} style={{ width: "146px", margin: "30px 70px 0 0" }}>{mainData?.paymentStatus}</div>
              </div>
            </div>

            <div className={styles.horizontal}>.........................................................................</div>

            <div className={styles.rightside}>

              <div className={styles.transactionInformation}>İşlem Bilgileri</div>
              <div className={styles.transactionValue} style={{ margin: "0 104px 4.9px 0" }}>Referans No</div>
              <div className={styles.values} style={{ margin: "4.9px 0 0" }}>{item?.details?.referanceNumber}</div>
              <div className={styles.transactionValue} style={{ margin: "18px 0 4.9px" }}>Ödeme Alan Kullanıcı</div>
              <div className={styles.values} style={{ margin: "4.9px 7px 0 0" }}>{item?.details?.paymentRecipient}</div>
              <div className={styles.transactionValue} style={{ margin: "18px 22px 5.4px 0.3px" }}>İşlem Açıklaması</div>
              <div className={styles.values} style={{ margin: "5.4px 0 0" }}>{item?.details?.transactionDescription}</div>
              <div className={styles.vertical} >................................................................................</div>

              <div className={styles.customerInformation}>Müşteri Bilgisi</div>
              <div className={styles.transactionValue} style={{ margin: "18px 56px 4.9px 0" }}>Telefon</div>
              <div className={styles.values} style={{ margin: "4.9px 0 0" }}>{formatPhoneNumber(item?.details?.customerPhoneNumber)}</div>
              <div className={styles.transactionValue} style={{ margin: "18px 101px 4.9px 0" }}>E-mail</div>
              <div className={styles.values} style={{ width: "122px", margin: "4.9px 0 0" }}>{item?.details?.customerEMail}</div>
            </div>

            {/* <img style={{ width: "64px", height: "45.7px" }} src={creditCard} alt="" /> */}
          </div>
          <span className={styles.adress}>Adres: Levent Mahallesi Meltem Sokak İşbankası Kuleleri Blok No:10 İç Kapı No:4 Beşiktaş / İstanbul</span>
        </div>

      </div>


      <div className={styles.test}>
        <section className={styles.section}>
          <Label text='İşlem Bilgileri' bold fontSize='1.1rem' />
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='Referans No' fontSize='.9rem' />
              <Label text={item?.details?.referanceNumber} bold />
            </div>
            <div className={styles.twiceright}></div>
          </div>
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='Komisyon Tutarı' fontSize='.9rem' />
              <Label text={item?.details?.commissionAmount + " TL"} bold />
            </div>

            <div className={styles.twiceright}>
              <Label text='Ödeme Alan Kullanıcı' fontSize='.9rem' />
              <Label text={item?.details?.paymentRecipient} bold />
            </div>
          </div>
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='İşlem Açıklaması' fontSize='.9rem' />
              <Label text={item?.details?.transactionDescription} bold />
            </div>
            <div className={styles.twiceright}></div>
          </div>
        </section>
        <span className={styles.verticalseparator}></span>
        <section className={styles.section}>
          <Label text='Kart Bilgileri' bold fontSize='1.1rem' />
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='Banka Adı' fontSize='.9rem' />
              <Label text={item?.details?.bankName} bold />
            </div>
            <div className={styles.twiceright}>
              <Label text='Kart Tipi' fontSize='.9rem' />
              <Label text={item?.details?.creditType} bold />
            </div>
          </div>
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='Kart Türü' fontSize='.9rem' />
              <Label text={item?.details?.cardType} bold />
            </div>
            <div className={styles.twiceright}>
              <Label text='Kart Üzerindeki İsim' fontSize='.9rem' />
              <Label text={item?.details?.nameOnTheCard} bold />
            </div>
          </div>
          <div className={styles.twicerow}>
            <div className={styles.twiceleft}>
              <Label text='Kart Numarası' fontSize='.9rem' />
              <Label text={item?.details?.cardNumber} bold />
            </div>
            <div className={styles.twiceright}>
              <Label text='3D Güvenlik' fontSize='.9rem' />
              <Label text={item?.details?.threeDSecurity} bold />
            </div>
          </div>
        </section>
        <span className={styles.verticalseparator}></span>
        <section className={styles.lastsection}>
          <Label text='Müşteri Bilgileri' bold fontSize='1.1rem' />
          <div className={styles.sectioncontainer}>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}>
                <Label text='Telefon' fontSize='.9rem' />
                <Label text={formatPhoneNumber(item?.details?.customerPhoneNumber)} bold />
              </div>
              <div className={styles.twiceright}>
                <Label text='E-mail' fontSize='.9rem' />
                <Label text={item?.details?.customerEMail} bold />
              </div>
            </div>
            <div className={styles.twicerow}>
              <div className={styles.twiceleft}></div>
              <div className={styles.twiceright}>
                <Pdf targetRef={ref} filename="detay.pdf">
                  {({ toPdf }) =>
                    <Button
                      width="100%"
                      height="3rem"
                      icon={<DownloadIcon width="30" height="20" />}
                      text="Detayı İndir"
                      onClick={toPdf}
                    />
                  }
                </Pdf>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TransactionsCollapse;
