import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

const checkNullOrEmpty = (value) => {
  return value !== undefined && value !== "" && value !== " ";
}

export default function PrivateRoute({ children }) {

  const cookies = new Cookies();
  const jwttoken = cookies.get("IdentityToken");
  
  return checkNullOrEmpty(jwttoken) ? children : <Navigate to="/SignIn" />;

}

