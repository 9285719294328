// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customer_collapsecontainer__wz5gz{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    margin: 2%;\r\n    width: 100%;\r\n}\r\n\r\n\r\n\r\n.customer_sendeddescription__FwYGF{\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.customer_receiveddescription__CvKhB{\r\n}\r\n\r\n.customer_text__9JtZZ{\r\n    margin-bottom: 10px;\r\n    color: #4f5386;\r\n    font-size: 0.8rem;\r\n}\r\n.customer_data__wxW6Q{\r\n    font-family: \"Metropolis\";\r\n    font-weight: bold;\r\n\r\n    color: #4f5386;\r\n    font-size: 0.8rem;\r\n}\r\n\r\n.customer_buttons__uWjkt {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    margin-top: 15px;\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/components/Global/List/CollapseForCustomerResponsive/customer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,UAAU;IACV,WAAW;AACf;;;;AAIA;IACI,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,iBAAiB;;IAEjB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;;AAEpB","sourcesContent":[".collapsecontainer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    margin: 2%;\r\n    width: 100%;\r\n}\r\n\r\n\r\n\r\n.sendeddescription{\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.receiveddescription{\r\n}\r\n\r\n.text{\r\n    margin-bottom: 10px;\r\n    color: #4f5386;\r\n    font-size: 0.8rem;\r\n}\r\n.data{\r\n    font-family: \"Metropolis\";\r\n    font-weight: bold;\r\n\r\n    color: #4f5386;\r\n    font-size: 0.8rem;\r\n}\r\n\r\n.buttons {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    margin-top: 15px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsecontainer": "customer_collapsecontainer__wz5gz",
	"sendeddescription": "customer_sendeddescription__FwYGF",
	"receiveddescription": "customer_receiveddescription__CvKhB",
	"text": "customer_text__9JtZZ",
	"data": "customer_data__wxW6Q",
	"buttons": "customer_buttons__uWjkt"
};
export default ___CSS_LOADER_EXPORT___;
