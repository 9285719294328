import PobUp from "components/Global/PopUp/PobUp";
import TripleButton from "components/Global/TripleButton";
import React from "react";
import { useEffect } from "react";
import styles from "./transactioncollapseresponsive.module.css"

const TransactionCollapseResponsive = ({ item, handleRefundButtonClick, handleCancelPaymentButtonClick, userRole, DeleteCheck, ReminderCheck, RefreshCheck, currentRef, popUpClick, isActive, handlePopUp, customerName, sendText }) => {

    // useEffect(() => {
    //     console.log("item : ", item);
    // }, [item])

    // useEffect(() => {
    //     console.log("currentRef : ", currentRef);
    // })


    const setDateFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var year = date.slice(0, 4);
            var month = date.slice(5, 7);
            var day = date.slice(8, 10);
            return day + "." + month + "." + year;
        }
    };

    const setTimeFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var time = date.slice(11, 16);
            return time;
        }
    };

    const setLinkPaymentButtonStatus = (row, i, status, isRefund) => {
        // console.log("rowum : ", row);
        if (row.paymentTypeId === 2) {

            if (row.paymentStatusId === 1 || row.paymentStatusId === 9) {

                return <>
                    <TripleButton

                        secondOnClick={() => { ReminderCheck([row.paymentId], i) }}

                        thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
                    />
                </>
            }

            return <TripleButton
                isRefund={isRefund}
                status={status}

                firstOnClick={() => { RefreshCheck([row.paymentId], i) }}

                thirdOnClick={() => { DeleteCheck([row.paymentId], i) }}
            />

        }

        return <TripleButton
            isRefund={isRefund}
            status={status}
            firstOnClick={() => { handleRefundButtonClick([row.paymentId], i) }}

            thirdOnClick={() => { handleCancelPaymentButtonClick([row.paymentId], i) }}
        />

    }

    return ( // Collpasse devap ediyorum
        <div className={styles.collapsecontainer}>
            <h3 className={styles.header}>İşlem Bilgileri</h3>
            {
                userRole == 1 ?

                    <div className={styles.sendeddescription}>
                        <div >
                            <div className={styles.text}>Bayi Bilgisi</div>
                            <div className={styles.data}>
                                {item?.data?.pobDealerName}
                            </div>
                        </div>
                        <div className={styles.secondcolumn}>
                            <div className={styles.text}>Alt Bayi Bilgisi</div>
                            <div className={styles.data}>
                                {item?.details?.paymentRecipient}
                            </div>
                        </div>
                    </div>
                    :
                    userRole == 5 ?
                        <div className={styles.receiveddescription}>
                            <div >
                                <div className={styles.text}>Alt Bayi Bilgisi</div>
                                <div className={styles.data}>
                                    {item?.data?.pobSubDealerName}
                                </div>
                            </div>
                        </div> : ""
            }

            <div className={styles.receiveddescription}>
                <div className={styles.text}>Referans No</div>
                <div className={styles.data}>
                    {item?.details?.referanceNumber}
                </div>
            </div>
            <div className={styles.sendeddescription}>
                <div>
                    <div className={styles.text}>Komisyon Tutarı</div>
                    <div className={styles.data}>
                        {item?.details?.commissionAmount}
                    </div>
                </div>
                {
                    userRole == 2 || userRole == 4 || userRole == 6 || userRole == 8 ?
                        ""
                        : <div className={styles.secondcolumn}>
                            <div className={styles.text}>Ödeme Alan Kullanıcı</div>
                            <div className={styles.data}>
                                {item?.details?.paymentRecipient}
                            </div>
                        </div>
                }
            </div>
            <div className={styles.receiveddescription}>
                <div>
                    <div className={styles.text}>İşlem Açıklaması</div>
                    <div className={styles.data}>
                        {item?.details?.transactionDescription}
                    </div>
                </div>
            </div>
            <h3 className={styles.header}>Kart Bilgileri</h3>
            <div className={styles.sendeddescription}>
                <div>
                    <div className={styles.text}>Banka Tipi</div>
                    <div className={styles.data}>
                        {item?.details?.bankName}
                    </div>
                </div>
                <div className={styles.secondcolumn}>
                    <div className={styles.text}>Kart Tipi</div>
                    <div className={styles.data}>
                        {item?.details?.creditType}
                    </div>
                </div>
            </div>
            <div className={styles.sendeddescription}>
                <div>
                    <div className={styles.text}>Kart Türü</div>
                    <div className={styles.data}>
                        {item?.details?.cardType}
                    </div>
                </div>
                <div className={styles.secondcolumn}>
                    <div className={styles.text}>Kart Üzerindeki İsim</div>
                    <div className={styles.data}>
                        {item?.details?.nameOnTheCard}
                    </div>
                </div>
            </div>
            <div className={styles.sendeddescription}>
                <div>
                    <div className={styles.text}>Kart Numarası</div>
                    <div className={styles.data}>
                        {item?.details?.cardNumber}
                    </div>
                </div>
                <div className={styles.secondcolumn}>
                    <div className={styles.text}>3D Güvenlik</div>
                    <div className={styles.data}>
                        {item?.details?.threeDSecurity}
                    </div>
                </div>
            </div>
            <h3 className={styles.header}>Müşteri Bilgileri</h3>
            <div className={styles.receiveddescription}>
                <div className={styles.text}>Müşteri İsmi</div>
                <div className={styles.data}>
                    {item?.data?.customerName}
                </div>
            </div>
            <div className={styles.receiveddescription}>
                <div className={styles.text}>Telefon</div>
                <div className={styles.data}>
                    {item?.details?.customerPhoneNumber}
                </div>
            </div>
            <div className={styles.receiveddescription}>
                <div className={styles.text}>E-Mail</div>
                <div className={styles.data}>
                    {item?.details?.customerEMail}
                </div>
            </div>

            <div className={styles.bottomicons}>
                <PobUp
                    callbackButtonEvent={handlePopUp}
                    clickEvent={popUpClick}
                    // dx={-400}
                    // dy={-1000}
                    isCustomer={false}
                    isActive={isActive}
                    ref={currentRef}
                    content={item?.modifiedDate == null ? `isimli kişiye ${sendText} göndermek istermisiniz ?` : `isimli müşteriye en son ${setTimeFormat(item?.modifiedDate) + " " + setDateFormat(item?.modifiedDate)} zamanında ${sendText} gönderildi. Tekrar ${sendText} göndermek ister misiniz?`}
                />
                <div className={styles.triple}>
                    {
                        (item.data.paymentStatusId == 2 || item.data.paymentStatusId == 1) && item.data.paymentTypeId == 2 ? setLinkPaymentButtonStatus(item.data, item.index, true, false) : setLinkPaymentButtonStatus(item.data, item.index, false, true)
                    }
                    {
                        item.data.paymentStatusId == 2 && (item.data.paymentTypeId == 2 || item.data.paymentTypeId == 1) ? setLinkPaymentButtonStatus(item.data, item.index, true, true) : ""
                    }
                </div>
            </div>

        </div >
    );
};

export default TransactionCollapseResponsive;
