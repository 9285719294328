import Button from "components/Global/Button";
import DoubleButton from "components/Global/DoubleButton";
import PobUp from "components/Global/PopUp/PobUp";
import CloseIcon from "components/icons/CloseIcon";
import EditIcon from "components/icons/EditIcon";
import React, { createRef, useRef, useState } from "react";
import { useEffect } from "react";
import styles from "./customer.module.css";

const CustomerCollapse = ({ item, handleUpdateClick, deleteSelector,updateCustomer, currentRef, popUpClick, isActive, handlePopUp, customerNames, func, handleDeleteCustomerButtonClick }) => {

    const ref = createRef();
    const refs = useRef([])

    const [mainData, setMainData] = useState()

    useEffect(() => {
        setMainData(func(item?.details))
    }, [])

    // useEffect(() => {
    //     console.log("itemxxx : ", item?.details);
    // })


    return (
        <div className={styles.collapsecontainer}>
            <div className={styles.sendeddescription}>
                <div className={styles.text}>E-Posta</div>
                <div className={styles.data}>
                    {item?.details?.email}
                </div>
            </div>
            <div className={styles.receiveddescription}>
                <div className={styles.text}>Grup bilgisi</div>
                <div className={styles.data}>
                    {
                        item?.details?.customerGroups
                    }
                </div>
            </div>
            <div className={styles.buttons}>
                <DoubleButton
                    firstOnClick={() => { handleUpdateClick(item.details) }}
                    secondOnClick={() => { deleteSelector(item.details.id, item?.i) }}
                    ref={refs}
                ></DoubleButton>
            </div>

        </div>
    );
};

export default CustomerCollapse;
