import React from 'react'
import styles from './banklist.module.css'
import bonus from '../../../assets/img/img_bonus.png'
import axess from '../../../assets/img/img_axess.png'
import mastercard from '../../../assets/img/img_mastercard.png'
import maximum from '../../../assets/img/img_maximum.png'
import paraf from '../../../assets/img/img_paraf.png'
import teb from '../../../assets/img/img_teb.png'
import visa from '../../../assets/img/img_visa.png'
import ziraat from '../../../assets/img/img_ziraat.png'
import saglamkart from '../../../assets/img/img_saglamkart.png'
import bankkart from '../../../assets/img/img_bankkart.png'
import advantage from '../../../assets/img/img_advantage.png'
import world from '../../../assets/img/img_world.png'
import cardfinans from '../../../assets/img/img_cardfinans.png'
const BankList = ({style}) => {
  return (
    <div style={style} className={styles.bankcontainer}>
      <ul>
        <li>
        <img src={maximum} alt="" />
        </li>
        <li>
        <img src={axess} alt="" />
        </li>
        <li>
        <img src={bonus} alt="" />
        </li>
        <li>
        <img src={paraf} alt="" />
        </li>
        <li>
        <img src={cardfinans} alt="" />
        </li>
        <li>
        <img src={world} alt="" />
        </li>
        <li>
        <img src={advantage} alt="" />
        </li>
        <li>
        <img src={bankkart} alt="" />
        </li>
        <li>
        <img src={saglamkart} alt="" />
        </li>
      </ul>
    </div>
  )
}

export default BankList