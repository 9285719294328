import Layout from "../../components/Layout/Layout";
import BMPStandard from "../../components/Profile/BigMarketPlace/Standard";
import BMPJustPayment  from '../../components/Profile/BigMarketPlace/JustPayment'
import BMPDealerStandard from '../../components/Profile/BigMarketPlace/Dealer/Standard';
import BMPDealerJustPayment from '../../components/Profile/BigMarketPlace/Dealer/JustPayment';
import BMPSubDealerStandard from '../../components/Profile/BigMarketPlace/SubDealer/Standard';
import BMPSubDealerJustPayment from '../../components/Profile/BigMarketPlace/SubDealer/JustPayment';
import SMPStandard from '../../components/Profile/SmallMarketPlace/Standard';
import SMPJustPayment from '../../components/Profile/SmallMarketPlace/JustPayment';
import Cookies from "universal-cookie";

export default function Profile() {

  const cookies = new Cookies();
  var userRole = cookies.get('roleNumber') //Get userRole

  switch (userRole) {
    // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
    case "1":
      return (
        <Layout>
          {console.log("Büyük Firma - Pazaryeri - Standart Kullanıcı ")}
          <BMPStandard />
        </Layout>
      )

    // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
    case "2":
      return (
        <Layout>
          <BMPJustPayment />
        </Layout>
      )

    // Küçük Firma - Pazaryeri - Standart Kullanıcı
    case "3":
      return (
        <Layout>
          <SMPStandard />
        </Layout>
      )

    // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
    case "4":
      return (
        <Layout>
          <SMPJustPayment />
        </Layout>
      )

    // Bayi - Standart Kullanıcı
    case "5":
      return (
        <Layout>
          <BMPDealerStandard />
        </Layout>
      )

    // Bayi - Ödeme Yetkili Kullanıcı
    case "6":
      return (
        <Layout>
          <BMPDealerJustPayment />
        </Layout>
      )

    // Alt Bayi - Standart Kullanıcı
    case "7":
      return (
        <Layout>
          <BMPSubDealerStandard />
        </Layout>
      )

    // Alt Bayi - Ödeme Yetkili Kullanıcı
    case "8":
      return (
        <Layout>
          <BMPSubDealerJustPayment />
        </Layout>
      )

    default:
      return (
        <Layout>
          ROLE COOKIE ERROR
        </Layout>
      )
  }
}
