import * as yup from 'yup';

const formLegalPersonValidator = yup.object().shape({

    taxNumber: yup.string().min(10,"Vergi Numarası 10 haneli olmalı").max(10,"Vergi Numarası 10 haneli olmalı").required('Lütfen vergi numarası alanını doldurunuz. 10 haneli ve yalnızca rakamlarla oluşmalıdır.'),
    contactName: yup.string().max(50).required('Lütfen Yetkili Ad Soyad alanını doldurunuz. ').min(5,"Yetkili Adı en az 5 karakter olmalı"),
    //companyName: yup.string().max(50).required('Lütfen Firma Adı alanını doldurunuz. ').min(5,"Firma Adı en az 5 karakter olmalı"),
    dealerName: yup.string().max(50).required('Lütfen Bayi Adı alanını doldurunuz. ').min(5,"Bayi Adı en az 5 karakter olmalı"),
    email: yup.string().email('Email formatı yanlış.'),
    address: yup.string().max(200).required('Lütfen adres alanını doldurunuz. ').min(10, "Adres en az 10 karakter olmalı"),
    phoneNumber: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
})

export default formLegalPersonValidator;