import axios from "axios";
import Cookies from "universal-cookie";
import axiosRetry from 'axios-retry';
import { BASE_URL } from "environment";
const getIdentityToken = () => {
  const cookies = new Cookies();
  const jwttoken = cookies.get("IdentityToken");

  if (jwttoken !== null && jwttoken !== undefined && jwttoken !== "") {
    return jwttoken;
  }
  return "";
};

//console.log("process.env", process.env);
//environment variables uzerinden alinacak.
const instance = axios.create({
  // baseURL: "http://localhost:12335/api",
  baseURL: BASE_URL,

  headers: { Authorization: "Bearer " + getIdentityToken(), },
});
axiosRetry(instance, { retries: 5 });
export default {
  BigMarketPlace(url = "/BigMarketPlace") {
    return {
      PaymentTransactionList: (data) =>
        instance.post(url + "/PaymentTransactionList", data),
      AccountedForPaymentList: (data) =>
        instance.post(url + "/AccountedForPaymentList", data),
      WillAccountedPaymentList: (data) =>
        instance.post(url + "/WillAccountedPaymentList", data),
    };
  },
  Customer(url = "/Customer") {
    return {
      Add: (data) => instance.post(url + "/Add", data),
      Update: (data) => instance.post(url + "/Update", data),
      GetAll: (data) => instance.post(url + "/GetAll", data),
      Delete: (data) => instance.post(url + "/Delete", data),
      Saved: (data) => instance.post(url + "/Saved", data),
    };
  },
  CustomerGroup(url = "/CustomerGroup") {
    return {
      Create: (data) => instance.post(url + "/Create", data),
      Update: (data) => instance.post(url + "/Update", data),
      Delete: (data) => instance.post(url + "/Delete", data),
      GetAll: (data) => instance.post(url + "/GetAll", data),
      AddCustomerToGroup: (data) =>
        instance.post(url + "/AddCustomerToGroup", data),
      DeleteCustomerToGroup: (data) =>
        instance.post(url + "/DeleteCustomerToGroup", data),
    };
  },
  Dealer(url = "/Dealer") {
    return {
      PaymentTransactionList: (data) =>
        instance.post(url + "/PaymentTransactionList", data),
      GetReportsData: (data) => instance.post(url + "/GetReportsData", data),
    };
  },
  Dashboard(url = "/Dashboard") {
    return {
      GetValuesForSmallMarketPlace: (data) =>
        instance.post(url + "/GetValuesForSmallMarketPlace", data),
      GetValuesForBigMarketPlace: (data) =>
        instance.post(url + "/GetValuesForBigMarketPlace", data),
      GetValuesForDealer: (data) =>
        instance.post(url + "/GetValuesForDealer", data),
      GetValuesForSubDealer: (data) =>
        instance.post(url + "/GetValuesForSubDealer", data),
      GetValuesForUser: (data) =>
        instance.post(url + "/GetValuesForUser", data),
    };
  },
  DealerManagement(url = "/DealerManagement") {
    return {
      Create: (data) => instance.post(url + "/Create", data),
      Update: (data) => instance.post(url + "/Update", data),
      Delete: (data) => instance.post(url + "/Delete", data),
      List: (data) => instance.post(url + "/List", data),
      GetById: (data) => instance.post(url + "/GetById", data),
    };
  },
  LimitAndCommissionRates(url = "/LimitAndCommissionRates") {
    return {
      GetComissionRate: (data) =>
        instance.post(url + "/GetComissionRate", data),
    };
  },
  PaymentAuthorizedUser(url = "/PaymentAuthorizedUser") {
    return {
      PaymentTransactionList: (data) =>
        instance.post(url + "/PaymentTransactionList", data),
      GetReports: (data) => instance.post(url + "/GetReports", data),
    };
  },
  PaymentInformation(url = "/PaymentInformation") {
    return {
      PaymentTransactionDetailList: (data) =>
        instance.post(url + "/PaymentTransactionDetailList", data),
    };
  },
  PaymentTransaction(url = "/PaymentTransaction") {
    return {
      ThreeDSecurePayment: (data) =>
        instance.post(url + "/ThreeDSecurePayment", data),
      CreatePaymentRequest: (data) =>
        instance.post(url + "/CreatePaymentRequest", data),
      UpdatePaymentRequest: (data) =>
        instance.post(url + "/UpdatePaymentRequest", data),
      GetPaymentRequest: (data) =>
        instance.post(url + "/GetPaymentRequest", data),
      PaymentRequestDenied: (data) =>
        instance.post(url + "/PaymentRequestDenied", data),
      PaymentRequestThreeDSecurePayment: (data) =>
        instance.post(url + "/PaymentRequestThreeDSecurePayment", data),
      RefreshPaymentRequest: (data) =>
        instance.post(url + "/RefreshPaymentRequest", data),
      CancelPaymentLinkRequest: (data) =>
        instance.post(url + "/CancelPaymentLinkRequest", data),
      PaymentRequestReminder: (data) =>
        instance.post(url + "/PaymentRequestReminder", data),
      RefundPaymentRequest: (data) =>
        instance.post(url + "/RefundPaymentRequest", data),
      CancelPaymentRequest: (data) =>
        instance.post(url + "/CancelPaymentRequest", data),
    };
  },
  SmallMarketPlace(url = "/SmallMarketPlace") {
    return {
      PaymentTransactionList: (data) =>
        instance.post(url + "/PaymentTransactionList", data),
      AccountedForPaymentList: (data) =>
        instance.post(url + "/AccountedForPaymentList", data),
      WillAccountedPaymentList: (data) =>
        instance.post(url + "/WillAccountedPaymentList", data),
    };
  },
  SubDealer(url = "/SubDealer") {
    return {
      PaymentTransactionList: (data) =>
        instance.post(url + "/PaymentTransactionList", data),
      GetReports: (data) => instance.post(url + "/GetReports", data),
    };
  },
  SubDealerManagement(url = "/SubDealerManagement") {
    return {
      Create: (data) => instance.post(url + "/Create", data),
      Update: (data) => {
        console.log("data", data);
        return instance.post(url + "/update", data);
      },
      Delete: (data) => instance.post(url + "/Delete", data),
      List: (data) => instance.post(url + "/List", data),
      GetById: (data) => instance.post(url + "/GetById", data),
    };
  },
  Support(url = "/Support") {
    return {
      Create: (data) => instance.post(url + "/Create", data),
      List: (data) => instance.post(url + "/List", data),
    };
  },
  User(url = "/User") {
    return {
      Login: (data) => instance.post(url + "/Login", data),
      Add: (data) => instance.post(url + "/Add", data),
      Edit: (data) => instance.post(url + "/Edit", data),
      Delete: (data) => instance.post(url + "/Delete", data),
      GetById: (data) => instance.post(url + "/GetById", data),
      GetInformation: (data) => instance.post(url + "/GetInformation", data),
      GetCompanyInformationForSmallMarketPlace: (data) =>
        instance.post(url + "/GetCompanyInformationForSmallMarketPlace", data),
      GetCompanyInformationForDealer: (data) =>
        instance.post(url + "/GetCompanyInformationForDealer", data),
      GetCompanyInformationForSubDealer: (data) =>
        instance.post(url + "/GetCompanyInformationForSubDealer", data),
      GetCompanyInformationForBigMarketPlace: (data) =>
        instance.post(url + "/GetCompanyInformationForBigMarketPlace", data),
      GetAllUser: (data) =>
        instance.post(url + "/GetAllUser", data),
      GetCompanyInformation: (data) =>
        instance.post(url + "/GetCompanyInformation", data),
      SetInformation: (data) => instance.post(url + "/SetInformation", data),
    };
  },
  Helpers(url = "/Helpers") {
    return {
      GetAllDealerForFilter: (data) =>
        instance.post(url + "/GetAllDealerForFilter", data),
      GetAllSubDealerForFilter: (data) =>
        instance.post(url + "/GetAllSubDealerForFilter", data),
      GetAllCity: (data) => instance.post(url + "/GetAllCity", data),
      GetAllDistrict: (data) => instance.post(url + "/GetAllDistrict", data),
      GetAllSupport: (data) => instance.post(url + "/GetAllSupportTopics", data),
      GetTermDay: (data) => instance.post(url + "/GetTermDay", data),
      GetAllPaymentStatus: (data) => instance.post(url + "/GetAllPaymentStatus", data),
      GetAllPaymentGroupStatus: (data) => instance.post(url + "/GetAllPaymentGroupStatus", data),
      GetAllERPStatus: (data) => instance.post(url + "/GetAllERPStatus", data),
    };
  },
  RedirectUrl(url = "/RedirectUrl") {
    return {
      GetPaymentInformation: (data) => instance.post(url + "/GetPaymentInformation", data),
    };
  },

};
