import React from 'react';

const LeftIcon = ({ color, size }) => {
    var color = color === undefined ? color = '#000d89' : color;
    var size = size === undefined ? size = 24 : size;
    return <>
        <symbol id="left">
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M13.384 4.074c1.155-1.08 1.894-1.682 2.41-1.935.677-.332 1.403-.075 1.157.846-.104.388-1.758 2.56-2.15 3.122-.825 1.18-1.771 2.561-2.311 3.588-.644 1.225-.81 2.157-.536 3.493.372 1.191 1.287 2.532 1.974 3.562.531.736 2.856 3.67 3.008 4.258.252.979-.608 1.18-1.291.837-.514-.258-1.248-.866-2.393-1.957-1.146-1.09-2.705-2.664-3.738-3.84-1.034-1.175-1.544-1.953-1.845-2.6-.54-1.16-.467-2.2.157-3.311.943-1.684 4.434-5.011 5.558-6.063z" fill={color} />
            </svg>
        </symbol>
    </>;
};

export default LeftIcon;
