import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import styles from "./Layout.module.css";
import MenuItems from "./MenuItems";
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/header_logo.svg';
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import { useMediaQuery } from 'react-responsive'
import LayoutResponsive from 'components/Responsive/Layout';

export default function Layout({ children }) {
  const [auth, setAuth] = useState([]);
  const [roleNumber, setRoleNumber] = useState()
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const cookies = new Cookies();

    setAuth(cookies.get('Auth'));
    setRoleNumber(cookies.get('roleNumber'));
    // console.log("roleNumber", cookies.get('roleNumber'))
    // console.log('auth', cookies.get('Auth'))
  }, [])

  useEffect(() => {
    //auth islemleri burada yapiliyor. cookie uzerinden gelen auth ile menu itemlari birbiriyle esitleniyor
    if (checkNullOrEmptyArray(auth)) {
      let menuArr = [];

      auth.forEach((item) => {

        // console.log("auth.forEach", item)

        MenuItems.forEach((menu) => {

          if (menu.props.id == item.menuId) {

            if (checkSpecialCases(item, roleNumber)) {
              menuArr.push(menu);
            }

          }

        })
      });

      menuArr.sort((a, b) => parseInt(a?.props.orderId) - parseInt(b?.props.orderId))

      setMenuList(menuArr);
    }
  }, [auth, roleNumber])

  //bazı yetkiler için özel durumlar
  const checkSpecialCases = (auth, roleNumber) => {
    // console.log("checkSpecialCases auth", auth, typeof auth?.menuId)
    // console.log("checkSpecialCases roleNumber", roleNumber, typeof roleNumber)

    if (checkNullOrEmpty(roleNumber)) {

      //özel durum 1 : big marketplace standart rolünün içinde subdealermanagement var ama o sayfaya giremeyecek
      if (roleNumber === "1") {
        if (auth.menuId === 7) {
          return false;
        }
      }

      //özel durum 2 : big marketplace standart rolünün içinde customers var ama o sayfaya giremeyecek
      if (roleNumber === "1") {
        if (auth.menuId === 5) {
          return false;
        }
      }

    }

    return true;
  }

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ?
      <LayoutResponsive menuList={menuList}>
        {children}
      </LayoutResponsive>
      : <>
        <div className={styles.menucontainer}>
          <div className={styles.menu}>

            <Link to='/'>
              <img src={logo} alt="" />
            </Link>
            {
              <div className={styles.menuarea}>
                {/* //test için
              {MenuItems.map((item) => {
                return React.cloneElement(item, {
                  className: styles.item
                })
              })} 
            */}
                {menuList && menuList.map((item) => {
                  return React.cloneElement(item, {
                    className: styles.item
                  })
                })}
              </div>

            }
            <Link to='/support' className={styles.supportarea}>Destek</Link>
            <Link to='/profile' className={styles.supportarea}>Profil</Link>
            <Link to='/SignOut' className={styles.supportarea}>Çıkış Yap</Link>
          </div>
        </div>
        <div className={styles.separator}></div>
        <>
          {children}
        </>
      </>

  );

}