import React from 'react';
import styles from './button.module.css';
import cx from 'classnames'
import { SettingsEthernetSharp } from '@mui/icons-material';

const Button = ({ disabled, children, width, height = '3rem', icon, text, fontSize, onClick, type, backgroundImage, backgroundColor, minWidth, maxWidth, border, color, fontFamily, visible }) => {
    if (visible) {
        return ""
    } else if (icon == undefined) {
        return (
            <button disabled={disabled} onClick={onClick} type={type} className={cx(disabled && styles.disabled, styles.btn)} style={{ width, height, justifyContent: 'center', fontSize, backgroundImage, backgroundColor, minWidth, maxWidth, border, color, fontFamily }}>
                {text}
             </button>
        )
    }
    else {
        return (
            <button type={type} onClick={onClick} className={styles.btn} style={{ width, height, fontSize, backgroundImage, backgroundColor, minWidth, maxWidth, border, color, fontFamily }}>
                <div className={styles.btncontainer}>
                    <div className={styles.icon}>{icon}</div>
                    <div className={styles.text}>{text}</div>
                </div>
            </button>
        )
    }
};

export default Button;
