import Button from 'components/Global/Button';
import Cardboard from 'components/Global/Cardboard';
import DateTimePicker from 'components/Global/DateTimePickerv2';
import Dropdown from 'components/Global/Dropdown';
import List from 'components/Global/List';
import ListContainer from 'components/Global/ListContainer';
import PriceLabel from 'components/Global/PriceLabel';
import SwitchButton from 'components/Global/SwitchButton';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import ResponsiveFilterArea from '../FilterArea';
import ResizeableCard from '../global/ResizeableCard';
import styles from './transactionreports.module.css'

const TransactionReportsResponsive = ({
    setPageType,
    pageType,
    currentItems,
    handleDateRangeChange,
    responseData,
    subAndDealersData,
    dealerFilter,
    setDealerFilter,
    searchData,
    setSearchData
}) => {
    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber');
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [buttonStatus, setButtonStatus] = useState(false);//filtreleye tıklanınca true oluyo
    const [value, setValue] = useState("");
    const [date, setDate] = useState();
    const [isOpen, setIsOpen] = useState(false); //isClosed true ise açılmıyo /kapanıyor false ise açılıyo

    useEffect(() => {
        if (buttonStatus == true) {
            setDealerFilter(value)
            if (date?.endDate != null) {
                let startDate = new Date(date.startDate).toLocaleDateString();
                let endDate = new Date(date.endDate).toLocaleDateString();
                if (startDate !== endDate) {
                    setSearchData({
                        ...searchData,
                        startDate: setDateFormat(startDate),
                        endDate: setDateFormat(endDate),
                    });
                }
            }
            setIsOpen(true);
        }
    }, [buttonStatus])

    useEffect(() => {
        isOpen && setButtonStatus(false)
    }, [isOpen])


    const setDateFormat = (dataTime) => {
        var day = dataTime.slice(0, 2);
        var month = dataTime.slice(3, 5);
        var year = dataTime.slice(6, 10);
        return year + "-" + month + "-" + day + "T00:00:00.000Z";
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        e.defaultPrevented && setButtonStatus(true)
    }

    const pull_data = (status) => status == false && setIsOpen(false);

    return (
        <div className={styles.container}>
            <div className={styles.filtercontainer} style={{ height: userRole == "5" || userRole == "7" ? "119px" : "50px" }}>
                {
                    (userRole == "5" || userRole == "7") &&
                    <div className={styles.switchbutton}>
                        <SwitchButton
                            callback={setPageType}
                            style={{ height: "2.8rem", width: "100%" }}
                            items={[
                                { id: 1, text: "Tarih Bazlı" },
                                { id: 2, text: userRole == "5" ? "Kullanıcı / Alt Bayi Bazlı" : "Kullanıcı Bazlı" },
                            ]}
                            selectedValue={pageType}
                        />
                    </div>

                }

                {
                    userRole == "5" ?
                        <ResponsiveFilterArea firstElement={<DateTimePicker onSelected={handleDateRangeChange} />} isButtonActive={true} modalClosed={isOpen} func={pull_data}>
                            <form onSubmit={handleFormSubmit} className={styles.form}>
                                <div className={styles.formitems}>
                                    <DateTimePicker onSelected={(d) => setDate(d)} />
                                    <Dropdown items={subAndDealersData} width={"100%"} text={"Bayim + Tüm Alt Bayiler"} color={"black"} selectItemId={dealerFilter} onSelected={(id) => setValue(id)} />
                                    <Button type={"submit"} text={"Filtrele"} width={"100%"}></Button>
                                </div>
                            </form>
                        </ResponsiveFilterArea> : <DateTimePicker onSelected={handleDateRangeChange} />
                }
            </div>

            <ResizeableCard showContent={
                !isCollapsed &&
                <>
                    <div className={styles.biglabel}>Başarılı İşlem Tutarı</div>
                    <PriceLabel
                        price={responseData.successTransactionAmount}
                        count={responseData.successTransactionQuantity}
                        size="3"
                        margin="15px"
                        countTextMargin="20px"
                    />
                </>
            }
                setIsCollapsed={setIsCollapsed}
                collapsibleContent={
                    < div className={styles.infoarea}>
                        <div className={styles.cardbg}>
                            <div className={styles.biglabel}>Başarılı İşlem Tutarı</div>
                            <PriceLabel
                                price={responseData.successTransactionAmount}
                                count={responseData.successTransactionQuantity}
                                size="3"
                                margin="15px"
                                countTextMargin="20px"
                            />
                            <div>
                                <table className={styles.table}>
                                    <tr className={styles.tablehead}>
                                        <td className={styles.spacer}>İşlem Durumu</td>
                                        <td className={styles.spacer}>İşlem Tutarı</td>
                                        <td className={styles.spacer}>İşlem Adedi</td>
                                    </tr>
                                    <tr className={styles.tableitem}>
                                        <td className={styles.spacer}>İptal / İade</td>
                                        <td className={styles.spacer}>{responseData.cancellationAndRefundTransactionAmount}</td>
                                        <td className={styles.spacer}>{responseData.cancellationAndRefundTransactionQuantity}</td>
                                    </tr>
                                    <tr className={styles.tableitem}>
                                        <td className={styles.spacer}>Bekleyenler</td>
                                        <td className={styles.spacer}>{responseData.waitingTransactionAmount}</td>
                                        <td className={styles.spacer}>{responseData.waitingTransactionQuantity}</td>
                                    </tr>
                                    <tr className={styles.tableitem}>
                                        <td className={styles.spacer}>Başarısızlar</td>
                                        <td className={styles.spacer}>{responseData.failedTransactionAmount}</td>
                                        <td className={styles.spacer}>{responseData.failedTransactionQuantity}</td>
                                    </tr>
                                    <tr className={styles.tableitem}>
                                        <td className={styles.spacer}>Ödenecek Komisyon</td>
                                        <td className={styles.spacer}>{responseData.commissionToBePaidTransactionAmount}</td>
                                        <td className={styles.spacer}>{responseData.commissionToBePaidTransactionQuantity}</td>
                                    </tr>
                                    <tr className={styles.tableitem}>
                                        <td className={styles.spacer}>Ana firmaya geçecek</td>
                                        <td className={styles.spacer}>{responseData.marketPlaceToBePaidAmountTransactionAmount}</td>
                                        <td className={styles.spacer}>{responseData.marketPlaceToBePaidAmountTransactionQuantity}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                } />

            <Cardboard style={{ margin: "15px", flex: 1 }}>
                <ListContainer>
                    <List
                        fontFamily="Metropolis"
                        columnNames={["", "BAŞARILI", "", ""]}
                        data={currentItems}
                    />
                </ListContainer>
            </Cardboard>


        </div>
    )
}

export default TransactionReportsResponsive