import Button from 'components/Global/Button'
import CloseIcon from 'components/icons/CloseIcon'
import React from 'react'
import styles from './modal.module.css'
import logo from 'assets/img/header_logo.svg';
import cx from 'classnames'

const ResponsiveModal = ({ callbackEvent, body: Body, modalStyle, isClosed = false, background }) => {

    // const [dynamicModalStyle, setDynamicModalStyle] = useState(modalStyle)
    const { scrollHeight: height, clientWidth: width } = document.body;

    return (
        !isClosed ?
            <section style={{ width: width, height: height }} className={cx(styles.modal, styles.active)}>
                <div className={styles.modalcontainer}>
                    <div className={styles.header}>
                        <img src={logo} alt="" />
                        <Button onClick={() => callbackEvent(false)} text={<CloseIcon color='#fff' />} />
                    </div>
                    <div className={styles.separator}></div>
                    <div style={{background:background}} className={styles.body}>

                        {Body}

                    </div>
                </div>
            </section > : ""
    )
}

export default ResponsiveModal