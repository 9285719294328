import Layout from '../../components/Layout/Layout';
import Cookies from "universal-cookie";

import CustomerGlobal from 'components/Customer/CustomerGlobal';

export default function Customer() {

    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole
    //console.log('cookiegetinCustomer', userRole);

    //Get by user role
    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1

        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    {console.log("Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    {console.log("Küçük Firma - Pazaryeri - Standart Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    {console.log("Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    {console.log("Bayi - Standart Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    {console.log("Bayi - Ödeme Yetkili Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    {console.log("Alt Bayi - Standart Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;
        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    {console.log("Alt Bayi - Ödeme Yetkili Kullanıcı")}
                    <CustomerGlobal />
                </Layout>
            )
            break;

        default:
            return (
                <Layout>
                    COOKIE ERROR
                </Layout>
            )
            break;

    }

}
