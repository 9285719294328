// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".responsivefilterarea_container__Y9HVX {\r\n    display: flex;\r\n    height: 50px;\r\n    margin: 15px 0 15px 0;\r\n    /* background-color: white;\r\n    border-bottom: 3px solid #e6e6e6; */\r\n}\r\n\r\n.responsivefilterarea_content__Bb2fe {\r\n    flex-basis: 100%;\r\n}\r\n\r\n.responsivefilterarea_button__KGM5D {\r\n    display: flex;\r\n    justify-content: center;\r\n    background-image: \"none\";\r\n    background-color: white;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/FilterArea/responsivefilterarea.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB;uCACmC;AACvC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;AAC3B","sourcesContent":[".container {\r\n    display: flex;\r\n    height: 50px;\r\n    margin: 15px 0 15px 0;\r\n    /* background-color: white;\r\n    border-bottom: 3px solid #e6e6e6; */\r\n}\r\n\r\n.content {\r\n    flex-basis: 100%;\r\n}\r\n\r\n.button {\r\n    display: flex;\r\n    justify-content: center;\r\n    background-image: \"none\";\r\n    background-color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "responsivefilterarea_container__Y9HVX",
	"content": "responsivefilterarea_content__Bb2fe",
	"button": "responsivefilterarea_button__KGM5D"
};
export default ___CSS_LOADER_EXPORT___;
