// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputsearch_searchcontainer__YGGeK{\n    display:flex;\n    box-sizing: border-box;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f5f7f9;\n    border-radius: 1rem;\n    padding-left:10px;\n    padding-right:10px;\n}\n\n.inputsearch_inputarea__oOciZ{\n    flex-basis: 85%;\n}\n\n.inputsearch_input__AcMxJ{\n    overflow: hidden;\n    text-overflow: ellipsis; \n    white-space: nowrap;\n    width: 80%;\n    background-color: #f5f7f9;\n    border-radius: 1rem;\n    border:none;\n\n}\n\n.inputsearch_logoarea__LRjb6{\n    display: flex;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/InputSearch/inputsearch.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;;AAEf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".searchcontainer{\n    display:flex;\n    box-sizing: border-box;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f5f7f9;\n    border-radius: 1rem;\n    padding-left:10px;\n    padding-right:10px;\n}\n\n.inputarea{\n    flex-basis: 85%;\n}\n\n.input{\n    overflow: hidden;\n    text-overflow: ellipsis; \n    white-space: nowrap;\n    width: 80%;\n    background-color: #f5f7f9;\n    border-radius: 1rem;\n    border:none;\n\n}\n\n.logoarea{\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchcontainer": "inputsearch_searchcontainer__YGGeK",
	"inputarea": "inputsearch_inputarea__oOciZ",
	"input": "inputsearch_input__AcMxJ",
	"logoarea": "inputsearch_logoarea__LRjb6"
};
export default ___CSS_LOADER_EXPORT___;
