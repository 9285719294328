import React, { useEffect, useState } from 'react';
import styles from './subdealeradd.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import SwitchButton from 'components/Global/SwitchButton';
import Dropdown from 'components/Global/Dropdown';
import formRealPersonValidator from './FormRealPersonValidator'
import formLegalPersonValidator from './FormLegalPersonValidator'
import { checkNullOrEmpty } from "hoc/Conditions";
import ReactInputMask from 'react-input-mask';

var initialDistrictSearch = {
    cityCode: 0,
    countryCode: 0
};

const DealerAdd = (props) => {
    const [responseErrors, setResponseErrors] = useState();//api den gelen hatalar
    const [isLoading, setIsLoading] = useState()
    const [cities, setCities] = useState();
    const [districts, setDistricts] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [selectedDealerType, setSelectedDealerType] = useState(2);
    const [dealerName, setDealerName] = useState()
    const [isFull, setisFull] = useState(false)

    useEffect(() => {
        getAllCitiesDataFromApi();
        getAllDistrictsDataFromApi();

        setSelectedDistrict();
        setSelectedCity();
    }, []);

    useEffect(() => {
        (
            (selectedDealerType == (1 || 2)) ?
                (
                    // values.taxNumber.length < 10
                    // // || values.contactName == "
                     values.subDealerName == ""
                    || values.email == ""
                    || values.address == ""
                    || values.phoneNumber.length < 13)
                :
                (
                    // values.trIdentityNumber.length < 10
                    // || values.individualName == ""
                     values.subDealerName == ""
                    || values.email == ""
                    || values.address == ""
                    || values.phoneNumber.length < 13
                )
        ) ?
            setisFull(true)
            : setisFull(false)

    })


    useEffect(() => {
        setDealerName(values?.pobDealerName)
        values.companyName = values.pobDealerName
    })

    useEffect(() => {

        if (selectedDealerType != 1) {

            setDealerName(values?.pobDealerName)

            values.companyName = values.pobDealerName

        }

    }, [selectedDealerType])


    useEffect(() => {
        handleReset({});
        setFieldValue("pobDealerName", props?.pobDealerName);
    }, [props.pobDealerName])


    const { handleChange, touched, handleBlur, values, handleSubmit, errors, handleReset, setFieldValue } = useFormik({

        initialValues: {
            dealerType: selectedDealerType,          //Dealer type için (Tüzel ltd = 2 / Tüzel şahıs= 3 / gerçek = 1 kişi)
            taxNumber: "",                           //Vergi numarası
            companyName: dealerName,                 //Firma Adı 
            subDealerName: "",                       //Alt bayi adı
            phoneNumber: "",                         //Tel no
            email: "",                               //Eposta 
            districtId: "",                          //İlçe
            cityCode: 0,                             //İl
            countryCode: 212,                        //Ülke (default 212=Türkiye)
            monthlyAmountLimit: 0,                   //Aylık limit (opsiyonel)
            trIdentityNumber: "",                    //Tc no
            individualName: "",                      //gerçek kişi bayi türü için bireysel ad soyad
            contactName: "",                         //Yetkili kişi
            address: "",                             //Adres
            erpNumber: ""

        },
        onSubmit: values => {

            setIsLoading(true);

            if (checkNullOrEmpty(selectedCity)) {
                values.cityCode = parseInt(selectedCity)
            }
            if (checkNullOrEmpty(selectedDistrict)) {
                values.districtId = selectedDistrict
            }
            // if (checkNullOrEmpty(values.monthlyAmountLimit) && values.monthlyAmountLimit != "") {
            //     console.log("Limit : ", values.monthlyAmountLimit)
            //     values.monthlyAmountLimit = parseInt(values.monthlyAmountLimit)
            // }
            if (checkNullOrEmpty(values.trIdentityNumber)) {

                values.trIdentityNumber = values.trIdentityNumber + ""

            }
            values.taxNumber = values.taxNumber + "";
            values.monthlyAmountLimit = parseInt(values.monthlyAmountLimit)
            values.erpNumber = values.erpNumber + "";

            values.dealerType = selectedDealerType
            console.log("Values : ", values)
            api.SubDealerManagement().Create(values).then((response) => {

                handleReset({});
                setSelectedDistrict();
                setSelectedCity();
                props?.handleModalClick(false);

            }).catch((error) => {
                // handle error
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data.userMessage);
                setResponseErrors(error?.response?.data.userMessage)

            }).finally(() => {
                setIsLoading(false);
            });

        },
        validationSchema: selectedDealerType === 1 ? formRealPersonValidator : formLegalPersonValidator
    });


    const getAllCitiesDataFromApi = () => {
        api.Helpers().GetAllCity({ isAll: true })
            .then((response) => {
                console.log("cities : ", response.data.data)
                if (checkNullOrEmpty(response.data.data)) {
                    setCities(response.data.data.map((item) => (
                        {
                            id: item.cityCode,
                            text: item.cityName
                        }
                    )))
                }
                // console.log("Get Cities response : ", response.data.data)
            })
            .catch((error) => {
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data.message);
                setResponseErrors("Şehirler Getirilirken Bir hata oluştu lütfen tekrar deneyiniz.")

            });
    }

    const getAllDistrictsDataFromApi = (searchData = initialDistrictSearch) => {
        api.Helpers().GetAllDistrict(searchData)
            .then((response) => {
                setDistricts(response.data.data.map((item) => (
                    {
                        id: item.districtId,
                        text: item.districtName
                    }
                )))
            })
            .catch((error) => {
                console.log('error', error?.response?.data);
                // setResponseErrors(error?.response?.data.message);
                setResponseErrors("İlçeler Getirilirken Bir hata oluştu lütfen tekrar deneyiniz.")

            });
    }

    const handleSelectCityDropdown = (cityCode) => {
        setSelectedCity(cityCode);
        getAllDistrictsDataFromApi({ cityCode: cityCode });
    }

    const switchItems = [
        { id: '2', text: 'Tüzel Kişi', smallText: 'LTD, AŞ' },
        { id: '3', text: 'Tüzel Kişi', smallText: 'Şahıs Firması' },
        { id: '1', text: 'Gerçek Kişi', smallText: 'Vergi Levhası Olmayan' },
    ];

    // //Bayi Ekle : Tüzel Kişi Ltd A.Ş.

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <h1>Yeni Alt Bayi Ekle</h1>

            <div className={styles.switcharea}>
                <SwitchButton
                    style={{ width: '70%', height: '3rem' }}
                    items={switchItems}
                    callback={setSelectedDealerType}
                    selectedValue={selectedDealerType}
                />
            </div>
            {(() => {

                if (selectedDealerType == 1) {
                    return <>
                        <form onSubmit={handleSubmit} className={styles.form} style={props?.style}>
                            <section>
                                <div className={styles.inputarea}>

                                    <ReactInputMask
                                        mask="99999999999"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.trIdentityNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="trIdentityNumber" name="trIdentityNumber" placeholder="T.C. Kimlik Numarası" onBlur={handleBlur} error={errors.trIdentityNumber && touched.trIdentityNumber} helperText={(errors.trIdentityNumber && touched.trIdentityNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        error={touched.individualName && errors.individualName}
                                        name='individualName'
                                        height='3rem'
                                        maxLength='100'
                                        value={values.individualName}
                                        width='100%'
                                        placeholder='Ad Soyad'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.subDealerName && errors.subDealerName}
                                        name='subDealerName'
                                        maxLength='100'
                                        height='3rem'
                                        width='100%'
                                        value={values.subDealerName}
                                        placeholder='Alt Bayi Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.monthlyAmountLimit && errors.monthlyAmountLimit}
                                        name='monthlyAmountLimit'
                                        width='100%'
                                        height='3rem'
                                        value={values.monthlyAmountLimit === 0 ? "" : values.monthlyAmountLimit}
                                        placeholder='Limit (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type='number'
                                    />

                                    <Input
                                        error={touched.customCode && errors.customCode}
                                        name='customCode'
                                        width='100%'
                                        height='3rem'
                                        value={values.customCode === 0 ? "" : values.customCode}
                                        placeholder='Bayi Kodu (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <ReactInputMask
                                        mask="999 999 99 99"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        error={touched.email && errors.email}
                                        name='email'
                                        maxLength='150'
                                        width='100%'
                                        height='3rem'
                                        value={values.email}
                                        placeholder='E-Posta'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Dropdown
                                        error={touched.cityCode && errors.cityCode}
                                        text="Şehir"
                                        height='3rem'
                                        width='100%'
                                        backgroundColor="#fff"
                                        border="2px solid #e4e4e4"
                                        items={cities}
                                        onSelected={handleSelectCityDropdown} />

                                    <Dropdown
                                        error={touched.districtId && errors.districtId}
                                        width='100%'
                                        height='3rem'
                                        items={districts}
                                        text='İlçe'
                                        backgroundColor="#fff"
                                        border="2px solid #e4e4e4"
                                        onSelected={setSelectedDistrict} />

                                    <Input
                                        error={touched.address && errors.address}
                                        name='address'
                                        maxLength='500'
                                        width='100%'
                                        value={values.address}
                                        placeholder='Adres'
                                        height='3rem'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                </div>

                                <div className={styles.footer}>
                                    <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull} />
                                </div>
                            </section>
                        </form>

                    </>
                }
                else if (selectedDealerType == 2 || selectedDealerType == 3) {//Tüzel kişiler
                    return <>
                        <form onSubmit={handleSubmit} className={styles.form} style={props?.style}>
                            <section>
                                <div className={styles.inputarea}>

                                    <ReactInputMask
                                        mask="9999999999"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.taxNumber}
                                        maskChar=""
                                    >
                                        {() => <Input name="taxNumber" placeholder="Vergi Numarası" onBlur={handleBlur} error={errors.taxNumber && touched.taxNumber} helperText={(errors.taxNumber && touched.taxNumber)} />}
                                    </ReactInputMask>
                                    <Input
                                        height='3rem'
                                        error={touched.contactName && errors.contactName}
                                        name='contactName'
                                        maxLength='100'
                                        value={values.contactName}
                                        width='100%'
                                        placeholder='Yetkili Ad Soyad'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <Input
                                        error={touched.companyName && errors.companyName}
                                        name='companyName'
                                        maxLength='100'
                                        width='100%'
                                        value={values.companyName}
                                        placeholder='Firma Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                    />

                                    <Input
                                        error={touched.subDealerName && errors.subDealerName}
                                        name='subDealerName'
                                        maxLength='100'
                                        width='100%'
                                        value={values.subDealerName}
                                        placeholder='Alt Bayi Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <ReactInputMask
                                        mask="999 999 99 99"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        error={touched.email && errors.email}
                                        name='email'
                                        maxLength='150'
                                        width='100%'
                                        height='3rem'
                                        value={values.email}
                                        placeholder='E-Posta'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.monthlyAmountLimit && errors.monthlyAmountLimit}
                                        name='monthlyAmountLimit'
                                        width='100%'
                                        value={values.monthlyAmountLimit === 0 ? "" : values.monthlyAmountLimit}
                                        placeholder='Limit (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type='number'
                                    />

                                    <Input
                                        error={touched.customCode && errors.customCode}
                                        name='customCode'
                                        width='100%'
                                        height='3rem'
                                        value={values.customCode === 0 ? "" : values.customCode}
                                        placeholder='Bayi Kodu (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.erpNumber && errors.erpNumber}
                                        name='erpNumber'
                                        width='100%'
                                        value={values.erpNumber === 0 ? "" : values.erpNumber}
                                        placeholder='ERP numarası (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type='number'
                                    />

                                    <Dropdown
                                        error={errors.cityCode}
                                        width='100%'
                                        items={cities}
                                        backgroundColor='#fff'
                                        onSelected={handleSelectCityDropdown}
                                        border="2px solid #e4e4e4"
                                        text='Şehir' />

                                    <Dropdown
                                        error={errors.districtId}
                                        width='100%'
                                        items={districts}
                                        text='İlçe'
                                        backgroundColor='#fff'
                                        border='2px solid #e4e4e4'
                                        onSelected={setSelectedDistrict} />

                                    <Input
                                        error={touched.address && errors.address}
                                        maxLength='500'
                                        name='address'
                                        width='100%'
                                        value={values.address}
                                        placeholder='Adres'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                </div>
                                <div className={styles.footer}>
                                    <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull} />
                                </div>
                            </section>
                        </form>
                    </>
                }

            })()}

            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }

        </>
    )
};

export default DealerAdd;
