import React, { useEffect, useState } from 'react'
import styles from './bmpdashboardjp.module.css';
import Container from 'components/Global/Container';
import RightIcon from 'components/icons/RightIcon';
import PriceLabel from 'components/Global/PriceLabel';
import ListContainer from 'components/Global/ListContainer';
import List from 'components/Global/List';
import Pagination from 'components/Global/Pagination';
import banner from "../../../../../assets/img/img_dummy_banner.png";
import Dropdown from 'components/Global/Dropdown';
import Card from 'components/Global/Card';
import FilterArea from "components/Global/FilterArea";
import Button from 'components/Global/Button';
import { checkNullOrEmpty, checkNullOrEmptyArray, isValidDate } from 'hoc/Conditions';
import api from '../../../../../store/api/actions'
import { Link, useSearchParams } from 'react-router-dom';
import Payment from 'components/Global/Forms/Payment'
import Modal from 'components/Global/Modal';
import CreditCardIcon from 'components/icons/CreditCardIcon';
import LinkPaymentIcon from 'components/icons/LinkPaymentIcon';
import DateLabel from 'components/Global/DateLabel';
import Label from 'components/Global/Label';
import TablePriceLabel from 'components/Global/TablePriceLabel';
import GetPaymentInfo from 'hoc/GetPaymentInfo';
import CustomLoadingBox from 'hoc/CustomLoadingBox';
import DateTimePicker from 'components/Global/DateTimePickerv2';
import DashboardCollapse from 'components/Global/List/CollapseForDashboard';
import { useMediaQuery } from 'react-responsive';
import DashboardResponsive from 'components/Responsive/Dashboard';

const GetDate = () => {
    var locale = "tr-TR";
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var monthName = today.toLocaleString(locale, { month: "long" });
    var yyyy = today.getFullYear();
    var time = today.getHours() + ":" + (String(today.getMinutes()).padStart(2, "0"));

    return { dd, mm, yyyy, monthName, time };
}

const initialSearchDataState = {
    startAmount: 0,
    endAmount: 0,
    paymentTypeId: 0,
    startTransactionDate: "",
    endTransactionDate: "",
    paymentStateId: 0,
    limit: 0,
    orderBy: 0,
    pageNo: 0,
    userList: []
};

const DealerDashboard = () => {

    const now = GetDate();

    const [usersData, setUsersData] = useState([]); // filtre için dealer listesi

    const [listData, setListData] = useState([[]]);

    const [searchData, setSearchData] = useState({});
    const [searchCardData, setCardSearchData] = useState({});


    const [waitingTotalTransactionAmount, setWaitingTotalTransactionAmount] = useState()
    const [waitingTotalTransactionCount, setWaitingTotalTransactionCount] = useState()
    const [cancelAndRefundTotalTransactionAmount, setCancelAndRefundTotalTransactionAmount] = useState()
    const [cancelAndRefundTotalTransactionCount, setCancelAndRefundTotalTransactionCount] = useState()
    const [unSuccessfulTotalTransactionAmount, setUnSuccessfulTotalTransactionAmount] = useState()
    const [unSuccessfulTotalTransactionCount, setUnSuccessfulTotalTransactionCount] = useState()
    const [successfulTotalTransactionAmount, setSuccessfulTotalTransactionAmount] = useState()
    const [successfulTotalTransactionCount, setSuccessfulTotalTransactionCount] = useState()
    const [modalClick, setModalClick] = useState(false);

    const [selectedUserValue, setSelectedUserValue] = useState(""); // sayfa içindeki kullanıcı filtreleme
    const [startsDate, setStartsDate] = useState();         //sayfa içindeki tarih filtreleme
    const [endsDate, setEndsDate] = useState();             //sayfa içindeki tarih filtreleme

    const [calendarValue, setCalendarValue] = useState(); // Format :  08.02.2022 - 10.02.2022 (DateTimePicker da user a görünecek aralık formatı)
    const [dateValue, setDateValue] = useState([null, null]); //Format : [Tue Feb 08 2022 00:00:00 GMT+0300 (GMT+03:00), Thu Feb 10 2022 00:00:00 GMT+0300 (GMT+03:00)] (api formati)

    //#region Paggination State
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState([[]]);
    const [pageCount, setPageCount] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    //#endregion
    const [startTodayDate, setStartTodayDate] = useState()
    const [endTodayDate, setEndTodayDate] = useState()
    const [paymentInfo, setPaymentInfo] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)
    const [listTotalCount, setListTotalCount] = useState(0);//veritabanında aslında bulunan tam veri saysısı
    const [cardResponseData, setCardResponseData] = useState()
    const [termDay, setTermDay] = useState()


    useEffect(() => {
        todayStartEnd()

        GetPaymentInfo(setPaymentInfo, searchParams, setModalClick);

        // Filtre Alanı için User Getir
        api.User().GetAllUser({}).then(response => {
            console.log('response Users', response?.data.data.userList)
            if (checkNullOrEmptyArray(response.data.data.userList)) {
                setUsersData(response.data.data.userList.map(item => ({
                    id: item.userId,
                    text: item.userFullName
                })));
            }
        }).catch(err => {
            console.log("getUsers Hatası : ", err?.response?.data);
        });

        api.Helpers().GetTermDay({}).then(response => {
            if (checkNullOrEmpty(response.data.data.termDay)) {
                // console.log('response Term Day', response.data.data.termDay)
                setTermDay(response.data.data.termDay);
            }
        }).catch(err => {
            console.log("getDealer Hatası : ", err?.response?.data);
        })

        killLoading();
    }, []);

    const killLoading = () => {
        setTimeout(() => {
            setIsLoading(false);
            console.log("Kill Loading");
        }, 4000);
    }

    useEffect(() => {
        if (checkNullOrEmpty(selectedUserValue) && selectedUserValue !== 0) {
            setSearchData({ ...searchData, userList: [selectedUserValue] });
        }
        else {
            setSearchData({ ...searchData, userList: [] });
        }
    }, [selectedUserValue])

    useEffect(() => {
        setCardSearchData({ ...searchData, startTransactionDate: startTodayDate, endTransactionDate: endTodayDate });
    }, [endTodayDate])

    useEffect(() => {
        getCardValues()
    }, [searchCardData])

    useEffect(() => {
        GetPaymentsFromApi();
    }, [searchData])

    useEffect(() => {
        if (checkNullOrEmpty(startsDate) && checkNullOrEmpty(endsDate)) {
            setSearchData({ ...searchData, startTransactionDate: startsDate, endTransactionDate: endsDate });
        }
    }, [startsDate, endsDate]);

    //Pagination
    useEffect(() => {
        if (checkNullOrEmptyArray(listData)) {
            setCurrentItems(
                listData.slice(itemOffset, itemOffset + itemsPerPage)
            );
        } else {
            setCurrentItems([[]]);
        }
        setPageCount(Math.ceil(listData?.length / itemsPerPage));
    }, [listData, itemOffset, itemsPerPage]);

    const handleUserSelect = (item) => {
        setSelectedUserValue(item);
    }

    const todayStartEnd = () => {
        var start = new Date();
        start.setDate(start.getDate());
        start.setUTCHours(0, 0, 0, 0);

        var end = new Date();
        end.setDate(end.getDate());
        end.setUTCHours(23, 59, 58, 999);
        // console.log("Start : ", start)
        // console.log("End : ", end)
        if (isValidDate(start) && isValidDate(end)) {
            setStartTodayDate(start);
            setEndTodayDate(end);
        }
    }
    const handleModalClick = (status) => {
        setModalClick(status);

        //modal kapandığında verileri yenile (apiden tekrar getir)
        if (status == false) {
            GetPaymentsFromApi();
        }
    }

    //selectedDateRange
    const handleDateRangeChange = (newValue) => {
        setDateValue(newValue);

        let str = "";
        if (newValue.endDate != null) {
            let startDate = new Date(newValue.startDate).toLocaleDateString();
            let endDate = new Date(newValue.endDate).toLocaleDateString();
            if (startDate !== endDate) {
                str = startDate + " - " + endDate;
                let startTransactionDate = setDateTimeFormat(startDate)
                let endTransactionDate = setDateTimeFormat(endDate)
                setStartsDate(startTransactionDate)
                setEndsDate(endTransactionDate)

            } else {
                str = startDate;
            }
        } else {
            let startDate = new Date(newValue.startDate).toLocaleDateString();
            str = startDate;
        }

        setCalendarValue(str);
    };

    const setDateTimeFormat = (dataTime) => {
        var day = dataTime.slice(0, 2);
        var month = dataTime.slice(3, 5);
        var year = dataTime.slice(6, 10);
        return year + "-" + month + "-" + day + "T00:00:00.000Z";
    };

    const setDateFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var year = date.slice(0, 4);
            var month = date.slice(5, 7);
            var day = date.slice(8, 10);
            return day + "." + month + "." + year;
        }
    };

    const setTimeFormat = (dateTime) => {
        if (
            dateTime === "0001-01-01T00:00:00" ||
            dateTime == null ||
            dateTime === "null"
        ) {
            return "";
        } else {
            var date = dateTime.slice(0, 16);
            var time = date.slice(11, 16);
            return time;
        }
    };

    //Paggination
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listData.length;
        setItemOffset(newOffset);
    };

    const GetPaymentsFromApi = () => {
        setIsLoading(true)
        api.SubDealer().PaymentTransactionList(searchData).then((response) => {
            // console.log("SubDealerPaymentList Response : ", response.data.data)
            if (checkNullOrEmptyArray(response.data.data.paymentList)) {
                setListTotalCount(response.data.data.paymentTransactionListTotalCount)

                setListData(
                    response.data.data.paymentList.map((item, i) => [
                        <div key={i}>
                            {item.paymentTypeId === 1 ? (
                                <CreditCardIcon />
                            ) : (
                                <LinkPaymentIcon />
                            )}
                        </div>,
                        <DateLabel
                            time={setTimeFormat(item.transactionDate)}
                            date={setDateFormat(item.transactionDate)}
                        />,
                        <Label text={item.customerName} />,
                        <TablePriceLabel price={item.amountAndCurrencyCode} />,
                        <Label text={item.userName} />,
                        {
                            details: item.details,
                        },
                    ])
                )
            } else {
                setListData([]);
            }

        }).catch((error) => {
            console.log("error", error?.response?.data);
        }).finally(() => {
            setIsLoading(false)
        });
    }
    const getCardValues = () => {
        api.Dashboard().GetValuesForSubDealer(searchCardData).then((response) => {
            // console.log("GetValuesForSub Response : ", response.data.data)

            setCancelAndRefundTotalTransactionCount(response.data.data.cancelAndRefundTotalTransactionCount)
            setCancelAndRefundTotalTransactionAmount(response.data.data.cancelAndRefundTotalTransactionAmount)
            setWaitingTotalTransactionCount(response.data.data.waitingTotalTransactionCount)
            setWaitingTotalTransactionAmount(response.data.data.waitingTotalTransactionAmount)
            setUnSuccessfulTotalTransactionAmount(response.data.data.unSuccessfulTotalTransactionAmount)
            setUnSuccessfulTotalTransactionCount(response.data.data.unSuccessfulTotalTransactionCount)
            setSuccessfulTotalTransactionAmount(response.data.data.successfulTotalTransactionAmount)
            setSuccessfulTotalTransactionCount(response.data.data.successfulTotalTransactionCount)
            setCardResponseData(response.data.data)
        }).catch((error) => {
            console.log("error", error?.response?.data);
        }).finally(() => { });
    }
    const handlePaymentButtonClick = () => {
        handleModalClick(true);
    }

    const willAccountedDateFormat = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate() + termDay);
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const accountedForDateFormatOre = (dateTime) => {
        dateTime = new Date();
        dateTime.setDate(dateTime.getDate());
        dateTime.setUTCHours(0, 0, 0, 0);
        let result = dateTime.toLocaleString().split(" ");
        return result[0];
    }

    const filterItems = [
        <DateTimePicker
            onSelected={handleDateRangeChange}
            width="235px"
            fontFamily="Metropolis-bold"
            fontSize="12px"
        />,

        <Dropdown
            text='Tüm alt bayiler'
            items={usersData}
            onSelected={handleUserSelect}
            selectItemId={selectedUserValue}
            width="235px"
            fontFamily="Metropolis-bold"
            fontSize="12px"
        />,
        <Button
            text="Ödeme Al"
            width="179px"
            onClick={handlePaymentButtonClick}
        ></Button>,
    ];
    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    return (

        isMobile ? <>
            <DashboardResponsive
                accountedForDate={accountedForDateFormatOre}
                willAccountedDateFormat={willAccountedDateFormat}
                cardResponseData={cardResponseData}
                usersData={usersData}
                handleUserSelect={handleUserSelect}
                selectedUserValue={selectedUserValue}
                termDay={termDay}
                role="altbayis"
            />
        </>
            : <>
                <CustomLoadingBox isLoading={isLoading} />
                <Modal
                    modalStyle={
                        {
                            boxShadow: 'none',
                            flexBasis: '40%',
                            width: '30%',
                            alignItems: 'stretch',
                        }
                    }
                    clickEvent={modalClick}
                    callbackEvent={handleModalClick}

                    body={<Payment paymentInfo={paymentInfo} modalClicked={modalClick} />}

                />
                <FilterArea width="40%" items={filterItems}></FilterArea>
                <div className={styles.margintop}></div>
                <Container minHeight='100%'>
                    <div className={styles.leftside}>
                        <div className={styles.headgroup}>
                            <div className={styles.textarea}>
                                <h4 className={styles.header}>Başarılı İşlemler</h4>
                                <a className={styles.headbtn} href="#">
                                    <Link
                                        style={{ textDecoration: "none" }}
                                        to={`/transactions`}
                                        state={{
                                            dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                            paymentStatusId: 2,
                                        }}
                                    >
                                        <RightIcon size={"15px"} />
                                    </Link>
                                </a>
                            </div>
                            <div className={styles.seperator}></div>
                        </div>
                        <div className={styles.infoarea}>
                            <div className={styles.cardbg}>

                                <div className={styles.infoheader}>
                                    <span>İptal iade işlemi düşülerek hesaplanan tutarlardır.</span>
                                    <span>
                                        Bu işlemler ana firmanızın hesabına
                                        <span className={styles.bolddate}> {now.dd + ' ' + now.monthName + ' ' + now.yyyy}</span>'de
                                        gerçekleştirilecektir. Son güncelleme {now.time}, {now.dd + '.' + now.mm + '.' + now.yyyy}
                                    </span>
                                </div>
                                <div className={styles.margintop}></div>

                                <div className={styles.infobottom}>
                                    <PriceLabel
                                        price={successfulTotalTransactionAmount}
                                        size="4"
                                    />
                                    <span className={styles.counttext}>
                                        {successfulTotalTransactionCount +
                                            " adet işlem"}
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div className={styles.listarea}>
                            <ListContainer>
                                <Pagination
                                    width="20%"
                                    handlePageClick={handlePageClick}
                                    pageCount={pageCount}
                                    setItemsPerPage={setItemsPerPage}
                                    infoCount={listTotalCount}
                                    itemsPerPage={itemsPerPage}
                                    listDataLength={listData?.length}
                                />
                                <List
                                    collapsed
                                    CollapseComponent={<DashboardCollapse />}
                                    columnNames={[
                                        "",
                                        "İŞLEM SAATİ VE TARİHİ",
                                        "MÜŞTERİ İSMİ",
                                        "İŞLEM TUTARI",
                                        "İŞLEM SAHİBİ",
                                        "",
                                        "",
                                    ]}
                                    data={currentItems}
                                />
                            </ListContainer>
                        </div>

                    </div>
                    <div className={styles.rightside}>
                        <div className={styles.section}>
                            <Card
                                headText='Bekleyen İşlemler'
                                subText='Bugün 00:00 itibariyle'
                                count={waitingTotalTransactionCount + " adet İşlem"}
                                price={waitingTotalTransactionAmount}
                                color='#000d89'
                                btnBackground='#f5f7f9'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusId: 1,
                                }}
                            />
                        </div>
                        <div className={styles.margintop}></div>
                        <div className={styles.section}>
                            <Card
                                headText='İptal/İade Edilen İşlemler'
                                subText='Bugün 00:00 itibariyle'
                                count={cancelAndRefundTotalTransactionCount + " adet işlem"}
                                price={cancelAndRefundTotalTransactionAmount}
                                color='#e99d36'
                                btnBackground='#fcf5ea'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusIdsArray: [5, 6],
                                }}
                            />
                        </div>
                        <div className={styles.margintop}></div>

                        <div className={styles.section}>
                            <Card
                                headText='Başarısız İşlemler'
                                subText='Zaman aşımına düşen ve reddedilen işlemleri kapsar. Bugün 00:00 itibariyle'
                                count={unSuccessfulTotalTransactionCount + ' adet işlem'}
                                price={unSuccessfulTotalTransactionAmount}
                                color='#ff5c5c'
                                btnBackground='#ffeeee'
                                href={"/transactions"}
                                state={{
                                    dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                                    paymentStatusIdsArray: [3, 4],
                                }}
                            />
                        </div>
                        <div className={styles.margintop}></div>

                        <div className={styles.bannerarea}>
                            <img src={banner} alt="" />
                        </div>
                    </div>
                </Container>
            </>
    )
}

export default DealerDashboard
