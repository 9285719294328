import * as yup from 'yup';

const validation = yup.object().shape({
    cardHolderFullName: yup.string().required("Müşteri ismi zorunludur."),
    cardNumber: yup.string().required("Kart Numarası zorunludur."),
    expMonth: yup.string().required("Lütfen ay seçiniz."),
    expYear: yup.string().required("Lütfen yıl seçiniz."),
    cvcNumber: yup.string().required("CVC numarası zorunludur."),
})

export default validation;