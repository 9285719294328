import {useEffect,useState} from 'react'
import styles from './label.module.css'
const Label = ({ text, bold = false,fontSize }) => {

    const [fontFamily,setFontFamily] = useState()

    useEffect(() => {
        if(bold) {
            setFontFamily('Metropolis-Bold')
        }
    }, [])
    return (
        <span style={{fontFamily,fontSize}} className={styles.label}>{text}</span>
    )
}

export default Label