import { useState, useEffect } from "react";
import styles from "./accountedforbmp.module.css";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import Cardboard from "components/Global/Cardboard";
import SwitchButton from "components/Global/SwitchButton";
import PriceLabel from "components/Global/PriceLabel";
import api from "store/api/actions";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { Link } from "react-router-dom";
import ListContainer from "components/Global/ListContainer";
import InfoArea from "components/Global/InfoArea";
import List from "components/Global/List";
import Pagination from "components/Global/Pagination";
import Dropdown from "components/Global/Dropdown";
import RightIcon from "components/icons/RightIcon";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import ExportCSV from "hoc/ExportCSV";
import TablePriceLabel from "components/Global/TablePriceLabel";
import Label from "components/Global/Label";
import { useLocation } from "react-router";
import DateTimePicker from "components/Global/DateTimePickerv2";
import Information from "components/Global/Info/Information";
import { useMediaQuery } from 'react-responsive'
import AccountedForAndWillAccountedResponsive from "components/Responsive/AccountedForAndWillAccounted";
import ExportExcel from "hoc/ExportExcel";

const initialSearchDataState = {
  startTransactionDate: "",
  endTransactionDate: "",
  dealerList: [],
  userList: [],
  cityCodeList: [],
  listingTypeId: 0,
  limit: 0,
  orderBy: 0,
  pageNo: 0,
};

const initialResponseState = {
  totalNetAccountAmountAndCurrency: "",
  totalCancellationAndRefundAmountAndCurrency: "",
  totalSuccessTransactionAmountAndCurrency: "",
  totalComissionAmountAndCurrency: "",
  paymentList: [],
  paymentListTotalCount: 0,
};

const initialPaymentListDataState = {
  pobDealerId: "",
  transactionDateOrDealerName: "",
  netAccountAmountAndCurrency: "",
  successTransactionAmountAndCurrency: "",
  cancellationAndRefundAmountAndCurrency: "",
  comissionAmountAndCurrency: "",
};

const initialDealerState = {
  pobDealerId: 0,
  pobDealerName: "",
};

const initialCityState = {
  cityCode: 0,
  cityName: "",
};

const AccountedForBMP = () => {

  const { state } = useLocation(); // dashboard tarih filtresi(01.03.2022)

  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState(initialResponseState);
  const [dealersData, setDealersData] = useState([initialDealerState]);
  const [citiesData, setCitiesData] = useState([initialCityState]);
  const [dealerFilter, setDealerFilter] = useState("");
  const [dealerNameFilter, setDealerNameFilter] = useState("");
  const [cityFilter, setCityFilter] = useState(0);
  const [searchData, setSearchData] = useState();

  const [pageType, setPageType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("Hesaba Geçenler");
  const [exportData, setExportData] = useState([]);

  const [allData, setAllData] = useState([])
  const [allSearchData, setAllSearchData] = useState({ limit: -1 });

  //#region Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  useEffect(() => {
    api.BigMarketPlace().AccountedForPaymentList(allSearchData)
      .then((response) => {
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setAllData(response.data.data.paymentList);
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });

    getAccountedForPaymentDataFromApi();

    //Get Dealer
    api
      .Helpers()
      .GetAllDealerForFilter({})
      .then((response) => {
        //console.log("response dealer", response.data.data);
        if (checkNullOrEmptyArray(response.data.data)) {
          setDealersData(
            response.data.data.map((item) => ({
              id: item.pobDealerId,
              text: item.pobDealerName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });

    //Get City
    api
      .Helpers()
      .GetAllCity({})
      .then((response) => {
        console.log("response city", response.data.data);
        if (checkNullOrEmptyArray(response.data.data)) {
          setCitiesData(
            response.data.data.map((item) => ({
              id: item.cityCode,
              text: item.cityName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getAccountedForPaymentDataFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (checkNullOrEmpty(pageType)) {
      setSearchData({ ...searchData, listingTypeId: pageType });
    }
  }, [pageType]);

  useEffect(() => {
    // console.log("dealerFilter",dealerFilter)
    if (checkNullOrEmpty(dealerFilter) && dealerFilter !== 0) {
      setSearchData({ ...searchData, dealerList: [dealerFilter] });
    } else {
      setSearchData({ ...searchData, dealerList: [] });
    }
  }, [dealerFilter]);

  useEffect(() => {
    if (checkNullOrEmpty(cityFilter) && cityFilter !== 0) {
      setSearchData({ ...searchData, cityCodeList: [cityFilter] });
    } else {
      setSearchData({ ...searchData, cityCodeList: [] });
    }
  }, [cityFilter]);

  useEffect(() => {
    if (checkNullOrEmpty(state)) {
      dashboardDateFilterData();
    }
  }, [state]);

  // Paggination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row) => [
          <Label text={row.transactionDateOrDealerName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <TablePriceLabel price={row.comissionAmountAndCurrency} />,
          <TablePriceLabel price={row.successTransactionAmountAndCurrency} />,
          <TablePriceLabel
            price={row.cancellationAndRefundAmountAndCurrency}
          />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrDealerName: row.transactionDateOrDealerName,
                pobDealerId: row.pobDealerId,
                listingTypeId: pageType,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#4f5386"
                }}
              >
                İşlemlere git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
      setCurrentItemsForResponsive(
        listData?.map((row) => [
          <Label text={row.transactionDateOrDealerName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrDealerName: row.transactionDateOrDealerName,
                pobDealerId: row.pobDealerId,
                listingTypeId: pageType,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#4f5386"
                }}
              >
                <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
    } else {
      setCurrentItems([]);
      setCurrentItemsForResponsive([]);
    }
  }, [listData, itemOffset, itemsPerPage]);

  useEffect(() => {

    if (checkNullOrEmptyArray(dealersData)) {
      const data = dealersData.find(i => i.text === dealerNameFilter);
      setDealerFilter(data?.id);
    }

  }, [dealerNameFilter, dealersData])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };

  // #region Filter Api
  const getAccountedForPaymentDataFromApi = () => {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {
      api.BigMarketPlace().AccountedForPaymentList(searchData).then((response) => {
        // console.log("responselist", response.data.data);
        setResponseData(response.data.data);
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListData(response.data.data.paymentList);
        } else {
          setListData([]);
        }
        setresponseListTotalCount(
          response.data.data.paymentListTotalCount
        );
      })
        .catch((error) => {
          console.log("error", error?.response?.data);
          setListData([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateExportData = (listValue) => {
    setExportData(
      listValue.map((row) => {
        return {
          "": `${row.transactionDateOrDealerName}`,
          "Net Hesaba Geçen": `${row.netAccountAmountAndCurrency}`,
          "Komisyon Tutarı": `${row.comissionAmountAndCurrency}`,
          "Başarılı Tutar": `${row.successTransactionAmountAndCurrency}`,
          "İptal/İade": `${row.cancellationAndRefundAmountAndCurrency}`,
        };
      })
    );
  };

  const setNextDateTimeFormat = (dateTime) => {
    let day = dateTime.slice(0, 2);
    let month = dateTime.slice(3, 5);
    let year = dateTime.slice(6, 10);

    return month + "/" + day + "/" + year;
  };

  const dashboardDateFilterData = () => {
    if (checkNullOrEmpty(state?.date)) {
      setTimeout(() => {
        let nextDay = new Date(setNextDateTimeFormat(state.date));
        nextDay.setDate(
          new Date(setNextDateTimeFormat(state.date)).getDate() - 1
        );

        let startDate = new Date(nextDay).toLocaleDateString();
        let endDate = state.date;
        const range = startDate + " - " + endDate;
        setSearchData({
          ...searchData,
          startTransactionDate: setDateFormat(startDate),
          endTransactionDate: setDateFormat(endDate),
        });
      }, 1000);
    }
    else if (checkNullOrEmpty(state?.startTransactionDate) && checkNullOrEmpty(state?.endTransactionDate)) {

      let startDate = state.startTransactionDate;
      let endDate = state.endTransactionDate;
      setTimeout(() => {
        setSearchData({
          ...searchData,
          startTransactionDate: setDateFormat(startDate),
          endTransactionDate: setDateFormat(endDate),
        });
      }, 1000);
    }
    else if (checkNullOrEmpty(state?.transactionDateOrDealerName)) {
      setTimeout(() => {
        setDealerNameFilter(state?.transactionDateOrDealerName);
      }, 1000);
    }

  };

  // Date range
  const setDateFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (value) => {
    let startDate = new Date(value.startDate).toLocaleDateString();
    let endDate = new Date(value.endDate).toLocaleDateString();

    console.log(startDate, endDate);
    if (value.endDate != null) {
      setSearchData({
        ...searchData,
        startTransactionDate: setDateFormat(startDate),
        endTransactionDate: setDateFormat(endDate),
      });
    }
  };


  //cityFilter
  const handleCityChange = (value) => {
    setCityFilter(value);
  };

  //dealerFilter
  const handleDealerChange = (value) => {
    setDealerFilter(value);
  };

  // #endregion

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      dateTimePickerTypeId={2}
      stringVal={state?.dateNow}
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      items={citiesData}
      onSelected={handleCityChange}
      selectItemId={cityFilter}
      text="Tüm Bölgeler"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      items={dealersData}
      onSelected={handleDealerChange}
      selectItemId={dealerFilter}
      text="Tüm Bayiler"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="85px"
    //   fontSize="12px"
    // />
    <ExportExcel
      excelData={exportData}
      fileName={fileName}
      text="İndir"
    />
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <AccountedForAndWillAccountedResponsive 
      responsivePaginationType="2"
      setPageType={setPageType}
      pageType={pageType}
      totalNetAccountAmountAndCurrency={responseData.totalNetAccountAmountAndCurrency}
      totalComissionAmountAndCurrency={responseData.totalComissionAmountAndCurrency}
      totalSuccessTransactionAmountAndCurrency={responseData.totalSuccessTransactionAmountAndCurrency}
      totalCancellationAndRefundAmountAndCurrency={responseData.totalCancellationAndRefundAmountAndCurrency}
      currentItems={currentItemsForResponsive}
      citiesData={citiesData}
      cityFilter={cityFilter}
      setCityFilter={setCityFilter}
      dealersData={dealersData}
      dealerFilter={dealerFilter}
      setDealerFilter={setDealerFilter}
      handleDateRangeChange={handleDateRangeChange}
      setSearchData={setSearchData}
      searchData={searchData}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea
          width="100%"
          style={{ flexBasis: "55%" }}
          items={filterItems}
          SwitchItem={() => (
            <SwitchButton
              callback={setPageType}
              style={{ height: "3rem", width: "360px", marginLeft: "15%" }}
              items={[
                { id: 1, text: "Tarih Bazlı" },
                { id: 2, text: "Bayi Bazlı" },
              ]}
              selectedValue={pageType}
            />
          )}
        ></FilterArea>
        <Container flexDirection="column">
          <Cardboard>
            <InfoArea>
              <div className={styles.total}>
                <div className={styles.biglabel}>Net Hesaba Geçen Tutar</div>
                <PriceLabel
                  price={responseData.totalNetAccountAmountAndCurrency}
                  size="3.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Komisyon Tutarı</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalComissionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Başarılı Tutar <Information text="Toplam tutardan iptal/iade tutarı düşülerek hesaplanmıştır." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalSuccessTransactionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>İptal / İade <Information text="İade işlemi belirlenen vade süresine göre hesaba geçecektir." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalCancellationAndRefundAmountAndCurrency}
                  size="2.5"
                />
              </div>
            </InfoArea>
          </Cardboard>
          <Cardboard>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={listData?.length}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />
              <List
                columnNames={[
                  "",
                  "NET HESABA GEÇEN",
                  "KOMİSYON TUTARI",
                  "BAŞARILI TUTAR",
                  "İPTAL/İADE",
                  "",
                  "",
                ]}
                data={currentItems}
              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};
export default AccountedForBMP;
