import Button from 'components/Global/Button'
import FilterIcon from 'components/icons/FilterIcon'
import React, { useState } from 'react'
import { useEffect } from 'react'
import ResponsiveModal from '../Modal'
import styles from './responsivefilterarea.module.css'

const ResponsiveFilterArea = ({ children, setFilterCallBack, firstElement, isButtonActive = true, style, modalClosed = false, issOpen = false, func = Function, width, backgroundImage = "none", color = "#000d89" }) => {

    const [isOpen, setIsOpen] = useState(issOpen)
    const [mainData, setMainData] = useState()

    useEffect(() => {
        console.log("isOpen", isOpen)
        setMainData(func(isOpen))
    }, [isOpen])

    useEffect(() => {
        modalClosed && setIsOpen(false)
    }, [modalClosed])

    return (
        <div className={styles.container} style={style}>
            <div className={styles.content} style={{ width: width }}>
                {
                    firstElement
                }
                {
                    isOpen && <ResponsiveModal callbackEvent={setIsOpen} body={children} isClosed={modalClosed} />
                }
            </div>
            {isButtonActive && <div style={{ width: "8%" }} />}
            {
                isButtonActive ?
                    <div className={styles.button}>
                        <Button icon={<FilterIcon width={"24px"} height={"16px"} fill={color} />} backgroundImage={backgroundImage} width={"50px"} height={"50px"} onClick={() => setIsOpen(true)} ></Button>
                    </div> : " "
            }
        </div>
    )
}

export default ResponsiveFilterArea