import React from 'react'
import styles from './tableitem.module.css'
import cx from 'classnames'

const TableItem = ({ name, value, first, last, minWidth= "208px", fontSize="1.1rem"}) => {
  return (
    <div style={{fontSize:fontSize}} className={cx(first && styles.first, last && styles.last, styles.tablecontainer)}>
      <div style={{minWidth:minWidth}} className={cx( first && styles.firstname, last && styles.lastname,styles.namearea)}>
        <span className={styles.text}>{name}</span>
      </div>
      <div className={cx(first && styles.firstvalue,last && styles.lastvalue,styles.valuearea)}>
        <span className={styles.text}>{value}</span>
      </div>
    </div>
  )
}

export default TableItem