

export const checkNullOrEmpty = (val) => {
    return val !== undefined && val !== "" && val !== null && val !== " " && val !== "null";
}

export const checkNullOrEmptyArray = (arr) => {
    if (checkNullOrEmpty(arr)) {
        if (Array.isArray(arr)) {
            if (arr.length > 0) {
                return true;
            }
        }
    }
    return false;
};

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}