// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_tablecontainer__TaXO1{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Table/table.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".tablecontainer{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablecontainer": "table_tablecontainer__TaXO1"
};
export default ___CSS_LOADER_EXPORT___;
