import { Chip, FormControlLabel } from '@mui/material'
import BottomSticky from 'components/Global/BottomSticky'
import Button from 'components/Global/Button'
import Cardboard from 'components/Global/Cardboard'
import DateTimePicker from 'components/Global/DateTimePickerv2'
import Dropdown from 'components/Global/Dropdown'
import FilterInfoButton from 'components/Global/FilterInfoButton'
import InputRange from 'components/Global/InputRange'
import InputSearch from 'components/Global/InputSearch'
import List from 'components/Global/List'
import TransactionCollapseResponsive from 'components/Global/List/CollapseForTransactionsResponsive'
import ListContainer from 'components/Global/ListContainer'
import RefreshIcon from 'components/icons/RefreshIcon'
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "components/icons/CloseIcon";
import { checkNullOrEmpty } from 'hoc/Conditions'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Cookies from 'universal-cookie'
import ResponsiveFilterArea from '../FilterArea'
import styles from "./transactionresponsive.module.css"
import Check from 'components/Global/Check'
import SlideFilter from '../global/SlideFilter/SlideFilter'
import { width } from '@mui/system'

const TransactionResponsive = ({ handleSelectedButtonChange, responseData, userErpStatus, handleSearchChange, handleDateRangeChange, handleAmountRangeChange, subDealersData, handleSubDealerChange, paymentGroupStatusData, selectedButtonStatus, handleTransactionStatusChange, paymentStatusFilter, state, erpData, handleERPChange, erpFilter, currentItemsResponsive, setIsMultipleSelection = Function, isMultipleSelection, handleRefreshLinkButtonClick, handleCancelButtonClick, handleReminderSendButtonClick, multipleSelection, setMultiSelectRow, forceAllCheck, listData, listId, setItem, setCustomerName, customerName, handleCancelPaymentButtonClick = Function, handleRefundButtonClick }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(false);//filtreleye tıklanınca true oluyo
    const [date, setDate] = useState()
    const [popUpClick, setPopUpClick] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const [currentRef, setCurrentRef] = useState()
    const [sendText, setSendText] = useState()
    const [selectedSubDealerId, setSelectedSubDealerId] = useState()
    const [amountRange, setAmountRange] = useState()
    const [paymentStatus, setPaymentStatus] = useState()

    const refs = useRef([])

    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole

    const pull_data = (status) => status == false && setIsOpen(false);

    const filterItems = [
        <FilterInfoButton
            width="70%"
            height="3rem"
            id="onHoldButton"
            text="Bekleyen İşlemler"
            value="Bekleyen İşlemler"
            count={responseData?.waitingPaymentTransactionTotalCount}
            onClick={handleSelectedButtonChange}
        />,
        <FilterInfoButton
            width="70%"
            height="3rem"
            id="rejectedButton"
            text="Reddedilen İşlemler"
            value="Reddedilen İşlemler"
            onClick={handleSelectedButtonChange}
            count={responseData?.rejectedPaymentTransactionTotalCount}
        />,
        <FilterInfoButton
            width="70%"
            height="3rem"
            id="timeOutButton"
            text="Zaman Aşımındaki İşlemler"
            value="Zaman Aşımındaki İşlemler"
            onClick={handleSelectedButtonChange}
            count={responseData?.timeOutPaymentTransactionTotalCount}
        />,
        userErpStatus == "true" ? (
            <FilterInfoButton
                width="70%"
                height="3rem"
                id="erpButton"
                text="ERP'ye Gönderilemeyenler"
                onClick={handleSelectedButtonChange}
                count={responseData?.cannotBeSentToErpTransactionTotalCount}
            />
        ) : (
            ""
        )
    ]

    useEffect(() => {
        // console.log("button status", buttonStatus)
        if (buttonStatus == true) {
            if (date?.endDate != null) {
                handleDateRangeChange(date)
            }
            if (selectedSubDealerId != null) {
                handleSubDealerChange(selectedSubDealerId)
            }
            if (checkNullOrEmpty(amountRange?.secondVal)) {
                // console.log("amount range", amountRange)
                handleAmountRangeChange(amountRange)
            }
            if (checkNullOrEmpty(paymentStatus)) {
                // console.log("paymentStatus ", paymentStatus)
                handleTransactionStatusChange(paymentStatus)
            }

            setIsOpen(true);
            setButtonStatus(false)

        }

    }, [buttonStatus])

    useEffect(() => {
        if (checkNullOrEmpty(deleteId)) {
            if (sendText == "hatırlatma") {
                ReminderCheck(deleteId)
                setIsActive(true)

            } else if (sendText == "yenileme") {
                RefreshCheck(deleteId)
                setIsActive(true)

            } else {
                DeleteCheck(deleteId)
                setIsActive(true)
            }
            setIsActive(false)
        }
        if (popUpClick) {
            console.log("popup false useeffect")
            setPopUpClick(false)
        }
    }, [popUpClick])

    const handleFormSubmit = (e) => {
        console.log("submitted")
        // e.preventDefault();
        // e.defaultPrevented && 
        setButtonStatus(true)
    }

    const handlePopUp = (status) => {
        console.log("popp handle", status)
        setPopUpClick(status)
        setIsActive(false)
    }

    const DeleteCheck = (id, listId) => {
        console.log("delete check", id, listId)
        setSendText("iptal")
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        const ref = refs.current[listId]
        console.log("ref : ", ref)
        setCurrentRef(ref)
        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        // console.log(" Delete :", popUpClick)

        if (popUpClick) {
            console.log("Delete Success")
            handleCancelButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            console.log("Delete Cancel ")
        }
    }

    const RefreshCheck = (id, listId) => {
        setSendText("yenileme")
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        const ref = refs.current[listId]

        setCurrentRef(ref)
        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        console.log("Refresh Delete :", popUpClick)

        if (popUpClick) {
            console.log("Refresh Success")
            handleRefreshLinkButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            console.log("Delete Cancel ")
        }
    }

    const ReminderCheck = (id, listId) => {
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        setSendText("hatırlatma")
        const ref = refs.current[listId]

        setCurrentRef(ref)

        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setDeleteId(id)
        setIsActive(true)
        // console.log("id : ", id)
        // console.log("listId : ", listId)
        listData?.map((item) => {
            if (item?.paymentId == id) {
                // console.log("customer Name : ", item)
                setItem(item)
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
        })
        console.log("Reminder  :", popUpClick)

        if (popUpClick) {
            handleReminderSendButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            // console.log("Delete Cancel ")
        }
    }

    return (
        <>
            <div className={styles.filtercontainer}>

                <ResponsiveFilterArea
                    modalClosed={isOpen}
                    func={pull_data}

                    firstElement={

                        <div className={styles.filter}>
                            <SlideFilter items={filterItems} />
                        </div>
                    }

                    children={
                        <div className={styles.form}>
                            <InputSearch
                                width="90%"
                                placeholder={"Müşteri,kart,işlem no,kullanıcı"}
                                onChange={handleSearchChange}
                            />,
                            <DateTimePicker
                                onSelected={(d) => setDate(d)}
                                width="90%"
                                stringVal={state && state?.listingTypeId == 1 || state && state.listingTypeId == undefined ? (checkNullOrEmpty(state?.transactionDateOrUserName) ? state?.transactionDateOrUserName : state?.dateNow) : "Lütfen Tarih Seçiniz"}
                            />,
                            <InputRange
                                callback={(s) => setAmountRange(s)}
                                width="90%"
                                height="3rem"
                                text="İşlem tutar aralığı gir"
                                firstPlaceHolder="minimum"
                                secondPlaceHolder="maksimum"
                            />,
                            <Dropdown
                                width="90%"
                                height="3rem"
                                items={subDealersData}
                                onSelected={(s) => setSelectedSubDealerId(s)}
                                selectItemId={selectedSubDealerId}
                                text="Alt Bayi Seç"
                                color={"#4f5386"}
                            />,
                            <Dropdown
                                width="90%"
                                height="3rem"
                                items={paymentGroupStatusData}
                                disabled={selectedButtonStatus}
                                onSelected={(p) => setPaymentStatus(p)}
                                selectItemId={paymentStatusFilter}
                                text="İşlem durumunu seç"
                                color={"#4f5386"}
                            />, <>
                                {
                                    userErpStatus == "true" ? (
                                        <Dropdown
                                            width="90%"
                                            height="3rem"
                                            items={erpData}
                                            onSelected={handleERPChange}
                                            selectItemId={erpFilter}
                                            text="ERP durumunu seç"
                                            color={"#4f5386"}

                                        />
                                    ) : ("")
                                }
                            </>
                            <Button width="90%" text={"Filtrele"} onClick={(e) => handleFormSubmit(e)} />
                        </div >

                    }
                />
            </div>

            <div div className={styles.multiplebutton} >
                {isMultipleSelection && (
                    <FormControlLabel
                        control={
                            <Check id="multiSelect" checked={forceAllCheck} onChange={setMultiSelectRow} />
                        }
                        label={<span style={{ fontSize: "12px" }}>Tümü</span>}
                        sx={{ color: "#000d89", fontFamily: "metropolis" }}
                    />
                )}

                <Chip
                    sx={{
                        backgroundImage:
                            isMultipleSelection &&
                            "linear-gradient(to right, #000d89 0%, #6608b7 100%)",
                        color: isMultipleSelection ? "white" : "#000d89",
                        borderRadius: 2,
                        fontFamily: "metropolis",
                    }}
                    variant="outlined"
                    label="Çoklu seçim"
                    disaleRipple
                    onClick={() => setIsMultipleSelection(!isMultipleSelection)}
                />
            </div>

            <div className={styles.container}>
                <Cardboard >
                    <ListContainer>
                        <List
                            ref={refs}
                            collapsed
                            columnNames={[
                                "",
                                "TARİH VE SAAT",
                                "İŞLEM TUTARI",
                                "İŞLEM DURUMU",
                                "",
                                ""
                            ]}
                            data={currentItemsResponsive}
                            CollapseComponent={<TransactionCollapseResponsive handleCancelPaymentButtonClick={handleCancelPaymentButtonClick} handleRefundButtonClick={handleRefundButtonClick} currentRef={currentRef} isActive={isActive} popUpClick={popUpClick} DeleteCheck={DeleteCheck} ReminderCheck={ReminderCheck} RefreshCheck={RefreshCheck} userRole={userRole} customerName={customerName} handlePopUp={handlePopUp} sendText={sendText} />}
                        />
                    </ListContainer>
                </Cardboard>
            </div>

            {
                isMultipleSelection && (
                    <div className={styles.sticky}>
                        <BottomSticky text={"" + multipleSelection.length + " Öğe Seçildi"}>

                            <Button
                                width="50%"
                                fontSize="12px"
                                backgroundColor="rgba(255, 255, 255, 0.22)"
                                backgroundImage="none"
                                height="2.5rem"
                                icon={<RefreshIcon width="30" height="20" color='white' />}
                                text="Link Yenile"
                                onClick={() => handleRefreshLinkButtonClick(multipleSelection)}
                            />
                            <div style={{ width: "10px" }}></div>
                            <Button
                                width="50%"
                                fontSize="12px"
                                backgroundColor="rgba(255, 255, 255, 0.22)"
                                backgroundImage="none"
                                height="2.5rem"
                                icon={<DeleteIcon width="30" height="20" color="white" />}
                                text="İptal et"
                                onClick={() => handleCancelButtonClick(multipleSelection)}
                            />
                            <div style={{ width: "10px" }}></div>
                            <Button
                                width="50%"
                                fontSize="12px"
                                backgroundColor="rgba(255, 255, 255, 0.22)"
                                backgroundImage="none"
                                height="2.5rem"
                                icon={<AccessTimeIcon width="30" height="20" />}
                                text="Hatırlatma gönder"
                                onClick={() => handleReminderSendButtonClick(multipleSelection)}
                            />
                        </BottomSticky>
                    </div>
                )
            }

        </>
    )
}

export default TransactionResponsive