
import Button from 'components/Global/Button';
import DownloadIcon from 'components/icons/DownloadIcon';
import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';

const ExportExcel = ({ excelData, fileName, text, width, backgroundImage, backgroundColor, fontSize, height }) => {

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <>

            <Button onClick={(e) => exportToExcel(fileName)}
                icon={<DownloadIcon width="30" height="20" />}
                text={text}
                backgroundImage={backgroundImage}
                width={width}
                backgroundColor={backgroundColor}
                fontSize={fontSize}
                height={height}
            >Exbort Buton
            </Button>

        </>
    )
}

export default ExportExcel;