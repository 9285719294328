import styles from './input.module.css';
import cx from 'classnames';
import MaskedInput from 'react-text-mask'
const Input = ({ width,autoComplate, height = '3rem', placeholder, fontSize, onChange, value, name, id, type = 'text', maxLength, error, disabled, onBlur, onFocus, isMasked, maskedOptions }) => {
  if (isMasked) {
    return (
      <>
        <MaskedInput mask={maskedOptions} disabled={disabled} autoComplete={autoComplate} maxLength={maxLength} type={type} value={value} onChange={onChange} name={name} id={id} onBlur={onBlur} placeholder={placeholder} className={cx(styles.input, error && styles.error)} style={{ width, height, fontSize }} onFocus={onFocus} />
        {
          error && (<span className={styles.errortext}>{error}</span>)
        }
      </>
    )
  }
  else {
    return <>
      <input disabled={disabled} autoComplete={autoComplate} maxLength={maxLength} type={type} value={value} onChange={onChange} name={name} id={id} onBlur={onBlur} placeholder={placeholder} className={cx(styles.input, error && styles.error)} style={{ width, height, fontSize }} onFocus={onFocus} />
      {
        error && (<span className={styles.errortext}>{error}</span>)
      }
    </>;
  }

};

export default Input;
