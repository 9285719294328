import React from 'react'
import TableItem from './TableItem'
import styles from './table.module.css'
import { checkNullOrEmpty } from 'hoc/Conditions'

const Table = ({minWidth,fontSize,data = [{ name: 'boş', value: 'boş' }] }) => {
    return (
        <>
            <div className={styles.tablecontainer}>
                {
                    data.map((e, i) => {
                        if (checkNullOrEmpty(e.name)) {
                        if (i == 0) {
                            return <TableItem fontSize ={fontSize} minWidth = {minWidth} first name={e.name} value={e.value} />
                        }
                        if ( i == data.length - 1) {
                            return <TableItem fontSize ={fontSize} minWidth = {minWidth} last name={e.name} value={e.value} />
                        }
                        return <TableItem fontSize ={fontSize} minWidth = {minWidth} name={e.name} value={e.value} />
                        }
                    })
                }
            </div>


        </>
    )
}

export default Table