import Cookies from 'universal-cookie';
import Layout from '../../components/Layout/Layout';

import BMPStandard from 'components/Transaction/BigMarketPlace/Standard';
import DealerStandard from '../../components/Transaction/BigMarketPlace/Dealer/Standard/';
import SubDealerStandard from '../../components/Transaction/BigMarketPlace/SubDealer/Standard/';
import SMPStandard from '../../components/Transaction/SmallMarketPlace/Standard'
import JustPayment from '../../components/Transaction/JustPayment/';

export default function Transactions() {
 
    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole
    //console.log('cookiegetinCustomer', userRole);

    //Get by user role
    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
        case "1":
            return (
                <Layout>
                    <BMPStandard/>
                </Layout>
            )
            break;
        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    {console.log("Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
                    <JustPayment />
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    {console.log("Küçük Firma - Pazaryeri - Standart Kullanıcı")}
                    <SMPStandard />
                </Layout>
            )
            break;
        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    {console.log("Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı")}
                    <JustPayment />
                </Layout>
            )
            break;
        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    {console.log("Bayi - Standart Kullanıcı")}
                    <DealerStandard />
                </Layout>
            )
            break;
        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    {console.log("Bayi - Ödeme Yetkili Kullanıcı")}
                    <JustPayment />
                </Layout>
            )
            break;
        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    {console.log("Alt Bayi - Standart Kullanıcı")}
                    <SubDealerStandard />
                </Layout>
            )
            break;
        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    {console.log("Alt Bayi - Ödeme Yetkili Kullanıcı")}
                    <JustPayment />
                </Layout>
            )
            break;

        default:
            return (
                <Layout>
                    Role COOKIE ERROR
                </Layout>
            )
            break;

    }

}