let BASE_URL = 'http://localhost:3000/'; // local
let CLIENT_ID = "mahsupp.web.client.test";
let REDIRECT_URI = "https://test.mahsupp.com/signin-oidc";
let POST_LOGOUT_REDIRECT_URI = "https://test.mahsupp.com/signin-oidc";
let AUTHORITY = "https://logintest.mahsupp.com"

// check the environment 
if (process.env.REACT_APP_ENV === 'prod' || process.env.NODE_ENV === 'prod') {
    BASE_URL = "https://api.mahsupp.com/api";
    CLIENT_ID = "mahsupp.web.client"; //sorulacak yanlış ise // mahsupp.web.prod.client
    REDIRECT_URI = "https://mahsupp.com/signin-callback.html";
    POST_LOGOUT_REDIRECT_URI = "https://mahsupp.com/signout-oidc";
    AUTHORITY = "https://login.mahsupp.com";
}
else if (process.env.REACT_APP_ENV === 'stage' || process.env.NODE_ENV === 'stage') {
    BASE_URL = "https://apitest.mahsupp.com/api";
    CLIENT_ID = "mahsupp.web.client.test";
    REDIRECT_URI = "https://test.mahsupp.com/signin-callback.html";
    POST_LOGOUT_REDIRECT_URI = "https://test.mahsupp.com/signout-oidc";
    AUTHORITY = "https://logintest.mahsupp.com";
}
else if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
    BASE_URL = "http://localhost:12335/api";
    CLIENT_ID = "mahsupp.web.client.dev";
    REDIRECT_URI = "http://localhost:3000/signin-callback.html";
    POST_LOGOUT_REDIRECT_URI = "http://localhost:3000/signout-oidc"
    AUTHORITY = "https://logintest.mahsupp.com";
}

export { BASE_URL, CLIENT_ID, REDIRECT_URI, POST_LOGOUT_REDIRECT_URI, AUTHORITY };
