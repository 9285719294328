import * as yup from 'yup';

const formValidator = yup.object({
    userFullName: yup.string().required("Ad soyad zorunlu"),
    phoneNumber: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
    email: yup.string().email("E-Posta adresi formatı doğru değil").required("E-posta zorunlu"),
    password: yup.string().required("Parola zorunlu"),
    passwordConfirmation: yup.string().oneOf(
      [yup.ref("password"), null],
      "Doğrulama parolası zorunlu"
    ),
  });

export default formValidator;