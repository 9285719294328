// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_container__OQ5Ql {\n    display: flex;\n    justify-content: space-between;\n    margin: 0 2%;\n    flex-grow: 1;\n    \n}\n\n.container_content__B7G\\+G {\n    background-color: #F9FBFC;\n    height: 80%;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Container/container.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n    margin: 0 2%;\n    flex-grow: 1;\n    \n}\n\n.content {\n    background-color: #F9FBFC;\n    height: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_container__OQ5Ql",
	"content": "container_content__B7G+G"
};
export default ___CSS_LOADER_EXPORT___;
