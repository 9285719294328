import React, { useEffect, useState } from 'react';
import styles from './dealeradd.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { Formik, useFormik } from 'formik';
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import SwitchButton from 'components/Global/SwitchButton';
import Dropdown from 'components/Global/Dropdown';
import formRealPersonValidator from './FormRealPersonValidator'
import formLegalPersonValidator from './FormLegalPersonValidator'
import { checkNullOrEmpty } from "hoc/Conditions";
import ReactInputMask from 'react-input-mask';

var initialCities = {
    cityCode: 0,
    cityName: ""
};

var initialDistricts = {
    countryCode: 0,
    cityCode: 0,
    districtId: "",
    districtName: ""
};

var initialDistrictSearch = {
    cityCode: 0,
    countryCode: 0
};

const DealerAdd = (props) => {

    const [responseErrors, setResponseErrors] = useState();//api den gelen hatalar
    const [isLoading, setIsLoading] = useState()
    const [cities, setCities] = useState();
    const [districts, setDistricts] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [selectedDealerType, setSelectedDealerType] = useState(2);
    const [emptyDealerError, setEmptyDealerError] = useState("")
    const [dealerName, setDealerName] = useState()
    const [isFull, setisFull] = useState(false)

    useEffect(() => {
        getAllCitiesDataFromApi();
        getAllDistrictsDataFromApi();
        //handleReset({});
    }, []);

    useEffect(() => {
        (
            (selectedDealerType == (1 || 2)) ?
                (
                    // values.taxNumber.length < 10
                    // // || values.contactName == "
                     values.dealerName == ""
                    || values.email == ""
                    || values.address == ""
                    || values.phoneNumber.length < 13)
                :
                (
                    // values.trIdentityNumber.length < 10
                    // || values.individualName == ""
                     values.dealerName == ""
                    || values.email == ""
                    || values.address == ""
                    || values.phoneNumber.length < 13
                )
        ) ?
            setisFull(true)
            : setisFull(false)

    })

    useEffect(() => {
        if (props.handleModalClick == true) {
            setEmptyDealerError("");
        }
    }, [props.handleModalClick])

    useEffect(() => {
        setDealerName(values?.pobDealerName)
        values.companyName = values.pobDealerName
    })

    useEffect(() => {

        if (selectedDealerType != 1) {

            setDealerName(values?.pobDealerName)

            values.companyName = values.pobDealerName

        }

    }, [selectedDealerType])

    // useEffect(() => {
    //     console.log("VALUES İÇİ: ",values)
    // })
    

    useEffect(() => {
        handleReset({});
        setFieldValue("pobDealerName", props?.pobDealerName);
    }, [props.pobDealerName])

    const { handleChange, touched, errors, handleBlur, values, handleSubmit, handleReset, resetForm, setFieldValue } = useFormik({

        initialValues: {

            dealerType: selectedDealerType,          // dealer type için (Tüzel ltd = 2 / Tüzel şahıs= 3 / gerçek = 1 kişi)
            taxNumber: "",          //Vergi numarası
            individualName: "",     // gerçek kişi bayi türü için bireysel ad soyad
            companyName: dealerName, //firma adı
            dealerName: "",         //Bayi adı (Firma adı başka bayi adı başka olabilir)
            phoneNumber: "",        //Tel No
            email: "",              //Eposta
            monthlyAmountLimit: 0,  //Aylık limit (Opsiyonel)
            districtId: "",         // İlçe
            cityCode: 0,            // İl
            countryCode: 212,       // Ülke (Default 212 = Türkiye)
            contactName: "",        //Yetkili kişi
            trIdentityNumber: "",   // TC no
            address: "",
            erpNumber: "",
            customCode: ""
        },

        onSubmit: values => {
            setIsLoading(true);

            values.taxNumber = values.taxNumber + ""
            values.monthlyAmountLimit = parseInt(values.monthlyAmountLimit)

            if (checkNullOrEmpty(selectedCity)) {
                values.cityCode = parseInt(selectedCity)
            }
            if (checkNullOrEmpty(selectedDistrict)) {
                values.districtId = selectedDistrict
            }
            // if (checkNullOrEmpty(values.monthlyAmountLimit) && values.monthlyAmountLimit != "") {
            //     values.monthlyAmountLimit = parseInt(values.monthlyAmountLimit)
            // }

            if (checkNullOrEmpty(values.trIdentityNumber)) {

                values.trIdentityNumber = values.trIdentityNumber + ""

            }

            if (checkNullOrEmpty(selectedDealerType)) {
                values.dealerType = selectedDealerType
            }
            //values.monthlyAmountLimit = values.monthlyAmountLimit + "";
            values.erpNumber = values.erpNumber + "";
            console.log("OnSubmit : ", values)

            api.DealerManagement().Create(values)
                .then((response) => {
                    props?.handleModalClick(false);
                }).catch((error) => {
                    setEmptyDealerError(error?.response?.data?.userMessage)
                    console.log('error dealer management ', error?.response?.data);
                    setResponseErrors(error?.response?.data?.userMessage);
                }).finally(() => {
                    setIsLoading(false);
                    resetForm({})
                });

        },
        validationSchema: selectedDealerType === 1 ? formRealPersonValidator : formLegalPersonValidator
    });

    const getAllCitiesDataFromApi = () => {
        api.Helpers().GetAllCity({ isAll: true })
            .then((response) => {
                if (checkNullOrEmpty(response.data.data)) {
                    setCities(response.data.data.map((item) => (
                        {
                            id: item.cityCode,
                            text: item.cityName
                        }
                    )))
                }
                // console.log("Get Cities response : ", response.data.data)
            })
            .catch((error) => {
                console.log('error', error?.response.data);
                setResponseErrors(error?.response?.data.userMessage);
            });
    }

    const getAllDistrictsDataFromApi = (searchData = initialDistrictSearch) => {
        api.Helpers().GetAllDistrict(searchData)
            .then((response) => {
                setDistricts(response.data.data.map((item) => (
                    {
                        id: item.districtId,
                        text: item.districtName
                    }
                )))
            })
            .catch((error) => {
                console.log('error', error?.response.data);
                setResponseErrors(error?.response.data?.message);
            });
    }

    const handleSelectCityDropdown = (cityCode) => {
        setSelectedCity(cityCode);
        getAllDistrictsDataFromApi({ cityCode: cityCode });
    }

    const switchItems = [
        { id: '2', text: 'Tüzel Kişi', smallText: 'LTD, AŞ' },
        { id: '3', text: 'Tüzel Kişi', smallText: 'Şahıs Firması' },
        { id: '1', text: 'Gerçek Kişi', smallText: 'Vergi Levhası Olmayan' },
    ];

    // //Bayi Ekle : Tüzel Kişi Ltd A.Ş.
    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <h1>Yeni Bayi Ekle</h1>

            <div className={styles.switcharea}>
                <SwitchButton
                    style={{ width: '70%', height: '3rem' }}
                    items={switchItems}
                    callback={setSelectedDealerType}
                    selectedValue={selectedDealerType}
                />
            </div>

            {(() => {
                if (selectedDealerType == 1) {
                    return <>
                        <form onSubmit={handleSubmit} className={styles.form} style={props.style}>
                            <section>
                                <div className={styles.inputarea}>

                                    <ReactInputMask
                                        mask="99999999999"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.trIdentityNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="trIdentityNumber" name="trIdentityNumber" placeholder="T.C. Kimlik Numarası" onBlur={handleBlur} error={errors.trIdentityNumber && touched.trIdentityNumber} helperText={(errors.trIdentityNumber && touched.trIdentityNumber)} />}
                                    </ReactInputMask>


                                    <Input
                                        error={touched.individualName && errors.individualName}
                                        name='individualName'
                                        height='3rem'
                                        maxLength='100'
                                        value={values.individualName}
                                        width='100%'
                                        placeholder='Ad Soyad'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.dealerName && errors.dealerName}
                                        name='dealerName'
                                        maxLength='100'
                                        height='3rem'
                                        width='100%'
                                        value={values.dealerName}
                                        placeholder='Bayi Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.monthlyAmountLimit && errors.monthlyAmountLimit}
                                        name='monthlyAmountLimit'
                                        width='100%'
                                        height='3rem'
                                        value={values.monthlyAmountLimit === 0 ? "" : values.monthlyAmountLimit}
                                        placeholder='Limit (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                    />

                                    <Input
                                        error={touched.customCode && errors.customCode}
                                        name='customCode'
                                        width='100%'
                                        height='3rem'
                                        value={values.customCode === 0 ? "" : values.customCode}
                                        placeholder='Bayi Kodu (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <ReactInputMask
                                        mask="999 999 99 99"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon (Başında 0 olmadan)" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        error={touched.email && errors.email}
                                        name='email'
                                        maxLength='150'
                                        width='100%'
                                        height='3rem'
                                        value={values.email}
                                        placeholder='E-Posta'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Dropdown
                                        error={touched.cityCode && errors.cityCode}
                                        text="İl seçiniz"
                                        height='3rem'
                                        width='100%'
                                        backgroundColor="#fff"
                                        items={cities}
                                        onSelected={handleSelectCityDropdown}
                                        border="2px solid #e6e6e6"
                                    />

                                    <Dropdown
                                        error={touched.districtId && errors.districtId}
                                        width='100%'
                                        height='3rem'
                                        items={districts}
                                        text='İlçe Seçiniz..'
                                        border="2px solid #e6e6e6"
                                        backgroundColor="#fff"
                                        onSelected={setSelectedDistrict}
                                    />

                                    <Input
                                        error={touched.address && errors.address}
                                        name='address'
                                        maxLength='500'
                                        width='100%'
                                        value={values.address}
                                        placeholder='Adres'
                                        height='3rem'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span style={{
                                        color: 'red'
                                    }}>
                                        {responseErrors}
                                    </span>
                                    <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull} />
                                </div>

                            </section>
                        </form>

                    </>
                }
                else if (selectedDealerType == 2 || selectedDealerType == 3) {//Tüzel kişiler
                    return <>
                        <form onSubmit={handleSubmit} className={styles.form} style={props.style}>
                            <section>
                                <div className={styles.inputarea}>
                                    
                                    <ReactInputMask
                                        mask="9999999999"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.taxNumber}
                                        maskChar=""
                                    >
                                        {() => <Input name="taxNumber" placeholder="Vergi Numarası" onBlur={handleBlur} error={errors.taxNumber && touched.taxNumber} helperText={(errors.taxNumber && touched.taxNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        height='3rem'
                                        error={touched.contactName && errors.contactName}
                                        name='contactName'
                                        maxLength='100'
                                        value={values.contactName}
                                        width='100%'
                                        placeholder='Yetkili Ad Soyad'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.companyName && errors.companyName}
                                        name='companyName'
                                        maxLength='100'
                                        width='100%'
                                        value={values.companyName}
                                        placeholder='Firma Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                    />

                                    <Input
                                        error={touched.dealerName && errors.dealerName}
                                        name='dealerName'
                                        maxLength='100'
                                        width='100%'
                                        value={values.dealerName}
                                        placeholder='Bayi Adı'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <ReactInputMask
                                        mask="999 999 99 99"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber}
                                        maskChar=""
                                    >
                                        {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon (Başında 0 olmadan)" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
                                    </ReactInputMask>

                                    <Input
                                        error={touched.email && errors.email}
                                        name='email'
                                        maxLength='150'
                                        width='100%'
                                        height='3rem'
                                        value={values.email}
                                        placeholder='E-Posta'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.monthlyAmountLimit && errors.monthlyAmountLimit}
                                        name='monthlyAmountLimit'
                                        width='100%'
                                        value={values.monthlyAmountLimit === 0 ? "" : values.monthlyAmountLimit}
                                        placeholder='Limit (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type='number'
                                    />

                                    <Input
                                        error={touched.customCode && errors.customCode}
                                        name='customCode'
                                        width='100%'
                                        height='3rem'
                                        value={values.customCode === 0 ? "" : values.customCode}
                                        placeholder='Bayi Kodu (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <Input
                                        error={touched.erpNumber && errors.erpNumber}
                                        name='erpNumber'
                                        width='100%'
                                        value={values.erpNumber === 0 ? "" : values.erpNumber}
                                        placeholder='ERP numarası (Opsiyonel)'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type='number'
                                    />

                                    <Dropdown
                                        error={errors.cityCode}
                                        width='100%'
                                        items={cities}
                                        backgroundColor='#fff'
                                        text='İl Seçiniz..'
                                        onSelected={handleSelectCityDropdown}
                                        border="2px solid #e6e6e6"

                                    />

                                    <Dropdown
                                        error={errors.districtId}
                                        width='100%'
                                        items={districts}
                                        text='İlçe Seçiniz..'
                                        backgroundColor='#fff'
                                        onSelected={setSelectedDistrict}
                                        border="2px solid #e6e6e6"
                                    />

                                    <Input
                                        error={touched.address && errors.address}
                                        maxLength='500'
                                        name='address'
                                        width='100%'
                                        value={values.address}
                                        placeholder='Adres'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span style={{
                                        color: 'red'
                                    }}>
                                        {/* {emptyDealerError} */}
                                    </span>

                                    <Button type='submit' fontSize='1.3rem' width='100%' height='3rem' text='Kaydet' disabled={isFull} />

                                </div>
                            </section>
                        </form>

                    </>
                }
            })()}

            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }

        </>
    )
};

export default DealerAdd;
