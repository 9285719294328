import React, { forwardRef, useEffect } from 'react';
import styles from './list.module.css';
import cx from 'classnames';
import ListItems from './ListItems';

/**
 * Generic List
 * @param {data} data itemlar verilir. Fragment içinde ya da tek bir parent eleman altında JSX elementleri-componentleri verilebilir.
 * @param {stringArray[]} columnNames kolon adları.
 * item sayısına göre column olmalıdır. Boş alanlar için ''
 */
const List = forwardRef(({ data, columnNames, collapsed, CollapseComponent,fontFamily="Metropolis-SemiBold" }, ref) => {
    // useEffect(() => {
    //     console.log("Ref List Componet: ", ref) // cuurent te ara
    // })

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columnNames?.map((e, i) => {
                            if (i == 0) {

                                return <th key={i} style={{fontFamily}} className={cx(styles.headcell, styles.firsthead)}>{e}</th>
                            }
                            if (i == columnNames.length - 1) {
                                return <th key={i} style={{fontFamily}} className={cx(styles.headcell, styles.lasthead)}>{e}</th>

                            }
                            return <th key={i} style={{fontFamily}} className={styles.headcell}>{e}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((item,i) => {
                            return (
                                <>
                                {/* refi her rowa tekrardan gonderiyorum */}
                                    <ListItems key={i} id={i} ref={ref} items={item} collapsed={collapsed} CollapseItem={CollapseComponent} />
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
})

export default List