import React from 'react'
import styles from './textarea.module.css';
import cx from 'classnames';

const TextArea = ({ width, height, placeholder, fontSize,onChange,value,name,id,type = 'text',maxLength, error, disabled }) => {
  return (
    <textarea disabled={disabled} maxLength={maxLength} type={type} value={value} onChange={onChange}  name={name} id={id} placeholder={placeholder} className={cx(styles.input, error && styles.error,styles.textarea)} style={{ width, height, fontSize}}>
        
    </textarea>
  )
}

export default TextArea