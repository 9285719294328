import React from 'react';

const AddUserIcon = ({ color = "#000D89" }) => {
    return <>
        <symbol id="add" >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M4.872 6.382A4.587 4.587 0 0 1 9.452 1.8a4.587 4.587 0 0 1 4.582 4.582 4.587 4.587 0 0 1-4.582 4.581v.9l-.033-.9a4.542 4.542 0 0 1-3.223-1.347 4.533 4.533 0 0 1-1.324-3.234zm4.543 6.381h.037a6.389 6.389 0 0 0 6.382-6.381A6.389 6.389 0 0 0 9.452 0a6.388 6.388 0 0 0-6.38 6.378c-.006 1.7.65 3.3 1.848 4.506a6.324 6.324 0 0 0 4.495 1.88zM1.8 19.338c0-1.718 2.575-2.59 7.652-2.59 5.078 0 7.653.879 7.653 2.616 0 1.718-2.575 2.59-7.653 2.59-5.077 0-7.652-.88-7.652-2.616zm-1.8 0c0 4.416 7.115 4.416 9.452 4.416 4.059 0 9.453-.455 9.453-4.39 0-4.417-7.115-4.417-9.453-4.417-4.058 0-9.452.455-9.452 4.391zm20.645-5.622a.9.9 0 0 1-.9-.9v-1.507h-1.553a.9.9 0 0 1 0-1.8h1.553V8.002a.9.9 0 0 1 1.8 0V9.51H23.1a.9.9 0 0 1 0 1.8h-1.555v1.507a.9.9 0 0 1-.9.9z" fill={color} />
            </svg>
        </symbol>
    </>;
};

export default AddUserIcon;
