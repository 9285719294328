import React from 'react';

const CopyIcon = ({size = '24'}) => {
    return <>
        <symbol id="copy">
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M7.768 0h8.352c.45 0 .799.345.799.786a.782.782 0 0 1-.8.786H7.769c-2.306 0-4.17 1.84-4.17 4.12v12.142a.782.782 0 0 1-.8.786.796.796 0 0 1-.798-.786V5.692C2 2.55 4.583 0 7.768 0zm1.485 4.088.206-.006h9.48c1.8 0 3.274 1.39 3.381 3.139l.006.203v13.234c0 1.775-1.408 3.23-3.181 3.336l-.207.006H9.46c-1.8 0-3.275-1.39-3.382-3.139l-.006-.203V7.424c0-1.775 1.408-3.23 3.182-3.336zm9.685 1.565H9.46c-.932 0-1.695.7-1.782 1.6l-.008.17v13.235c0 .923.708 1.677 1.617 1.762l.173.008h9.48c.931 0 1.694-.699 1.78-1.599l.01-.171V7.424c0-.924-.709-1.677-1.618-1.763l-.173-.008z" fill="#000D89" />
            </svg>

        </symbol>
    </>
};

export default CopyIcon;