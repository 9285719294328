// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textarea_textarea__AG-Gn {\n    width: 100%;\n    height: 150px;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    background-color: #fff;\n    font-size: 16px;\n    resize: none;\n    border-radius: 12px;\n    border: solid 1px #bfc3de;\n    font-family: 'Metropolis';\n  }\n  \ntextarea{\n  font-family: 'Metropolis';\n  font-weight: .8rem;\n}\n.textarea_textarea__AG-Gn:focus { \n    outline: none !important;\n}\n\n.textarea_error__gf7AG{\n  border-color: red !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Global/TextArea/textarea.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;EAC3B;;AAEF;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;IACI,wBAAwB;AAC5B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".textarea {\n    width: 100%;\n    height: 150px;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    background-color: #fff;\n    font-size: 16px;\n    resize: none;\n    border-radius: 12px;\n    border: solid 1px #bfc3de;\n    font-family: 'Metropolis';\n  }\n  \ntextarea{\n  font-family: 'Metropolis';\n  font-weight: .8rem;\n}\n.textarea:focus { \n    outline: none !important;\n}\n\n.error{\n  border-color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "textarea_textarea__AG-Gn",
	"error": "textarea_error__gf7AG"
};
export default ___CSS_LOADER_EXPORT___;
