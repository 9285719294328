import React from 'react';

const EditIcon = ({ color = "#000D89" }) => {
    return <>
        <symbol id="down" >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="m10.895 7.79-7.2 9.006a.879.879 0 0 0-.17.755l.68 2.885 3.04-.038a.949.949 0 0 0 .732-.352l7.178-8.983-4.26-3.272zm.937-1.17 4.26 3.27 1.41-1.768a1.36 1.36 0 0 0 .141-1.004 1.411 1.411 0 0 0-.652-.887 328.898 328.898 0 0 1-1.808-1.398 1.497 1.497 0 0 0-2.073.188l-1.278 1.598zm5.034 4.705C18.7 9.027 18.716 9 18.734 8.973a2.862 2.862 0 0 0 .368-2.201c-.18-.766-.655-1.413-1.263-1.775l-1.723-1.34c-1.26-1.01-3.128-.828-4.16.407a.062.062 0 0 0-.006.006l-.006.007-9.42 11.781a2.371 2.371 0 0 0-.46 2.037l.82 3.471a.75.75 0 0 0 .73.578h.01l3.638-.046a2.447 2.447 0 0 0 1.886-.915c3.918-4.9 6.23-7.793 7.595-9.505a.758.758 0 0 0 .123-.153zm3.884 10.62h-7.253a.75.75 0 0 1 0-1.5h7.253a.75.75 0 0 1 0 1.5z" fill={color} />
            </svg>

        </symbol>
    </>;
};

export default EditIcon;
