import React from 'react';
import styles from './listcontainer.module.css';

const ListContainer = ({ children }) => {
    return <div className={styles.listcontainer}>
        {children}
    </div>;
};

export default ListContainer;
