import styles from "../../Profile/profile.module.css";
import Input from "components/Global/Input";
import Button from "components/Global/Button";
import { useFormik } from "formik";
import formValidator from "./formValidator";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { useEffect, useState } from "react";
import api from "store/api/actions";
import ReactInputMask from "react-input-mask";

const UserEdit = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [responseErrors, setResponseErrors] = useState()
  const [isFull, setisFull] = useState(false)

  // useEffect(() => {
  //   props.setDynamicModalStyle({
  //     boxShadow: "none",
  //     flexBasis: "40%",
  //     width: "30%",
  //     alignItems: "stretch",
  //   });
  // }, []);

  useEffect(() => {
    handleReset({});
    setFieldValue("userId", props?.selectId);
    setFieldValue("userFullName", props?.userEditData?.userFullName);
    setFieldValue("phoneNumber", props?.userEditData?.phoneNumber);
    setFieldValue("email", props?.userEditData?.email);
  }, [props.selectId, props.userEditData]);

  useEffect(() => {
    (
        values.userFullName == null
        || values.phoneNumber.length < 13
        || values.email == ""
    ) ?
      setisFull(true)
      : setisFull(false)
  })
  
  const {handleChange, touched, handleBlur, values, handleSubmit, errors, handleReset, setFieldValue} = useFormik({
    initialValues: {
      userId: props?.selectId,
      userFullName: props?.userEditData?.userFullName,
      phoneNumber: props?.userEditData?.phoneNumber,
      email: props?.userEditData?.email,
      roleNumber: 3,
    },
    validationSchema: formValidator,
    onSubmit: (values) => {
      setIsLoading(true);

      api
        .User()
        .Edit(values)
        .then((response) => {
          //console.log("response", response);
          props.handleModalClick(false);
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
          // setResponseErrors(error?.response?.data);
          setResponseErrors(error?.response?.data.userMessage)
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <CustomLoadingBox isLoading={isLoading} />
      <div style={{width:props.width}} className={styles.forminfo}>Kullanıcıyı Düzenle</div>
      <form style={{width:props.width, height:props.height}} className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputgroup}>
          <Input
            width="100%"
            height="3rem"
            id="userFullName"
            placeholder="Ad Soyad"
            name="userFullName"
            onChange={handleChange}
            value={values.userFullName}
            onBlur={handleBlur}
            error={touched.userFullName && errors.userFullName}
            type="string"
          />
          <ReactInputMask
            mask="999 999 99 99"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            maskChar=""
          >
            {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
          </ReactInputMask>
          <Input
            width="100%"
            id="email"
            height="3rem"
            placeholder="E-posta"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && errors.email}
            type="string"
          />
          {
            responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
          }
          <Button
            type="submit"
            fontSize="1.3rem"
            width="100%"
            height="3rem"
            text="Kaydet"
            disabled={isFull}
          />
        </div>
      </form>
    </>
  );
};

export default UserEdit;
