import React from 'react';
import styles from './infoarea.module.css';

const InfoArea = ({children,style,containerStyle}) => {
    return <>
        <div style={style} className={styles.headinfo}>
            <div style={containerStyle} className={styles.headcontainer}>
                {children}
            </div>
        </div>
    </>;
};

export default InfoArea;
