// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list_table__LZPW5 {\n    border-collapse: separate;\n    border-spacing: 0 15px;\n    width: 100%;\n    height: 100%;\n}\n\n.list_firsthead__8GehI {\n    border-top-left-radius: 12px;\n    border-bottom-left-radius: 12px;\n}\n\n.list_lasthead__m4\\+EL {\n    border-top-right-radius: 12px;\n    border-bottom-right-radius: 12px;\n}\n\n.list_headcell__Din9t {\n    background-color: #f5f7f9;\n    color: #4f5386;\n    text-align: left;\n    height: 35px;\n    padding-left: 20px;\n    /* font-family: 'Metropolis-SemiBold'; */\n    font-size:.8rem;\n}\n\n.list_head__Kv2j4 {\n    text-align: left;\n    border-top: 1px solid #edeef3;\n    border-bottom: 1px solid #edeef3;\n    height: 28px;\n\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/Global/List/list.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,gCAAgC;IAChC,YAAY;;AAEhB","sourcesContent":[".table {\n    border-collapse: separate;\n    border-spacing: 0 15px;\n    width: 100%;\n    height: 100%;\n}\n\n.firsthead {\n    border-top-left-radius: 12px;\n    border-bottom-left-radius: 12px;\n}\n\n.lasthead {\n    border-top-right-radius: 12px;\n    border-bottom-right-radius: 12px;\n}\n\n.headcell {\n    background-color: #f5f7f9;\n    color: #4f5386;\n    text-align: left;\n    height: 35px;\n    padding-left: 20px;\n    /* font-family: 'Metropolis-SemiBold'; */\n    font-size:.8rem;\n}\n\n.head {\n    text-align: left;\n    border-top: 1px solid #edeef3;\n    border-bottom: 1px solid #edeef3;\n    height: 28px;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "list_table__LZPW5",
	"firsthead": "list_firsthead__8GehI",
	"lasthead": "list_lasthead__m4+EL",
	"headcell": "list_headcell__Din9t",
	"head": "list_head__Kv2j4"
};
export default ___CSS_LOADER_EXPORT___;
