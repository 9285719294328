import * as yup from 'yup';

const formRealPersonValidator = yup.object().shape({

    trIdentityNumber: yup.string().min(11, "T.C Kimlik alanı 11 haneli olmalı").max(11, "T.C Kimlik alanı 11 haneli olmalı").required('Lütfen T.C Kimlik alanını doldurunuz. 11 haneli ve yalnızca rakamlarla oluşmalıdır.'),
    individualName: yup.string().max(50).required('Lütfen Ad Soyad alanını doldurunuz. ').min(5, "Ad Soyad en az 5 karakter olmalı"),
    dealerName: yup.string().max(50).required('Lütfen Bayi Adı alanını doldurunuz. ').min(5, "Bayi Adı en az 5 karakter olmalı"),
    phoneNumber: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
    email: yup.string().email('Email formatı yanlış.'),
    address: yup.string().max(200).required('Lütfen adres alanını doldurunuz. ').min(10, "Adres en az 10 karakter olmalı"),
})

export default formRealPersonValidator;