import React, { forwardRef, useEffect } from 'react';
import styles from './doublebutton.module.css'
import cx from 'classnames'
import EditIcon from 'components/icons/EditIcon'
import CloseIcon from 'components/icons/CloseIcon'

const DoubleButton = forwardRef(({firstOnClick, secondOnClick},ref = []) => {
    useEffect(() => {
        console.log("ref",ref)
    })
    
    return (
        <div className={styles.doublecontainer}>
            <div className={styles.section}>
                <a onClick={firstOnClick} className={styles.button}><EditIcon size='15' />Düzenle</a>
            </div>
            <div className={cx(styles.section, styles.bottom)}>
                <a ref={ref} onClick={secondOnClick} className={styles.button}><CloseIcon size='15' />Kaldır</a>
            </div>
        </div>
    )
})

export default DoubleButton