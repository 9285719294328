import React, { useEffect, useState } from "react";
import Container from "components/Global/Container";
import styles from "./smpstandartdb.module.css";
import Card from "../../../Global/Card";
import List from "../../../Global/List";
import RightIcon from "../../../icons/RightIcon.js";
import PriceLabel from "../../../Global/PriceLabel";
import Pagination from "../../../Global/Pagination";
import CreditCardIcon from "../../../icons/CreditCardIcon";
import LinkPaymentIcon from "../../../icons/LinkPaymentIcon";
import Button from "components/Global/Button";
import Modal from "components/Global/Modal";
import api from "../../../../store/api/actions";
import Payment from "components/Global/Forms/Payment";
import ListContainer from "components/Global/ListContainer";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import Dropdown from "components/Global/Dropdown";
import { Link, useSearchParams } from "react-router-dom";
import FilterArea from "components/Global/FilterArea";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import BannerArea from "components/Global/BannerArea";
import DateLabel from "components/Global/DateLabel";
import TablePriceLabel from "components/Global/TablePriceLabel";
import Label from "components/Global/Label";
import GetPaymentInfo from "hoc/GetPaymentInfo";
import DateTimePicker from 'components/Global/DateTimePickerv2'
import DashboardCollapse from "components/Global/List/CollapseForDashboard";
import { useMediaQuery } from "react-responsive";
import DashboardResponsive from "components/Responsive/Dashboard";

const GetDate = () => {
  var locale = "tr-TR";
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var monthName = today.toLocaleString(locale, { month: "long" });
  var yyyy = today.getFullYear();
  var time =
    today.getHours() + ":" + String(today.getMinutes()).padStart(2, "0");

  return { dd, mm, yyyy, monthName, time };
};

const initialSearchDataState = {
  startDate: "",
  endDate: "",
  users: [],
};

const initialResponseDataState = {
  waitingTotalTransactionAmount: "",
  waitingTotalTransactionCount: "",
  cancelAndRefundTotalTransactionAmount: "",
  cancelAndRefundTotalTransactionCount: "",
  unSuccessfulTotalTransactionAmount: "",
  unSuccessfulTotalTransactionCount: "",
  successfulTotalTransactionAmount: "",
  successfulTotalTransactionCount: "",
};

const initialAccountedForResponseState = {
  totalNetAccountAmountAndCurrency: "",
  totalCancellationAndRefundAmountAndCurrency: "",
  totalSuccessTransactionAmountAndCurrency: "",
  totalComissionAmountAndCurrency: "",
  paymentList: [],
  paymentListTotalCount: 0,
};

const initialPaymentResponseListState = {
  transactionDate: "",
  customerName: "",
  amountAndCurrencyCode: "",
  userName: "",
  installment: 0,
  paymentStatus: "",
  paymentTypeId: 0,
  paymentStatusId: 0,
  details: {
    referanceNumber: "",
    commissionAmount: "",
    paymentRecipient: "",
    transactionDescription: "",
    bankName: "",
    cardType: "",
    creditType: "",
    nameOnTheCard: "",
    cardNumber: "",
    threeDSecurity: "",
    customerPhoneNumber: "",
    customerEMail: "",
  },
};

const initialWillAcountedResponseState = {
  transactionDateOrUserName: "",
  totalNetAccountAmountAndCurrency: "", //net hesaba geçen
  totalSuccessTransactionAmountAndCurrency: "", // net işlem tutarı
  totalCancellationAndRefundAmountAndCurrency: "", // iptal tutarı ve iade tutarı
  totalComissionAmountAndCurrency: "",
  paymentListTotalCount: 0,
};

const initialUserState = {
  userId: 0,
  userFullName: "",
};

const initialPaymentInfoState = {
  amount: "",
  installmentNumber: "",
  userNameOrPobDealerName: "",
  customerName: "",
  description: "",
};

const DashboardSMPStandard = () => {
  const now = GetDate();
  //#region Search State
  const [cardSearchData, setCardSearchData] = useState();
  const [accountedForPaymentSearchData, setAccountedForPaymentSearchData] =
    useState({});
  const [
    paymentTransactionListSearchData,
    setPaymentTransactionListSearchData,
  ] = useState({ limit: -1 });
  const [
    willAccountedPaymentListSearchData,
    setWillAccountedPaymentListSearchData,
  ] = useState({});
  //#endregion

  //#region Response Data State
  const [cardResponseData, setCardResponseData] = useState(
    initialResponseDataState
  );
  const [accountedForResponseData, setAccountedForResponseData] = useState(
    initialAccountedForResponseState
  );
  const [willAccountedResponseData, setWillAccountedResponseData] = useState(
    initialWillAcountedResponseState
  );
  const [paymentResponseListData, setPaymentResponseListData] = useState([
    [initialPaymentResponseListState],
  ]);
  //#endregion

  //#region Date State
  const [calendarValue, setCalendarValue] = useState();
  const [dateValue, setDateValue] = useState([null, null]);
  //#endregion

  //#region Paggination State
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [listTotalCount, setListTotalCount] = useState()
  //#endregion

  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [modalClick, setModalClick] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [termDayData, setTermDayData] = useState();
  const [startDate, setStartDate] = useState();
  const [paymentInfo, setPaymentInfo] = useState();

  const [startTodayDate, setStartTodayDate] = useState();
  const [endTodayDate, setEndTodayDate] = useState();

  //GetAll Data
  useEffect(() => {
    setIsLoading(true)
    todayStartEnd();

    const date = now.dd + "/" + now.mm + "/" + now.yyyy;
    // console.log("Now : ", setDateTimeFormat(date));
    //#region Get User
    GetPaymentInfo(setPaymentInfo, searchParams, setModalClick);
    //#endregion


    getAccountedForPaymentDataFromApi();
    getPaymentTransactionListDataFromApi();
    getWillAccountedPaymentListDataFromApi();

    const getUrlLinkId = searchParams.get("success");
    // console.log("success", getUrlLinkId);
    //#region Get User

    api
      .User()
      .GetAllUser({})
      .then((response) => {
        //console.log("response user", response.data.data.userList);
        if (checkNullOrEmptyArray(response.data.data.userList)) {
          setUsersData(
            response?.data?.data?.userList.map((item) => ({
              id: item.userId,
              text: item.userFullName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      });

    //#endregion

    api
      .Helpers()
      .GetTermDay({})
      .then((response) => {
        if (checkNullOrEmpty(response.data.data.termDay)) {
          // console.log('response Term Day', response.data.data.termDay)
          setTermDayData(response.data.data.termDay);
        }
      })
      .catch((err) => {
        console.log("getDealer Hatası : ", err?.response?.data);
      });

    getCardDataFromApi()
    killLoading();
  }, []);

  const killLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
      console.log("Kill Loading");
    }, 4000);
  }

  const setCartAndPaymentLinkColor = (item) => {
    const statusList = [
      { id: 1, text: "Beklemede" },
      {
        id: 2,
        text: "Başarılı",
      },
      { id: 3, text: "Başarısız" },
      { id: 4, text: "Müşteri Reddi" },
      { id: 5, text: "Zaman Aşımı" },
      { id: 6, text: "Banka Reddi" },
      { id: 7, text: "İptal" },
      { id: 8, text: "İade" },
      { id: 9, text: "Beklemede" },
    ];
    const status = statusList.find((a) => a.id == item?.paymentStatusId);
    let color =
      status?.id == 2
        ? "#1ebcc8"
        : status?.id > 2 && status?.id < 9
          ? "#ff5c5c"
          : "#000D89";

    return item.paymentTypeId == 1 ? (
      <CreditCardIcon color={color} />
    ) : (
      <LinkPaymentIcon color={color} />
    );
  };

  useEffect(() => {
    var start = new Date();
    start.setDate(start.getDate() - termDayData);
    start.setUTCHours(0, 0, 0, 0);

    var end = new Date();
    end.setDate(end.getDate() - termDayData);
    end.setUTCHours(23, 59, 58, 999);

    if (isValidDate(start) && isValidDate(end)) {
      let strt = start.toLocaleString().split(" ");
      // console.log("Start Date : ", strt[0]);
      setStartDate(strt[0]);

      setAccountedForPaymentSearchData({ startTransactionDate: start, endTransactionDate: end })

    }
    // console.log(start.toUTCString() + ':' + end.toUTCString());
  }, [termDayData]);

  //Pagination
  useEffect(() => {
    if (checkNullOrEmptyArray(paymentResponseListData)) {
      setCurrentItems(
        paymentResponseListData
          .slice(itemOffset, itemOffset + itemsPerPage)
          .map((item, i) => [
            <div key={i}>
              {
                setCartAndPaymentLinkColor(item)
              }
            </div>,
            <DateLabel
              time={setTimeFormat(item.transactionDate)}
              date={setDateFormat(item.transactionDate)}
            />,
            <Label text={item.customerName} />,
            <TablePriceLabel price={item.amountAndCurrencyCode} />,
            <Label text={item.userName} />,
            {
              details: item.details,
            },
          ])
      );
    }
    else {
      setCurrentItems([[]]);
    }
    setPageCount(Math.ceil(paymentResponseListData.length / itemsPerPage));
  }, [paymentResponseListData, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmpty(selectedValue) && selectedValue !== 0) {
      setCardSearchData({
        userList: [selectedValue],
        startTransactionDate: startTodayDate,
        endTransactionDate: endTodayDate,
      });
      setPaymentTransactionListSearchData({
        ...paymentTransactionListSearchData,
        userList: [selectedValue],
      });
    } else {
      setCardSearchData({
        userList: [],
        startTransactionDate: startTodayDate,
        endTransactionDate: endTodayDate,
      });
      setPaymentTransactionListSearchData({
        ...paymentTransactionListSearchData,
        userList: [],
      });
    }
  }, [selectedValue]);

  useEffect(() => {
    // console.log("Card SEarch ,", endTodayDate)
    getCardDataFromApi();
  }, [cardSearchData]);

  useEffect(() => {
    setCardSearchData({
      startTransactionDate: startTodayDate,
      endTransactionDate: endTodayDate,
    });
  }, [endTodayDate]);

  useEffect(() => {
    getAccountedForPaymentDataFromApi();
  }, [accountedForPaymentSearchData]);

  useEffect(() => {
    getPaymentTransactionListDataFromApi();
  }, [paymentTransactionListSearchData]);

  useEffect(() => {
    getWillAccountedPaymentListDataFromApi();
  }, [willAccountedPaymentListSearchData]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % paymentResponseListData.length;
    setItemOffset(newOffset);
    setPaymentTransactionListSearchData({ ...paymentTransactionListSearchData, pageNo: event.selected + 1 });

  };

  const todayStartEnd = () => {

    setIsLoading(true)

    var start = new Date();
    start.setDate(start.getDate());
    start.setUTCHours(0, 0, 0, 0);

    var end = new Date();
    end.setDate(end.getDate());
    end.setUTCHours(23, 59, 58, 999);
    // console.log("Start : ", start)
    // console.log("End : ", end)
    if (isValidDate(start) && isValidDate(end)) {
      setStartTodayDate(start);
      setEndTodayDate(end);
    }
  };

  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  useEffect(() => {
    willAccountedDateFormat()
  }, [])

  const willAccountedDateFormat = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate() + termDayData);
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }

  const accountedForDateFormat = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate() - termDayData);
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }

  // #region Filter Api

  const getCardDataFromApi = () => {
    if (checkNullOrEmpty(cardSearchData) && checkNullOrEmpty(endTodayDate)) {
      setIsLoading(true)
      api
        .Dashboard()
        .GetValuesForSmallMarketPlace(cardSearchData)
        .then((response) => {
          // console.log("responselist", response.data.data);
          setCardResponseData(response.data.data);
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
        })
        .finally(() => {

        });
    }
  };

  const getAccountedForPaymentDataFromApi = () => {
    if (checkNullOrEmpty(accountedForPaymentSearchData)) {
      api
        .SmallMarketPlace()
        .AccountedForPaymentList(accountedForPaymentSearchData)
        .then((response) => {
          // console.log("AccountedFor", response.data.data);
          setAccountedForResponseData(response.data.data);
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
        })
        .finally(() => { });
    }
  };

  const getPaymentTransactionListDataFromApi = () => {
    api
      .SmallMarketPlace()
      .PaymentTransactionList(paymentTransactionListSearchData)
      .then((response) => {
        //console.log("Response Payment List : ", response.data.data)
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListTotalCount(response.data.data.paymentTransactionListTotalCount)
          setPaymentResponseListData(response.data.data.paymentList);
        } else {
          setPaymentResponseListData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 1099);
      });
  };

  const getWillAccountedPaymentListDataFromApi = () => {
    if (checkNullOrEmpty(willAccountedPaymentListSearchData)) {
      api
        .SmallMarketPlace()
        .WillAccountedPaymentList(willAccountedPaymentListSearchData)
        .then((response) => {
          setWillAccountedResponseData(response.data.data);
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
        })
        .finally(() => { });
    }
  };

  const dateTimeDiff = (dateTime, paymentStatusId) => {
    const date1 = Date.now();
    const date2 = Date.parse(dateTime);
    var hours = Math.abs(date1 - date2) / 36e5;
    if (hours < 25) {
      if (
        paymentStatusId === 3 ||
        paymentStatusId === 4 ||
        paymentStatusId === 5 ||
        paymentStatusId === 6
      ) {
        return (
          "Zaman Aşımı + Reddedilen İşlemler Bugün " +
          setDateFormat(dateTime) +
          " itibariyle"
        );
      }
      return "Bugün " + setDateFormat(dateTime) + " itibariyle";
    }
    return setDateFormat(dateTime);
  };

  const setDateTimeFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (newValue) => {
    setDateValue(newValue);
    console.log("Tarih Seçtin  : ", newValue);

    let str = "";
    if (newValue.endDate != null) {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      let endDate = new Date(newValue.endDate).toLocaleDateString();
      if (startDate !== endDate) {
        str = startDate + " - " + endDate;

        // console.log("Start date : ", startDate);
        // console.log("End date :", endDate);

        setCardSearchData({
          startTransactionDate: startTodayDate,
          endTransactionDate: endTodayDate,
        });
        setPaymentTransactionListSearchData({
          ...paymentTransactionListSearchData,
          startTransactionDate: setDateTimeFormat(startDate),
          endTransactionDate: setDateTimeFormat(endDate),
        });
        setWillAccountedPaymentListSearchData({
          ...willAccountedPaymentListSearchData,
          // startTransactionDate: setDateTimeFormat(startDate),
          // endTransactionDate: setDateTimeFormat(endDate),
        });
      } else {
        str = startDate;
      }
    } else {
      let startDate = new Date(newValue.startDate);
      str = startDate.toLocaleDateString();
    }

    setCalendarValue(str);
  };

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };
  // #endregion

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      items={usersData}
      onSelected={setSelectedValue}
      selectItemId={selectedValue}
      text="Tüm Kullanıcılar"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Button
      onClick={() => setModalClick((value) => !value)}
      text="Ödeme Al"
      width="179px"
    ></Button>,
  ];
  const accountedForDateFormatOre = (dateTime) => {
    dateTime = new Date();
    dateTime.setDate(dateTime.getDate());
    dateTime.setUTCHours(0, 0, 0, 0);
    let result = dateTime.toLocaleString().split(" ");
    return result[0];
  }

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <DashboardResponsive
        usersData={usersData}
        accountedForResponseData={accountedForResponseData}
        accountedForDate={accountedForDateFormatOre}
        accountedForDateFormat={accountedForDateFormat}
        willAccountedDateFormat={willAccountedDateFormat}
        willAccounted={willAccountedResponseData}
        termDay={termDayData}
        role="smp"
        cardResponseData={cardResponseData}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        setDateTimeFormat={setDateTimeFormat}
        setCardSearchData={setCardSearchData}
        setWillAccountedPaymentListSearchData={setWillAccountedPaymentListSearchData}
        handleDateRangeChange={handleDateRangeChange}

      />
    </>
      : <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea width="40%" items={filterItems}></FilterArea>
        {/* <Chart/> */}
        <Container flexDirection="column" rowGap="20px">
          <Modal
            modalStyle={{
              boxShadow: "none",
              flexBasis: "70%",
              width: "50%",
              alignItems: "stretch",
            }}
            clickEvent={modalClick}
            callbackEvent={setModalClick}
            body={<Payment paymentInfo={paymentInfo} modalClicked={modalClick} />}
          />
          <div className={styles.cards}>
            {
              console.log("total : ", cardResponseData.waitingTotalTransactionAmount)
            }
            <Card
              headText="Bekleyen İşlemler"
              subText={"Bugün 00:00 itibariyle"}
              price={cardResponseData.waitingTotalTransactionAmount}
              money="TL"
              fontSize={"14px"}
              count={
                cardResponseData.waitingTotalTransactionCount + " adet işlem"
              }
              color="#000d89"
              btnBackground="#f5f7f9"
              style={{ width: "25%" }}
              href={"/transactions"}
              state={{
                dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                paymentStatusId: 1,
              }}
            ></Card>

            <Card
              headText="İptal/İade İşlemleri"
              subText={"Bugün 00:00 itibariyle"}
              price={cardResponseData.cancelAndRefundTotalTransactionAmount}
              money="TL"
              fontSize={"14px"}
              count={
                cardResponseData.cancelAndRefundTotalTransactionCount +
                " adet işlem"
              }
              color="#e99d36"
              btnBackground="#fcf5ea"
              href={"/transactions"}
              state={{
                dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                paymentStatusIdsArray: [5, 6],
              }}
              style={{ width: "25%" }}
            />

            <Card
              headText="Başarısız İşlemler"
              subText={"Zaman aşımı + Reddedilen İşlemler Bugün 13:00 itibariyle"}
              price={cardResponseData.unSuccessfulTotalTransactionAmount}
              money="TL"
              fontSize={"14px"}
              count={
                cardResponseData.unSuccessfulTotalTransactionCount + " adet işlem"
              }
              color="#ff5c5c"
              btnBackground="#fee"
              href={"/transactions"}
              state={{
                dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                paymentStatusIdsArray: [3, 4],
              }}
              style={{ width: "25%" }}
            />

            <Card
              headText="Hesaba geçen toplam tutar"
              subText="Bugün, 00.00 da hesabınıza geçen tutar ve bu tutarı oluşturan işlem adedi"
              price={accountedForResponseData.totalNetAccountAmountAndCurrency}
              money="TL"
              count={
                accountedForResponseData.paymentListTotalCount + " adet işlem"
              }
              color="#1ebcc8"
              btnBackground="#e8f8f9"
              fontSize={"14px"}
              href="/accountedFor"
              state={{
                date: accountedForDateFormat(new Date()),
              }}
              style={{ width: "25%" }}
            />
          </div>
          <div className={styles.sides}>
            <div className={styles.leftside}>
              <div className={styles.headgroup}>
                <div className={styles.textarea}>
                  <span className={styles.header}>Başarılı İşlemler</span>
                  <a className={styles.headbtn} href="#">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/transactions`}
                      state={{
                        dateNow: now.dd + "." + now.mm + "." + now.yyyy,
                        paymentStatusId: 2,
                      }}
                    >
                      <RightIcon size={"15px"} />
                    </Link>
                  </a>
                </div>
                <div className={styles.seperator}></div>
              </div>

              <div className={styles.infoarea}>
                <div className={styles.cardbg}>

                  <div className={styles.infoheader}>
                    <span>İptal iade işlemi düşülerek hesaplanan tutarlardır.</span>
                    <span>
                      Bu işlemler firmanızın hesabına
                      <span className={styles.bolddate}>
                        {" "}
                        {now.dd + " " + now.monthName + " " + now.yyyy}
                      </span>
                      'de gerçekleştirilecektir. Son güncelleme {now.time},{" "}
                      {now.dd + "." + now.mm + "." + now.yyyy}
                    </span>
                  </div>

                  <div className={styles.infobottom}>
                    <PriceLabel
                      price={cardResponseData.successfulTotalTransactionAmount}
                      size="4"
                    />
                    <span className={styles.counttext}>
                      {cardResponseData.successfulTotalTransactionCount +
                        " adet işlem"}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.listarea}>
                <ListContainer>
                  <Pagination
                    width="20%"
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    setItemsPerPage={setItemsPerPage}
                    infoCount={listTotalCount}
                    itemsPerPage={itemsPerPage}
                    listDataLength={paymentResponseListData.length}
                  />
                  <List
                    collapsed
                    CollapseComponent={<DashboardCollapse />}
                    columnNames={[
                      "",
                      "İŞLEM SAATİ VE TARİHİ",
                      "MÜŞTERİ İSMİ",
                      "İŞLEM TUTARI",
                      "İŞLEM SAHİBİ",
                      "",
                      "",
                    ]}
                    data={currentItems}
                  />
                </ListContainer>
              </div>
            </div>
            <div className={styles.rightside}>
              <BannerArea style={{ height: "45%" }} />
              <div className={styles.chartcontainer}>
                <div className={styles.headgroup}>
                  <div className={styles.rightbottomtextarea}>
                    <span className={styles.header}>
                      Hesaba Geçecek Toplam Tutar
                    </span>
                    <a className={styles.headbtn} href="willaccounted">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/willaccounted`}
                        state={{
                          dateNow: willAccountedDateFormat(new Date()),
                        }}
                      >
                        <RightIcon size={"15px"} />
                      </Link>
                    </a>
                  </div>
                  <div className={styles.seperator}></div>
                </div>

                <div className={styles.chart}>
                  <div className={styles.chartinfo}>
                    <div className={styles.rightinfo}>
                      {startDate} itibariyle,{" "}
                      {now.dd + "." + now.mm + "." + now.yyyy} tarihine kadar
                      hesabınıza geçecek toplam tutar:
                    </div>
                    <div className={styles.rightpricecontainer}>
                      <div className={styles.rightprice}>
                        <PriceLabel
                          price={
                            willAccountedResponseData.totalNetAccountAmountAndCurrency
                          }
                          size="2.6"
                        />
                      </div>
                      <div className={styles.rightcount}>
                        {willAccountedResponseData.paymentListTotalCount +
                          " adet işlem"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </>
  );
};

export default DashboardSMPStandard;
