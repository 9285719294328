import Button from 'components/Global/Button'
import DownIcon from 'components/icons/DownIcon'
import UpIcon from 'components/icons/UpIcon'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styles from './resizeablecard.module.css'

const ResizeableCard = ({ showContent, collapsibleContent, setIsCollapsed }) => {

    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false)

    useEffect(() => {
        setIsCollapsed(isCollapsibleOpen)
    }, [isCollapsibleOpen])


    return (
        <div className={styles.container}>
            <div>
                {showContent}
            </div>
            {
                isCollapsibleOpen &&
                <div>
                    {collapsibleContent}
                </div>
            }
            <div style={{ height: "15px" }} />
            <div className={styles.button}>
                <Button height = '2.5rem' text={isCollapsibleOpen ? <UpIcon /> : <DownIcon />} backgroundImage="none" onClick={() => setIsCollapsibleOpen(e => !e)}></Button>
            </div>
        </div>
    )
}

export default ResizeableCard