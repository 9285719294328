import * as yup from 'yup';

const formValidator = yup.object().shape({

    firstName: yup.string().max(50).required("Lütfen Ad Soyad alanını doldurunuz.").min(5, 'Ad Soyad alanını  minimum 5 harf olmalıdır.'),
    // email: yup.string().email("Mail adresi geçersiz").required("Email adresi zorunludur."),
    // gsmNumber: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
    gsmNumber: yup.string().when('email', {
        is: (email) => !email || email.length === 0,
        then: yup.string().max(50).required("lütfen en az 1 iletişim bilgisi giriniz."),
        otherwise: yup.string()
    }),
    email: yup.string().email("Email geçerli bir formatta değil").when('gsmNumber', {
        is: (gsmNumber) => !gsmNumber || gsmNumber.length === 0,
        then: yup.string().max(50).required("lütfen en az 1 iletişim bilgisi giriniz."),
        otherwise: yup.string()
    }),
}, [['gsmNumber', 'email']])

export default formValidator;