// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dealerbmps_filtercontainer__HEidE{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/SubDealerManagement/Standard/dealerbmps.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".filtercontainer{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtercontainer": "dealerbmps_filtercontainer__HEidE"
};
export default ___CSS_LOADER_EXPORT___;
