// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdownitem_item__VFuBK {\n    display: flex;\n    align-items: center;\n    padding: 1.3vh 0 1.3vh 1.3vh;\n    cursor: pointer;\n    padding-left: 2.5vh;\n    border-bottom: solid 1px #bfc3de;\n    \n}\n\n.dropdownitem_separator__1Zkzf {\n    border: solid 1px #bfc3de;\n    border-left: 0px;\n    border-right: 0px;\n    border-top-width: 1px;\n    border-bottom-width: 1px;\n}\n\n.dropdownitem_clicked__ZcLmg {\n    background-image: linear-gradient(to right, #020e8a 0%, #6608b7 100%);\n    color: white;\n}\n\n.dropdownitem_first__LixYh {\n    border-top-left-radius: 1rem;\n}\n\n.dropdownitem_last__S4Hxj {\n    border-bottom-left-radius: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Dropdown/DropdownItem/dropdownitem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,eAAe;IACf,mBAAmB;IACnB,gCAAgC;;AAEpC;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,qEAAqE;IACrE,YAAY;AAChB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".item {\n    display: flex;\n    align-items: center;\n    padding: 1.3vh 0 1.3vh 1.3vh;\n    cursor: pointer;\n    padding-left: 2.5vh;\n    border-bottom: solid 1px #bfc3de;\n    \n}\n\n.separator {\n    border: solid 1px #bfc3de;\n    border-left: 0px;\n    border-right: 0px;\n    border-top-width: 1px;\n    border-bottom-width: 1px;\n}\n\n.clicked {\n    background-image: linear-gradient(to right, #020e8a 0%, #6608b7 100%);\n    color: white;\n}\n\n.first {\n    border-top-left-radius: 1rem;\n}\n\n.last {\n    border-bottom-left-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "dropdownitem_item__VFuBK",
	"separator": "dropdownitem_separator__1Zkzf",
	"clicked": "dropdownitem_clicked__ZcLmg",
	"first": "dropdownitem_first__LixYh",
	"last": "dropdownitem_last__S4Hxj"
};
export default ___CSS_LOADER_EXPORT___;
