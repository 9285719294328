// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pricelabel_pricetext__iWKs5{\n    font-family: 'Metropolis-Bold';\n}\n\n.pricelabel_aftercomma__VemPE{\n    font-family: 'Metropolis-Medium';\n    letter-spacing: 0.1rem;\n}\n.pricelabel_counttext__8hER9{\n    font-weight: 470;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/PriceLabel/pricelabel.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;IAChC,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".pricetext{\n    font-family: 'Metropolis-Bold';\n}\n\n.aftercomma{\n    font-family: 'Metropolis-Medium';\n    letter-spacing: 0.1rem;\n}\n.counttext{\n    font-weight: 470;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricetext": "pricelabel_pricetext__iWKs5",
	"aftercomma": "pricelabel_aftercomma__VemPE",
	"counttext": "pricelabel_counttext__8hER9"
};
export default ___CSS_LOADER_EXPORT___;
