import React from 'react';

const RefreshIcon = ({ color = "#000D89", size = 26 }) => {
    return <>
        <symbol id="refresh" >
            <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 25C6.373 25 1 19.521 1 12.762 1 9.126 2.562 5.87 5.032 3.63H2.921c-.594 0-1.075-.49-1.075-1.096v-.436c0-.605.481-1.096 1.075-1.096H8.9L8.913 1h.428c.297 0 .566.123.76.322.195.199.316.472.316.775v.436l-.001.01v6.1c0 .606-.482 1.097-1.075 1.097h-.428c-.594 0-1.075-.49-1.075-1.096v-3.85a9.64 9.64 0 0 0-4.24 8.015c0 5.31 4.222 9.616 9.43 9.616 5.208 0 9.43-4.305 9.43-9.616 0-4.142-2.572-7.662-6.173-9.016.025-.024.047-.051.072-.076a1.031 1.031 0 0 1-.403-.818v-.41c0-.568.452-1.03 1.01-1.03h.386c.006-.028.013-.057.018-.086.114.046.223.1.335.15a.977.977 0 0 1 .125.055C22.047 3.474 25 7.758 25 12.762 25 19.52 19.627 25 13 25z" fill={color} stroke={color} strokeWidth=".1" />
            </svg>

        </symbol>
    </>;
};

export default RefreshIcon;
