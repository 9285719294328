import SMPStandard from 'components/Dashboard/SmallMarketPlace/Standard'
import BMPDashboard from 'components/Dashboard/BigMarketPlace/Standard'
import DealerDashboard from 'components/Dashboard/BigMarketPlace/Dealer/Standard'
// import BMPJPDashboard from 'components/Dashboard/BigMarketPlace/JustPayment'
// import SMPJPDashboard from 'components/Dashboard/SmallMarketPlace/JustPayment/'
// import DealerJpDashboard from 'components/Dashboard/BigMarketPlace/Dealer/JustPayment'
// import SubDealerJPDashboard from 'components/Dashboard/BigMarketPlace/SubDealer/JustPayment'
import SubDealerStandardDashboard from 'components/Dashboard/BigMarketPlace/SubDealer/Standard'
import UnAuthorized from 'container/UnAuthorized/UnAuthorized';
import Layout from 'components/Layout/Layout';
import Cookies from 'universal-cookie';
import JustPayment from 'components/Dashboard/JustPayment';

export default function Dashboard(props) {
    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole

    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
        case "1":
            return (
                <Layout>
                    {console.log("Büyük Firma - Pazaryeri - Standart Kullanıcı ")}
                    <BMPDashboard />
                </Layout>
            )

        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    <JustPayment />
                </Layout>
            )

        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    <SMPStandard />
                </Layout>
            )

        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    <JustPayment />
                </Layout>
            )

        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    <DealerDashboard />
                </Layout>
            )

        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    <JustPayment />
                </Layout>
            )

        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    <SubDealerStandardDashboard />
                </Layout>
            )

        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    <JustPayment />
                </Layout>
            )

        default:
            return (
                <Layout>
                    <UnAuthorized />
                </Layout>
            )
    }
}
