// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customerresponsive_filter__BVKVM {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.customerresponsive_modalinside__Rsqhr {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    background-color: #f5f7f9;\r\n    height: 60%;\r\n    width: 90%;\r\n    padding: 20px 10% \r\n}\r\n\r\n.customerresponsive_modalinsideUpdate__HJuKN{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    background-color: #f5f7f9;\r\n    height: 40%;\r\n    width: 90%;\r\n    padding: 20px 10% \r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/Customer/customerresponsive.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,UAAU;IACV;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,UAAU;IACV;AACJ","sourcesContent":[".filter {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.modalinside {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    background-color: #f5f7f9;\r\n    height: 60%;\r\n    width: 90%;\r\n    padding: 20px 10% \r\n}\r\n\r\n.modalinsideUpdate{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    background-color: #f5f7f9;\r\n    height: 40%;\r\n    width: 90%;\r\n    padding: 20px 10% \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "customerresponsive_filter__BVKVM",
	"modalinside": "customerresponsive_modalinside__Rsqhr",
	"modalinsideUpdate": "customerresponsive_modalinsideUpdate__HJuKN"
};
export default ___CSS_LOADER_EXPORT___;
