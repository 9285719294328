// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile_forminfo__NBWHe{\n  display:flex;\n  width: 100%;\n  font-size: 1.7rem;\n  font-weight: bold;\n  color: #000d89;\n  padding: 1rem;\n}\n\n.profile_switcharea__0Yd73 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.profile_form__R6kpm {\n  width: 100%;\n  height: 100%;\n}\n\n.profile_inputgroup__1Ozmz{\n  display : flex;\n  flex-direction: column;\n  justify-content: space-around;\n  width: 100%;\n  height: 100%;\n  row-gap:10px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Forms/Profile/profile.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".forminfo{\n  display:flex;\n  width: 100%;\n  font-size: 1.7rem;\n  font-weight: bold;\n  color: #000d89;\n  padding: 1rem;\n}\n\n.switcharea {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.form {\n  width: 100%;\n  height: 100%;\n}\n\n.inputgroup{\n  display : flex;\n  flex-direction: column;\n  justify-content: space-around;\n  width: 100%;\n  height: 100%;\n  row-gap:10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forminfo": "profile_forminfo__NBWHe",
	"switcharea": "profile_switcharea__0Yd73",
	"form": "profile_form__R6kpm",
	"inputgroup": "profile_inputgroup__1Ozmz"
};
export default ___CSS_LOADER_EXPORT___;
