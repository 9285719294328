import Button from 'components/Global/Button'
import Cardboard from 'components/Global/Cardboard'
import InputSearch from 'components/Global/InputSearch'
import Label from 'components/Global/Label'
import List from 'components/Global/List'
import ListContainer from 'components/Global/ListContainer'
import SwitchButton from 'components/Global/SwitchButton'
import Table from 'components/Global/Table'
import UserInformationLabel from 'components/Global/UserInformationLabel'
import AddUserIcon from 'components/icons/AddUserIcon'
import EditIcon from 'components/icons/EditIcon'
import SuitcaseIcon from 'components/icons/SuitcaseIcon'
import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import ResponsiveFilterArea from '../FilterArea'
import ResponsiveProfileCollapse from '../global/List/ResponsiveProfileCollapse'
import styles from './profile.module.css'
import CommissionIcon from "components/icons/CommissionIcon";
import CreditCardIcon from 'components/icons/CreditCardIcon'
import BankList from 'components/Global/BankList'
import BannerArea from 'components/Global/BannerArea'
import Container from 'components/Global/Container'
import UserAdd from 'components/Global/Forms/Profile/UserAdd'
import ResponsiveModal from '../Modal'
import ProfileEdit from 'components/Global/Forms/Profile/ProfileEdit'
import UserEdit from 'components/Global/Forms/Profile/UserEdit'
import { useEffect } from 'react'
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions'
import ResizeableCard from '../global/ResizeableCard'
import CommissionRate from '../global/CommissionRate'
import { useRef } from 'react'
import PobUp from 'components/Global/PopUp/PobUp'

const ProfileResponsive = ({ setPageType, pageType, currentItems, handleSearchChange, userProfileData, getCompanyInformationData,
  getAllUserDataFromApi, getProfilDataFromApi, selectId, handleProfileEditButtonClick,
  setSelectId, handleUserDeleteButtonClick }) => {

  const cookies = new Cookies();
  var userRole = cookies.get('roleNumber');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(true)
  const [modalClick, setModalClick] = useState(false)
  const [profileEdit, setProfileEdit] = useState(false)
  const [userEdit, setUserEdit] = useState(false)
  const [userEditData, setUserEditData] = useState([])
  const [popUpClick, setPopUpClick] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const [userName, setUserName] = useState("")
  const [currentRef, setCurrentRef] = useState()
  const refs = useRef([])

  const installmentsAndCommissions = [
    { text: "Peşin", rate: getCompanyInformationData?.comissionList?.commissionRate },
    { text: 2, rate: getCompanyInformationData?.comissionList?.commissionRate2 },
    { text: 3, rate: getCompanyInformationData?.comissionList?.commissionRate3 },
    { text: 4, rate: getCompanyInformationData?.comissionList?.commissionRate4 },
    { text: 5, rate: getCompanyInformationData?.comissionList?.commissionRate5 },
    { text: 6, rate: getCompanyInformationData?.comissionList?.commissionRate6 },
    { text: 7, rate: getCompanyInformationData?.comissionList?.commissionRate7 },
    { text: 8, rate: getCompanyInformationData?.comissionList?.commissionRate8 },
    { text: 9, rate: getCompanyInformationData?.comissionList?.commissionRate9 },
    { text: 10, rate: getCompanyInformationData?.comissionList?.commissionRate10 },
    { text: 11, rate: getCompanyInformationData?.comissionList?.commissionRate11 },
    { text: 12, rate: getCompanyInformationData?.comissionList?.commissionRate12 },

  ];

  useEffect(() => {
    currentItems?.map((item) => item.filter((row) => selectId == row?.details?.userId)).map((index) => checkNullOrEmptyArray(index) && setUserEditData(index[0]?.details))
  }, [selectId])

  useEffect(() => {
    if (checkNullOrEmpty(deleteId)) {
      deleteSelector(deleteId)
      setIsActive(false)
    }
    if (popUpClick)
      setPopUpClick(false)
  }, [popUpClick])

  const setPhoneNumberFormat = (phoneNumber,areaCode=false) => {
    return (
      (areaCode ? ("0 (") : ("+90 (")) +
      phoneNumber.slice(0, 3) +
      ") " +
      phoneNumber.slice(3, 6) +
      " " +
      phoneNumber.slice(6, 8) +
      " " +
      phoneNumber.slice(8, 10)
    );
  };

  const handleUserAddClick = (id) => {
    setIsOpen(false)
    setProfileEdit(false)
    setUserEdit(false)
  }

  const pull_data = (userId) => {
    return currentItems.find(item => item?.details?.userId == userId);
  }

  const handleProfileEditClick = (id) => {
    handleProfileEditButtonClick(userProfileData);
    setIsOpen(false)
    setProfileEdit(true)
    setUserEdit(false)
  }

  const handleUserEditButtonClick = (id) => {
    setSelectId(id)
    setIsOpen(false)
    setProfileEdit(false)
    setUserEdit(true)
  }

  const handleUserDeleteClick = (id) => {
    handleUserDeleteButtonClick(id)
  }



  const deleteSelector = (id, listId) => {
    const ref = refs.current[listId]
    currentItems?.map((item) => item.filter((row) => id == row?.details?.userId)).map((index) => checkNullOrEmptyArray(index) && setUserName(index[0]?.details?.userFullName))
    setCurrentRef(ref)
    setDeleteId(id)
    setIsActive(true)
    if (popUpClick) {
      handleUserDeleteClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
    }
  }

  const handlePopUp = (status) => {
    setPopUpClick(status)
    setIsActive(false)
  }

  const handleModalClick = (status) => {
    setModalClick(status);
    setIsOpen(true);
    setProfileEdit(false)
    if (status == false) {
      getAllUserDataFromApi();
      getProfilDataFromApi();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filtercontainer} style={{ height: pageType === 1 ? "119px" : "50px" }}>
        <div className={styles.switchbutton}>
          <SwitchButton
            callback={setPageType}
            style={{ height: "2.8rem", width: "100%" }}
            items={[
              { id: 1, text: "Kullanıcı Bilgiler" },
              { id: 2, text: "Firma Bilgileri" },
            ]}
            selectedValue={pageType}
          />
        </div>
        {pageType === 1 ? <ResponsiveFilterArea firstElement={
          <div className={styles.filter}>
            <InputSearch width="100%" placeholder={"Kullanıcı İsmi"} onChange={handleSearchChange} />
            <div style={{ width: "10px" }} />
            <Button icon={<AddUserIcon width={"24px"} height={"16px"} fill={"#000d89"} />} backgroundImage="none" width={"50px"} height={"50px"} onClick={handleUserAddClick} />
          </div>
        } isButtonActive={false} /> : ""}
      </div>

      <ResponsiveModal
        clickEvent={modalClick}
        isClosed={isOpen}
        background={'#f5f7f9'}
        body={
          profileEdit ? (
            <div className={styles.modal}>
              <ProfileEdit
                width="92%"
                height="50%"
                userProfileData={userProfileData}
                handleModalClick={handleModalClick}
              />
            </div>
          ) : userEdit ? (
            <UserEdit width="92%" height="30%" userEditData={userEditData} selectId={selectId} handleModalClick={handleModalClick} />
          ) : (
            <div className={styles.modal}><UserAdd width="92%" height="50%" handleModalClick={handleModalClick} /></div>
          )
        }
        callbackEvent={handleModalClick}

      />

      {pageType === 1 ? (
        <>
          <Cardboard style={{ margin: "15px", flex: 0 }}>
            <div className={styles.infoarea}>
              <div className={styles.userinfo}>
                <UserInformationLabel
                  color="#000d89"
                  fontFamily="Metropolis-Bold"
                  text={userProfileData?.userFullName}
                />
                <div className={styles.normallabel}>
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={
                      userProfileData?.phoneNumber === undefined
                        ? ""
                        : setPhoneNumberFormat(userProfileData.phoneNumber)
                    }
                  />
                </div>
                <div className={styles.normallabel}>
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.email}
                  />
                </div>
                <div className={styles.normallabel}>
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.userFullName}
                  />
                </div>
                <div className={styles.normallabel}>
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={"*********"}
                  />
                </div>
              </div>
              <Button
                width="50px"
                onClick={handleProfileEditClick}
                icon={<EditIcon color="#fff" />}
              />
            </div>
          </Cardboard>
          <Cardboard style={{ margin: "15px", flex: 1 }}>
            <ListContainer>
              <List
                collapsed
                CollapseComponent={<ResponsiveProfileCollapse deleteSelector={deleteSelector} currentRef={currentRef} userName={userName} isActive={isActive} handlePopUp={handlePopUp} popUpClick={popUpClick} handleUserDeleteClick={handleUserDeleteClick} handleUserEditButtonClick={handleUserEditButtonClick} func={pull_data} />}
                columnNames={["KULLANICI İSMİ", "TELEFON", "", "", ""]}
                data={currentItems}
                ref={refs}
              />
            </ListContainer>
              <PobUp
                callbackButtonEvent={handlePopUp}
                clickEvent={popUpClick}
                content="kullanıcınızı silmek istediğinize emin misiniz ?"
                isCustomer={true}
                isActive={isActive}
                ref={currentRef}
                headerName={userName}
              />
          </Cardboard>
        </>
      ) :
        (<>
          <Container minHeight="100%" marginTop="20px" columnGap="20px">
            <div className={styles.companyinformation}>
              <ResizeableCard showContent={
                !isCollapsed && //1de oluşacak
                <Table
                  minWidth="170px"
                  fontSize=".7rem"
                  data={[
                    ((userRole == "1" || userRole == "2" || userRole == "3") ?
                      (
                        {
                          name: "Firma",
                          value: getCompanyInformationData.companyName,
                        }) :
                      (
                        {
                          name: "Ana Firma",
                          value: getCompanyInformationData.marketPlace,
                        })),
                    ((userRole == "1" || userRole == "2" || userRole == "3") ?
                      (
                        {
                          name: "Firma Tipi",
                          value: getCompanyInformationData.companyType,
                        }) :
                      (
                        {
                          name: "Bayi",
                          value: getCompanyInformationData.dealerName,
                        })),
                    ((userRole == "4" || userRole == "7" || userRole == "8") &&
                    {
                      name: "Alt Bayi",
                      value: getCompanyInformationData.subDealerName,
                    }),
                    {
                      name: "Yetkili Kişi",
                      value: getCompanyInformationData.contactName,
                    },
                  ]}
                />
              }
                setIsCollapsed={setIsCollapsed}
                collapsibleContent={
                  <Table
                    minWidth="170px"
                    fontSize=".7rem"
                    data={[
                      ((userRole == "1" || userRole == "2" || userRole == "3") ?
                        (
                          {
                            name: "Firma",
                            value: getCompanyInformationData.companyName,
                          }) :
                        (
                          {
                            name: "Ana Firma",
                            value: getCompanyInformationData.marketPlace,
                          })),
                      ((userRole == "1" || userRole == "2" || userRole == "3") ?
                        (
                          {
                            name: "Firma Tipi",
                            value: getCompanyInformationData.companyType,
                          }) :
                        (
                          {
                            name: "Bayi",
                            value: getCompanyInformationData.dealerName,
                          })),

                      ((userRole == "7" || userRole == "8" || userRole == "4") &&
                      {
                        name: "Alt Bayi",
                        value: getCompanyInformationData.subDealerName,
                      }),
                      {
                        name: "Yetkili Kişi",
                        value: getCompanyInformationData.contactName,
                      },
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "Vergi Dairesi", value: "Vergi Dairesi" }), //muallak olup olmaması
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                      {
                        name: "Vergi No",
                        value: getCompanyInformationData.taxNumber,
                      }),
                      { name: "Adres", value: getCompanyInformationData.address },
                      {
                        name: "İl / İlçe",
                        value:
                          getCompanyInformationData.cityName +
                          " / " +
                          getCompanyInformationData.districtName,
                      },
                      { name: "Telefon", value: getCompanyInformationData.phone },
                      { name: "E - posta", value: getCompanyInformationData.email },
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "Müşteri Key", value: "Müşteri Key" }), //ERP ile gelicek
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "IBAN", value: getCompanyInformationData.iban }),
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "ERP Bayi Kodu", value: "0" }),
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "ERP Kullanıcı Adı", value: "0" }),
                      ((userRole == "1" || userRole == "2" || userRole == "3") &&
                        { name: "ERP Şifre", value: "0" })
                    ]}
                  />
                } />

              <div className={styles.undertable}>
                <div className={styles.uprow}><SuitcaseIcon text="Çalışma Koşulları" width="15px" justifyContent="flex-start" alignItems="center" color="#000d89" fontSize="14px" fontWeight="bold" /></div>
                <div className={styles.underrow}>
                  <Label
                    bold
                    fontSize="14px"
                    text={
                      getCompanyInformationData.bankOfTerm +
                      " gün vadeli çalışmaktasınız."
                    }
                  />
                </div>
                <div className={styles.uprowsecond}><CommissionIcon text="Komisyon Oranları" width="15px" justifyContent="flex-start" alignItems="center" color="#000d89" fontSize="14px" fontWeight="bold" /></div>
                <table width={"100%"} border={"0"} cellPadding={"2"} cellSpacing={"0"}></table>
                <div className={styles.underrowsecond}>
                  <CommissionRate items={installmentsAndCommissions} />
                </div>
                <div className={styles.uprow}><CreditCardIcon text="Geçerli Kartlar" width="15px" justifyContent="flex-start" alignItems="center" color="#000d89" fontSize="14px" fontWeight="bold" /></div>
                <div className={styles.underrow}><BankList style={{ width: "100%" }} /></div>
              </div>

              <div className={styles.bannerarea}>
                <BannerArea style={{ backgroundColor: "gray" }} />
              </div>

            </div>
          </Container>
        </>)
      }
    </div >
  )
}

export default ProfileResponsive