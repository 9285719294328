import React from 'react';

const SuitcaseIcon = ({ size = "20", text, width, color, fontSize, fontWeight,alignItems,justifyContent= "space-around" }) => {
    return <div style={{ display: "flex", flexDirection: "row", justifyContent : justifyContent,alignItems:alignItems, color:color, fontSize:fontSize, fontWeight:fontWeight }}>
        <symbol id="search">

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M15.158 3.814h-1.642a2.47 2.47 0 0 0-2.446-2.148H8.92a2.47 2.47 0 0 0-2.446 2.148H4.84a3.175 3.175 0 0 0-3.175 3.168v2.874c0 .223.118.428.31.54 2.058 1.205 4.664 1.908 7.394 2v1.501a.625.625 0 0 0 1.25 0v-1.5c2.733-.095 5.338-.797 7.395-2.002a.625.625 0 0 0 .31-.539V6.99a3.174 3.174 0 0 0-3.167-3.176zm-4.088-.898c.56 0 1.034.381 1.175.898h-4.5c.14-.517.614-.898 1.175-.898h2.15zm-1.079 8.241c-2.598 0-5.095-.59-7.075-1.665v-2.51c0-1.058.863-1.918 1.925-1.918h10.317c1.057 0 1.916.864 1.916 1.926v2.502c-1.98 1.076-4.48 1.665-7.083 1.665zm-4.83 6.959h9.667a3.34 3.34 0 0 0 3.316-3.076l.158-2.09a.625.625 0 0 0-.575-.671.638.638 0 0 0-.671.576l-.159 2.09a2.085 2.085 0 0 1-2.069 1.921H5.162a2.086 2.086 0 0 1-2.07-1.92l-.157-2.09a.634.634 0 0 0-.67-.578.626.626 0 0 0-.578.671l.159 2.091a3.34 3.34 0 0 0 3.316 3.076z" fill="#4F5386" />
            </svg>

        </symbol>
        <div style={{width:width}}/>
        {text}
    </div>
};

export default SuitcaseIcon;
