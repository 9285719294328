import Cookies from "universal-cookie";
import axios from 'axios';
import { BASE_URL } from 'environment';
import { getUser } from 'store/user/userService'

const SignInOidc = () => {
    getUser().then((user) => {
        axios.post(BASE_URL + "/user/login", {
            email: user.profile.email
        }, {
            headers: {
                'Authorization': `Bearer ${user.access_token}`
            }
        }).then(response => {
            const cookies = new Cookies();
            if (response.data) {
                if (user.access_token.length > 0) {
                    cookies.set("IdentityToken", user.access_token)
                }
                else {
                    window.location.href = '/SignOut';
                }
                //statu kodu 401 hatalı giriş
                if (response.status === 401) {
                    console.log("E-Posta veya şifreniz hatalıdır.");
                    cookies.remove("IdentityToken");
                    cookies.remove("Auth");
                    cookies.remove("roleNumber");
                    window.location.href = '/SignOut';
                }
                else if (response.status === 500) {
                    cookies.remove("IdentityToken");
                    cookies.remove("Auth");
                    cookies.remove("roleNumber");
                    console.log("E-Posta veya şifreniz hatalıdır.");
                    window.location.href = '/SignOut';
                }
                else if (response.status === 501) {
                    cookies.remove("IdentityToken");
                    cookies.remove("Auth");
                    cookies.remove("roleNumber");
                    console.log("E-Posta veya şifreniz hatalıdır.");
                    window.location.href = '/SignOut';
                }
                else if (response.data.data.isAdmin == true) {
                    console.log("Lütfen Admin Panelinden Giriş Yapınız")
                    window.location.href = '/SignOut';
                }
                else if (response.status === 200) {
                    // console.log("Oturum açma başarılı.", response);

                    var date = new Date();
                    date.setHours(date.getHours() + 1);;
                    console.log("data", response.data)

                    //Authories
                    //Yetkiler array türünde geliyor, bunları json a çevirip cookie lere kaydediyoruz
                    // response.data.data.userAuthMenuList.unshift({ 'roleId': response.data.data.roleNumber });
                    var menuAuthSerilize = JSON.stringify(response.data.data.userAuthMenuList);
                    var roleNumber = JSON.stringify(response.data.data.roleNumber);
                    var bankOfTerm = JSON.stringify(response.data.data.bankOfTerm);
                    var isHaveErp = JSON.stringify(response.data.data.isHaveErp);
                    var userCommissionList = JSON.stringify(response.data.data.userCommissionList);

                    // console.log("userCommissionList",response.data.data.userCommissionList,response.data.data.bankOfTerm);

                    //Kullanıcını tam adı
                    cookies.set("fullName", response.data.data.fullName, { expires: date });

                    //Banka vade günü
                    cookies.set("bankOfTerm", bankOfTerm, { expires: date });

                    //ERP var mı?
                    cookies.set("isHaveErp", isHaveErp, { expires: date });

                    //Taksit sayısındaki komisyon oranları
                    cookies.set("userCommissionList", userCommissionList, { expires: date });

                    //Rol numarası
                    cookies.set("roleNumber", roleNumber, { expires: date });

                    //Yetkisi bulunduğu Menüler
                    cookies.set("Auth", menuAuthSerilize, { expires: date });

                    // navigate('../', { replace: true });
                    window.location.href = '/';
                }
            }
            else {
                cookies.remove("IdentityToken");
                cookies.remove("Auth");
                cookies.remove("roleNumber");
                window.location.href = '/SignOut';
            }
        })
    });
}

export default SignInOidc