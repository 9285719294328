import React from "react";
import styles from "./pricelabel.module.css";
import { useLocation } from "react-router-dom";
import { checkNullOrEmpty } from "hoc/Conditions";

const PriceLabel = ({ color, price = '00,00', money, size, count, margin, countTextMargin }) => {
  const location = useLocation();

  var color = !checkNullOrEmpty(color) ? "#000d89" : color;
  var price = !checkNullOrEmpty(price) ? "0" : price?.toString();
  var money = !checkNullOrEmpty(money) ? "TL" : money;
  var size = !checkNullOrEmpty(size) ? 2.6 : size;
  var count = !checkNullOrEmpty(count) ? "0" : count;
  var afterComma = size / 3 + "rem";
  size = size + "rem";

  price = price.split(",");

  var bold = bold ? "font-family:'Metropolis-Bold'" : ""
  return (
    <>
      <div style={{ color, fontSize: size, margin:margin }} className={styles.pricetext}>
        {price[0]}
        <span style={{ fontSize: afterComma }} className={styles.aftercomma}>
          ,
          {price[1]}
          {money}
        </span>
      </div>
      {location.pathname === "/transactionReports" ? (
        <div style={{ margin:countTextMargin }} className={styles.counttext}>
          {count}
          {" adet işlem"}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PriceLabel;
