// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".autocompleteitem_listitem__5LbL-{\n    display : flex;\n    width: 100%;\n    margin-bottom: 5px;\n    border-radius: 10px;\n    border: solid 1px #bfc3de;\n    max-height: 4rem;\n    min-height: 3rem;\n}\n\n.autocompleteitem_listitem__5LbL-:hover{\n    background-color: rgb(219, 219, 219);\n    cursor: pointer;\n    color: white !important;\n}\n\n.autocompleteitem_itemicon__fm5Of{\n display: flex;\n align-items:center;\n justify-content:center;\n flex-basis: 10%;\n background-color: #f5f7f9;\n margin:5px;\n border-radius: 10px;\n}\n\n.autocompleteitem_itemlabel__0GSTH{\n    display: flex;\n    align-items:center;\n    flex-basis: 90%;\n    color: #4f5386;\n    font-size: 1rem;\n    font-weight: lighter;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/AutoComplete/CompleteItem/autocompleteitem.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,uBAAuB;AAC3B;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,sBAAsB;CACtB,eAAe;CACf,yBAAyB;CACzB,UAAU;CACV,mBAAmB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".listitem{\n    display : flex;\n    width: 100%;\n    margin-bottom: 5px;\n    border-radius: 10px;\n    border: solid 1px #bfc3de;\n    max-height: 4rem;\n    min-height: 3rem;\n}\n\n.listitem:hover{\n    background-color: rgb(219, 219, 219);\n    cursor: pointer;\n    color: white !important;\n}\n\n.itemicon{\n display: flex;\n align-items:center;\n justify-content:center;\n flex-basis: 10%;\n background-color: #f5f7f9;\n margin:5px;\n border-radius: 10px;\n}\n\n.itemlabel{\n    display: flex;\n    align-items:center;\n    flex-basis: 90%;\n    color: #4f5386;\n    font-size: 1rem;\n    font-weight: lighter;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listitem": "autocompleteitem_listitem__5LbL-",
	"itemicon": "autocompleteitem_itemicon__fm5Of",
	"itemlabel": "autocompleteitem_itemlabel__0GSTH"
};
export default ___CSS_LOADER_EXPORT___;
