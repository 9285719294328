import React from 'react'
import styles from './tablepricelabel.module.css'

const TablePriceLabel = ({ price = '00,00' }) => {

    const splittedPrice = price.split(",")

    return (
        <div className={styles.tableprice}>
            <span className={styles.beforecomma}>{splittedPrice[0]}</span>
            ,
            <span>{splittedPrice[1]}</span>
            TL
        </div>
    )
}

export default TablePriceLabel