import Box from "@mui/system/Box"

const modalStyle = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100%",
    bgcolor: '#ffffffc2',
    p: 4,
    zIndex: "15",
    textAlign: "center"
}

const CustomLoadingBox = ({ isLoading }) => {
    return (
        <>
            {
                isLoading && (
                    <Box sx={modalStyle}>
                        <img
                            alt="loading "
                            src="./loading.gif"
                            style={{ margin: "10pc 0", width: "200px", height: "200px" }}
                        />
                    </Box>
                )
            }
        </>

    )
}

export default CustomLoadingBox
