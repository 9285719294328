// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slidefilter_text__R9XSL {\r\n    background-color: #f5f7f9;\r\n}\r\n\r\n.slidefilter_slick-list__1qi4v {\r\n    width: 100% !important;\r\n}\r\n\r\n\r\n.slidefilter_scrollablehorizontalitems__alVwf {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    overflow-x: scroll;\r\n    overflow-y: hidden;\r\n    width: 100%;\r\n    padding: 20px 5px;\r\n}\r\n\r\n.slidefilter_scrollablehorizontalitems__alVwf::-webkit-scrollbar {\r\n    background-color: transparent;\r\n    height: 10px;\r\n}\r\n\r\n.slidefilter_scrollablehorizontalitems__alVwf::-webkit-scrollbar-thumb {\r\n    display: none;\r\n}\r\n\r\n.slidefilter_hidden__JoBYZ {\r\n    display: none;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Responsive/global/SlideFilter/slidefilter.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".text {\r\n    background-color: #f5f7f9;\r\n}\r\n\r\n.slick-list {\r\n    width: 100% !important;\r\n}\r\n\r\n\r\n.scrollablehorizontalitems {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    overflow-x: scroll;\r\n    overflow-y: hidden;\r\n    width: 100%;\r\n    padding: 20px 5px;\r\n}\r\n\r\n.scrollablehorizontalitems::-webkit-scrollbar {\r\n    background-color: transparent;\r\n    height: 10px;\r\n}\r\n\r\n.scrollablehorizontalitems::-webkit-scrollbar-thumb {\r\n    display: none;\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "slidefilter_text__R9XSL",
	"slick-list": "slidefilter_slick-list__1qi4v",
	"scrollablehorizontalitems": "slidefilter_scrollablehorizontalitems__alVwf",
	"hidden": "slidefilter_hidden__JoBYZ"
};
export default ___CSS_LOADER_EXPORT___;
