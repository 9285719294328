import React from 'react'
import styles from "./responsivesupportcollapse.module.css";

const ResponsiveSupportCollapse = ({ item }) => {
  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };

  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };

  return (
    <div className={styles.collapsecontainer}>

      <div className={styles.dates}>
        <div className={styles.headerandvalue}>
          <div className={styles.header}>Destek saati ve tarihi</div>
          <div className={styles.value}>
            <div className={styles.date}>{setTimeFormat(item?.details?.supportDate)}</div>
            <div className={styles.date}>{setDateFormat(item?.details?.supportDate)}</div>
          </div>
        </div>

        <div className={styles.headerandvalue}>
          <div className={styles.header}>Cevaplanma saati ve tarihi</div>
          <div className={styles.value}>
            {item.replyDate === "0001-01-01T00:00:00" ? (
              "-"
            ) : (
              <>
                <div className={styles.date}>{setTimeFormat(item?.details?.replyDate)}</div>
                <div className={styles.date}>{setDateFormat(item?.details?.replyDate)}</div>
              </>
            )}
          </div>
        </div>

      </div>

      <div className={styles.description}>
        <div className={styles.headerandvalue}>
          <div className={styles.header}>Destek Talep Açıklaması</div>
          <div className={styles.date}>
            {item?.details?.details?.description}
          </div>
        </div>

        <div className={styles.headerandvalue}>
          <div className={styles.header}>Cevap Açıklaması</div>
          <div className={styles.date}>
            {item?.details?.details?.answerDescription}
          </div>
        </div>

      </div>

    </div>
  );
};

export default ResponsiveSupportCollapse