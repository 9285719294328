// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label_label__I7UtR{\n    color: #4f5386;\n    font-size: .9rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Label/label.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".label{\n    color: #4f5386;\n    font-size: .9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "label_label__I7UtR"
};
export default ___CSS_LOADER_EXPORT___;
