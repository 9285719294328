// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".datelabel_datelabel__a3ZR1{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.datelabel_time__O5eR2{\nfont-family: 'Metropolis-Bold';\nfont-size:.9rem;\ncolor: \n#4f5386;\n}\n\n.datelabel_date__71PSi{\n    font-family: 'Metropolis';\n    font-size:.9rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/DateLabel/datelabel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;AACA,8BAA8B;AAC9B,eAAe;AACf;OACO;AACP;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".datelabel{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.time{\nfont-family: 'Metropolis-Bold';\nfont-size:.9rem;\ncolor: \n#4f5386;\n}\n\n.date{\n    font-family: 'Metropolis';\n    font-size:.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datelabel": "datelabel_datelabel__a3ZR1",
	"time": "datelabel_time__O5eR2",
	"date": "datelabel_date__71PSi"
};
export default ___CSS_LOADER_EXPORT___;
