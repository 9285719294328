import Button from "components/Global/Button";
import Cardboard from "components/Global/Cardboard";
import Modal from "components/Global/Modal";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { read, utils, writeFile } from 'xlsx';
import { checkNullOrEmpty } from "./Conditions";

const HomeComponent = ({ func }) => {
    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber')
    const [rowData, setRowData] = useState([]);
    const [fileSelected, setFileSelected] = useState(false)
    const [mainData, setMainData] = useState()
    const [selectedFileName, setSelectedFileName] = useState("")
    const [modalClick, setModalClick] = useState(false)


    useEffect(() => {
        if (rowData.length > 0) {
            setFileSelected(true)
            setMainData(func(rowData))
        }
    }, [rowData])



    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                setSelectedFileName(file.name);
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setRowData(rows)
                }
            }

            reader.readAsArrayBuffer(file);
        }
    }

    const handleModalClick = (status) => {
        setModalClick(status);

    };

    const handleExport = () => {
        setModalClick(true)


        const headings = [[
            // 'Alt Bayi Tipi (Zorunlu - 1 :Gerçek Kişi(Vergi Levhası olmayan), 2 :Tüzel Kişi (Ltd.A.Ş), 3: Tüzel Kişi (Şahıs Firması) (Zorunlu))',
            // 'Vergi Numarası (Tüzel kişi için zorunlu)',
            // 'Yetkili Ad Soyad (Tüzel kişi için zorunlu)',
            // 'Firma Adı (Tüzel kişi için zorunlu)',
            // (userRole == "5" && 'Alt Bayi Adı (Zorunlu)'),
            // 'Telefon (Zorunlu)',
            // 'E-Posta (Zorunlu)',
            // 'Limit(Opsiyonel)',
            // 'ERP numarası (Tüzel kişi için opsiyonel)',
            // 'Şehir (Zorunlu)',
            // 'İlçe (Zorunlu)',
            // 'Adres (Zorunlu)',
            // 'T.C. Kimlik Numarası (Gerçek kişi için zorunlu)',
            // 'Ad Soyad (Gerçek kişi için zorunlu)',

            'Bayinin_Tipi',
            'Vergi_Numarasi',
            'Ad_Soyad',
            'Firma_Adi',
            'Telefon_Numarasi',
            'Email',
            'Limit',
            'Erp_Numarasi',
            'Sehir',
            'Ilce',
            'Adres',
            'TC_Numarasi',
            'Yetkili_Ad_Soyad',
            'Bayi_Kodu',
            (userRole == "5" ? 'AltBayi_Adi' : "Bayi_Adi"),
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, rowData, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Excel Şablonu.xlsx');
    }
    const ModalContent = () => {

        return <div>
            {
                <div>
                    <h4>{userRole == "1" ? "Bayi Tipi" : "Alt Bayi Tipi"} (Hepsi için zorunlu - 1 :Gerçek Kişi(Vergi Levhası olmayan), 2 :Tüzel Kişi (Ltd.A.Ş), 3: Tüzel Kişi (Şahıs Firması) (Zorunlu)</h4>
                    <h4>Vergi Numarası (Sadece tüzel kişiler için zorunlu)"</h4>
                    <h4>Yetkili Ad Soyad (Sadece tüzel kişiler için zorunlu)     </h4>
                    <h4>Firma Adı (Sadece tüzel kişiler için zorunlu)</h4>
                    <h4>{userRole == "1" && "Bayi Adı (Hepsi için zorunlu)"}</h4>
                    <h4>{userRole == "5" && "Alt Bayi Adı (Hepsi için zorunlu)"}</h4>
                    <h4> Telefon(Hepsi için zorunlu)</h4>
                    <h4>E-Posta (Hepsi için zorunlu)</h4>
                    <h4>Limit(Opsiyonel)</h4>
                    <h4>ERP numarası (Sadece tüzel kişiler için opsiyonel)</h4>
                    <h4>Şehir (Hepsi için zorunlu)</h4>
                    <h4>İlçe (Hepsi için zorunlu)</h4>
                    <h4>Adres (Hepsi için zorunlu)</h4>
                    <h4>T.C. Kimlik Numarası (Sadece gerçek kişi için zorunlu)</h4>
                    <h4>Ad Soyad (Sadece gerçek kişi için zorunlu)</h4>
                </div>
            }
        </div>

    }

    return (
        <>
            <div className="row" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Button onClick={handleExport} width="170px" text="Excel Şablonu İndir" height="3rem" />
                <div style={{ width: "21px" }} />
                <Modal
                    clickEvent={modalClick}
                    callbackEvent={handleModalClick}
                    body={<ModalContent></ModalContent>}
                />
                <div
                    style={{
                        display: "flex",
                        height: "1.5rem",
                        alignItems: "center",
                        border: "none",
                        color: "white",
                        fontSize: "15px",
                        borderRadius: "10px",
                        background: "linear-gradient(to right, #000d89 0%, #6608b7 100%)",
                        cursor: "pointer",
                        padding: "0.8rem",
                        fontFamily: "-apple-system, BlinkMacSystemFont, 'Metropolis', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
                    }}
                >
                    <label style={{
                        display: "inline-block",
                        padding: "6px 12px",
                        cursor: "pointer",
                    }}>
                        <input
                            style={{ display: "none", }}
                            type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        Dosya Seç
                    </label>

                </div>
                <div style={{ width: "21px" }} />
                {fileSelected && <p><b>{selectedFileName}</b>  dosyası seçildi!</p>}
            </div>
        </>

    );
};

export default HomeComponent;
