import { useState, useEffect } from "react";
import styles from "./willaccountedbmps.module.css";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import Cardboard from "components/Global/Cardboard";
import SwitchButton from "components/Global/SwitchButton";
import PriceLabel from "components/Global/PriceLabel";
import ListContainer from "components/Global/ListContainer";
import api from "store/api/actions";
import { Link } from "react-router-dom";
import InfoArea from "components/Global/InfoArea";
import List from "components/Global/List";
import Pagination from "components/Global/Pagination";
import RightIcon from "components/icons/RightIcon";
import Dropdown from "components/Global/Dropdown";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import ExportCSV from "hoc/ExportCSV";
import Label from "components/Global/Label";
import TablePriceLabel from "components/Global/TablePriceLabel";
import { useLocation } from "react-router";
import DateTimePicker from "components/Global/DateTimePickerv2";
import Cookies from "universal-cookie";
import Information from "components/Global/Info/Information";
import { useMediaQuery } from 'react-responsive'
import AccountedForAndWillAccountedResponsive from "components/Responsive/AccountedForAndWillAccounted";
import ExportExcel from "hoc/ExportExcel";

const initialPaymentsResponseState = {
  totalNetAccountAmountAndCurrency: "", //net hesaba geçen tutar
  totalCancellationAndRefundAmountAndCurrency: "", //iptal ve iade
  totalSuccessTransactionAmountAndCurrency: "", //başarılı işlem tutarı
  totalComissionAmountAndCurrency: "", //Komisyon
  paymentList: [],
  paymentListTotalCount: "",
};

const initialPaymentListDataState = {
  transactionDateOrDealerName: "",
  netAccountAmountAndCurrency: 0, //net hesaba geçen
  successTransactionAmountAndCurrency: 0, // net işlem tutarı
  cancellationAndRefundAmountAndCurrency: 0, // iptal tutarı ve iade tutarı
  comissionAmountAndCurrency: 0,
};

const initialPaymentsRequestState = {
  startTransactionDate: "",
  endTransactionDate: "",
  dealerList: [],
  userList: [],
  cityCodeList: [],
  listingTypeId: 0,
  limit: 0,
  orderBy: 0,
  pageNo: 0,
};

const initialDealerState = {
  pobDealerId: 0,
  pobDealerName: "",
  cityCode: 0,
};

const initialCityState = {
  cityCode: 0,
  cityName: "",
};

const WillAccountedBMP = () => {
  const { state } = useLocation(); // dashboard tarih filtresi(01.03.2022)
  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState(
    initialPaymentsResponseState
  );
  const [dealersData, setDealersData] = useState([initialDealerState]);
  const [citiesData, setCitiesData] = useState([initialCityState]);
  const [dealerFilter, setDealerFilter] = useState("");
  const [cityFilter, setCityFilter] = useState(0);
  const [searchData, setSearchData] = useState();

  const [pageType, setPageType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("Hesaba Geçecekler");
  const [exportData, setExportData] = useState([]);
  const [termDay, setTermDay] = useState()
  //date range
  const [calendarValue, setCalendarValue] = useState();
  const [dateValue, setDateValue] = useState([null, null]);
  const [selectedDateRange, setSelectedDateRange] = useState(
    "01.01.2021 - 01.08.2021"
  );

  const [allData, setAllData] = useState([])
  const [allSearchData, setAllSearchData] = useState({ limit: -1 });

  //#region Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]])
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  useEffect(() => {
    api.BigMarketPlace().WillAccountedPaymentList(allSearchData)
      .then((response) => {
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setAllData(response.data.data.paymentList);
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });

    getPaymentDataFromApi();

    //Get Dealer
    api
      .Helpers()
      .GetAllDealerForFilter({})
      .then((response) => {
        //console.log("response dealer", response.data.data);
        setDealersData(
          response.data.data.map((item) => ({
            id: item.pobDealerId,
            text: item.pobDealerName,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });

    //Get City
    api
      .Helpers()
      .GetAllCity({})
      .then((response) => {
        //console.log("response city", response.data.data);
        setCitiesData(
          response.data.data.map((item) => ({
            id: item.cityCode,
            text: item.cityName,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });

    const cookies = new Cookies();
    setTermDay(cookies.get("bankOfTerm")) //Get userRole
    // console.log('cookiegetinCustomer', userRole);

  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getPaymentDataFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    if (checkNullOrEmpty(dealerFilter) && dealerFilter !== 0) {
      setSearchData({ ...searchData, dealerList: [dealerFilter] });
    } else {
      setSearchData({ ...searchData, dealerList: [] })
    }
  }, [dealerFilter]);

  useEffect(() => {
    if (checkNullOrEmpty(cityFilter) && cityFilter !== 0) {
      setSearchData({ ...searchData, cityCodeList: [cityFilter] });
    } else {
      setSearchData({ ...searchData, cityCodeList: [] })
    }
  }, [cityFilter]);

  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (checkNullOrEmpty(pageType)) {
      setSearchData({ ...searchData, listingTypeId: pageType });
    }
  }, [pageType]);

  useEffect(() => {
    if (checkNullOrEmpty(state)) {
      dashboardDateFilterData();
    }
  }, [state]);

  // #region Paggination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row) => [
          <Label text={row.transactionDateOrDealerName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <TablePriceLabel price={row.comissionAmountAndCurrency} />,
          <TablePriceLabel price={row.successTransactionAmountAndCurrency} />,
          <TablePriceLabel price={row.cancellationAndRefundAmountAndCurrency} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                // transactionDateOrDealerName: row.transactionDateOrDealerName,
                transactionDateOrDealerName: formatDate(removeDays(row.transactionDateOrDealerName, termDay)),

                pobDealerId: row.pobDealerId,
                listingTypeId: pageType,
              }}
            >
              <span style={{ display: "flex", justifyContent: 'center', alignItems: 'center', textDecoration: "none", color: '#4f5386' }}>
                İşlemlere git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );

      setCurrentItemsForResponsive(
        listData?.map((row) => [
          <Label text={row.transactionDateOrDealerName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                // transactionDateOrDealerName: row.transactionDateOrDealerName,
                transactionDateOrDealerName: formatDate(removeDays(row.transactionDateOrDealerName, termDay)),

                pobDealerId: row.pobDealerId,
                listingTypeId: pageType,
              }}
            >
              <span style={{ display: "flex", justifyContent: 'center', alignItems: 'center', textDecoration: "none", color: '#4f5386' }}>
                İşlemlere git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
    } else {
      setCurrentItems([]);
      setCurrentItemsForResponsive([]);
    }
  }, [listData, itemOffset, itemsPerPage]);

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }

  function removeDays(theDate, days) {
    console.log("theDate", theDate)
    console.log("term date", termDay)
    var dateParts = theDate.split(".");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    console.log("exist : ", new Date(dateObject?.getTime() - days * 24 * 60 * 60 * 1000))
    return new Date(dateObject?.getTime() - days * 24 * 60 * 60 * 1000);
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };
  //#endregion

  function getPaymentDataFromApi() {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {
      api.BigMarketPlace().WillAccountedPaymentList(searchData).then((response) => {
        console.log("responselist", response.data.data);
        setResponseData(response.data.data);
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListData(response.data.data.paymentList);
        } else {
          setListData([]);
        }
        setresponseListTotalCount(
          response.data.data.paymentListTotalCount
        );
      })
        .catch((error) => {
          console.log("error", error?.response?.data);
          setListData([])
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const updateExportData = (listValue) => {
    setExportData(
      listValue.map((row) => {
        return {
          "": `${row.transactionDateOrDealerName}`,
          "Net Hesaba Geçecek Tutar": `${row.netAccountAmountAndCurrency}`,
          "Ödenen Komisyon": `${row.comissionAmountAndCurrency}`,
          "Başarılı Tutar": `${row.successTransactionAmountAndCurrency}`,
          "İptal/İade": `${row.cancellationAndRefundAmountAndCurrency}`,
        };
      })
    );
  };

  const setNextDateFormat = (dateTime) => {
    let day = dateTime.slice(0, 2);
    let month = dateTime.slice(3, 5);
    let year = dateTime.slice(6, 10);

    return month + "/" + day + "/" + year;
  };

  const dashboardDateFilterData = () => {
    if (checkNullOrEmpty(state)) {
      setTimeout(() => {
        let nextDay = new Date(setNextDateFormat(state.dateNow));
        nextDay.setDate(
          new Date(setNextDateFormat(state.dateNow)).getDate() + 1
        );

        let startDate = state.dateNow;
        let endDate = new Date(nextDay).toLocaleDateString();
        const range = startDate + " - " + endDate;
        setSearchData({
          ...searchData,
          startTransactionDate: setDateFormat(startDate),
          endTransactionDate: setDateFormat(endDate),
        });

        setCalendarValue(startDate);
        changeSeletedDateRange(range);
      }, 1000);
    }
  };

  //#region Filter
  //selectedDateRange
  const setDateFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (newValue) => {
    setDateValue(newValue);

    let str = "";
    if (newValue.endDate != null) {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      let endDate = new Date(newValue.endDate).toLocaleDateString();
      if (startDate !== endDate) {
        str = startDate + " - " + endDate;
        setSearchData({
          ...searchData,
          startTransactionDate: setDateFormat(startDate),
          endTransactionDate: setDateFormat(endDate),
        });
      } else {
        str = startDate;
      }
    } else {
      let startDate = new Date(newValue.startDate).toLocaleDateString();
      str = startDate;
    }

    setCalendarValue(str);
    changeSeletedDateRange(str);
  };
  const changeSeletedDateRange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  //cityFilter
  const handleCityChange = (value) => {
    setCityFilter(value);
  };

  //dealerFilter
  const handleDealerChange = (value) => {
    setDealerFilter(value);
  };


  //#endregion

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      termRange={termDay}
      isTermDay="true"
      dateTimePickerTypeId={1}
      stringVal={state?.dateNow}
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      height="3rem"
      items={citiesData}
      onSelected={handleCityChange}
      selectItemId={cityFilter}
      text="Tüm Bölgeler"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="235px"
      height="3rem"
      items={dealersData}
      onSelected={handleDealerChange}
      selectItemId={dealerFilter}
      text="Tüm Bayiler"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="7rem"
    // />,
    <ExportExcel
    excelData={exportData}
    fileName={fileName}
    text="İndir"
    />,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <AccountedForAndWillAccountedResponsive
        responsivePaginationType="1"
        setPageType={setPageType}
        pageType={pageType}
        totalNetAccountAmountAndCurrency={responseData.totalNetAccountAmountAndCurrency}
        totalComissionAmountAndCurrency={responseData.totalComissionAmountAndCurrency}
        totalSuccessTransactionAmountAndCurrency={responseData.totalSuccessTransactionAmountAndCurrency}
        totalCancellationAndRefundAmountAndCurrency={responseData.totalCancellationAndRefundAmountAndCurrency}
        currentItems={currentItemsForResponsive}
        citiesData={citiesData}
        cityFilter={cityFilter}
        setCityFilter={setCityFilter}
        dealersData={dealersData}
        dealerFilter={dealerFilter}
        setDealerFilter={setDealerFilter}
        handleDateRangeChange={handleDateRangeChange}
        setSearchData={setSearchData}
        searchData={searchData}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea
          width="100%"
          style={{ flexBasis: "55%" }} items={filterItems} SwitchItem={() => <SwitchButton
            callback={setPageType}
            style={{ height: '3rem' }}
            items={[
              { id: 1, text: "Tarih Bazlı" },
              { id: 2, text: "Bayi Bazlı" },
            ]}
            selectedValue={pageType}
          />} />
        <Container flexDirection="column">
          <Cardboard>
            <InfoArea>
              <div className={styles.total}>
                <div className={styles.biglabel}>Net Hesaba Geçecek Tutar</div>
                <PriceLabel
                  price={responseData.totalNetAccountAmountAndCurrency}
                  size="3.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Komisyon Tutarı</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalComissionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Başarılı Tutar <Information text="Toplam tutardan iptal/iade tutarı düşülerek hesaplanmıştır." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalSuccessTransactionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>İptal / İade <Information text="İade işlemi belirtilen zaman aralığında olmamış olabilir." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalCancellationAndRefundAmountAndCurrency}
                  size="2.5"
                />
              </div>
            </InfoArea>
          </Cardboard>
          <Cardboard>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={listData?.length}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />
              <List
                columnNames={[
                  "",
                  "NET HESABA GEÇECEK",
                  "KOMİSYON TUTARI",
                  "BAŞARILI TUTAR",
                  "İPTAL/İADE",
                  "",
                  "",
                ]}
                data={currentItems}
              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};
export default WillAccountedBMP;
