import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './listitems.module.css';
import cx from 'classnames';
import UpIcon from 'components/icons/UpIcon';
import DownIcon from 'components/icons/DownIcon';

const ListItems = forwardRef(({ items, collapsed, CollapseItem, id }, ref = []) => {
    // console.log('collapseitem',CollapseItem)
    const [collapse, setCollapse] = useState(false);
    const [isCollapseOpen, setCollapseOpen] = useState(false);
    useEffect(() => {
        collapsed && setCollapse(collapsed);
    })

    const handleCollapse = () => {
        setCollapseOpen(!isCollapseOpen);
        console.log(isCollapseOpen)
    }
    // console.log("İtems : ", items)

    return (// List İtems A ref Ekledim inceleyeceğim
        <>
            {/* eger ref varsa ref arrayi burada arraye id indexiyle kendi refini ekliyor */}
            <tr ref={tr => ref != null ? ref.current[id] = tr : null} id={id}>
                {items?.map((item, i) => {
                    if (i == 0) {
                        return <td key={i} className={cx(styles.cell, styles.firstcell, isCollapseOpen && styles.bottomborderremove)}>{React.isValidElement(item) && React.cloneElement(item)}</td>
                    }
                    if (i == items.length - 1 && !collapse) {
                        return <td key={i} className={cx(styles.cell, styles.lastcell, isCollapseOpen && styles.bottomborderremove)}>{React.isValidElement(item) && React.cloneElement(item)}</td>
                    }
                    return <td key={i} className={cx(styles.cell, isCollapseOpen && styles.bottomborderremove)}>{React.isValidElement(item) && React.cloneElement(item)}</td>
                })}
                {items?.length > 0 &&
                    collapse && <td onClick={handleCollapse} className={cx(styles.cell, styles.collapsecell, isCollapseOpen && styles.bottomborderremove)}> {isCollapseOpen ? <UpIcon /> : <DownIcon />}</td>
                }
            </tr>
            {items?.length > 0 && collapse &&
                <tr className={cx(styles.collapserow, !isCollapseOpen && styles.close)}>
                    <td colspan="100%">
                        <div className={styles.collapsedarea}>
                            {
                                React.isValidElement(CollapseItem) && React.cloneElement(CollapseItem, {
                                    item: items[items.length - 1]
                                })
                            }
                        </div>
                    </td>
                </tr>
            }
        </>
    )
});

export default ListItems;
