import Layout from '../../components/Layout/Layout';
import Cookies from 'universal-cookie';

import AccountedForSMP from 'components/AccountedFor/SmallMarketPlace/Standard';
import AccountedForBMP from 'components/AccountedFor/BigMarketPlace/Standard';
import UnAuthorized from 'container/UnAuthorized/UnAuthorized';

export default function AccountedFor() {

    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole

    //Get by user role
    switch (userRole) {
        // Büyük Firma - Pazaryeri - Standart Kullanıcı 1
        case "1":
            return (
                <Layout>
                    {console.log("Büyük Firma - Pazaryeri - Standart Kullanıcı ")}
                    <AccountedForBMP />
                </Layout>
            )
            
        // Büyük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "2":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            
        // Küçük Firma - Pazaryeri - Standart Kullanıcı
        case "3":
            return (
                <Layout>
                    {console.log("Küçük Firma - Pazaryeri - Standart Kullanıcı ")}
                    <AccountedForSMP />
                </Layout>
            )
            
        // Küçük Firma - Pazaryeri - Ödeme Yetkili Kullanıcı
        case "4":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            
        // Bayi - Standart Kullanıcı
        case "5":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            
        // Bayi - Ödeme Yetkili Kullanıcı
        case "6":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            
        // Alt Bayi - Standart Kullanıcı
        case "7":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            
        // Alt Bayi - Ödeme Yetkili Kullanıcı
        case "8":
            return (
                <Layout>
                    <UnAuthorized/>
                </Layout>
            )
            

        default:
            return (
                <Layout>
                    ROLE COOKIE ERROR
                </Layout>
            )
            

    }
}
