// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banklist_bankcontainer__kXRmI{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    margin: 10px auto;\n    padding: 10px;\n}\n.banklist_bankcontainer__kXRmI li{\n    display: inline;\n    padding: 20px;\n}\n.banklist_bankcontainer__kXRmI ul{\n    text-align: center;\n    list-style:none outside none;\n    margin: 0px 0px 0px -65px;    \n}", "",{"version":3,"sources":["webpack://./src/components/Global/BankList/banklist.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,eAAe;IACf,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,yBAAyB;AAC7B","sourcesContent":[".bankcontainer{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    margin: 10px auto;\n    padding: 10px;\n}\n.bankcontainer li{\n    display: inline;\n    padding: 20px;\n}\n.bankcontainer ul{\n    text-align: center;\n    list-style:none outside none;\n    margin: 0px 0px 0px -65px;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bankcontainer": "banklist_bankcontainer__kXRmI"
};
export default ___CSS_LOADER_EXPORT___;
