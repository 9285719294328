import * as yup from 'yup';

const formValidator = yup.object({
  fullName: yup.string().required("Ad soyad zorunlu"),
  userName: yup.string().required("Kullanıcı adı zorunlu"),
  phone: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
  email: yup.string().email("E-Posta adresi formatı doğru değil").required("E-posta zorunlu"),
  password: yup.string().required("Parola zorunlu"),
  passwordConfirmation: yup.string().oneOf(
    [yup.ref("password"), null],
    "Doğrulama parolası zorunlu"
  ),
});

export default formValidator;