// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".triplebutton_triplecontainer__kfUXW{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 170px;\n    height: 30px;\n    border-radius: 10px;\n    border: solid 1px #d4ddf0;\n}\n\n.triplebutton_section__zCTfi{\n    flex-grow: 1;\n    height: 100%;\n}\n\n.triplebutton_button__0B64J{\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.triplebutton_bottom__UGS9n{\n    border-right:  solid 1px #d4ddf0;\n    border-left:  solid 1px #d4ddf0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Global/TripleButton/triplebutton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,+BAA+B;AACnC","sourcesContent":[".triplecontainer{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 170px;\n    height: 30px;\n    border-radius: 10px;\n    border: solid 1px #d4ddf0;\n}\n\n.section{\n    flex-grow: 1;\n    height: 100%;\n}\n\n.button{\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.bottom{\n    border-right:  solid 1px #d4ddf0;\n    border-left:  solid 1px #d4ddf0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"triplecontainer": "triplebutton_triplecontainer__kfUXW",
	"section": "triplebutton_section__zCTfi",
	"button": "triplebutton_button__0B64J",
	"bottom": "triplebutton_bottom__UGS9n"
};
export default ___CSS_LOADER_EXPORT___;
