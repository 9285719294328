import Cookies from "universal-cookie";
import { checkNullOrEmptyArray } from "./Conditions";

export default function useCreditCardItems() {

    const cookies = new Cookies();

    const userCommissionList = cookies.get('userCommissionList');

    let installmentsAndCommissions = checkNullOrEmptyArray(userCommissionList) && userCommissionList?.map(item => {
        if (item.installmentNumber === 1) {
            return ({ text: `Yok`, id: 0 });// Örn : { text: "Yok", id: 0 }
        }
        else {
            // Örn : { text: "2 (%2)", id: 2 }
            return ({ text:item.installmentNumber,rate:item.rate, id: item.installmentNumber });
        }
    });
// console.log("kerdi kartı :",installmentsAndCommissions)
// console.log("komistyon kartı :",userCommissionList)

    let months = [];
    for (let i = 1; i <= 12; i++) {
        months.push({ text: String(i).padStart(1, '0'), id: i });// örn: {text: "1", id: 1}
    }

    var today = new Date();
    var todayYear = today.getFullYear();

    let years = [];
    for (let i = todayYear; i <= (todayYear + 30); i++) {
        years.push({ text: "" + i, id: i });// örn: {text: "2022", id: 2022}
    }

    return { months, years, installmentsAndCommissions };
}
