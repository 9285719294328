import React from 'react'
import styles from './datelabel.module.css'

const DateLabel = ({ time, date }) => {
    return (

        <div className={styles.datelabel}>
            <span className={styles.time}>{time}</span>
            <span className={styles.date}>{date}</span>
        </div>
    )
}

export default DateLabel