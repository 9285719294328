import React from 'react';

const CreditCardIcon = ({ size = "20", text, width, color, fontSize, fontWeight,alignItems,justifyContent= "space-around" }) => {
    return <>
        <div style={{ display: "flex", flexDirection: "row",justifyContent:justifyContent,alignItems:alignItems, color:color, fontSize:fontSize, fontWeight:fontWeight }}>
            <symbol id="card">
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.763 3a2.265 2.265 0 0 1 1.58.643c.42.411.656.968.657 1.55V17.95a2.175 2.175 0 0 1-.657 1.55c-.419.41-.987.642-1.58.643H2.237a2.265 2.265 0 0 1-1.58-.643A2.175 2.175 0 0 1 0 17.95V5.193a2.175 2.175 0 0 1 .656-1.55c.42-.41.988-.642 1.581-.643h19.526zm1.016 6.777H1.22v8.173c0 .265.107.518.298.705.19.187.45.292.719.292h19.526c.27 0 .528-.105.719-.292a.987.987 0 0 0 .297-.705V9.777zm-2.643 5.582c.337 0 .61.268.61.598 0 .33-.273.598-.61.598h-2.091a.604.604 0 0 1-.61-.598c0-.33.273-.598.61-.598h2.09zm1.627-11.163H2.236c-.27 0-.528.105-.72.292a.987.987 0 0 0-.297.705v1.45h21.56v-1.45a.987.987 0 0 0-.298-.705 1.028 1.028 0 0 0-.72-.292z" fill={color} />
                </svg>
            </symbol>
            <div style={{width:width}}/>
            {text}
        </div>
    </>
};

export default CreditCardIcon;