import Button from 'components/Global/Button'
import Table from 'components/Global/Table'
import TickIcon from 'components/icons/TickIcon'
import React, { useState } from 'react'
import styles from './paymentsuccess.module.css'
import api from 'store/api/actions'
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { useEffect } from 'react'
import InputShare from 'components/Global/InputShare'
import CancelIcon from 'components/icons/CancelIcon'
import Cookies from 'universal-cookie'
import { checkNullOrEmpty } from 'hoc/Conditions'
import SavedCustomer from './SavedCustomer'

const initiAlTableData = {
    amount: "",
    installment: "",
    customerName: "",
    description: "",
    pobDealerCustomerId: "",
    userNameOrPobDealerName: ""
}

//successPageType 1 ise kart ile ödeme başarı sayfası, 2 ise link ile ödeme başarı sayfası, 3 ise toplu link ile ödeme başarı sayfası
const PaymentSuccess = ({ rejected = false, tableData, responsePath, link, style, savedCustomer, successPageType = 1, directPaymentError }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [buttonText, setButtonText] = useState('Müşterilere Kaydet')
    const [infoText, setInfoText] = useState('ödemeniz başarıyla alınmıştır.');
    const [fullName, setFullName] = useState("");
    const [wantToSaveCustomer, setWantToSaveCustomer] = useState(false);
    let tryCurrency = new Intl.NumberFormat('tr-TR', {
        currency: 'TRY',
    });
    useEffect(() => {

        if (successPageType == 1) {//Dashboard daki işlemler için başarı sayfası ise
            const cookies = new Cookies();
            const data = cookies.get("fullName");
            //eğer cookie lerde fullName varsa; 
            if (checkNullOrEmpty(data)) {
                console.log("Data : ", data);
                // setFullName(tableData.customerName);
                setFullName(data)
            }
        }
        else if (successPageType == 2) {//Link ile ödeme işleminde açılan başarı sayfası ise
            setFullName(tableData.customerName);
        }
        // console.log("tableData : ", tableData)
 
    }, [])

    useEffect(() => {
        // console.log("tableData : ", tableData)
        if (responsePath > 0) {
            if (responsePath == 1) {
                setInfoText('ödemeniz başarıyla alınmıştır.')
            }
            else if (responsePath == 2) {
                setInfoText('link başarıyla oluşturulmuştur.')
            }
            else if (responsePath == 3) {
                setInfoText('linkler başarıyla oluşturulmuştur.')
            }
        }
        if (rejected) {
            setInfoText('aşağıda bilgileri verilen ödemeyi reddettiniz')
        }
        if (directPaymentError) {
            setInfoText('ödeme başarısız oldu. Lütfen tekrar deneyiniz.')
        }
    }, [responsePath])

    //return function
    const ReturnFunc = () => {
        // console.log("return func", tableData)
        return (
            <SavedCustomer
                tableData={tableData}
            />
        );
    }

    const handleSaveCustomer = () => {
        console.log("tableDatasa succsess", tableData)
        setWantToSaveCustomer(true)
    }
    // setIsLoading(true)
    // api.Customer()
    //     .Saved({ pobDealerCustomerId: tableData?.pobDealerCustomerId })
    //     .then(response => {
    //         console.log(tableData)
    //         if (response.data.success) {
    //             setButtonDisabled(true)
    //             setButtonText('Kayıt Başarılı.')
    //         }
    //     }).catch((err) => {
    //         console.log("Error", err?.response?.data)
    //     }).finally(_ => {
    //         setIsLoading(false)
    //     });

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            {!wantToSaveCustomer ?
                <div className={styles.container}>
                    <div className={styles.circlelogo} style={style}>
                        <div className={styles.circle}>
                            {
                                rejected || directPaymentError ? <CancelIcon size='40' /> : <TickIcon size='40' />
                            }
                        </div>
                    </div>
                    <div className={styles.infolabel}>
                        <div className={styles.headlabel}>Sayın <span className={styles.bold}>{fullName}</span></div>
                        <div className={styles.sublabel}>{infoText}</div>
                    </div>
                    <div className={styles.table}>
                        {console.log("tableData2 : ", tableData)}
                        <Table
                            data={
                                [
                                    { name: 'Tutar', value: tryCurrency.format(Number.parseFloat(tableData?.amount).toFixed(2)) + " TL" },
                                    { name: 'Taksit Miktarı', value: tableData?.installment != "0" && tableData?.installment != undefined ? tableData?.installment : '0' },
                                    { name: 'Firma Bilgisi', value: tableData?.companyName },
                                    { name: 'Açıklama', value: tableData?.description?.length > 0 ? tableData?.description : 'Yok' }
                                ]
                            }
                        />
                    </div>
                    {

                        responsePath == 2 &&
                        <InputShare
                            value={link}
                            whatsappNumber={tableData?.gsmNumber}
                            whatsappMessage={`Merhaba, ${tableData.companyName} firmasının ${tryCurrency.format(tableData.amount)} TL tutarında ödeme bekliyor. Ödeme isteğine bu linkten ulaşabilirsiniz: ${window.location.origin + "/SharedPaymentLink?link=" + link}`}
                        />
                    }
                    {
                        (successPageType == 1 && savedCustomer) &&
                        <>
                            <div className={styles.savelabel}><span className={styles.bold}>{tableData?.customerName}</span> &nbsp; adlı müşteriden daha sonra da ödeme almak ister misiniz?</div>
                            <div className={styles.button}>
                                <Button disabled={buttonDisabled} onClick={handleSaveCustomer} text={buttonText} width='100%' fontSize='1.2rem' />
                            </div>
                        </>
                    }
                </div>
                :
                <div>
                    <ReturnFunc />
                </div>
            }
        </>
    )
}

export default PaymentSuccess