import React, { useState } from 'react';
import styles from 'components/Global/Input/input.module.css';
import cx from 'classnames';

const UploadFile = ({ width, height = '3rem', placeholder, fontSize, value, name, id, type = 'text', maxLength, error, onBlur }) => {
    const [url, setUrl] = useState('');

    const onUploadFile = (e) => {
        const files = e.target.files;
        files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    };

    return (
        <>
            <input type="file" maxLength={maxLength} value={value} onChange={onUploadFile} name={name} id={id} onBlur={onBlur} placeholder={placeholder} className={cx(styles.input, error && styles.error)} style={{ width, height, fontSize }} />
            {
                error && (<span className={styles.errortext}>{error}</span>)
                //url && <iframe src={url} title="pdf" style={{ width: 400 }} />
            }
            
        </>
    );
};

export default UploadFile;