import FilterArea from "components/Global/FilterArea";
import { useState, useEffect, useRef } from "react";
import styles from "./dealerbmps.module.css";
import InputSearch from "components/Global/InputSearch";
import Button from "components/Global/Button";
import AddUserIcon from "components/icons/AddUserIcon";
import Cardboard from "components/Global/Cardboard";
import Container from "components/Global/Container";
import ListContainer from "components/Global/ListContainer";
import Pagination from "components/Global/Pagination";
import List from "components/Global/List";
import Modal from "components/Global/Modal";
import EditIcon from "components/icons/EditIcon";
import DeleteIcon from "components/icons/CloseIcon";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { checkNullOrEmptyArray, checkNullOrEmpty } from "hoc/Conditions";
import api from "../../../store/api/actions";
import SubDealerAdd from "components/Global//Forms/SubDealer/Add";
import SubDealerUpdate from "components/Global//Forms/SubDealer/update";
import AddIcon from "components/icons/AddIcon";
import { useMediaQuery } from "react-responsive";
import DealerManagementResponsive from "components/Responsive/DealerManagement";
import Label from "components/Global/Label";
import HomeComponent from "hoc/ExcelImport";
import PobUp from "components/Global/PopUp/PobUp";

var initialUpdateDealer = {
  pobSubDealerId: "",
  cityCode: 0,
  countryCode: 0,
  districtId: "",
  address: "",
  phoneNumber: "",
  pobSubDealerName: "",
  companyName: "",
  monthlyAmountLimit: 0,
};

var initialDistrictSearch = {
  cityCode: 0,
  countryCode: 0
};

var initialData = {
  DealerType: 0,
  TaxNumber: "",
  ContactName: "",
  CompanyName: "",
  PhoneNumber: "",
  Email: "",
  MonthlyAmountLimit: 0,
  IndividualName: "",
  DistrictId: "",
  CityCode: 0,
  TrIdentityNumber: "",
  Address: "",
  CustomCode: "",
  SubDealerName: ""
}

const SubDealerManagementForDealer = () => {
  const searchInput = useRef(null);

  // #region Pagination state
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion
  const [currentRef, setCurrentRef] = useState()
  const [responseData, setResponseData] = useState([]); //api den gelen ham veri
  const [listData, setListData] = useState([]); //listelenecek ve filtrelenecek veri
  const [searchData, setSearchData] = useState();
  const [updateDealer, setUpdateDealer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalClick, setModalClick] = useState(false);
  const [pobDealerName, setPobDealerName] = useState()
  const [currentItemsResponsive, setCurrentItemsResponsive] = useState([[]])
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const [errors, setErrors] = useState();
  const [isActive, setIsActive] = useState(false)
  const [popUpClick, setPopUpClick] = useState(false);
  const [popupPosition, setPopupPosition] = useState()
  const [deleteId, setDeleteId] = useState()
  const [dealerName, setDealerName] = useState()

  const refs = useRef([])



  //#region Effects
  useEffect(() => {
    getAllSubDealerFromApi();
    getAllCitiesDataFromApi();
    getAllDistrictsDataFromApi();
  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getAllSubDealerFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    if (checkNullOrEmpty(deleteId)) {
      deleteSelector(deleteId)
      setIsActive(false)
    }
    if (popUpClick)
      setPopUpClick(false)
  }, [popUpClick])

  useEffect(() => {
    searchInput?.current?.focus();
  }, [isLoading]);

  //Paggination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((item, i) => [
          <Label key={i} text={item.name} />,
          <Label key={i} text={item.transactionLimitAmountAndCurreny} />,
          <Label key={i} text={item.subDealerCount} />,
          <Label key={i} text={item.userCount} />,
          <Label key={i} text={item.phoneNumber} />,
          <Label key={i} text={item.address} />,
          <div></div>,
          <div></div>,
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              columnGap: "20px",
            }}
          >
            <div
              onClick={() => {
                handleUpdateDealerButtonClick(item.pobDealerId);
              }}
            >
              <EditIcon />
              </div>
              <div
                onClick={() => { deleteSelector(item.pobDealerId, i) }}
              >
                <div onClick={() => { deleteSelector(item.pobDealerId, i) }}><DeleteIcon /></div>
              </div>
          </div>,
        ])
      );
      setCurrentItemsResponsive(
        listData?.map((item, i) => [
          <Label key={i} text={item.name} />,

          <Label key={i} text={item.phoneNumber} />,
          <div></div>,
          <div></div>,
          {
            details: item, i
          }
        ])
      );
    } else {
      setCurrentItems([[]]);
    }
  }, [listData, itemOffset, itemsPerPage]);

  //#endregion
  //#region Handlers
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };

  const handleCreateDealerButtonClick = () => {
    setUpdateDealer(null);
    handleModalClick(true);
  };

  const handleUpdateDealerButtonClick = (pobDealerId) => {
    setIsLoading(true);
    api
      .SubDealerManagement()
      .GetById({ pobSubDealerId: pobDealerId })
      .then((response) => {
        if (checkNullOrEmpty(response.data.data)) {
          setUpdateDealer(response.data.data);
          handleModalClick(true);
        }

        // console.log("Get dealer response : ", response)
      })
      .catch((error) => {
        console.log("error", error?.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const deleteSelector = (id, listId) => {
    //listId => ilgili rowun refini arraya appendladigi indis numarasi
    const ref = refs.current[listId]
    //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
    setCurrentRef(ref)
    setDeleteId(id)
    setIsActive(true)

    // console.log("Delete :", popUpClick)

    listData.map((item) => {
      if (item.pobDealerId === id) {
        setDealerName(item.name)
        // console.log("Name :", item.name)
      }
    })

    if (popUpClick) {
      handleDeleteDealerButtonClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
      // console.log("Delete Cancel ")
    }
  }
  const handleDeleteDealerButtonClick = (pobDealerId) => {
    //console.log("Delete Values : ", pobDealerId);
    setIsLoading(true);
    api
      .SubDealerManagement()
      .Delete({ subDealerId: pobDealerId })
      .then((response) => {
        console.log("Dealer Delete Response :", response.data);

        setResponseData(
          responseData.filter((item) => item.pobDealerId !== pobDealerId)
        );
        setListData(
          responseData.filter((item) => item.pobDealerId !== pobDealerId)
        );
      })
      .catch((error) => {
        console.log("Dealer Delete Error : ", error.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClick = (status) => {
    setModalClick(status);
    //modal kapandığında verileri yenile (apiden tekrar getir)
    if (status == false) {
      getAllSubDealerFromApi();
      setUpdateDealer(undefined);
    }
  };

  const handleSearchChange = (e) => {
    const value = e?.target?.value?.trim();
    //console.log("value",value)
    if (checkNullOrEmpty(value)) {
      setSearchData({ ...searchData, pobSubDealerName: value });
    } else {
      setSearchData({ ...searchData, pobSubDealerName: "" });
    }
  };
  //#endregion

  const getAllSubDealerFromApi = () => {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {
      api
        .SubDealerManagement()
        .List(searchData)
        .then((response) => {
          // console.log("SubDealer Response : ", response.data.data.subDealerList );
          // console.log("response.data.data.pobDealerName", response.data.data.pobDealerName)
          if (checkNullOrEmpty(response?.data?.data?.pobDealerName)) {
            setPobDealerName(response?.data?.data?.pobDealerName);
          }
          if (checkNullOrEmptyArray(response.data.data.subDealerList)) {
            setResponseData(response.data.data.subDealerList);
            setListData(response.data.data.subDealerList);
          } else {
            setResponseData([]);
            setListData([]);
          }
          setresponseListTotalCount(
            response.data.data.subDealerListTotalCount
          );
        })
        .catch((error) => {
          console.log("DealerList Error : ", error?.response?.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const handlePopUp = (status) => {
    setPopUpClick(status)
    // console.log("Status : ", status)
    setIsActive(false)
  }
  const getAllCitiesDataFromApi = () => {
    api.Helpers().GetAllCity({ isAll: true })
      .then((response) => {
        if (checkNullOrEmpty(response.data.data)) {
          setCities(response.data.data.map((item) => (
            {
              id: item.cityCode,
              text: item.cityName
            }
          )))
        }
      })
      .catch((error) => {
        console.log('error', error?.response?.data);
      });
  }

  const getAllDistrictsDataFromApi = (searchData = initialDistrictSearch) => {
    api.Helpers().GetAllDistrict(searchData)
      .then((response) => {
        setDistricts(response.data.data.map((item) => (
          {
            id: item.districtId,
            text: item.districtName
          }
        )))
      })
      .catch((error) => {
        console.log('error', error?.response?.data);
      });
  }

  const pull_data = (subDealerData) => {
    subDealerData.forEach(element => {
      initialData.DealerType = element.Bayinin_Tipi
      initialData.TaxNumber = element.Vergi_Numarasi
      initialData.ContactName = element.Yetkili_Ad_Soyad
      initialData.CompanyName = element.Firma_Adi
      initialData.PhoneNumber = element.Telefon_Numarasi
      initialData.Email = element.Email
      initialData.MonthlyAmountLimit = element.Limit
      initialData.ErpNumber = element.Erp_Numarasi
      initialData.TrIdentityNumber = element.TC_Numarasi
      initialData.Address = element.Adres
      initialData.IndividualName = element.Ad_Soyad
      initialData.CustomCode = element.Bayi_Kodu
      initialData.SubDealerName = element.AltBayi_Adi

      initialData.CityCode = element.Sehir.toLowerCase();
      initialData.CityCode = element.Sehir.charAt(0).toUpperCase() + element.Sehir.slice(1);

      initialData.DistrictId = element.Ilce.toLowerCase();
      initialData.DistrictId = element.Ilce.charAt(0).toUpperCase() + element.Ilce.slice(1);

      cities.forEach(item => {
        if (item.text == initialData.CityCode)
          initialData.CityCode = item.id;
        else
          setErrors("Lütfen şehir ismini doğru giriniz");
      });

      districts.forEach(item => {
        if (item.text == initialData.DistrictId)
          initialData.DistrictId = item.id;
        else
          setErrors("Lütfen ilçe ismini doğru giriniz");
      });

      initialData.TaxNumber = initialData.TaxNumber + "";
      initialData.PhoneNumber = initialData.PhoneNumber + "";
      initialData.ErpNumber = initialData.ErpNumber + "";
      initialData.DistrictId = initialData.DistrictId + "";
      initialData.TrIdentityNumber = initialData.TrIdentityNumber + "";

      api.SubDealerManagement().Create(initialData).then((response) => {
      }).catch((error) => {
        console.log('error', error?.response?.data);
      }).finally(() => {
      });


    });
  }

  const filterItems = [
    <div className={styles.filtercontainer} style={{ display: "flex", justifyContent: "space-between" }}>
      <HomeComponent func={pull_data} />
      <div style={{ display: "flex", justifyContent: "space-between", width: "22%" }}>
        <InputSearch
          placeholder={"Alt Bayi ara"}
          onChange={handleSearchChange}
          width="210px"
          height="3rem"
        />
        <Button
          onClick={handleCreateDealerButtonClick}
          width="155px"
          text="Alt Bayi Ekle"
          icon={<AddIcon color="#fff" />}
        />
      </div>

    </div>,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <DealerManagementResponsive
        handleSearchChange={handleSearchChange}
        handleCreateDealerButtonClick={handleCreateDealerButtonClick}
        handleDeleteDealerButtonClick={handleDeleteDealerButtonClick}
        handleUpdateDealerButtonClick={handleUpdateDealerButtonClick}
        getAllSubDealerFromApi={getAllSubDealerFromApi}
        updateDealer={updateDealer}
        pobDealerName={pobDealerName}
        currentItemsResponsive={currentItemsResponsive}
        refs={refs}

      />

    </> :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <Modal
          modalStyle={{
            boxShadow: "none",
            flexBasis: "40%",
            width: "30%",
            alignItems: "stretch",
          }}
          clickEvent={modalClick}
          callbackEvent={setModalClick}
          body={
            checkNullOrEmpty(updateDealer) ? (
              <SubDealerUpdate
                style={{ width: "35rem" }}
                dealer={updateDealer}
                handleModalClick={handleModalClick}
              />
            ) : (
              <SubDealerAdd
                style={{ width: "35rem" }}
                pobDealerName={pobDealerName}
                handleModalClick={handleModalClick}
              />
            )
          }
        />

        <FilterArea width="100%" items={filterItems} />

        <Container height="100%">
          <Cardboard>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={responseListTotalCount}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
                desciription="bayi"
                pageViewStatus={true}
              />
              <PobUp
                callbackButtonEvent={handlePopUp}
                clickEvent={popUpClick}
                content="Bayinizi silmek istediğinize emin misiniz ?"
                isCustomer={true}
                isActive={isActive}
                position={popupPosition}
                ref={currentRef}
                headerName={dealerName}
              />
              <List
                columnNames={[
                  "Alt Bayi İsmi",
                  "Kullanıcı Sayısı",
                  "Telefon",
                  "Adres",
                  "",
                  "",
                  "",
                  "",
                  "",
                ]}
                data={currentItems}
                ref={refs}

              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};

export default SubDealerManagementForDealer;
