// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "  .tooltip_element__LxcCz {\n    background-color: #eee;\n    padding: 15px;\n    border-radius: 15px;\n    position: relative;\n  }\n  .tooltip_tooltip__U3Trs {\n    position: absolute;\n    background-color: #020d89;\n    color: #e5e5e5;\n    border-radius: 10px;\n    padding: 10px;\n    box-shadow: 0 5px 10px -5px #060606;\n    transform: translateX(-50%) scale(0);\n    transform-origin: bottom;\n    transition: transform 0.3s;\n  }\n  .tooltip_tooltip__U3Trs::after {\n    content: \"\";\n    position: absolute;\n    width: 10px;\n    aspect-ratio: 1;\n    background-color: #020d89;\n    bottom: -5px;\n    left: 50%;\n    transform: translateX(-50%) rotate(45deg);\n  }\n  .tooltip_active__3wYbC {\n    transform: translateX(-50%) scale(1);\n  }", "",{"version":3,"sources":["webpack://./src/components/Global/ToolTip/tooltip.module.css"],"names":[],"mappings":"EAAE;IACE,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,mCAAmC;IACnC,oCAAoC;IACpC,wBAAwB;IACxB,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,SAAS;IACT,yCAAyC;EAC3C;EACA;IACE,oCAAoC;EACtC","sourcesContent":["  .element {\n    background-color: #eee;\n    padding: 15px;\n    border-radius: 15px;\n    position: relative;\n  }\n  .tooltip {\n    position: absolute;\n    background-color: #020d89;\n    color: #e5e5e5;\n    border-radius: 10px;\n    padding: 10px;\n    box-shadow: 0 5px 10px -5px #060606;\n    transform: translateX(-50%) scale(0);\n    transform-origin: bottom;\n    transition: transform 0.3s;\n  }\n  .tooltip::after {\n    content: \"\";\n    position: absolute;\n    width: 10px;\n    aspect-ratio: 1;\n    background-color: #020d89;\n    bottom: -5px;\n    left: 50%;\n    transform: translateX(-50%) rotate(45deg);\n  }\n  .active {\n    transform: translateX(-50%) scale(1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "tooltip_element__LxcCz",
	"tooltip": "tooltip_tooltip__U3Trs",
	"active": "tooltip_active__3wYbC"
};
export default ___CSS_LOADER_EXPORT___;
