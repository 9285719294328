import { remove, signoutRedirect } from "store/user/userService";
import Cookies from "universal-cookie";

export default function SignOut() {
    const cookies = new Cookies();
    cookies.remove("IdentityToken");
    cookies.remove("Auth");
    cookies.remove("roleNumber");
    remove()
    return (
        <>
            {signoutRedirect()}
        </>
    )
}
