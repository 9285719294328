import HamburgerIcon from 'components/icons/HamburgerIcon'
import React from 'react'
import styles from './layout.module.css'
import mahsupIcon from '../../../assets/img/header_logo.svg';
import { Link } from 'react-router-dom';
import Button from 'components/Global/Button';
import Hamburger from './Hamburger';
import { useState } from 'react';

const LayoutResponsive = ({ children, menuList }) => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);


    return (
        <>
            <div className={styles.container}>
                <div className={styles.navbar}>
                    <Link to='/'>
                        <img height="33px" src={mahsupIcon} alt="" />
                    </Link>
                    <Button icon={<HamburgerIcon />} backgroundImage="none" backgroundColor='transparent' color='#000d89' onClick={() => setIsBurgerOpen(true)} ></Button>
                </div>

                {children}
            </div>
            {isBurgerOpen && <Hamburger setIsBurgerOpen={setIsBurgerOpen} menuList={menuList} />}
        </>
    )
}

export default LayoutResponsive