import React, { useEffect, useState } from 'react';
import styles from './customeradd.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import formValidator from './FormValidator';
import CustomLoadingBox from "hoc/CustomLoadingBox";
import MultipleSelect from 'components/Global/MultipleSelect/MultipleSelect';
import { checkNullOrEmptyArray } from 'hoc/Conditions';
import ReactInputMask from 'react-input-mask';

const CustomerAdd = ({ handleModalClick, groups }) => {
    // console.log("Groups :", groups)


    const [responseErrors, setResponseErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [multiselectCallBack, setMultiselectCallBack] = useState()
    const [isFull, setisFull] = useState(false)

    useEffect(() => {
        (
            values.firstName == ""
            || values.gsmNumber.length < 13
            && values.email == ""
        ) ?
          setisFull(true)
          : setisFull(false)
      })
      

    useEffect(() => {
        handleReset({});
        // console.log("Groups : ", groups)
        groups?.forEach(element => {
            element.name = element.text
        });
        // console.log("Grupups: ", groups)
    }, [])

    const { handleChange, touched, handleBlur, values, handleSubmit, errors, handleReset } = useFormik({
        initialValues: {

            firstName: "",
            lastName: "",
            gender: 0,
            birthDate: "",
            gsmNumber: "",
            email: "",
            address: "",
            customerGroupIds: []
        },

        onSubmit: values => {
            setIsLoading(true);

            if (checkNullOrEmptyArray(multiselectCallBack)) {

                values.customerGroupIds = multiselectCallBack.map(item => item.id);
            }

            // console.log("Values : ", values)
            api.Customer().Add(values)
                .then((response) => {

                    handleReset({});
                    handleModalClick(false);
                    // console.log("Add response : ", response)

                })
                .catch((error) => {
                    // handle error
                    console.log('hataMesaji', error?.response?.data);
                    // setResponseErrors(error?.response?.data);
                    setResponseErrors(error?.response?.data.userMessage);

                })
                .finally(() => {
                    setIsLoading(false);

                });
        },
        validationSchema: formValidator
    });

    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />
            <div className={styles.formitems}>
                <div className={styles.forminfo}>
                    Yeni Müşteri Ekle
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div onChange={handleChange} className={styles.inputgroup}>
                        <Input // input yerine textfield yazınca input girişi var  
                            width='100%'
                            height='3rem'
                            id="firstName"
                            placeholder="Müşteri Ad Soyad"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            error={touched.firstName && errors.firstName}
                        />

                        <ReactInputMask
                            mask="999 999 99 99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gsmNumber}
                            maskChar=""
                        >
                            {() => <Input id="gsmNumber" name="gsmNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.gsmNumber && touched.gsmNumber} helperText={(errors.gsmNumber && touched.gsmNumber)} />}
                        </ReactInputMask>

                        <Input
                            width='100%'
                            id="email"
                            height='3rem'
                            placeholder="E-posta"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={touched.email && errors.email}
                        />

                        <MultipleSelect
                            items={groups}
                            error={touched.customerFullName && errors.customerFullName}
                            maxLength='70'
                            onChange={setMultiselectCallBack}
                            height='3rem'
                            name="text"
                            onBlur={handleBlur}
                            placeholder='Grup Ekle'
                            littleText='Grup'
                        />
                    
                        <Button type="submit" width='100%' text="Kaydet" fontSize="1.3rem" disabled={isFull} />
                    </div>
                </form>
            </div>
            {
                responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
            }
        </>
    )
};

export default CustomerAdd;
