// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customergroupadd_formitems__MSzJy{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n    row-gap: 20px;\n}\n\n.customergroupadd_forminfo__4aYMn{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n}\n\n.customergroupadd_inputgroup__Utn0H{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.customergroupadd_form__n5PwA{\n    width: 100%;\n    height: 100%;\n    \n}", "",{"version":3,"sources":["webpack://./src/components/Global/Forms/Customer/GroupAdd/customergroupadd.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,8BAA8B;IAC9B,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;;AAEhB","sourcesContent":[".formitems{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n    row-gap: 20px;\n}\n\n.forminfo{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n}\n\n.inputgroup{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.form{\n    width: 100%;\n    height: 100%;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formitems": "customergroupadd_formitems__MSzJy",
	"forminfo": "customergroupadd_forminfo__4aYMn",
	"inputgroup": "customergroupadd_inputgroup__Utn0H",
	"form": "customergroupadd_form__n5PwA"
};
export default ___CSS_LOADER_EXPORT___;
