import Button from 'components/Global/Button'
import Cardboard from 'components/Global/Cardboard'
import DealerUpdate from 'components/Global/Forms/Dealer/Update'
import DealerAdd from 'components/Global/Forms/Dealer/Add'
import SubDealerAdd from 'components/Global/Forms/SubDealer/Add'
import SubDealerUpdate from 'components/Global/Forms/SubDealer/update'
import InputSearch from 'components/Global/InputSearch'
import List from 'components/Global/List'
import DealerCollapse from 'components/Global/List/collapseForDealerResponsive'
import SubDealerCollapse from 'components/Global/List/collapseForSubDealerResponsive'
import ListContainer from 'components/Global/ListContainer'
import PobUp from 'components/Global/PopUp/PobUp'
import AddIcon from 'components/icons/AddIcon'
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import ResponsiveFilterArea from '../FilterArea'
import ResponsiveModal from '../Modal'
import styles from "./dealermanagementresponsive.module.css"

const DealerManagementResponsive = ({ handleSearchChange, currentItemsResponsive, getAllSubDealerFromApi, getDealerListFromApi, refs, handleDeleteDealerButtonClick, handleUpdateDealerButtonClick, handleCreateDealerButtonClick, updateSubDealer, updateDealer, pobDealerName, pobSubDealerName }) => {

    const [modalClick, setModalClick] = useState(false)
    const [isClosed, setIsClosed] = useState(true)
    const [dealerSubdealer, setDealerSubdealer] = useState()
    const [popUpClick, setPopUpClick] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const [currentRef, setCurrentRef] = useState()
    const [popupPosition, setPopupPosition] = useState()
    const [listData, setListData] = useState([]);
    const [dealerName, setDealerName] = useState()
    const [dealerSubdealerId, setDealerSubDealerId] = useState()
    const [dealerSubdealerName, setDealerSubDealerName] = useState()


    const cookies = new Cookies();
    var userRole = cookies.get('roleNumber') //Get userRole

    useEffect(() => {
        userRole && userRole == "5" ? setDealerSubdealer("Alt Bayi ismi") : setDealerSubdealer("Bayi ismi")
    }, [userRole])

    useEffect(() => {
        userRole && userRole == "5" ? setDealerSubDealerId("pobSubDealerId") : setDealerSubDealerId("pobDealerId")
    }, [userRole])

    useEffect(() => {
        userRole && userRole == "5" ? setDealerSubDealerName("pobSubDealerName") : setDealerSubDealerName("pobDealerName")
    }, [userRole])

    const pull_data = (dealerSubdealerId) => {
        return currentItemsResponsive.find(item => item?.details?.id == dealerSubdealerId);


    }


    useEffect(() => {
        console.log("EKRANA BASILAN: ", updateDealer)
    }, [updateDealer])


    useEffect(() => {
        if (checkNullOrEmpty(currentItemsResponsive)) {
            console.log("currentItems: ", currentItemsResponsive)
        }
    }, [currentItemsResponsive])


    const handleDeleteClick = (id) => {
        handleDeleteDealerButtonClick(id)
    }

    const handleUpdateClick = (id) => {
        // console.log("handleUpdateClick kid", id)
        setIsClosed(false)
        handleUpdateDealerButtonClick(id)
    }
    const handlePopUp = (status) => {
        setPopUpClick(status)
        setIsActive(false)
    }
    const handleCreateClick = (id) => {
        setIsClosed(false)
        handleCreateDealerButtonClick(id)
    }

    const handleModalClick = (status) => {
        if (status == false) {
            if (userRole == 1) {
                getDealerListFromApi()
            }
            else {
                getAllSubDealerFromApi()
            }
        }
        setModalClick(status);
        setIsClosed(true);
    }
    useEffect(() => {
        if (checkNullOrEmpty(deleteId)) {
            deleteSelector(deleteId)
            setIsActive(false)
        }
        if (popUpClick)
            setPopUpClick(false)
    }, [popUpClick])

    const deleteSelector = (id, listId) => {
        const ref = refs.current[listId]
        setDealerName(currentItemsResponsive.map((item) => item[4]?.details?.name)[listId])
        // currentItemsResponsive?.map((item) => item.filter((row) => id == row?.details?.pobDealerId)).map((index) => setDealerName(index) )
        // console.log("GELEN DEĞERLER: ", currentItemsResponsive.map((item) => console.log("item log: ", item[4]?.details?.name)))
        setCurrentRef(ref)
        setDeleteId(id)
        setIsActive(true)
        if (popUpClick) {
            handleDeleteClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
        }
    }

    return (
        <>
            <ResponsiveFilterArea width={"95%"} firstElement={
                <div className={styles.filter}>
                    <div className={styles.inputarea}>
                        <InputSearch
                            placeholder={dealerSubdealer}
                            onChange={handleSearchChange}
                            width="100%"
                        />

                    </div>

                    <div className={styles.addbutton}>
                        <Button
                            height="3rem"
                            fontSize="0.9rem"
                            onClick={handleCreateClick}
                            backgroundImage={"none"}
                            backgroundColor={"#f5f7f9"}
                            icon={<AddIcon color="#000d89" />}
                        />
                    </div>
                </div>
            }
                isButtonActive={false}
            >
            </ResponsiveFilterArea>
            <div className={styles.container}>

                <ResponsiveModal
                    clickEvent={modalClick}
                    isClosed={isClosed}
                    body={
                        (userRole == "1") ?
                            (
                                checkNullOrEmpty(updateDealer) ? (
                                    <div className={styles.modal}>
                                        <DealerUpdate
                                            dealer={updateDealer}
                                            style={{ width: "85%" }}
                                            handleModalClick={handleModalClick}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.modaladd}>
                                        <DealerAdd
                                            style={{ width: "85%" }}
                                            pobDealerName={pobDealerName}
                                            handleModalClick={handleModalClick}
                                        />
                                    </div>
                                )
                            ) :
                            (
                                checkNullOrEmpty(updateDealer) ? (
                                    <div className={styles.modal}>
                                        <SubDealerUpdate
                                            dealer={updateDealer}
                                            style={{ width: "85%" }}
                                            handleModalClick={handleModalClick}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.modaladd}>
                                        <SubDealerAdd
                                            style={{ width: "85%" }}
                                            pobDealerName={pobDealerName}
                                            handleModalClick={handleModalClick}
                                        />
                                    </div>
                                )
                            )
                    }

                    callbackEvent={handleModalClick}

                />

                <div className={styles.content}>
                    <Cardboard>
                        <ListContainer>
                            {userRole == "1" ?
                                (<List
                                    collapsed
                                    CollapseComponent={<DealerCollapse deleteSelector={deleteSelector} currentRef={currentRef} isActive={isActive} handlePopUp={handlePopUp} popUpClick={popUpClick} handleUpdateClick={handleUpdateClick} handleDeleteClick={handleDeleteClick} userRole={userRole} func={pull_data} />}
                                    columnNames={[
                                        dealerSubdealer,
                                        "Telefon",
                                        "",
                                        "",
                                        "",
                                        ""
                                    ]}
                                    data={currentItemsResponsive}
                                    ref={refs}
                                />) :
                                (<List
                                    collapsed
                                    CollapseComponent={<SubDealerCollapse deleteSelector={deleteSelector} currentRef={currentRef} isActive={isActive} handlePopUp={handlePopUp} popUpClick={popUpClick} handleUpdateClick={handleUpdateClick} handleDeleteClick={handleDeleteClick} userRole={userRole} func={pull_data} />}
                                    columnNames={[
                                        dealerSubdealer,
                                        "Telefon",
                                        "",
                                        "",
                                        "",
                                        ""
                                    ]}
                                    data={currentItemsResponsive}
                                    ref={refs}
                                />)
                            }
                            <PobUp
                                callbackButtonEvent={handlePopUp}
                                clickEvent={popUpClick}
                                content="Bayinizi silmek istediğinize emin misiniz ?"
                                isCustomer={true}
                                isActive={isActive}
                                position={popupPosition}
                                ref={currentRef}
                                headerName={dealerName}
                            />
                        </ListContainer>
                    </Cardboard>
                </div>
            </div>

        </>
    )
}

export default DealerManagementResponsive
