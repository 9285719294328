// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statuslabel_label__cGwz3{\n    color:#4f5386;\n    font-size: .9rem;\n}\n.statuslabel_bold__NxZOZ{\n    font-family: 'Metropolis-Bold';\n}\n\n.statuslabel_detail__vwsUb{\n    font-size: .8rem;\n}\n\n.statuslabel_success__g\\+mjN{\n    color:#1ebcc8;\n}\n\n.statuslabel_failure__smIlR{\n    color:#ff5c5c;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/StatusLabel/statuslabel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".label{\n    color:#4f5386;\n    font-size: .9rem;\n}\n.bold{\n    font-family: 'Metropolis-Bold';\n}\n\n.detail{\n    font-size: .8rem;\n}\n\n.success{\n    color:#1ebcc8;\n}\n\n.failure{\n    color:#ff5c5c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "statuslabel_label__cGwz3",
	"bold": "statuslabel_bold__NxZOZ",
	"detail": "statuslabel_detail__vwsUb",
	"success": "statuslabel_success__g+mjN",
	"failure": "statuslabel_failure__smIlR"
};
export default ___CSS_LOADER_EXPORT___;
