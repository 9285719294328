// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".installment_scrollablehorizontalitems__8eCHe {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    overflow-x: scroll;\n    overflow-y: hidden;\n    width: 100%;\n    padding: 20px 5px;\n}\n\n.installment_scrollablehorizontalitems__8eCHe::-webkit-scrollbar {\n    background-color: transparent;\n    height: 10px;\n}\n.installment_scrollablehorizontalitems__8eCHe::-webkit-scrollbar-thumb {\n    display: none;\n}\n\n.installment_hidden__e2Qcj{\n    display: none;\n}\n\n.installment_rightarrow__w81D7{\n    position: absolute;\n    z-index: 1;\n    padding: 5px;\n    right: 0;\n    top: 30%;\n    background-color: white;\n    border: 1px solid #bfc3de;\n    border-radius: 50%;\n    align-items: center;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/InstallmentArea/installment.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;AAChB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,QAAQ;IACR,QAAQ;IACR,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".scrollablehorizontalitems {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    overflow-x: scroll;\n    overflow-y: hidden;\n    width: 100%;\n    padding: 20px 5px;\n}\n\n.scrollablehorizontalitems::-webkit-scrollbar {\n    background-color: transparent;\n    height: 10px;\n}\n.scrollablehorizontalitems::-webkit-scrollbar-thumb {\n    display: none;\n}\n\n.hidden{\n    display: none;\n}\n\n.rightarrow{\n    position: absolute;\n    z-index: 1;\n    padding: 5px;\n    right: 0;\n    top: 30%;\n    background-color: white;\n    border: 1px solid #bfc3de;\n    border-radius: 50%;\n    align-items: center;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollablehorizontalitems": "installment_scrollablehorizontalitems__8eCHe",
	"hidden": "installment_hidden__e2Qcj",
	"rightarrow": "installment_rightarrow__w81D7"
};
export default ___CSS_LOADER_EXPORT___;
