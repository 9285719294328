import Button from "components/Global/Button";
import Cardboard from "components/Global/Cardboard";
import Container from "components/Global/Container";
import FilterArea from "components/Global/FilterArea";
import InputSearch from "components/Global/InputSearch";
import List from "components/Global/List";
import ListContainer from "components/Global/ListContainer";
import Pagination from "components/Global/Pagination";
import SwitchButton from "components/Global/SwitchButton";
import AddUserIcon from "components/icons/AddUserIcon";
import EditIcon from "components/icons/EditIcon";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import React, { useEffect, useRef, useState } from "react";
import styles from "./profilesmpsd.module.css";
import api from "store/api/actions";
import DeleteIcon from "components/icons/CloseIcon";
import Modal from "components/Global/Modal";
import UserAdd from "components/Global/Forms/Profile/UserAdd";
import UserEdit from "components/Global/Forms/Profile/UserEdit";
import ProfileEdit from "components/Global/Forms/Profile/ProfileEdit";
import InfoArea from "components/Global/InfoArea";
import UserInformationLabel from "components/Global/UserInformationLabel";
import Table from "components/Global/Table";
import Label from "components/Global/Label";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import BankList from "components/Global/BankList";
import BannerArea from "components/Global/BannerArea";
import PobUp from "components/Global/PopUp/PobUp";
import CreditCardIcon from "components/icons/CreditCardIcon";
import CommissionIcon from "components/icons/CommissionIcon";
import SuitcaseIcon from "components/icons/SuitcaseIcon";
import { useMediaQuery } from 'react-responsive'
import ProfileResponsive from "components/Responsive/Profile";
import Information from "components/Global/Info/Information";

//#region initialState
const intialResponseState = {
  userId: "",
  userFullName: "",
  phoneNumber: "",
  email: "",
  lastAccessDate: "",
  roleName: "",
};

const initialUserEditResponseDataState = {
  userFullName: "",
  phoneNumber: "",
  email: "",
};

const initialCompanyInformationDataState = {
  companyName: "",
  companyType: "",
  contactName: "",
  address: "",
  cityName: "",
  districtName: "",
  taxOfficeName: "",
  countryName: "",
  phone: "",
  email: "",
  iban: "",
  taxNumber: "",
  bankOfTerm: "",
  comissionList: {
    commissionRate: "",
    commissionRate2: "",
    commissionRate3: "",
    commissionRate4: "",
    commissionRate5: "",
    commissionRate6: "",
    commissionRate7: "",
    commissionRate8: "",
    commissionRate9: "",
    commissionRate10: "",
    commissionRate11: "",
    commissionRate12: "",
  },
};

const initialUserProfilDataState = {
  userFullName: "",
  phoneNumber: "",
  email: "",
};
//#endregion

const SMPStandard = () => {
  const searchInput = useRef(null);

  // #region Pagination state
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]])
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [searchData, setSearchData] = useState();
  const [getCompanyInformationData, setGetCompanyInformationData] = useState([
    [],
  ]);
  const [userProfileData, setUserProfileData] = useState();
  const [userEditData, setUserEditData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState(1);
  const [modalClick, setModalClick] = useState(false);
  const [selectedButtonStatus, setSelectedButtonStatus] = useState(false);

  const [isUserAddSelected, setIsUserAddSelected] = useState(false);
  const [isUserEditSelected, setIsUserEditSelected] = useState(false);
  const [selectId, setSelectId] = useState();
  const [isProfileEditSelected, setIsProfileEditSelected] = useState(false);

  const [popUpClick, setPopUpClick] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [popupPosition, setPopupPosition] = useState()
  const [currentRef, setCurrentRef] = useState()
  const refs = useRef([])
  const [deleteId, setDeleteId] = useState()
  const [userName, setUserName] = useState("")

  useEffect(() => {
    //GetAll
    getAllUserDataFromApi();

    //GetInformation
    getProfilDataFromApi();

    //GetCompanyInformation
    api
      .User()
      .GetCompanyInformationForSmallMarketPlace({})
      .then((response) => {
        //console.log("company", response);
        setGetCompanyInformationData(response.data.data);
      })
      .catch((error) => {
        console.log("GetCompanyInformationForSmallMarketPlace", error?.response?.data);
      })
      .finally(() => { });
  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(userProfileData)) {
      getAllUserDataFromApi();
    }
  }, [userProfileData]);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getAllUserDataFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    searchInput?.current?.focus();
  }, [isLoading]);

  useEffect(() => {
    if (pageType == 2) {
      setSelectedButtonStatus(true);
    }
    if (pageType == 1) {
      setSelectedButtonStatus(false);
    }
  }, [pageType]);

  useEffect(() => {
    if (checkNullOrEmpty(deleteId)) {
      deleteSelector(deleteId)
      setIsActive(false)
    }
    if (popUpClick)
      setPopUpClick(false)
  }, [popUpClick])

  // #region Pagination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row, i) => [
          <Label text={row.userFullName} />,
          <Label text={row.roleName} />,
          <div style={{ color: "#4f5386", fontSize: ".9rem" }}>
            {row.lastAccessDate === "0001-01-01T00:00:00" ||
              row.lastAccessDate == null ||
              row.lastAccessDate === "null" ? (
              "-"
            ) : (
              <>
                <span style={{ fontFamily: "Metropolis-SemiBold" }}>
                  {setTimeFormat(row.lastAccessDate)}
                </span>
                ,&nbsp;
                <span>{setDateFormat(row.lastAccessDate)}</span>
              </>
            )}
          </div>,
          <Label text={row?.phoneNumber === undefined
            ? ""
            : setPhoneNumberFormat(row?.phoneNumber, true)} />,
          <Label text={row.email} />,
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              columnGap: "20px",
            }}
          >
            <div
              onClick={() => {
                handleUserEditButtonClick(row.userId);
              }}
            >
              <EditIcon />
            </div>
            <div onClick={() => { deleteSelector(row.userId, i) }}><DeleteIcon /></div>
          </div>,
        ])
      );

      setCurrentItemsForResponsive(
        listData?.map((row, i) => [
          <Label text={row.userFullName} />,
          <Label text={row?.phoneNumber === undefined
            ? ""
            : setPhoneNumberFormat(row?.phoneNumber, true)} />,
          <div></div>,
          {
            details: row, i,
          },
        ])
      );
    } else {
      setCurrentItems([[]]);
      setCurrentItemsForResponsive([[]]);
    }
  }, [listData, itemOffset, itemsPerPage, userEditData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };
  //#endregion

  const getAllUserDataFromApi = () => {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {
      api
        .User()
        .GetAllUser(searchData)
        .then((response) => {
          //console.log("responselist", response.data.data);
          if (checkNullOrEmptyArray(response.data.data.userList)) {
            setResponseData(response.data.data.userList.filter((row) => userProfileData.userId !== row.userId));
            setListData(response.data.data.userList.filter((row) => userProfileData.userId !== row.userId));
          } else {
            setResponseData([]);
            setListData([]);
          }
          setresponseListTotalCount(
            response.data.data.userListTotalCount
          );
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getProfilDataFromApi = () => {
    api
      .User()
      .GetInformation({})
      .then((response) => {
        setUserProfileData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
  };

  const handleUserEditButtonClick = (id) => {
    setIsUserAddSelected(false);
    setIsProfileEditSelected(false);
    //console.log("id", id);
    api
      .User()
      .GetById({ userId: id })
      .then((response) => {
        setUserEditData(response.data.data);
        setSelectId(id);
        setIsUserEditSelected(true);
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => { });
    handleModalClick(true);
  };

  const deleteSelector = (id, listId) => {
    const ref = refs.current[listId]
    setUserName(responseData.filter((item) => item.userId == id)[0].userFullName)
    setCurrentRef(ref)
    setDeleteId(id)
    setIsActive(true)
    if (popUpClick) {
      handleUserDeleteButtonClick(id)
      setIsActive(true)
    } else {
      setIsActive(true)
    }
  }

  const handleUserDeleteButtonClick = (id) => {
    setIsLoading(true);
    api
      .User()
      .Delete({ userId: id })
      .then((response) => {
        getAllUserDataFromApi();
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClick = (status) => {
    setModalClick(status);
    if (status == false) {
      getAllUserDataFromApi();
      getProfilDataFromApi();
    }
  };

  const handleProfileEditButtonClick = () => {
    setIsUserAddSelected(false);
    setIsUserEditSelected(false);
    setIsProfileEditSelected(true);
    handleModalClick(true);
  };

  const setDateFormat = (dateTime) => {
    var date = dateTime.slice(0, 16);
    var year = date.slice(0, 4);
    var month = date.slice(5, 7);
    var day = date.slice(8, 10);
    return day + "." + month + "." + year;
  };

  const setTimeFormat = (dateTime) => {
    var date = dateTime.slice(0, 16);
    var time = date.slice(11, 16);
    return time;
  };

  const setPhoneNumberFormat = (phoneNumber, areaCode = false) => {
    return (
      (areaCode ? ("0 (") : ("+90 (")) +
      phoneNumber.slice(0, 3) +
      ") " +
      phoneNumber.slice(3, 6) +
      " " +
      phoneNumber.slice(6, 8) +
      " " +
      phoneNumber.slice(8, 10)
    );
  };

  const handleSearchChange = (e) => {
    const value = e?.target?.value?.trim();
    //console.log("value",value)
    if (checkNullOrEmpty(value)) {
      setSearchData({ ...searchData, userFullName: value });
    } else {
      setSearchData({ ...searchData, userFullName: "" });
    }
  };

  const handlePopUp = (status) => {
    setPopUpClick(status)
    setIsActive(false)
  }

  const filterItems = [
    <SwitchButton
      items={[
        { id: 1, text: "Kullanıcı Bilgileri" },
        { id: 2, text: "Firma Bilgileri" },
      ]}
      callback={setPageType}
      style={{ width: "25%", height: "3rem" }}
      selectedValue={pageType}
    />,
    <div style={{ width: "40%" }}></div>,
    <InputSearch
      width="15%"
      placeholder={"Kullanıcı"}
      onChange={handleSearchChange}
    />,
    <Button
      height="3rem"
      fontSize="0.9rem"
      width="10%"
      text="Kullanıcı Ekle"
      onClick={() => {
        setIsUserAddSelected(true);
        setIsUserEditSelected(false);
        setIsProfileEditSelected(false);
        handleModalClick(true);
      }}
      icon={<AddUserIcon color="#fff" />}
    />,
  ];

  const companyInformationFilter = [
    <SwitchButton
      items={[
        { id: 1, text: "Kullanıcı Bilgileri" },
        { id: 2, text: "Firma Bilgileri" },
      ]}
      callback={setPageType}
      style={{ width: "25%", height: "3rem" }}
      selectedValue={pageType}
    />,
    <div style={{ width: "65%" }}></div>,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <ProfileResponsive
        setPageType={setPageType}
        pageType={pageType}
        currentItems={currentItemsForResponsive}
        handleSearchChange={handleSearchChange}
        userProfileData={userProfileData}
        getCompanyInformationData={getCompanyInformationData}
        getAllUserDataFromApi={getAllUserDataFromApi}
        getProfilDataFromApi={getProfilDataFromApi}
        selectId={selectId}
        handleProfileEditButtonClick={handleProfileEditButtonClick}
        setSelectId={setSelectId}
        handleUserDeleteButtonClick={handleUserDeleteButtonClick}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea
          marginTop="0"
          width="100%"
          items={
            selectedButtonStatus === false
              ? filterItems
              : companyInformationFilter
          }
        />
        {pageType === 1 ? (
          <Container flexDirection="column" >
            <Modal
              modalStyle={{
                boxShadow: "none",
                flexBasis: "70%",
                width: "30%",
                alignItems: "stretch",
              }}
              clickEvent={modalClick}
              callbackEvent={handleModalClick}
              body={
                isUserEditSelected === true ? (
                  <UserEdit
                    userEditData={userEditData}
                    selectId={selectId}
                    handleModalClick={handleModalClick}
                  />
                ) : isProfileEditSelected === true ? (
                  <ProfileEdit
                    userProfileData={userProfileData}
                    handleModalClick={handleModalClick}
                  />
                ) : (
                  <UserAdd handleModalClick={handleModalClick} />
                )
              }
            />
            <Cardboard style={{ padding: "20px" }}>
              <InfoArea
                style={{ height: "1rem", width: "100%" }}
                containerStyle={{ width: "100%", marginRight: "20px" }}
              >
                <UserInformationLabel
                  color="#000d89"
                  fontFamily="Metropolis-Bold"
                  text={userProfileData?.userFullName}
                  size="2rem"
                />
                <Button
                  width="150px"
                  text="Düzenle"
                  onClick={() => {
                    handleProfileEditButtonClick(userProfileData);
                  }}
                  icon={<EditIcon color="#fff" />}
                />
              </InfoArea>
              <InfoArea>
                <div className={styles.normallabel}>
                  <Label text="Telefon" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={
                      userProfileData?.phoneNumber === undefined
                        ? ""
                        : setPhoneNumberFormat(userProfileData.phoneNumber)
                    }
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="E-posta" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.email}
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="Kullanıcı İsmi" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={userProfileData?.userFullName}
                  />
                </div>
                <div className={styles.normallabel}>
                  <Label text="Şifre" />
                  <UserInformationLabel
                    color="#4f5386"
                    size="2"
                    text={"*********"}
                  />
                </div>
              </InfoArea>
            </Cardboard>
            <Cardboard>
              <ListContainer>
                <Pagination
                  width="20%"
                  handlePageClick={handlePageClick}
                  pageCount={pageCount}
                  setItemsPerPage={setItemsPerPage}
                  infoCount={responseListTotalCount === 0 ? 0 : responseListTotalCount - 1}
                  itemsPerPage={itemsPerPage}
                  listDataLength={listData?.length}
                />

                <PobUp
                  callbackButtonEvent={handlePopUp}
                  clickEvent={popUpClick}
                  content="kullanıcınızı silmek istediğinize emin misiniz ?"
                  isCustomer={true}
                  isActive={isActive}
                  position={popupPosition}
                  ref={currentRef}
                  headerName={userName}
                />
                <List
                  columnNames={[
                    "Kullanıcı İsmi",
                    <div className={styles.info}>Kullanıcı Yetki Türü<Information profileInformation={true} text="Kullanıcı yetki türü kayıt oluşturulurken atanmış olan yetki türüdür." /></div>,
                    "Son Erişim Zamanı",
                    "Telefon",
                    "E-posta",
                    "",
                  ]}
                  data={currentItems}
                  ref={refs}
                />
              </ListContainer>
            </Cardboard>
          </Container>
        ) : (
          <>
            <Container marginTop="20px" columnGap="20px">
              <div className={styles.leftside}>
                <Table
                  data={[
                    {
                      name: "Firma",
                      value: getCompanyInformationData.companyName,
                    },
                    {
                      name: "Firma Tipi",
                      value: getCompanyInformationData.companyType,
                    },
                    {
                      name: "Yetkili Kişi",
                      value: getCompanyInformationData.contactName,
                    }, //BackEnd sorunu giderilecek
                    { name: "Vergi Dairesi", value: "Vergi Dairesi" }, //muallak olup olmaması
                    {
                      name: "Vergi No",
                      value: getCompanyInformationData.taxNumber,
                    },
                    { name: "Adres", value: getCompanyInformationData.address },
                    {
                      name: "İl / İlçe",
                      value:
                        getCompanyInformationData.cityName +
                        " / " +
                        getCompanyInformationData.districtName,
                    },
                    { name: "Telefon", value: getCompanyInformationData.phone },
                    { name: "E - posta", value: getCompanyInformationData.email },
                    { name: "Müşteri Key", value: "Müşteri Key" }, //ERP ile gelicek
                    { name: "IBAN", value: getCompanyInformationData.iban },
                    { name: "ERP Bayi Kodu", value: "0" },
                    { name: "ERP Kullanıcı Adı", value: "0" },
                    { name: "ERP Şifre", value: "0" },
                  ]}
                />
              </div>
              <div className={styles.rightside}>
                <div className={styles.righttable}>
                  <Table
                    data={[
                      {
                        name: <SuitcaseIcon text="Çalışma Koşulları" />,
                        value: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <Label
                              bold
                              fontSize="1.2rem"
                              text={
                                getCompanyInformationData.bankOfTerm +
                                " gün vadeli çalışmaktasınız."
                              }
                            />
                            <Label
                              fontSize=".8rem"
                              text="Bağlı olduğunuz ana firma tarafından anlaşma sağlanmıştır."
                            />
                          </div>
                        ),
                      },
                      {
                        name: <CommissionIcon text="Komisyon Oranları" />,
                        value: (
                          <List
                            columnNames={[
                              "Taksit Sayısı",
                              "Peşin",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                              "9",
                              "10",
                              "11",
                              "12",
                            ]}
                            data={[
                              [
                                <Label fontSize=".8rem" text="Komisyon Oranı" />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate2
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate3
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate4
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate5
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate6
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate7
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate8
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate9
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate10
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate11
                                  }
                                />,
                                <Label
                                  bold
                                  fontSize=".8rem"
                                  text={
                                    getCompanyInformationData.comissionList
                                      .commissionRate12
                                  }
                                />,
                              ],
                            ]}
                          />
                        ),
                      },
                      {
                        name: <CreditCardIcon color="#000D89" text="Geçerli Kartlar" />,
                        value: <BankList style={{ width: "100%" }} />,
                      },
                    ]}
                  />
                </div>
                <BannerArea style={{ backgroundColor: "gray" }} />
              </div>
            </Container>
          </>
        )}
      </>
  );
};

export default SMPStandard;
