import React, { useEffect } from 'react';
import styles from './inputsearch.module.css';
import SearchIcon from 'components/icons/SearchIcon';

const InputSearch = ({ marginRight, width, height = '3rem', placeholder, onChange, value, disabled, onFocus, fontFamily, fontSize }) => {
    useEffect(() => {
        console.log("first")
    }, [onFocus]);

    return <>
        <div className={styles.searchcontainer} style={{ marginRight, width, height }}>
            <div className={styles.inputarea}>
                <input onChange={onChange} value={value} placeholder={placeholder} className={styles.input} type="text" disabled={disabled} onFocus={onFocus} style={{ fontFamily, fontSize }} />
            </div>
            <div className={styles.logoarea}>
                <SearchIcon width="20" height="20" color="#000d89" />
            </div>
        </div>
    </>;
};

export default InputSearch;
