import Layout from '../../components/Layout/Layout';
import GlobalSupport from '../../components/Support';

export default function Support (){
    return (
        <Layout>
            <GlobalSupport />
        </Layout>
    )
}
