import * as yup from 'yup';

const groupLinkValidator = yup.object().shape({
    customerFullName: yup.string().when('pobDealerCustomerGroupId', {
        is: (pobDealerCustomerGroupId) => !pobDealerCustomerGroupId || pobDealerCustomerGroupId.length === 0,
        then: yup.string().max(50).required("Müşteri Grubu zorunludur."),
        otherwise: yup.string()
    }),

})

export default groupLinkValidator