// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customeradd_formitems__S78EH{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n}\n\n.customeradd_forminfo__4MlTa{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n    margin-bottom: 20px;\n}\n\n.customeradd_inputgroup__7Y7Ao{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.customeradd_form__qRv1n{\n    width: 90%;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Forms/Payment/customeradd.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,8BAA8B;IAC9B,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".formitems{\n    display:flex;\n    flex-direction:column;\n    justify-content :space-between;\n    height: 100%;\n    width: 100%;\n    margin: 1rem;\n}\n\n.forminfo{\n    display:flex;\n    width: 100%;\n    font-size: 1.7rem;\n    font-weight: bold;\n    color: #000d89;\n    margin-bottom: 20px;\n}\n\n.inputgroup{\n    display : flex;\n    flex-direction: column;\n    justify-content: space-around;\n    width: 100%;\n    height: 100%;\n    row-gap: 10px;\n}\n\n.form{\n    width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formitems": "customeradd_formitems__S78EH",
	"forminfo": "customeradd_forminfo__4MlTa",
	"inputgroup": "customeradd_inputgroup__7Y7Ao",
	"form": "customeradd_form__qRv1n"
};
export default ___CSS_LOADER_EXPORT___;
