import React from 'react';

//css
const styles = {
    icon: {
        width: '30px',
        height: '20px',
        pointerEvents: 'none',
    },
};


const DownloadIcon = ({ width, height }) => {
    return <>
        <div style={styles.icon}>
            <symbol id="download">
                <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.522 21.354c.477 0 .918.252 1.156.661.239.41.239.914 0 1.323-.238.41-.679.662-1.156.662H4.335c-.477 0-.918-.252-1.156-.662a1.313 1.313 0 0 1 0-1.323c.238-.41.679-.661 1.156-.661h16.187zM13.096.177c.413.237.667.673.667 1.146V15.28l4.801-4.758c.338-.332.828-.46 1.287-.338.46.123.818.478.942.933.123.455-.006.941-.341 1.276l-7.08 7.017a1.337 1.337 0 0 1-1.356.323l-.193-.08a1.328 1.328 0 0 1-.338-.243l-7.08-7.017a1.316 1.316 0 0 1-.34-1.276 1.33 1.33 0 0 1 .941-.933c.46-.123.95.006 1.287.338l4.799 4.757.002-13.955c0-.473.254-.91.667-1.146a1.345 1.345 0 0 1 1.335 0z" fill="#fff" />
                </svg>
            </symbol>
        </div>
    </>
};

export default DownloadIcon;