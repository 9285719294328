import Button from 'components/Global/Button'
import Cardboard from 'components/Global/Cardboard'
import Dropdown from 'components/Global/Dropdown'
import CustomerUpdate from 'components/Global/Forms/Customer/Update'
import InputSearch from 'components/Global/InputSearch'
import List from 'components/Global/List'
import CustomerCollapse from 'components/Global/List/CollapseForCustomerResponsive'
import ListContainer from 'components/Global/ListContainer'
import PobUp from 'components/Global/PopUp/PobUp'
import SwitchButton from 'components/Global/SwitchButton'
import AddUserIcon from 'components/icons/AddUserIcon'
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ResponsiveFilterArea from '../FilterArea'
import ResponsiveModal from '../Modal'
import styles from "./customerresponsive.module.css"
import api from '../../../store/api/actions'
import CustomerAdd from 'components/Global/Forms/Customer/Add'
import CustomerGroupAdd from 'components/Global/Forms/Customer/GroupAdd'
import CustomerGroupUpdate from 'components/Global/Forms/Customer/GroupUpdate'

const CustomerResponsive = ({ item, handleSearchChange, getCustomerGroupListFromApi, addModalClick, getCustomerListFromApi, groupResponseData, setUpdateCustomer, id, handleGroupSelect, selectedGroupValue, customerName, currentItemsResponsive, handleUpdateCustomerButtonClick, handleDeleteCustomerButtonClick, }) => {

    const [modalClick, setModalClick] = useState(false)
    const [isClosed, setIsClosed] = useState(true)
    const [hereClosed, setHereClosed] = useState(true)
    const [groupClosed, setGroupClosed] = useState(true)
    const [switchCallback, setSwitchCallback] = useState(1);
    const [deleteId, setDeleteId] = useState()
    const [currentRef, setCurrentRef] = useState()
    const [isActive, setIsActive] = useState(false);
    const [customerNames, setCustomerNames] = useState("")
    const [popUpClick, setPopUpClick] = useState(false);
    const [customersId, setCustomersId] = useState();
    const [customerData, setCustomerData] = useState();
    const [groupAdd, setGroupAdd] = useState(false)
    const [groupUpdate, setGroupUpdate] = useState()
    // const [addCutomer, setAddCutomer] = useState()

    const refs = useRef([])
    // const [groupResponseData, setGroupResponseData] = useState()


    useEffect(() => {
        console.log("Normal durum: ", isClosed)
        console.log("Düzenleme için durum: ", hereClosed)
    })


    // useEffect(() => {
    //     console.log("addodalclikc", addModalClick)
    //     if (addModalClick == false) {
    //         setIsClosed(true);
    //     }
    // }, [addModalClick])


    // useEffect(() => {
    //     getCustomerGroupListFromApi()
    // }, [])


    useEffect(() => {
        currentItemsResponsive?.map((item) => item.filter((row) => id == row?.details?.customerId)).map((index) => checkNullOrEmptyArray(index) && setCustomerData(index[0]?.details))
        console.log("customer data yazdırma: ", customerData)
    }, [id])




    useEffect(() => {
        if (checkNullOrEmpty(deleteId)) {
            deleteSelector(deleteId)
            setIsActive(false)
        }
        if (popUpClick)
            setPopUpClick(false)
    }, [popUpClick])

    useEffect(() => {
        if (switchCallback == 2) {
            setGroupAdd(true)
        } else {
            setGroupAdd(false)
        }
    }, [switchCallback])



    const handleUpdateClick = (id) => {
        // console.log("handleUpdateClick İÇERİK", id)
        setIsClosed(false);
        setHereClosed(false)
        handleUpdateCustomerButtonClick(id);
        setCustomersId(id);
        // getCustomerGroupListFromApi();
    }

    const handleCustomerAdd = () => {
        setHereClosed(true)
        setIsClosed(false)
        setUpdateCustomer(undefined)

    }

    // const handleModalClick = (status) => {
    //     if (status == false) {
    //         getCustomerListFromApi()
    //     }
    //     setModalClick(status);
    //     setIsClosed(true);
    //     setHereClosed(true);
    //     addModalClick = true
    // }

    const handleModalClick = (status) => {
        setModalClick(status);
        console.log("STATUS DEĞERİ: ", status);
        setHereClosed(true);

        //modal kapandığında verileri yenile (apiden tekrar getir)
        if (status == false) {
            // setGroupResponseData(undefined)
            getCustomerListFromApi();
            getCustomerGroupListFromApi();
            setUpdateCustomer(undefined);
            setGroupUpdate(undefined);
            setIsClosed(true);
            // setIsGroupSelect(false);
        }
    }

    const handleCustomerDeleteClick = (id) => {
        handleDeleteCustomerButtonClick(id)
    }

    const handleCustomerUpdateClick = (id) => {
        handleUpdateCustomerButtonClick(true)
        setGroupAdd(false)
    }

    const handlegroupAddClick = (id) => {
        handleUpdateCustomerButtonClick(false)
        setGroupAdd(true)
    }

    const SetModalBodyElement = () => {
        if (!groupAdd) {
            if (!checkNullOrEmpty(customersId)) {
                return <CustomerAdd groups={groupResponseData} handleModalClick={handleModalClick} />
            }
            else {
                return <CustomerUpdate customer={customersId} groups={groupResponseData} handleModalClick={handleModalClick} />
            }
        }
        else {
            if (!checkNullOrEmpty(groupUpdate)) {
                return <CustomerGroupAdd handleModalClick={handleModalClick} />
            }
            else {
                return <CustomerGroupUpdate group={groupUpdate} handleModalClick={handleModalClick} />
            }
        }
    }



    // const getCustomerGroupListFromApi = () => {

    //     api.CustomerGroup()
    //         .GetAll({})
    //         .then((response) => {
    //             console.log("Customer Grup Response : ", response.data.data)
    //             if (checkNullOrEmptyArray(response.data.data)) {
    //                 setGroupResponseData(response.data.data.map(item => ({
    //                     id: item.pobDealerCustomerGroupId,
    //                     text: item.customerGroupName
    //                 })));
    //             }

    //         }).catch((error) => {
    //             console.log("Grup Hatası : ", error?.response?.data);
    //         });
    // }


    //ŞUAN ALINAN SONUCU BİR DEĞERE TANIMLA, ONUN İÇERİSİNDE İLERLEYEREK İSİM DEĞERİNE ULAŞ?????

    const deleteSelector = (id, listId) => {
        const ref = refs.current[listId]
        setCustomerNames((currentItemsResponsive?.map((item) => item.filter((row) => row.details))).map((item) => item.map((arrow) => arrow.details.customerName))[listId])
        // customerDatas.map((item) => console.log("İSİM YAZACAK OLAN YER: ",item.map((arrow) => console.log("İSİM ARROW: ",arrow.details.customerName))))
        // currentItemsResponsive?.map((item) => item.filter((row) => id == row?.details?.id)).map((index) => checkNullOrEmptyArray((index)) )
        // currentItemsResponsive?.map((item) => item.filter((row) => id == row?.details?.customerId)).map((index) => setCustomerNames(index?.details?.customerName))
        // currentItemsResponsive?.map((item) => item.filter((row) => customerName === row?.details?.customerName)).map((index) => setCustomerNames(index[listId]?.details?.customerName))
        // setCustomerNames((currentItemsResponsive?.map((item) => item.filter((row) => row.details?.customerName))).details)
        // listid id tutunca oradaki isim değerini al set işlemi yap
        // console.log("id içeriği: ", listId)
        // console.log("UĞRAŞILAN: ", currentItemsResponsive?.map((item) => item.filter((row) => row.details)))
        // const findList = itemLists.map((item,index) =>
        //     currentItemsResponsive?.map((item) => item.filter((row) => row.details)))
        // console.log("İSİM ULAŞIŞAN: ",customerDatas[listId])
        // }
        // setCustomerDatas(currentItemsResponsive?.map((item) => item.filter((row) => row.details)))
        // console.log("İSİM ALINMAYA ÇALIŞILAN: ", customerDatas[0]?.details)
        // customerDatas?.map((index) => setCustomerNames(index[0]?.details?.customerName ))
        // console.log("AD GELDİ Mİ: ", customerName)
        // item.map((arrow) => console.log("İSİM ARROW: ",arrow.details.customerName))
        // setDeneme(arrow.details.customerName)
        // for(let i = 0; i<=listId; i++){
        //     console.log("deneme")
        //     if(listId == id){
        //         currentItemsResponsive?.map(setCustomerNames((item) => item.filter((row) => row.details?.customerName))?.index[i]?.details?.customerName)
        //     }
        //     else
        //         continue
        // }
        // console.log("İSİM BURADALARDA OLMALI????: ", currentItemsResponsive?.map((item) => item.filter((row) => row.details?.customerName)))
        // console.log("ref içinde bunlar var: ", ref)

        setCurrentRef(ref)
        setDeleteId(id)
        setIsActive(true)
        if (popUpClick) {
            handleCustomerDeleteClick(id)
            setIsActive(true)
            setHereClosed(true)
        } else {
            setIsActive(true)
            setHereClosed(true)
        }
    }

    const handlePopUp = (status) => {
        setPopUpClick(status)
        setIsActive(false)
        setHereClosed(true)
        console.log("KAPATMA BUTONU")
    }

    const pull_data = (customerId) => {
        return currentItemsResponsive.find(item => item?.details?.id == customerId);
    }

    return (
        <>

            <ResponsiveModal
                clickEvent={modalClick}
                isClosed={isClosed}
                body={
                    (<div className={styles.modalinside}>
                        <div style={{ width: '90%', height: '3rem' }}                        >
                            <SwitchButton
                                items={[
                                    {
                                        id: 1,
                                        text: 'Yeni Müşteri Ekle',
                                    },
                                    {
                                        id: 2,
                                        text: 'Yeni Grup Ekle',
                                    }
                                ]}
                                style={{ width: '100%', height: '3rem' }}
                                callback={setSwitchCallback}
                                selectedValue={switchCallback}
                                clickEvent={handleModalClick}
                                onClick={handleModalClick}
                                handleModalClick={handleModalClick}
                            />
                        </div>
                        <SetModalBodyElement />
                    </div>)
                }
                callbackEvent={handleModalClick}
            />
            {/* <ResponsiveModal
                clickEvent={modalClick}
                HereClosed={setHereClosed}
                body={
                    <div className={styles.modalinside}>
                        <SetModalBodyElement />
                    </div>
                }
                callbackEvent={handleModalClick}
            /> */}

            <ResponsiveFilterArea width={"95%"} firstElement={
                <div className={styles.filter}>
                    <div className={styles.inputarea}>
                        <InputSearch
                            placeholder={"Müşteri ara"}
                            onChange={handleSearchChange}
                            width="210px"
                            fontFamily="Metropolis"
                            fontSize="12px"
                        />
                    </div>

                    <div className={styles.addbutton}>
                        <Dropdown
                            width="210px"
                            items={groupResponseData}
                            onSelected={handleGroupSelect}
                            selectItemId={selectedGroupValue}
                            text="Tüm Liste"
                            fontFamily="Metropolis-bold"
                            fontSize="12px"
                        />
                    </div>
                    <div className={styles.addbutton}>

                        <Button fontSize='12px' onClick={handleCustomerAdd} backgroundImage={"none"} icon={<AddUserIcon />} />

                    </div>
                </div>
            }
                isButtonActive={false}
            >
            </ResponsiveFilterArea>

            <div className={styles.container}>
                <Cardboard >
                    <ListContainer>
                        <List
                            collapsed
                            columnNames={[
                                "MÜŞTERİ İSMİ",
                                "TELEFON",
                                "",
                                ""

                            ]}
                            data={currentItemsResponsive}
                            ref={refs}
                            CollapseComponent={<CustomerCollapse handleUpdateClick={handleUpdateClick} deleteSelector={deleteSelector} customerNames={customerNames} currentRef={currentRef} isActive={isActive} handlePopUp={handlePopUp} popUpClick={popUpClick} handleCustomerDeleteClick={handleCustomerDeleteClick} func={pull_data} />}
                        />
                        <PobUp
                            callbackButtonEvent={handlePopUp}
                            clickEvent={popUpClick}
                            content="kullanıcınızı silmek istediğinize emin misiniz ?"
                            isCustomer={true}
                            isActive={isActive}
                            ref={currentRef}
                            headerName={customerNames}
                        />
                    </ListContainer>

                </Cardboard>
            </div>
        </>
    )
}

export default CustomerResponsive