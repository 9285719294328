import { useState, useEffect } from "react";
import styles from "./willaccountedbmps.module.css";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import Cardboard from "components/Global/Cardboard";
import SwitchButton from "components/Global/SwitchButton";
import PriceLabel from "components/Global/PriceLabel";
import ListContainer from "components/Global/ListContainer";
import api from "store/api/actions";
import { Link } from "react-router-dom";
import InfoArea from "components/Global/InfoArea";
import List from "components/Global/List";
import Pagination from "components/Global/Pagination";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import Dropdown from "components/Global/Dropdown";
import RightIcon from "components/icons/RightIcon";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import ExportCSV from "hoc/ExportCSV";
import TablePriceLabel from "components/Global/TablePriceLabel";
import Label from "components/Global/Label";
import { useLocation } from "react-router";
import DateTimePicker from "components/Global/DateTimePickerv2";
import Cookies from "universal-cookie";
import Information from "components/Global/Info/Information";
import { useMediaQuery } from 'react-responsive'
import AccountedForAndWillAccountedResponsive from "components/Responsive/AccountedForAndWillAccounted";
import ExportExcel from "hoc/ExportExcel";

const initialPaymentsResponseState = {
  totalNetAccountAmountAndCurrency: "", //net hesaba geçen tutar
  totalCancellationAndRefundAmountAndCurrency: "", //iptal ve iade
  totalSuccessTransactionAmountAndCurrency: "", //başarılı işlem tutarı
  totalComissionAmountAndCurrency: "", //Komisyon
  paymentList: [],
  paymentListTotalCount: 0,
};

const initialPaymentsRequestState = {
  startTransactionDate: "",
  endTransactionDate: "",
  userList: [],
  listingTypeId: 0,
  limit: 0,
  orderBy: 0,
  pageNo: 0,
};

const initialPaymentListDataState = {
  transactionDateOrUserName: "",
  netAccountAmountAndCurrency: "", //net hesaba geçen
  successTransactionAmountAndCurrency: "", // net işlem tutarı
  cancellationAndRefundAmountAndCurrency: "", // iptal tutarı ve iade tutarı
  comissionAmountAndCurrency: "",
};

const initialUserState = {
  userId: 0,
  userFullName: "",
};

const WillAccountedSMP = () => {
  const { state } = useLocation(); // dashboard tarih filtresi(01.03.2022)
  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState(
    initialPaymentsResponseState
  );
  const [usersData, setUsersData] = useState([]);

  const [pageType, setPageType] = useState(1); //pageType int ve defaultu 1 dir
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [fileName, setFileName] = useState("Hesaba Geçecekler");
  const [exportData, setExportData] = useState([]);
  const [termDay, setTermDay] = useState()

  const [allData, setAllData] = useState([])
  const [allSearchData, setAllSearchData] = useState({ limit: -1 });

  //#region Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]])
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  function removeDays(theDate, days) {
    console.log("theDate", theDate)
    var dateParts = theDate.split(".");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    console.log("exist : ", new Date(dateObject?.getTime() - days * 24 * 60 * 60 * 1000))
    return new Date(dateObject?.getTime() - days * 24 * 60 * 60 * 1000);
  }

  useEffect(() => {
    api.SmallMarketPlace().WillAccountedPaymentList(allSearchData)
      .then((response) => {
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setAllData(response.data.data.paymentList);
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });

    //console.log("first",state)
    if (state) {
      dashboardDateFilterData();
    }
    else {
      setSearchData({ ...searchData, limit: 10 });
    }

    getPaymentDataFromApi();

    api
      .User().GetAllUser({}).then((response) => {
        if (checkNullOrEmptyArray(response.data.data.userList)) {
          setUsersData(
            response?.data?.data?.userList.map((item) => ({
              id: item.userId,
              text: item.userFullName,
            }))
          );
        } else {
          setUsersData([]);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data);
      })
      .finally(() => { });

    const cookies = new Cookies();
    setTermDay(cookies.get("bankOfTerm")) //Get userRole
    // console.log('cookiegetinCustomer', userRole);

  }, []);

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getPaymentDataFromApi();
    }
  }, [searchData]);

  useEffect(() => {
    if (checkNullOrEmptyArray(allData)) {
      updateExportData(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (checkNullOrEmpty(pageType)) {
      setSearchData({ ...searchData, listingTypeId: pageType });
    }
  }, [pageType]);

  useEffect(() => {
    if (checkNullOrEmpty(selectedValue) && selectedValue !== 0) {
      setSearchData({ ...searchData, userList: [selectedValue] });
    } else {
      setSearchData({ ...searchData, userList: [] });
    }
  }, [selectedValue]);

  useEffect(() => {
    if (checkNullOrEmpty(state)) {
      dashboardDateFilterData();
    }
  }, [state]);


  //#region Pagination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((row) => [
          <Label text={row.transactionDateOrUserName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <TablePriceLabel price={row.comissionAmountAndCurrency} />,
          <TablePriceLabel price={row.successTransactionAmountAndCurrency} />,
          <TablePriceLabel
            price={row.cancellationAndRefundAmountAndCurrency}
          />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrUserName: formatDate(removeDays(row.transactionDateOrUserName, termDay)),
                // transactionDateOrUserName: row.transactionDateOrUserName,

                pobUserId: row.pobUserId,
                listingTypeId: pageType,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                İşlemlere git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
      setCurrentItemsForResponsive(
        listData?.map((row) => [
          <Label text={row.transactionDateOrUserName} />,
          <TablePriceLabel price={row.netAccountAmountAndCurrency} />,
          <div></div>,
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`/transactions`}
              state={{
                transactionDateOrUserName: formatDate(removeDays(row.transactionDateOrUserName, termDay)),
                // transactionDateOrUserName: row.transactionDateOrUserName,

                pobUserId: row.pobUserId,
                listingTypeId: pageType,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                İşlemlere git <RightIcon />
              </span>
            </Link>
          </div>,
        ])
      );
    } else {
      setCurrentItems([]);
      setCurrentItemsForResponsive([]);
    }
  }, [listData, itemOffset, itemsPerPage, usersData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }
  //#endregion

  function getPaymentDataFromApi() {
    setIsLoading(true);
    if (checkNullOrEmpty(searchData)) {

      api.SmallMarketPlace().WillAccountedPaymentList(searchData).then((response) => {
        // console.log("responselist", response.data.data);
        setResponseData(response.data.data);
        if (checkNullOrEmptyArray(response.data.data.paymentList)) {
          setListData(response.data.data.paymentList);
        } else {
          setListData([]);
        }
        setresponseListTotalCount(
          response.data.data.paymentListTotalCount
        );
      })
        .catch((error) => {
          console.log("error", error?.response?.data);
          setListData([]);
        })
        .finally(() => {
          setIsLoading(false);
        });

    }
  }

  const updateExportData = (listValue) => {
    setExportData(
      listValue.map((row) => {
        return {
          "": `${row.transactionDateOrUserName}`,
          "Net Hesaba Geçen": `${row.netAccountAmountAndCurrency}`,
          "Ödenen Komisyon": `${row.comissionAmountAndCurrency}`,
          "Başarılı Tutar": `${row.successTransactionAmountAndCurrency}`,
          "İptal/İade": `${row.cancellationAndRefundAmountAndCurrency}`,
        };
      })
    );
  };

  const setNextDateFormat = (dateTime) => {
    let day = dateTime.slice(0, 2);
    let month = dateTime.slice(3, 5);
    let year = dateTime.slice(6, 10);

    return month + "/" + day + "/" + year;
  };

  const dashboardDateFilterData = () => {
    console.log(state);
    if (checkNullOrEmpty(state)) {
      setTimeout(() => {
        let nextDay = new Date(setNextDateFormat(state.dateNow));
        nextDay.setDate(
          new Date(setNextDateFormat(state.dateNow)).getDate() + 1
        );

        let startDate = state.dateNow;
        let endDate = new Date(nextDay).toLocaleDateString();

        setSearchData({
          ...searchData,
          startTransactionDate: setDateFormat(startDate),
          endTransactionDate: setDateFormat(endDate),
        });
      }, 1000);
    }
  };

  // Date range
  const setDateFormat = (dataTime) => {
    var day = dataTime.slice(0, 2);
    var month = dataTime.slice(3, 5);
    var year = dataTime.slice(6, 10);
    return year + "-" + month + "-" + day + "T00:00:00.000Z";
  };

  const handleDateRangeChange = (value) => {
    let startDate = new Date(value.startDate).toLocaleDateString();
    let endDate = new Date(value.endDate).toLocaleDateString();

    console.log(startDate, endDate);
    if (value.endDate != null) {
      setSearchData({
        ...searchData,
        startTransactionDate: setDateFormat(startDate),
        endTransactionDate: setDateFormat(endDate),
      });
    }
  };

  const filterItems = [
    <DateTimePicker
      onSelected={handleDateRangeChange}
      width="235px"
      termRange={termDay}
      isTermDay="true"
      dateTimePickerTypeId={1}
      stringVal={state?.dateNow}
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    <Dropdown
      width="40%"
      height="3rem"
      items={usersData}
      onSelected={setSelectedValue}
      text="Tüm Kullanıcılar"
      fontFamily="Metropolis-bold"
      fontSize="12px"
    />,
    // <ExportCSV
    //   csvData={exportData}
    //   fileName={fileName}
    //   text="İndir"
    //   width="7rem"
    // />,
    <ExportExcel
    excelData={exportData}
    fileName={fileName}
    text="İndir"
    />,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <AccountedForAndWillAccountedResponsive
        responsivePaginationType="1"
        setPageType={setPageType}
        pageType={pageType}
        totalNetAccountAmountAndCurrency={responseData.totalNetAccountAmountAndCurrency}
        totalComissionAmountAndCurrency={responseData.totalComissionAmountAndCurrency}
        totalSuccessTransactionAmountAndCurrency={responseData.totalSuccessTransactionAmountAndCurrency}
        totalCancellationAndRefundAmountAndCurrency={responseData.totalCancellationAndRefundAmountAndCurrency}
        currentItems={currentItemsForResponsive}
        usersData={usersData}
        termDay={termDay}
        setSelectedValue={setSelectedValue}
        handleDateRangeChange={handleDateRangeChange}
        setSearchData={setSearchData}
        searchData={searchData}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        <FilterArea
          width="100%"
          style={{ flexBasis: "40%" }}
          items={filterItems}
          SwitchItem={() => (
            <SwitchButton
              callback={setPageType}
              style={{ height: "3rem" }}
              items={[
                { id: 1, text: "Tarih Bazlı" },
                { id: 2, text: "Kullanıcı Bazlı" },
              ]}
              selectedValue={pageType}
            />
          )}
        />
        <Container flexDirection="column">
          <Cardboard>
            <InfoArea>
              <div className={styles.total}>
                <div className={styles.biglabel}>Net Hesaba Geçecek Tutar</div>
                <PriceLabel
                  price={responseData.totalNetAccountAmountAndCurrency}
                  size="3.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Komisyon Tutarı</div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalComissionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>Başarılı Tutar <Information text="Toplam tutardan iptal/iade tutarı düşülerek hesaplanmıştır." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalSuccessTransactionAmountAndCurrency}
                  size="2.5"
                />
              </div>
              <div className={styles.normallabel}>
                <div className={styles.info}>İptal / İade <Information text="İade işlemi belirtilen zaman aralığında olmamış olabilir." /></div>
                <PriceLabel
                  color="#4f5386"
                  price={responseData.totalCancellationAndRefundAmountAndCurrency}
                  size="2.5"
                />
              </div>
            </InfoArea>
            <div className={styles.chartcontainer}></div>
          </Cardboard>
          <Cardboard>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={listData?.length}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />
              <List
                columnNames={[
                  "",
                  "NET HESABA GEÇECEK",
                  "KOMİSYON TUTARI",
                  "BAŞARILI TUTAR",
                  "İPTAL/İADE",
                  "",
                  "",
                ]}
                data={currentItems}
              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};
export default WillAccountedSMP;
