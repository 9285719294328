import React, { useState, useEffect } from 'react';
import styles from './dropdownitem.module.css';
import cx from 'classnames';

const ButtonItem = ({ id, onClick, text, first, last, selectedCallback, isActive = false }) => {

  const [isClicked, setClicked] = useState(isActive);

  useEffect(() => {
    if (id != selectedCallback) {
      setClicked(false);
    }
  }, [selectedCallback])

  useEffect(() => {
    setClicked(isActive)
  }, [isActive])

  const handleClick = (e) => {
    const id = e?.target?.id;
    const text = e?.target?.textContent;
    if (isClicked) {
      onClick({ 'id': 0, 'text': null });
    }
    else {
      onClick({ id, text });
    }
    setClicked(!isClicked);
  }

  return (
    <div
      id={id}
      onClick={handleClick}
      className={cx(styles.item, id % 2 == 0 ? styles.separator : '', isClicked && styles.clicked, first && styles.first, last && styles.last)}
    >{text}</div>
  )
};

export default ButtonItem;
