import { useState, useEffect } from "react";
import styles from "./support.module.css";
import Button from "components/Global/Button";
import api from "../../store/api/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import ListContainer from "components/Global/ListContainer";
import Pagination from "components/Global/Pagination";
import List from "components/Global/List";
import Dropdown from "components/Global/Dropdown";
import Input from "components/Global/Input";
import FilterArea from "components/Global/FilterArea";
import Container from "components/Global/Container";
import Cardboard from "components/Global/Cardboard";
import TextArea from "components/Global/TextArea";
import SupportCollapse from "components/Global/List/CollapseForSupport";
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import UploadFile from "./uploadFile";
import { useMediaQuery } from 'react-responsive'
import SupportResponsive from "components/Responsive/Support";

const schema = Yup.object({
  topic: Yup.string().required("Destek konusu zorunludur"),
  description: Yup.string().required("Açıklama zorunludur"),
});

const initialResponseState = {
  supportDate: "",
  topic: "",
  supportStatus: "",
  replyDate: "",
  fileUrl: "",
  details: {
    description: "",
    answerDescription: "",
  },
};

const initialSearchState = {
  supportTopicTypeId: 0,
  limit: 0,
  pageNo: 0,
  orderBy: 0
}

const Support = () => {
  // #region Pagination state
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([[]]);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1)
  const [responseListTotalCount, setresponseListTotalCount] =
    useState(0);
  //#endregion

  const [listData, setListData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [currentItemsForResponsive, setCurrentItemsForResponsive] = useState([[]])
  const [searchData, setSearchData] = useState();
  const [topicsData, setTopicsData] = useState([]);
  const [topicFilter, setTopicFilter] = useState("");
  const [filter, setFilter] = useState("");
  const [isSelectedTopic, setIsSelectedTopic] = useState()
  const [isSelectedFilterTopic, setIsSelectedFilterTopic] = useState()
  const [onSubmitSupport, setOnSubmitSupport] = useState(false)

  const [buttonDisable, setButtonDisable] = useState(false)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSupportDataFromApi();
    getTopicDataFromApi();
  }, []);

  useEffect(() => {
    if (onSubmitSupport)
      setOnSubmitSupport(false)
  }, [onSubmitSupport])

  useEffect(() => {
    setIsSelectedTopic(isSelectedTopic)
  }, [isSelectedTopic])

  useEffect(() => {
    setIsSelectedFilterTopic(isSelectedFilterTopic)
  }, [isSelectedFilterTopic])

  useEffect(() => {
    if (checkNullOrEmpty(pageNumber)) {
      setSearchData({ ...searchData, pageNo: pageNumber });
    }
  }, [pageNumber])

  useEffect(() => {
    if (checkNullOrEmpty(itemsPerPage)) {
      setSearchData({ ...searchData, limit: itemsPerPage });
    }
  }, [itemsPerPage])

  useEffect(() => {
    if (checkNullOrEmpty(searchData)) {
      getSupportDataFromApi();
    }
  }, [searchData]);

  //Pagination
  useEffect(() => {
    setPageCount(Math.ceil(responseListTotalCount / itemsPerPage));
  }, [responseListTotalCount, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmptyArray(listData)) {
      setCurrentItems(
        listData?.map((item) => [
          <div>
            <div>{setTimeFormat(item.supportDate)}</div>
            <div>{setDateFormat(item.supportDate)}</div>
          </div>,
          <div>{item.topic}</div>,
          <div>{item.supportStatus}</div>,
          <div>
            {item.replyDate === "0001-01-01T00:00:00" ? (
              "-"
            ) : (
              <>
                <div>{setTimeFormat(item.replyDate)}</div>
                <div>{setDateFormat(item.replyDate)}</div>
              </>
            )}
          </div>,
          // <div>{item.fileUrl === "" ? "-" : <>{item.fileUrl}</>}</div>,
          {
            details: item.details,
          },
        ])
      );

      setCurrentItemsForResponsive(
        listData?.map((item) => [
          <div>{item.topic}</div>,
          <div>{item.supportStatus}</div>,
          <div></div>,
          {
            details: item,
          },
        ])
      );

    } else {
      setCurrentItems([[]]);
      setCurrentItemsForResponsive([[]]);
    }
  }, [listData, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (checkNullOrEmpty(filter) && filter != 0) {
      setSearchData({ ...searchData, supportTopicTypeId: filter });
    } else {
      setSearchData({ ...searchData, supportTopicTypeId: 0 });
    }
  }, [filter]);

  useEffect(() => {
    (formik.values.description == "" || formik.values.supportTopicTypeId == "") ? setButtonDisable(true) : setButtonDisable(false)
  })

  const formik = useFormik({
    initialValues: {
      supportTopicTypeId: "",
      description: "",
      fileUrl: "",
    },
    //validationSchema: schema,
    onSubmit: (values) => {
      // console.log("formik", values);
      api
        .Support()
        .Create(values)
        .then((response) => {
          getSupportDataFromApi();
          setIsSelectedTopic()
          setFilter("")
          setIsSelectedFilterTopic()
          setOnSubmitSupport(true)
        })
        .catch((error) => {
          console.log("error", error?.response.data);
        })
        .finally(() => {
          formik.resetForm({});
        });
    },
  });

  const setDateFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var year = date.slice(0, 4);
      var month = date.slice(5, 7);
      var day = date.slice(8, 10);
      return day + "." + month + "." + year;
    }
  };
  const setTimeFormat = (dateTime) => {
    if (
      dateTime === "0001-01-01T00:00:00" ||
      dateTime == null ||
      dateTime === "null"
    ) {
      return "";
    } else {
      var date = dateTime.slice(0, 16);
      var time = date.slice(11, 16);
      return time;
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % responseListTotalCount;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };

  const getSupportDataFromApi = () => {
    setIsLoading(true);
    api
      .Support()
      .List(searchData)
      .then((response) => {
        //console.log("responselist", response.data.data.supportList);

        if (checkNullOrEmptyArray(response.data.data.supportList)) {
          setResponseData(response.data.data.supportList);
          setListData(response.data.data.supportList);
        } else {
          setListData([])
          setResponseData([])
        }
        setresponseListTotalCount(
          response.data.data.supportListTotalCount
        );
      })
      .catch((error) => {
        console.log("error", error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTopicDataFromApi = () => {
    api
      .Helpers()
      .GetAllSupport({})
      .then((response) => {
        //console.log("topic list",response.data.data)
        if (checkNullOrEmpty(response.data.data)) {
          setTopicsData(
            response?.data?.data.map((item) => ({
              id: item.id,
              text: item.topic,
            }))
          );
        }
      })
      .catch((error) => {
        console.log("Error", error?.response?.data);
      })
      .finally(() => { });
  };

  const handleTopicChange = (value) => {
    setFilter(value);
    setIsSelectedFilterTopic(value)
  };

  const handleSubmitSelectedDropdown = (value) => {
    formik.values.supportTopicTypeId = value;
    setIsSelectedTopic(value)
  };

  const filterItems = [
    <div className={styles.searchareafilter}>
      <Dropdown
        width="15%"
        items={topicsData}
        onSelected={handleTopicChange}
        selectItemId={filter}
        text="Destek Konusu"
        onSubmit={onSubmitSupport}
      />
    </div>,
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    isMobile ? <>
      <SupportResponsive
        currentItems={currentItemsForResponsive}
        topicsData={topicsData}
        topicFilter={topicFilter}
        filter={filter}
        setTopicFilter={setTopicFilter}
        setFilter={setFilter}
        formik={formik}
        onSubmitSupport={onSubmitSupport}
        isSelectedTopic={isSelectedTopic}
        setIsSelectedTopic={setIsSelectedTopic}
        isSelectedFilterTopic={isSelectedFilterTopic}
        setIsSelectedFilterTopic={setIsSelectedFilterTopic}
        getSupportDataFromApi={getSupportDataFromApi}
        handleSubmitSelectedDropdown={handleSubmitSelectedDropdown}
      />
    </>
      :
      <>
        <CustomLoadingBox isLoading={isLoading} />
        {/* Filter */}
        <FilterArea width="100%" marginTop="0" items={filterItems}></FilterArea>

        {/* Destek Al - List - Detail */}
        <Container>
          {/* Destek Al */}
          <Cardboard
            style={{
              flexBasis: "50%",
              maxHeight: "500px",
              minHeight: "500px",
              padding: "20px",
            }}
          >
            <div className={styles.forminfo}>Destek al</div>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
              <div className={styles.formitems}>
                <Dropdown
                  width="100%"
                  items={topicsData}
                  onSelected={handleSubmitSelectedDropdown}
                  selectItemId={topicFilter}
                  text="Destek talebinizin konusunu seçiniz"
                  error={formik.touched.supportTopicTypeId && Boolean(formik.errors.supportTopicTypeId)}
                  helperText={formik.touched.supportTopicTypeId && formik.errors.supportTopicTypeId}
                  onSubmit={onSubmitSupport}
                />
                <TextArea
                  width="100%"
                  id="description"
                  placeholder="Destek talebinizin konusunu yazınız."
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                {/* <Input
                width="100%"
                id="fileUrl"
                height="3.5rem"
                placeholder="Dosya yükleyiniz (Opsyonel)"
                name="fileUrl"
                onChange={formik.handleChange}
                value={formik.values.fileUrl}
              /> */}

                <Button
                  type="submit"
                  width="100%"
                  text="Gönder"
                  fontSize="1.3rem"
                  disabled={buttonDisable}
                />
              </div>
            </form>
          </Cardboard>
          <Cardboard style={{ flexGrow: "1", marginLeft: "20px" }}>
            <ListContainer>
              <Pagination
                width="20%"
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                setItemsPerPage={setItemsPerPage}
                infoCount={responseListTotalCount}
                itemsPerPage={itemsPerPage}
                listDataLength={listData?.length}
              />
              <List
                collapsed
                CollapseComponent={<SupportCollapse />}
                columnNames={[
                  "DESTEK TARİHİ VE SAATİ",
                  "DESTEK KONUSU",
                  "CEVAP DURUMU",
                  "CEVAPLAMA TARİHİ VE SAATİ",
                  // "YÜKLENEN DOSYA",
                  "",
                  "",
                  "",
                ]}
                data={currentItems}
              />
            </ListContainer>
          </Cardboard>
        </Container>
      </>
  );
};

export default Support;
