// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardarea_cardarea__qbSsy{\n    margin-top: 20px;\n    display:flex;\n    flex-direction: column;\n    justify-content: space-between;\n    flex-basis: 100%;\n    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);\n    border-radius: 16px;\n    background-color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/Cardboard/cardarea.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,gBAAgB;IAChB,kDAAkD;IAClD,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".cardarea{\n    margin-top: 20px;\n    display:flex;\n    flex-direction: column;\n    justify-content: space-between;\n    flex-basis: 100%;\n    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);\n    border-radius: 16px;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardarea": "cardarea_cardarea__qbSsy"
};
export default ___CSS_LOADER_EXPORT___;
