import FilterArea from 'components/Global/FilterArea';
import { useState, useEffect, useRef } from 'react';
import styles from './customerJp.module.css';
import InputSearch from 'components/Global/InputSearch';
import Button from 'components/Global/Button';
import AddUserIcon from 'components/icons/AddUserIcon';
import Cardboard from 'components/Global/Cardboard';
import Container from 'components/Global/Container';
import ListContainer from 'components/Global/ListContainer';
import Pagination from 'components/Global/Pagination';
import List from 'components/Global/List';
import Modal from 'components/Global/Modal';
import CustomerAdd from 'components/Global/Forms/Customer/Add';
import CustomerUpdate from 'components/Global/Forms/Customer/Update';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/CloseIcon';
import CustomLoadingBox from "hoc/CustomLoadingBox";
import api from '../../../store/api/actions'
import CustomerGroupAdd from '../../Global/Forms/Customer/GroupAdd'
import CustomerGroupUpdate from '../../Global/Forms/Customer/GroupUpdate'
import Dropdown from 'components/Global/Dropdown';
import { checkNullOrEmpty, checkNullOrEmptyArray } from 'hoc/Conditions';
import Label from 'components/Global/Label';
import PobUp from 'components/Global/PopUp/PobUp';
import AddIcon from 'components/icons/AddIcon';
import { useMediaQuery } from 'react-responsive';
import CustomerResponsive from 'components/Responsive/Customer/Index';


const initialSearchData = {
    customerGroupIdList: [
        "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    ],
    customerName: "string",
    limit: 0,
    pageNo: 0,
    orderBy: 0
};

const CustomerGlobal = () => {

    const refs = useRef([])
    const [currentRef, setCurrentRef] = useState()

    //DECLARATIONS for Func
    const [listData, setListData] = useState([]);//listelenecek veri (limit bilgisi kadar)
    const [listTotalCount, setListTotalCount] = useState(0);//veritabanında aslında bulunan tam veri saysısı
    const [searchData, setSearchData] = useState({ limit: 10 });

    const [groupResponseData, setGroupResponseData] = useState()
    const [updateCustomer, setUpdateCustomer] = useState();
    const [isLoading, setIsLoading] = useState();
    const [groupAdd, setGroupAdd] = useState(false)
    const [groupUpdate, setGroupUpdate] = useState()
    const [selectedGroupValue, setSelectedGroupValue] = useState()  // Grup Filtresi İçin
    const [isGroupSelect, setIsGroupSelect] = useState(false)
    const [modalClick, setModalClick] = useState(false);
    const [popupPosition, setPopupPosition] = useState()
    const [popUpClick, setPopUpClick] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const [isActive, setIsActive] = useState(false)

    //#region Paggination State

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState([[]]);
    const [currentItemsResponsive, setCurrentItemsResponsive] = useState([[]]);

    const [pageCount, setPageCount] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    const [pageNumber, setPageNumber] = useState(1)
    const [customerName, setCustomerName] = useState()
    const [addCutomer, setAddCutomer] = useState()
    //#endregion

    //#region Effects

    //Pagination

    useEffect(() => {
        if (checkNullOrEmpty(pageNumber)) {
            setSearchData({ ...searchData, pageNo: pageNumber });
        }
    }, [pageNumber])

    useEffect(() => {
        setPageCount(Math.ceil(listTotalCount / itemsPerPage));
    }, [listTotalCount, itemOffset, itemsPerPage]);

    useEffect(() => {
        if (checkNullOrEmptyArray(listData)) {
            setCurrentItemsResponsive(
                listData?.map((item, i) => [

                    <Label text={item.customerName} />,
                    <Label text={item.phone} />,
                    {
                        details: item, i
                    }

                ])
            )
            setCurrentItems(
                listData?.map((item, i) => [

                    <Label text={item.customerName} />,
                    <Label text={item.phone} />,
                    <Label text={item.email} />,
                    <Label text={item.customerGroups} />,
                    <div></div>,
                    <div></div>,
                    <div></div>,
                    <div></div>,
                    //guncelle silme islemi yapmak icin elementlere id verilip state uzerinden kontrol edilebilir

                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', cursor: 'pointer', columnGap: '20px', marginRight: '20px' }}>
                        <div onClick={() => { handleUpdateCustomerButtonClick(item) }}><EditIcon /></div>
                        {/* deleteselector icerisine indis degerini gondererek click esnasinda hangi indis numarasinda refini tutuyor amac bunu ogrenmek */}
                        <div onClick={() => { deleteSelector(item.id, i) }}><DeleteIcon /></div>
                    </div>

                ])
            );
        } else {
            setCurrentItems([[]]);
        }
    }, [listData, itemOffset, itemsPerPage]);

    useEffect(() => {
        setSearchData({ ...searchData, limit: 10 });
        getCustomerGroupListFromApi();

    }, [])

    useEffect(() => {
        if (checkNullOrEmpty(searchData)) {
            getCustomerListFromApi()
        }

    }, [searchData])

    useEffect(() => {
        if (checkNullOrEmpty(deleteId)) {
            deleteSelector(deleteId)
            setIsActive(false)
        }
        if (popUpClick) {
            setPopUpClick(false)
        }
    }, [popUpClick])

    //#endregion

    const getCustomerGroupListFromApi = () => {

        api.CustomerGroup()
            .GetAll({})
            .then((response) => {
                console.log("Customer Grup Response : ", response)
                if (checkNullOrEmptyArray(response.data.data)) {
                    setGroupResponseData(response.data.data.map(item => ({
                        id: item.pobDealerCustomerGroupId,
                        text: item.customerGroupName
                    })));
                }

            }).catch((error) => {
                console.log("Grup Hatası : ", error?.response?.data);
            });
    }

    const getCustomerListFromApi = () => {
        setIsLoading(true);
        // console.log("searchData",searchData)
        api.Customer()
            .GetAll(searchData)
            .then((response) => {

                // console.log("Customer Response : ", response.data.data)
                if (checkNullOrEmptyArray(response.data.data.customerList)) {
                    response.data.data.customerList.map(item => (
                        item.customerGroups = item.customerGroups.replace(/,/g, " /")
                    ))
                    setListData(response.data.data.customerList);

                } else {
                    setListData([]);
                }
                setListTotalCount(response.data.data.customerListTotalCount)

            }).catch((error) => {
                console.log("Customer Hatası : ", error?.response?.data);
            }).finally(() => {
                setIsLoading(false);
            });

    }

    useEffect(() => {
        if (checkNullOrEmpty(itemsPerPage)) {
            setSearchData({ ...searchData, limit: itemsPerPage });
        }
    }, [itemsPerPage])

    //#region Handlers

    //Paggination
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listTotalCount;
        setItemOffset(newOffset);
        setPageNumber(event.selected + 1);
    };

    const handleCreateCustomerButtonClick = () => {
        setUpdateCustomer(undefined);
        setGroupAdd(false)
        handleModalClick(true);
    }

    const handleUpdateCustomerButtonClick = (item) => {
        setUpdateCustomer(item);
        setGroupAdd(false)
        handleModalClick(true);
        console.log("GELENLER: ", item)
    }

    const handleUpdateCustomerGroupButtonClick = () => {
        const data = groupResponseData.find((element) => element.id === selectedGroupValue)
        if (checkNullOrEmpty(data)) {
            // console.log("handleUpdateCustomerGroupButtonClick", data, groupUpdate)
            setGroupUpdate(data)
            setIsGroupSelect(false)
            setGroupAdd(true)
            handleModalClick(true);
        }
    }

    const deleteSelector = (id, listId) => {
        //listId => ilgili rowun refini arraya appendladigi indis numarasi
        const ref = refs.current[listId]
        //state e refi setleyip popupa spawnlanacagi referansi iletiyoruz
        setCurrentRef(ref)
        setDeleteId(id)
        setIsActive(true)
        // console.log("DELETESELECTOR TEST: ",id)
        listData?.map((item) => {
            if (item?.id === id) {
                setCustomerName(item?.customerName)
                // console.log("Name :", item.)
            }
            console.log("AD BURADA: ", customerName)
        })
        // console.log("Delete :", popUpClick)

        if (popUpClick) {
            handleDeleteCustomerButtonClick(id)
            setIsActive(true)
        } else {
            setIsActive(true)
            console.log("Delete Cancel ")
        }
    }

    const handleDeleteCustomerButtonClick = (id) => {

        setIsLoading(true);
        // console.log("Delete Values : ", id)
        api.Customer().Delete({ pobDealerCustomerId: id })
            .then((response) => {

                getCustomerListFromApi();

            }).catch((error) => {
                console.log("Customer Hatası : ", error.response.data);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const handleCreateCustomerGroupButtonClick = () => {
        setUpdateCustomer(undefined);
        setGroupUpdate(null)
        setGroupAdd(true)
        handleModalClick(true);
    }

    const handleGroupSelect = (item) => {

        if (checkNullOrEmpty(item) && item !== 0) {
            setIsGroupSelect(true)
            setSearchData({ ...searchData, customerGroupIdList: [item] });
        }
        else {
            setIsGroupSelect(false)
            setSearchData({ ...searchData, customerGroupIdList: [] });
        }
        setSelectedGroupValue(item)
    }

    const handleModalClick = (status) => {
        setModalClick(status);
        console.log("STATUS DEĞERİ: ", status);
        //modal kapandığında verileri yenile (apiden tekrar getir)
        if (status == false) {
            setAddCutomer(status)
            setGroupResponseData(undefined)
            getCustomerListFromApi();
            getCustomerGroupListFromApi();
            setUpdateCustomer(undefined);
            setGroupUpdate(undefined);
            setIsGroupSelect(false);
        }
    }

    // Seach handle
    const handleSearchChange = (value) => {
        //console.log("Handle Search :", value);
        setSearchData({ ...searchData, customerName: value.target.value });
    };
    //#endregion

    const SetModalBodyElement = () => {
        if (!groupAdd) {
            if (!checkNullOrEmpty(updateCustomer)) {
                return <CustomerAdd groups={groupResponseData} handleModalClick={handleModalClick} />
            }
            else {
                return <CustomerUpdate customer={updateCustomer} groups={groupResponseData} handleModalClick={handleModalClick} />
            }
        }
        else {
            if (!checkNullOrEmpty(groupUpdate)) {
                return <CustomerGroupAdd handleModalClick={handleModalClick} />
            }
            else {
                return <CustomerGroupUpdate group={groupUpdate} handleModalClick={handleModalClick} />
            }
        }
    }

    const filterItems = [
        <div className={styles.filtercontainer}>
            <div className={styles.firstarea} style={{ width: isGroupSelect ? "630px" : "450px" }}>
                <InputSearch
                    placeholder={"Müşteri ara"}
                    onChange={handleSearchChange}
                    width="210px"
                    fontFamily="Metropolis"
                    fontSize="12px"
                />
                <Dropdown
                    width="210px"
                    items={groupResponseData}
                    onSelected={handleGroupSelect}
                    selectItemId={selectedGroupValue}
                    text="Tüm Liste"
                    fontFamily="Metropolis-bold"
                    fontSize="12px"
                />
                {
                    isGroupSelect === true && selectedGroupValue != "Tüm Liste" ?
                        <Button fontSize='12px' onClick={handleUpdateCustomerGroupButtonClick} width="154px" text=" Grubu Düzenle"
                            icon={<AddUserIcon color="#fff" />} />
                        :
                        ""
                }
            </div>
            <div className={styles.lastarea}>
                <Button fontSize='12px' onClick={handleCreateCustomerGroupButtonClick} width="145px" text='Grup Ekle' icon={<AddIcon color="#fff" />} />
                <Button fontSize='12px' onClick={handleCreateCustomerButtonClick} width="145px" text='Müşteri Ekle' icon={<AddUserIcon color="#fff" />} />
            </div>
        </div>
    ]

    const handlePopUp = (status) => {
        setPopUpClick(status)
        console.log("Status : ", status)
        setIsActive(false)
    }

    const isMobile = useMediaQuery({
        query: '(max-width: 1224px)'
    })

    useEffect(() => {
        console.log("ad burada: ", customerName)
    })


    return (
        isMobile ? <>
            <CustomerResponsive
                handleSearchChange={handleSearchChange}
                groupResponseData={groupResponseData}
                handleGroupSelect={handleGroupSelect}
                selectedGroupValue={selectedGroupValue}
                SetModalBodyElement={SetModalBodyElement}
                getCustomerListFromApi={getCustomerListFromApi}
                setUpdateCustomer={setUpdateCustomer}
                setGroupAdd={setGroupAdd}
                addModalClick={addCutomer}
                setGroupUpdate={setGroupUpdate}
                currentItemsResponsive={currentItemsResponsive}
                updateCustomer={updateCustomer}
                customerName={customerName}
                handleDeleteCustomerButtonClick={handleDeleteCustomerButtonClick}
                handleUpdateCustomerButtonClick={handleUpdateCustomerButtonClick}
                getCustomerGroupListFromApi={getCustomerGroupListFromApi}
                groupUpdate={groupUpdate}
                groupAdd={groupAdd}
            />
        </>
            :
            <>
                <CustomLoadingBox isLoading={isLoading} />

                <Modal
                    modalStyle={
                        {
                            boxShadow: 'none',
                            flexBasis: '40%',
                            width: '30%',
                            alignItems: 'stretch',
                        }
                    }
                    clickEvent={modalClick}
                    callbackEvent={handleModalClick}

                    body={<SetModalBodyElement />}
                />

                <FilterArea width='100%' items={filterItems} />
                <Container minHeight="100%">

                    <Cardboard >
                        <ListContainer>
                            <Pagination
                                width="20%"
                                handlePageClick={handlePageClick}
                                pageCount={pageCount}
                                setItemsPerPage={setItemsPerPage}
                                infoCount={listTotalCount}
                                itemsPerPage={itemsPerPage}
                                listDataLength={listData?.length}
                                desciription="adet kayıtlı müşteri"
                                pageViewStatus={true}
                                deleteSelector={deleteSelector}
                            />

                            {/* PobUp Demo */}

                            <PobUp
                                callbackButtonEvent={handlePopUp}
                                clickEvent={popUpClick}
                                content="Müşterinizi silmek istediğinize emin misiniz ?"
                                isCustomer={true}
                                isActive={isActive}
                                position={popupPosition}
                                ref={currentRef}
                                headerName={customerName}
                            />

                            {/* PobUp Demo */}
                            {/* listeye ref arrayini veriyorum */}
                            <List
                                columnNames={[
                                    "MÜŞTERİ İSMİ",
                                    "TELEFON",
                                    "E-POSTA",
                                    "GRUP BİLGİSİ",
                                    "",
                                    "",
                                    "",
                                    "",
                                    ""
                                ]}
                                data={currentItems}
                                ref={refs}
                            />

                        </ListContainer>
                    </Cardboard>
                </Container>
            </>
    )
};

export default CustomerGlobal;
