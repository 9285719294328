// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".doublebutton_doublecontainer__J82n-{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    height: 30px;\n    border-radius: 10px;\n    border: solid 1px #d4ddf0;\n    background-color: #ffffff;\n}\n\n.doublebutton_section__pVtJ\\+{\n    flex-grow: 1;\n    height: 100%;\n}\n\n.doublebutton_button__\\+f8b3{\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n    cursor: pointer;\n    color: #000d89;\n    font-size: 12px;\n}\n\n.doublebutton_bottom__rGTvs{\n    border-left:  solid 1px #d4ddf0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Global/DoubleButton/doublebutton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".doublecontainer{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    height: 30px;\n    border-radius: 10px;\n    border: solid 1px #d4ddf0;\n    background-color: #ffffff;\n}\n\n.section{\n    flex-grow: 1;\n    height: 100%;\n}\n\n.button{\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n    cursor: pointer;\n    color: #000d89;\n    font-size: 12px;\n}\n\n.bottom{\n    border-left:  solid 1px #d4ddf0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doublecontainer": "doublebutton_doublecontainer__J82n-",
	"section": "doublebutton_section__pVtJ+",
	"button": "doublebutton_button__+f8b3",
	"bottom": "doublebutton_bottom__rGTvs"
};
export default ___CSS_LOADER_EXPORT___;
