import styles from "../../Profile/profile.module.css";
import Input from "components/Global/Input";
import Button from "components/Global/Button";
import { useFormik } from "formik";
import formValidator from "./formValidator";
import CustomLoadingBox from "hoc/CustomLoadingBox";
import { useEffect, useState } from "react";
import api from "store/api/actions";
import ReactInputMask from "react-input-mask";

const ProfileEdit = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [responseErrors, setResponseErrors] = useState()
  const [isFull, setisFull] = useState(false)
  useEffect(() => {
    (
        values.userFullName == ""
        || values.phoneNumber.length < 13
        || values.email == ""
        || values.userName == ""
        || values.password == ""
        || values.passwordConfirmation == ""
    ) ?
      setisFull(true)
      : setisFull(false)
  })
  // useEffect(() => {
  //   props.setDynamicModalStyle({
  //     boxShadow: "none",
  //     flexBasis: "53%",
  //     width: "30%",
  //     alignItems: "stretch",
  //   });
  // }, []);

  useEffect(() => {
    handleReset({});
    setFieldValue("userFullName", props?.userProfileData?.userFullName);
    setFieldValue("phoneNumber", props?.userProfileData?.phoneNumber);
    setFieldValue("email", props?.userProfileData?.email);
  }, [props.userProfileData]);

  const {handleReset,handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue} = useFormik({
    initialValues: {
      userFullName: props?.userProfileData?.userFullName,
      phoneNumber: props?.userProfileData?.phoneNumber,
      email: props?.userProfileData?.email,
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: formValidator,
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .User()
        .SetInformation(values)
        .then((response) => {
          //console.log("response", response);
          props.handleModalClick(false);
        })
        .catch((error) => {
          console.log("error", error?.response?.data);
          // setResponseErrors(error?.response?.data);
          setResponseErrors(error?.response?.data.userMessage)
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  return (
    <>
      <CustomLoadingBox isLoading={isLoading} />
      <div style={{width:props.width}} className={styles.forminfo}>Profil Düzenle</div>
      <form style={{width:props.width, height:props.height}} className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputgroup}>
          <Input
            width="100%"
            height="3.4rem"
            id="userFullName"
            placeholder="Ad Soyad"
            name="userFullName"
            onChange={handleChange}
            value={values.userFullName}
            onBlur={handleBlur}
            error={touched.userFullName && errors.userFullName}
            type="string"
          />
          <ReactInputMask
            mask="999 999 99 99"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            maskChar=""
          >
            {() => <Input id="phoneNumber" name="phoneNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.phoneNumber && touched.phoneNumber} helperText={(errors.phoneNumber && touched.phoneNumber)} />}
          </ReactInputMask>

          <Input
            width="100%"
            id="email"
            height="3.4rem"
            placeholder="E-posta"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && errors.email}
            type="string"
          />
          <Input
            autoComplate="off"
            width="100%"
            id="password"
            height="3.4rem"
            placeholder="Şifre"
            name="password"
            onChange={handleChange}
            value={values.password}
            onBlur={handleBlur}
            error={touched.password && errors.password}
            type="password"
          />
          <Input
            autoComplate="off"
            width="100%"
            id="passwordConfirmation"
            height="3.4rem"
            placeholder="Şifre Doğrula"
            name="passwordConfirmation"
            onChange={handleChange}
            value={values.passwordConfirmation}
            onBlur={handleBlur}
            error={
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
            type="password"
          />
          {
            responseErrors && (<span style={{ color: 'red' }}>{responseErrors}</span>)
          }
          <Button
            type="submit"
            fontSize="1.3rem"
            width="100%"
            height="3rem"
            text="Kaydet"
            disabled={isFull}
          />
        </div>
      </form>
    </>
  );
};
export default ProfileEdit;
