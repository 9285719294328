import React from "react";
import { CSVLink } from "react-csv";
import Button from "components/Global/Button";
import DownloadIcon from "components/icons/DownloadIcon";
import { checkNullOrEmptyArray } from "./Conditions";

const ExportCSV = ({ fontSize, csvData, fileName, text, width, height = "3rem", backgroundImage, backgroundColor, minWidth }) => {
  return (
    <>
      {checkNullOrEmptyArray(csvData) ?
        (
          <CSVLink style={{ color: "white", textDecoration: "none" }} data={csvData} filename={fileName} disabled={false}>
            <Button
              fontSize={fontSize}
              minWidth={minWidth}
              backgroundImage={backgroundImage}
              backgroundColor={backgroundColor}
              width={width}
              height={height}
              icon={<DownloadIcon width="30" height="20" />}
              text={text}
            />
          </CSVLink>
        ) : (
          <Button
            fontSize={fontSize}
            minWidth={minWidth}
            backgroundImage={backgroundImage}
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            icon={<DownloadIcon width="30" height="20" />}
            text={text}
          />
        )
      }
    </>
  );
};

export default ExportCSV;
