import React from 'react';

const UpIcon = ({ size = 24 }) => {
    return <>
        <symbol id="up">
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M19.926 13.384c1.08 1.155 1.682 1.894 1.935 2.41.332.677.075 1.403-.846 1.157-.388-.104-2.56-1.758-3.122-2.15-1.18-.825-2.561-1.771-3.588-2.311-1.225-.644-2.157-.81-3.493-.536-1.191.372-2.532 1.287-3.562 1.974-.736.531-3.67 2.856-4.258 3.008-.979.252-1.18-.608-.837-1.291.258-.514.866-1.248 1.957-2.393 1.09-1.146 2.664-2.705 3.84-3.738 1.175-1.034 1.953-1.544 2.6-1.845 1.16-.54 2.2-.467 3.311.157 1.684.943 5.011 4.434 6.063 5.558z" fill="#000D89" />
            </svg>

        </symbol>
    </>;
};

export default UpIcon;
