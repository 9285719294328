import React from 'react';

const RemindIcon = ({ color = "#000D89", size = 24 }) => {
    return <>
        <symbol id="remind" >
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0zm0 2.571A9.428 9.428 0 0 0 2.571 12 9.428 9.428 0 0 0 12 21.429 9.428 9.428 0 0 0 21.429 12 9.428 9.428 0 0 0 12 2.571zm-.392 3.332c.64 0 1.17.466 1.269 1.077l.017.209v4.884l3.74 2.231c.548.327.765 1 .537 1.575l-.092.188c-.327.549-1 .765-1.575.538l-.188-.093-4.367-2.605a1.286 1.286 0 0 1-.612-.912l-.015-.192V7.19c0-.71.576-1.286 1.286-1.286z" fill={color} />
            </svg>

        </symbol>
    </>;
};

export default RemindIcon;
