// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{\n    overflow: scroll;\n}\n.accountedforbmp_searcharea__1kvPD {\n    display: flex;\n    justify-content: space-between;\n    width: 32%;\n}\n\n.accountedforbmp_biglabel__Iv2W2 {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.accountedforbmp_normallabel__bB3A5 {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n.accountedforbmp_chartcontainer__OzOdB {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.accountedforbmp_info__JulRJ {\n    display: flex;\n    flex-direction: row;\n    font-size: 16px;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/AccountedFor/BigMarketPlace/Standard/accountedforbmp.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;AACd;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":["body{\n    overflow: scroll;\n}\n.searcharea {\n    display: flex;\n    justify-content: space-between;\n    width: 32%;\n}\n\n.biglabel {\n    margin: 0 47px 5px 0;\n    font-size: 1.8rem;\n    color: #000d89;\n    font-weight: 600;\n}\n\n.normallabel {\n    font-size: 1.2rem;\n    margin: 1px 5px 10px 0;\n    color: #4f5386;\n}\n\n.chartcontainer {\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    align-items: center;\n}\n\n.info {\n    display: flex;\n    flex-direction: row;\n    font-size: 16px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searcharea": "accountedforbmp_searcharea__1kvPD",
	"biglabel": "accountedforbmp_biglabel__Iv2W2",
	"normallabel": "accountedforbmp_normallabel__bB3A5",
	"chartcontainer": "accountedforbmp_chartcontainer__OzOdB",
	"info": "accountedforbmp_info__JulRJ"
};
export default ___CSS_LOADER_EXPORT___;
