import { useState, useEffect } from 'react'
import styles from './installmentitem.module.css'
import cx from 'classnames';
const InstallmentItem = ({ item, setCallback, callback,style }) => {

    const [clicked, setClicked] = useState(false);
    const handleClick = (e) => {
        if(clicked){
            setCallback(0);
        }
        else{
            setCallback(e.target.id);
        }
        setClicked(a => !a);
    }
    useEffect(() => {
        if (callback != Number(item.text)) {
            setClicked(false);
        }
    }, [callback])
    return (
        <div id={item.text} style={style}  className={cx(styles.item, clicked ? styles.clicked : '')} onClick={handleClick}>
            <span id={item.text}  className={styles.text}>{item.text}</span>
            <span id={item.text}  className={styles.rate}>{`(%${item.rate})`}</span>
        </div>
    )
}

export default InstallmentItem