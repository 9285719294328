// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tablepricelabel_tableprice__Idjlw {\n    color: #4f5386;\n    font-size: .9rem;\n    /* font-family: 'Metropolis-Bold'; */\n\n}\n\n.tablepricelabel_beforecomma__3Jx0a {\n    font-family: 'Metropolis-Bold';\n}", "",{"version":3,"sources":["webpack://./src/components/Global/TablePriceLabel/tablepricelabel.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,oCAAoC;;AAExC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".tableprice {\n    color: #4f5386;\n    font-size: .9rem;\n    /* font-family: 'Metropolis-Bold'; */\n\n}\n\n.beforecomma {\n    font-family: 'Metropolis-Bold';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableprice": "tablepricelabel_tableprice__Idjlw",
	"beforecomma": "tablepricelabel_beforecomma__3Jx0a"
};
export default ___CSS_LOADER_EXPORT___;
