import React from 'react'

const UserGroupIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clipRule="evenodd" d="M8.819 8.568a3.072 3.072 0 0 1 3.068-3.069 3.073 3.073 0 0 1 3.069 3.069 3.072 3.072 0 0 1-3.07 3.069v.75l-.024-.75a3.036 3.036 0 0 1-2.157-.903 3.033 3.033 0 0 1-.886-2.166zm3.04 4.569h.028c2.52 0 4.569-2.05 4.569-4.569a4.574 4.574 0 0 0-4.57-4.569A4.573 4.573 0 0 0 7.32 8.565a4.518 4.518 0 0 0 1.322 3.226 4.52 4.52 0 0 0 3.219 1.346zm5.286-1.638a.75.75 0 0 0 .846.64 3.642 3.642 0 0 0 3.12-3.585 3.611 3.611 0 0 0-3.035-3.576.75.75 0 0 0-.244 1.48 2.115 2.115 0 0 1 1.779 2.094 2.133 2.133 0 0 1-1.827 2.1.75.75 0 0 0-.64.847zm3.015 6.548a.75.75 0 0 0 .968.434c1.43-.545 1.644-1.538 1.644-2.082 0-.89-.509-2.029-2.932-2.392a.755.755 0 0 0-.854.63.752.752 0 0 0 .631.854c1.098.165 1.655.47 1.655.909 0 .137 0 .422-.678.68a.75.75 0 0 0-.434.968zm-8.273-2.133c-2.376 0-5.21.292-5.21 1.682 0 1.401 2.834 1.695 5.21 1.695s5.208-.29 5.208-1.678c0-1.404-2.832-1.699-5.208-1.699zm0 4.877c-1.66 0-6.71 0-6.71-3.195 0-3.182 5.05-3.182 6.71-3.182 1.659 0 6.708 0 6.708 3.2 0 3.177-4.873 3.177-6.708 3.177zm-6.001-8.646a.737.737 0 0 1-.104-.006 3.641 3.641 0 0 1-3.12-3.583 3.612 3.612 0 0 1 3.036-3.578.75.75 0 0 1 .244 1.48 2.116 2.116 0 0 0-1.78 2.096 2.132 2.132 0 0 0 1.827 2.098.75.75 0 0 1-.103 1.494zm-3.241 6.336a.75.75 0 0 0 .534-1.402c-.679-.259-.679-.543-.679-.68 0-.438.557-.744 1.655-.908a.752.752 0 0 0 .631-.854.755.755 0 0 0-.853-.63C1.509 14.37 1 15.51 1 16.4c0 .543.214 1.536 1.645 2.082z" fill="url(#oztp7sn6da)" />
            <defs>
                <linearGradient id="oztp7sn6da" x1=".75" y1="12.75" x2="22.5" y2="12.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#000D89" />
                    <stop offset="1" stop-color="#6608B7" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default UserGroupIcon





