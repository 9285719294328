import * as yup from 'yup';

const subDealerUpdate = yup.object().shape({

    pobSubDealerId: yup.string().required('Lütfen bir Bayi seçiniz.'),
    pobSubDealerName: yup.string().max(50, "Bayi adı maksimum 50 karakter olması gerekmektedir.").required('Lütfen Bayi Adı alanını doldurunuz.'),
    phoneNumber: yup.string('Telefon numarası rakamlardan oluşmalıdır.').typeError('Telefon numarası rakamlardan oluşmalıdır.').required("Telefon zorunlu"),
    districtId: yup.string().required('Lütfen bir İlçe seçiniz.'),
    address: yup.string().max(500).required('Lütfen Adres girin.'),
    cityCode: yup.string().max(2).required('Lütfen şehir seçiniz. '),

})

export default subDealerUpdate;