import { useState, useEffect, useRef } from 'react'
import styles from './autocomplete.module.css';
import cx from 'classnames';
import SearchIcon from 'components/icons/SearchIcon';
import useOutside from 'hoc/CustomOutsideHook';
import CompleteItem from './CompleteItem';
import { checkNullOrEmptyArray } from 'hoc/Conditions';
import Button from '../Button';

const AutoComplete = ({
    width: inputWidth,
    height = '2.4rem',
    placeholder,
    fontSize,
    onChange,
    name,
    value,
    id,
    type = 'text',
    maxLength,
    error,
    items,
    onBlur,
    callback,
    isLink = false,
    customerList,
    customerGroupList,
    buttonStatus
}) => {

    const inputRef = useRef();
    const areaRef = useRef();
    const [ref, setRef] = useState(inputRef);
    const [clicked, setClicked] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState('');
    const [isError, setIsError] = useState(false);
    const [customerData, setCustomerData] = useState(customerList);
    const [customerGroupData, setCustomerGroupData] = useState(customerGroupList);


    const handleRef = () => {
        if (inputRef != undefined) {
            return setRef(inputRef);
        }
    }

    const handleCB = () => {
        callback({
            buttonClick: true,
        })
    }



    useEffect(() => {
        setRef(ref)
    }, [clicked, inputRef])

    useOutside(inputRef, areaRef, () => {
        setClicked(false);
    })

    useEffect(() => {
        if (error != undefined) {
            setIsError(true)
        }
        else {
            setIsError(false)
        }
    }, [error])

    const handleChange = (e) => {
        if (e.target) {
            onChange({ name: e.target.value })
            setInputValue(e.target.value)
        }
        else {
            setInputValue(e.name)
            setClicked(false);
            onChange({ id: e.id, name: e.name })
        }
    }

    const handleSearch = () => {
        if (inputValue == "" && !isLink) {
            setFilteredData(items)
        }
        if (inputValue == "" && isLink) {
            setCustomerGroupData(customerGroupList)
            setCustomerData(customerList)
        }
        setClicked(!clicked);
    }

    useEffect(() => {

        if (isLink) {
            if (checkNullOrEmptyArray(customerList)) {
                const filteredCustomer = customerList?.filter((item) => {
                    return Object.keys(item)
                        .some((key) => {
                            return (
                                item[key]?.toString()
                                    .toLowerCase()
                                    .includes(inputValue?.toLowerCase()
                                    )
                            )
                        })
                })
                setCustomerData(filteredCustomer)
            }

            if (checkNullOrEmptyArray(customerGroupList)) {
                const filteredGroups = customerGroupList?.filter((item) => {
                    return Object.keys(item)
                        .some((key) => {
                            return (
                                item[key]?.toString()
                                    .toLowerCase()
                                    .includes(inputValue?.toLowerCase()
                                    )
                            )
                        })
                })
                setCustomerGroupData(filteredGroups)
            }

        }
        else {
            if (checkNullOrEmptyArray(items)) {
                const filtered = items?.filter((item) => {
                    return Object.keys(item)
                        .some((key) => {
                            return (
                                item[key]?.toString()
                                    .toLowerCase()
                                    .includes(inputValue?.toLowerCase()
                                    )
                            )
                        })
                })
                setFilteredData(filtered)
            }
        }
    }, [inputValue])

    return (
        <>
            <div ref={ref}
                className={cx(styles.inputcontainer, isError && styles.error)} style={{ width: inputWidth, height, fontSize }}>
                <div className={styles.inputarea}>
                    <input onBlur={onBlur} onClick={handleRef} className={cx(error && styles.righterror, styles.input)} onChange={handleChange} value={inputValue} maxLength={maxLength} type={type} name={name} id={id} placeholder={placeholder} />
                </div>
                <div onClick={handleSearch} className={styles.iconarea}>
                    <SearchIcon />
                </div>
            </div>
            {
                isError && (<span className={styles.errortext}>{error}</span>)
            }
            {
                clicked &&
                <>
                    <div ref={areaRef} className={styles.searcharea}
                        style={{
                            left: ref?.current?.offsetLeft,
                            top: ref?.current?.offsetTop + ref?.current?.offsetHeight + 5,
                            width: ref?.current?.clientWidth + 2,
                        }}
                    >
                        {
                            isLink ?
                                <>
                                    <span className={styles.text}>Müşteriler</span>
                                    {

                                        customerData?.length > 0 ? customerData?.map((data,i) => {
                                            return <CompleteItem key={i} data={data} callback={handleChange} />
                                        }) : <div className={styles.emptyalert}>Müşteri Bulunamadı</div>
                                    }
                                    <span className={styles.text}>Gruplar</span>
                                    {
                                        customerGroupData?.length > 0 ? customerGroupData?.map((data,i) => {
                                            return <CompleteItem key={i} isGroup={true} data={data} callback={handleChange} />
                                        }) : <div className={styles.emptyalert}>Grup Bulunamadı</div>
                                    }
                                    <Button width={"100%"} text="Grup Ekle" fontFamily={"Metropolis"} onClick={handleCB} visible={!buttonStatus} />
                                </> :
                                <>
                                    {
                                        filteredData?.length > 0 ? filteredData?.map((data,i) => {
                                            return <CompleteItem key={i} data={data} callback={handleChange} />
                                        }) : <div className={styles.emptyalert}>Müşteri Bulunamadı</div>
                                    }
                                </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default AutoComplete