// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bannerarea_image__Tn7in {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    border-radius: 16px;\n    font-size: 20px;\n    color: white;\n    background-color: #2691cc;\n    align-items: center;\n    justify-content: center;\n    max-height: 250px;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/BannerArea/bannerarea.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".image {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    border-radius: 16px;\n    font-size: 20px;\n    color: white;\n    background-color: #2691cc;\n    align-items: center;\n    justify-content: center;\n    max-height: 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "bannerarea_image__Tn7in"
};
export default ___CSS_LOADER_EXPORT___;
