// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".supportcollapse_collapsecontainer__KdkCY{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 2%;\n}\n\n.supportcollapse_sendeddescription__bFaBQ{\n    margin-bottom: 20px;\n}\n\n.supportcollapse_receiveddescription__AVivp{\n}\n\n.supportcollapse_text__0Ub4T{\n    margin-bottom: 10px;\n    color: #4f5386;\n    font-size: 1.3rem;\n    font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/components/Global/List/CollapseForSupport/supportcollapse.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".collapsecontainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 2%;\n}\n\n.sendeddescription{\n    margin-bottom: 20px;\n}\n\n.receiveddescription{\n}\n\n.text{\n    margin-bottom: 10px;\n    color: #4f5386;\n    font-size: 1.3rem;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsecontainer": "supportcollapse_collapsecontainer__KdkCY",
	"sendeddescription": "supportcollapse_sendeddescription__bFaBQ",
	"receiveddescription": "supportcollapse_receiveddescription__AVivp",
	"text": "supportcollapse_text__0Ub4T"
};
export default ___CSS_LOADER_EXPORT___;
