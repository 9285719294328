import React from 'react';

const CalendarIcon = ({ color = "#000D89", size = '24' }) => {
    return <>
        <symbol id="calendar" >
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M16.427.5c.454 0 .822.36.822.802l.002.907c1.598.108 2.926.643 3.866 1.562 1.026 1.007 1.566 2.454 1.56 4.19v9.76c0 3.565-2.317 5.779-6.046 5.779H7.367c-3.73 0-6.047-2.245-6.047-5.86V7.96c0-3.362 2.067-5.52 5.438-5.75v-.908C6.758.86 7.126.5 7.58.5c.453 0 .821.36.821.802v.887h7.204l.001-.887c0-.443.368-.802.821-.802zm4.606 9.525H2.962l.001 7.615c0 2.745 1.564 4.255 4.404 4.255h9.264c2.84 0 4.404-1.482 4.404-4.174l-.002-7.696zm-4.16 6.731c.454 0 .822.36.822.803a.812.812 0 0 1-.821.802.816.816 0 0 1-.826-.802c0-.443.363-.803.816-.803h.01zm-4.86 0c.454 0 .822.36.822.803a.812.812 0 0 1-.821.802.816.816 0 0 1-.826-.802c0-.443.363-.803.816-.803h.01zm-4.87 0c.454 0 .822.36.822.803a.812.812 0 0 1-.821.802.817.817 0 0 1-.827-.802c0-.443.363-.803.817-.803h.01zm9.73-4.157c.454 0 .822.359.822.802a.812.812 0 0 1-.821.802.816.816 0 0 1-.826-.802c0-.443.363-.802.816-.802h.01zm-4.86 0c.454 0 .822.359.822.802a.812.812 0 0 1-.821.802.816.816 0 0 1-.826-.802c0-.443.363-.802.816-.802h.01zm-4.87 0c.454 0 .822.359.822.802a.812.812 0 0 1-.821.802.817.817 0 0 1-.827-.802c0-.443.363-.802.817-.802h.01zm8.462-8.807H8.401v1.03a.812.812 0 0 1-.821.803.812.812 0 0 1-.822-.802V3.818c-2.453.201-3.795 1.654-3.795 4.142l-.001.46h18.07l.003-.46c.004-1.322-.36-2.349-1.082-3.055-.633-.62-1.559-.99-2.703-1.086l-.001 1.004a.812.812 0 0 1-.822.802.812.812 0 0 1-.821-.802l-.001-1.03z" fill="#000D89" stroke="#000D89" strokeWidth=".5" />
            </svg>


        </symbol>
    </>;
};

export default CalendarIcon;
