import React from 'react';

const FilterIcon = ({ width, height, fill }) => {
    return <>
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <rect y="4" width="24" height="3" rx="1.5" fill={fill} />
            <rect x="4" y="11" width="16" height="3" rx="1.5" fill={fill} />
            <rect x="8" y="18" width="8" height="3" rx="1.5" fill={fill} />
        </svg>
    </>
};

export default FilterIcon;