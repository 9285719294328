import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Checkbox } from '@mui/material'
import React from 'react'
import TickIcon from 'components/icons/TickIcon';


const theme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    border: 'solid 1px #bfc3de',
                    borderRadius: '5px',
                    padding: '5px',
                    width: '25px',
                    height: '25px',
                    margin: '0 15px',
                    '&.Mui-disabled': {
                        visibility:'hidden',
                    },
                }
            },
        },

    },
});

const Check = ({ onChange = Function(), id = "", checked, disabled }) => {
    return (
        <ThemeProvider theme={theme}>
            <Checkbox
                checked={checked}
                disabled={disabled}
                id={id}
                onChange={onChange}
                disableRipple
                icon={<></>}
                checkedIcon={<TickIcon size='14' />}
            />
        </ThemeProvider>
    )
}

export default Check

