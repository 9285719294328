import { useState, useEffect,forwardRef } from 'react'
import styles from './tooltip.module.css'
import cx from 'classnames'

const ToolTip = forwardRef(({ visibility, text},ref) => {

    return (
        <div style={{ left: ref?.current?.offsetLeft + ref?.current?.scrollWidth/2, top: ref?.current?.offsetTop - ref?.current?.scrollHeight-ref?.current?.clientHeight }} className={cx(visibility && styles.active, styles.tooltip)}>{text}</div>
    )
})

export default ToolTip