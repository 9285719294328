import { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './flip.module.css'
import CloseIcon from 'components/icons/CloseIcon'

const InputFlip = ({ showInput, style = {}, buttonText, onChange = Function(), value, closed = Function(), placeholder, disabled, name, onBlur = Function(), error, length="50" }) => {

    const [clicked, setClicked] = useState(true)
    const handleClick = (e) => {
        setClicked(val => !val)
        if (clicked) {
            closed(false)
        }
        else {
            closed(true)
        }
    }

    useEffect(() => {
        console.log(`Show `, showInput)
        if (showInput > 1) {
            setClicked(false)
        }
    }, [showInput])

    return (
        <div style={style} className={cx(clicked && styles.active, styles.buttonflipper)}>
            <div className={styles.inputcontainer}>
                <input maxLength={length} onBlur={onBlur} name={name} disabled={disabled} placeholder={placeholder} onChange={onChange} value={value} type="text" className={styles.input} />
                <div className={styles.icon} onClick={handleClick} >
                    <CloseIcon size='15' />
                </div>
            </div>
            <button onClick={handleClick} type="button" className={cx(styles.frontbutton)}>{buttonText}</button>
            {error && <span>{error}</span>}
        </div>
    )
}

export default InputFlip;