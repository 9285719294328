// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputshare_sharecontainer__fbSGp{\n    display:flex;\n    justify-content:space-between;\n    box-sizing: border-box;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    padding: 1rem;\n\n}\n\n.inputshare_copy__nG0WT{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor:pointer;\n    min-width: 40px;\n}\n\n.inputshare_alert__NT1mj{\n    font-size: 10px;\n}\n\n.inputshare_input__ez8EE{\n    width:80%;\n    background-color: white;\n    border:none;\n    color: #000d89;\n    font-weight: bold;\n\n}", "",{"version":3,"sources":["webpack://./src/components/Global/InputShare/inputshare.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;IAC7B,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,iBAAiB;;AAErB","sourcesContent":[".sharecontainer{\n    display:flex;\n    justify-content:space-between;\n    box-sizing: border-box;\n    align-items: center;\n    font-size: 1rem;\n    border-radius: 10px;\n    background-color: white;\n    border: solid 1px #bfc3de;\n    padding: 1rem;\n\n}\n\n.copy{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor:pointer;\n    min-width: 40px;\n}\n\n.alert{\n    font-size: 10px;\n}\n\n.input{\n    width:80%;\n    background-color: white;\n    border:none;\n    color: #000d89;\n    font-weight: bold;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sharecontainer": "inputshare_sharecontainer__fbSGp",
	"copy": "inputshare_copy__nG0WT",
	"alert": "inputshare_alert__NT1mj",
	"input": "inputshare_input__ez8EE"
};
export default ___CSS_LOADER_EXPORT___;
