import React, { useEffect, useState } from 'react';
import styles from './customerupdate.module.css';
import Button from 'components/Global/Button';
import Input from 'components/Global/Input';
import { useFormik } from 'formik';
import api from 'store/api/actions'
import formValidator from './FormValidator';
import { checkNullOrEmpty, checkNullOrEmptyArray } from "hoc/Conditions";
import CustomLoadingBox from 'hoc/CustomLoadingBox';
import MultipleSelect from 'components/Global/MultipleSelect/MultipleSelect';
import ReactInputMask from 'react-input-mask';

const CustomerUpdate = ({ customer, groups, handleModalClick }) => {

    const [isLoading, setIsLoading] = useState()
    const [responseErrors, setResponseErrors] = useState();
    const [selectedGroupValue, setSelectedGroupValue] = useState("")
    const [multiselectCallBack, setMultiselectCallBack] = useState()


    // useEffect(() => {
    //     console.log("Get groups Data : ", groups)
    // }, [groups])


    useEffect(() => {
        groups?.forEach(element => {
            element.name = element.text
        });
    }, [])
    
    useEffect(() => {
        setValues({
            pobDealerCustomerId: customer.id,
            firstName: customer.customerName,
            lastName: "",
            gender: customer.gender,
            birthDate: customer.birthDate,
            gsmNumber: customer.phone,
            email: customer.email,
            address: customer.address,
        })

        const data = groups?.find(item => item.text === customer.customerGroups);
        if (checkNullOrEmptyArray(data)) {
            setSelectedGroupValue(data.data.data.map(item => ({
                id: item.id,
                name: item.text,
            })))
        }

        api.CustomerGroup()
            .GetAll({ customerId: customer?.id })
            .then((response) => {
                console.log("get all grp :", response.data.data)
                if (checkNullOrEmptyArray(response.data.data)) {
                    setSelectedGroupValue(response.data.data.map(item => ({
                        id: item.pobDealerCustomerGroupId,
                        name: item.customerGroupName,
                    })));
                }
                else {
                    setSelectedGroupValue([]);
                }

            }).catch((error) => {
                console.log("error", error?.response?.data);
                setResponseErrors(error?.response?.data.userMessage);
            });

    }, [customer])

    useEffect(() => {
        console.log(selectedGroupValue)
        if (checkNullOrEmpty(selectedGroupValue)) {
            setValues({ customerGroupIds: selectedGroupValue })
        }
    }, [])
    const [isFull, setisFull] = useState(false);


    useEffect(() => {
        console.log("OKUNANLAR: ", values)
    })


    useEffect(() => {
        (
            values.firstName == ""
            || values.gsmNumber.length < 13

        ) ?
            setisFull(true)
            : setisFull(false)
    })





    const { handleChange, handleBlur, touched, values, handleSubmit, errors, setValues, handleReset } = useFormik({

        initialValues: {

            pobDealerCustomerId: customer.id,
            firstName: customer.customerName,
            lastName: "",
            gender: customer.gender,
            birthDate: customer.birthDate,
            gsmNumber: customer.phone + "",
            email: customer.email,
            address: customer.address,
            customerGroupIds: checkNullOrEmpty(multiselectCallBack) ? multiselectCallBack : [],
        },

        onSubmit: values => {
            console.log("Submit Values : ", values)
            setIsLoading(true);

            if (checkNullOrEmptyArray(multiselectCallBack)) {
                console.log("multiselectCallBack", multiselectCallBack)

                values.customerGroupIds = multiselectCallBack.map(item => item.id);
            }

            // customer de pobDealerCustomerId id yerine id gidiyor!
            values.gsmNumber = values.gsmNumber + ""
            console.log("Values : ", values)

            api.Customer().Update(values)
                .then((response) => {

                    handleModalClick(false);

                })
                .catch((error) => {
                    // handle error
                    console.log('error', error?.response);
                    setResponseErrors(error?.response?.data.userMessage)
                    // setResponseErrors("Bir hata oluştu lütfen tekrar deneyiniz.")
                })
                .finally(() => {
                    setIsLoading(false);
                    handleReset({});
                });
        },
        validationSchema: formValidator
    });




    return (
        <>
            <CustomLoadingBox isLoading={isLoading} />

            <div className={styles.formitems}>
                <div className={styles.forminfo}>
                    Müşteriyi Düzenle
                </div>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div onChange={handleChange} className={styles.inputgroup}>

                        <Input // input yerine textfield yazınca input girişi var  
                            width='100%'
                            height='3rem'
                            id="firstName"
                            placeholder="Müşteri Ad Soyad"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            error={touched.firstName && errors.firstName}
                        />

                        <ReactInputMask
                            mask="999 999 99 99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gsmNumber}
                            maskChar=""
                        >
                            {() => <Input id="gsmNumber" name="gsmNumber" placeholder="Telefon" onBlur={handleBlur} error={errors.gsmNumber && touched.gsmNumber} helperText={(errors.gsmNumber && touched.gsmNumber)} />}
                        </ReactInputMask>

                        <Input
                            width='100%'
                            id="email"
                            height='3rem'
                            placeholder="E-posta"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={touched.email && errors.email}
                        />

                        <MultipleSelect
                            items={groups && groups}
                            error={touched.customerFullName && errors.customerFullName}
                            maxLength='70'
                            onChange={setMultiselectCallBack}
                            height='3rem'
                            name='customerName'
                            onBlur={handleBlur}
                            placeholder='Grup Seç (opsiyonel)'
                            selectedItems={selectedGroupValue}
                            littleText="Grup"
                        />
                        <div className={styles.buttontop}>
                            <Button type={'submit'} width='100%' text="Kaydet" fontSize="1.3rem" disabled={isFull} />
                        </div>
                    </div>
                </form>
                {
                    responseErrors && (<span style={{ color: 'red' }}>{"Bir sorun meydana geldi. Lütfen daha sonra tekrar deneyiniz."}</span>)
                }
            </div>
        </>
    )
};

export default CustomerUpdate;
