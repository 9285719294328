import * as yup from 'yup';

const paymentValidator = yup.object().shape({
  
  customerFullName: yup.string().when('pobDealerCustomerId', {
    is: (pobDealerCustomerId) => !pobDealerCustomerId || pobDealerCustomerId.length === 0,
    then: yup.string().max(50).required("Müşteri adı zorunludur."),
    otherwise: yup.string()
  }),

  // phone: yup.string().when('email', {
  //   is: (email) => !email || email.length === 0 || email === null ,
  //   then: yup.string().max(50).required("Lütfen en az 1 iletişim bilgisi giriniz."),
  //   otherwise: yup.string()
  // }),

  // email: yup.string().email("Email formatı hatalı.").when('phone', {
  //   is: (phone) => !phone || phone.length === 0 || phone === '',
  //   then: yup.string().max(50).required("Lütfen en az 1 iletişim bilgisi giriniz."),
  //   otherwise: yup.string()
  // }),
  
  pobDealerCustomerId: yup.string(),
  cardHolderFullName: yup.string().max(50).required("Kart üzerindeki isim zorunludur."),
  expMonth: yup.string().max(2, '01 şeklinde giriniz.').required('Ay zorunlu'),
  expYear: yup.string().max(4, 'Tarih xxxx formatında olmalıdır').required('Tarih zorunlu'),
  cvcNumber: yup.string().max(3, 'CVC maksimum 3 hane olmalı').required('CVC zorunlu'),
  amount: yup.string().min(1, 'Lütfen tutar giriniz.').required('Tutar zorunludur.'),
  installmentNumber: yup.number().max(12, 'Taksit sayısı max 12'),
  cardNumber: yup.number().min(16, 'Kart numarasi 16 rakamdan oluşmalıdır.').required('Kart numarası zorunlu')
  
},['phone', 'email'])

export default paymentValidator;