import CloseIcon from 'components/icons/CloseIcon'
import React, { useEffect, useState } from 'react'
import Button from '../Button'
import styles from './pobup.module.css'
import cx from 'classnames'
import { forwardRef } from 'react'

const PobUp = forwardRef(({ dy = 0, dx = 0, dimensionsAdder = false, callbackEvent, callbackButtonEvent, headerName = "Müşteri", content = "isimli müşteriye en son 12:03 10.08.2021 zamanında SMS gönderildi. Tekrar hatırlatma göndermek ister misiniz?", isCustomer = false, isActive = false }, ref) => {
    const [style, setStyle] = useState();

    // useEffect(() => {
    //     console.log("isAective", isActive)
    //     console.log("ref", ref)
    //     console.log("style", style)
    // }, [isActive])


    useEffect(() => {
        if (dimensionsAdder == false) {
            if (isActive) {
                //eger popup acilmissa popup acilirken rowun koordinatlari alinir.
                var dimensions = ref?.getBoundingClientRect();
                //burada state e set ediyoruz cunku popup coklu render ediliyor
                setStyle({ top: dimensions?.y + 30 + "px", right: dimensions?.x + 40 + "px" });
            }
            else {
                //eger kapali durumdaysa flash yapmamasi icin popup ekranin disina ucurulur ki yeni bir click eventi oldugu zaman yer degistirdigi gorunmesin => 20k pixel disariya ucurduk
                setStyle({ top: 20000, right: 20000 });
            }
        } else {
            setStyle({ top: dimensions?.y + dy + "px", right: dimensions?.x + dx + "px" });
        }

    }, [isActive]);

    return (
        <div className={cx(!isActive && styles.disable, styles.container)} style={style}>
            <div className={styles.header}>
                {headerName}
                {
                    isCustomer ? "" : <Button height='30px' width="30px" onClick={() => callbackEvent(false)} text={<CloseIcon color='#fff' size='17px' />} />
                }
            </div>
            <div className={styles.content}>{content}</div>
            <div className={styles.buttons}>
                <Button height='40px' width="50%" onClick={() => callbackButtonEvent(false)} text="Hayır" />
                <Button height='40px' width="50%" onClick={() => callbackButtonEvent(true)} text="Evet" />
            </div>
        </div>
    )
})

export default PobUp