import RightIcon from 'components/icons/RightIcon'
import React from 'react'
import styles from './hamburgeritem.module.css'

const HamburgerItem = ({ data, lastItem }) => {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "white", width: "100%", padding: "20px 15px 20px 15px", boxSizing: "border-box", }} >
                <div>
                    {data}
                </div>
                <div><RightIcon size={18} /></div>
            </div>
            <div className={styles.seperator}></div>
        </>
    )
}

export default HamburgerItem