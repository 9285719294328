import AutoComplete from 'components/Global/AutoComplete';
import BannerArea from 'components/Global/BannerArea';
import Button from 'components/Global/Button';
import Card from 'components/Global/Card';
import DateTimePicker from 'components/Global/DateTimePickerv2'
import Dropdown from 'components/Global/Dropdown';
import Payment from 'components/Global/Forms/Payment';
import InputFlip from 'components/Global/InputFlip';
import List from 'components/Global/List';
import ListContainer from 'components/Global/ListContainer';
import PriceLabel from 'components/Global/PriceLabel';
import { checkNullOrEmpty } from 'hoc/Conditions';
import React, { useState } from 'react'
import { useEffect } from 'react';
import ResponsiveFilterArea from '../FilterArea'
import ResizeableCard from '../global/ResizeableCard';
import styles from './dashboard.module.css'
import api from 'store/api/actions';
import ResponsiveModal from '../Modal';

const DashboardResponsive = ({ allCities, dealers, accountedForDate = Function, paymentResponseListTotaltotalSuccessTransaction, paymentListTotalCount, currentItems, willAccounted, willAccountedDateFormat = Function, termDay, role, usersData, handleDateRangeChange, cardResponseData, handleDealerSelect, handleCitySelect, selectedDealerValue, selectedCityValue, accountedForResponseData, accountedForDateFormat = Function, selectedValue, setSelectedValue, subAndDealersData, selectedSubDealerValue, handlePaymentButtonClick, handleUserSelect, selectedUserValue, setDateTimeFormat, setCardSearchData, setWillAccountedPaymentListSearchData
}) => {
    const [filterCallBack, setFilterCallBack] = useState();
    const [allCities1, setAllCities] = useState();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [formSend, setFormSend] = useState(false);
    const [selectedUser, setSelectedUser] = useState()
    const [customerData, setCustomerData] = useState()
    const [customerGroupData, setCustomerGroupData] = useState()
    const [phoneFlipClosed, setPhoneFlipClosed] = useState(false);
    const [mailFlipClosed, setMailFlipClosed] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [showMail, setShowMail] = useState(1);
    const [showPhone, setShowPhone] = useState(1);
    const [modalClick, setModalClick] = useState(false);
    const [isClosed, setIsClosed] = useState(true)



    useEffect(() => {
        formSend && setSelectedValue(selectedUser);

        console.log("form senede", formSend)
        setFormSend(false)
    }, [selectedUser])

    useEffect(() => {
        api.CustomerGroup().GetAll({}).then(response => {
            console.log('customerGroupData', response.data.data)
            setCustomerGroupData(response.data.data?.map(item => ({
                id: item.pobDealerCustomerGroupId,
                name: item.customerGroupName,
            })))
        })

    }, [customerData])

    useEffect(() => {
        api.Customer().GetAll({}).then(response => {
            console.log('customerData', response.data.data)
            setCustomerData(response.data.data.customerList?.map(item => ({
                id: item.id,
                name: item.customerName,
                phone: item.phone,
                email: item.email
            })))
        })

    }, [])

    useEffect(() => {
        setAllCities(allCities)
        console.log("cities ", allCities)
    }, [allCities])

    useEffect(() => {
        if (role != "bmp") {
            isCollapsed && setIsClosed(false)
        }
        console.log(isCollapsed)
    }, [isCollapsed])

    const handleModalClick = (status) => {
        setModalClick(status);
        setIsClosed(true);
    }

    const handleFormSubmit = (e) => {
        console.log(e)
        e.preventDefault();
        console.log('Form submitted');
        setFormSend(true);
    }

    return (
        <div className={styles.container}>
            <div className={styles.filtercontainer}>
                <ResponsiveFilterArea firstElement={<DateTimePicker onSelected={handleDateRangeChange} fontFamily="Metropolis-bold" fontSize="12px" />} isButtonActive={true} setFilterCallBack={setFilterCallBack} >
                    <form onSubmit={handleFormSubmit} className={styles.form}>
                        <div className={styles.formitems}>
                            {<>
                                {
                                    role == "smp" ? <Button
                                        text="Ödeme Al"
                                        width="100%"
                                        onClick={() => setIsClosed(false)}
                                    ></Button> : ""
                                }
                                < DateTimePicker onSelected={handleDateRangeChange} fontFamily="Metropolis-bold" fontSize="12px" />,
                                {
                                    role == "bmp" ? <>
                                        <Dropdown name="city" width={"100%"} text={"Tüm Bölgeler"} items={allCities} color={"black"} onSelected={handleCitySelect} selectItemId={selectedCityValue} />,
                                        <Dropdown width={"100%"} text={"Tüm Bayiler"} items={dealers} color={"black"} onSelected={handleDealerSelect} selectItemId={selectedDealerValue} />
                                    </> : ""
                                },
                                {
                                    role == "smp" ? <>

                                        <Dropdown
                                            width="100%"
                                            items={usersData}
                                            onSelected={setSelectedUser}
                                            selectItemId={selectedValue}
                                            text="Tüm Kullanıcılar"
                                            fontFamily="Metropolis-bold"
                                            fontSize="12px"
                                            color={"black"}
                                        />,

                                    </> : ""
                                }
                                {
                                    role == "bayis" ? <>
                                        <Dropdown
                                            text='Bayim + Tüm Alt Bayiler'
                                            items={subAndDealersData}
                                            onSelected={handleDealerSelect}
                                            selectItemId={selectedSubDealerValue}
                                            width="100%"
                                            fontFamily="Metropolis-bold"
                                            fontSize="12px"
                                            color={"black"}
                                        />,
                                        <Button
                                            text="Ödeme Al"
                                            width="100%"
                                            onClick={() => setIsClosed(false)}
                                        ></Button>,
                                    </> : ""
                                }

                                {
                                    role == "altbayis" ? <>
                                        <Dropdown
                                            text='Tüm alt bayiler'
                                            items={usersData}
                                            onSelected={handleUserSelect}
                                            selectItemId={selectedUserValue}
                                            width="100%"
                                            fontFamily="Metropolis-bold"
                                            fontSize="12px"
                                            color={"black"}
                                        />,
                                        <Button
                                            text="Ödeme Al"
                                            width="100%"
                                            onClick={() => setIsClosed(false)}
                                        ></Button>,
                                    </> : ""
                                }

                            </>
                            }
                            <Button type={"submit"} text={"Filtrele"} width={"100%"}></Button>
                        </div>
                    </form>
                </ResponsiveFilterArea>
            </div>

            {/* This is top side of the page (Hesaba geçen toplam tutar) */}
            {
                role == "bmp" ? < ResizeableCard showContent={
                    !isCollapsed &&
                    <>

                        <h4 className={styles.header}>Hesaba Geçen Toplam Tutar</h4>
                        <div className={styles.infoheader}>
                            <span>
                                <span className={styles.normaldate}>
                                    {" "}
                                    {accountedForDate(Date.now())}
                                    , 00.00 da hesabınıza geçen tutar ve bu tutarı oluşturan işlem adedi.
                                </span>
                            </span>
                        </div>
                        <div className={styles.infobottom}>
                            <PriceLabel
                                price={
                                    checkNullOrEmpty(
                                        paymentResponseListTotaltotalSuccessTransaction
                                    )
                                        ? paymentResponseListTotaltotalSuccessTransaction
                                        : 0
                                }
                                size="4"
                            />
                            <span className={styles.counttext}>
                                {paymentListTotalCount + " adet işlem"}
                            </span>
                        </div>
                    </>
                }
                    setIsCollapsed={setIsCollapsed}
                    collapsibleContent={
                        <div className={styles.infoheader}>
                            <h4 className={styles.header2}>Hesaba Geçen Toplam Tutar</h4>

                            <span>
                                <span className={styles.normaldate}>
                                    {" "}
                                    {
                                        accountedForDate(Date.now())

                                    }
                                    , 00.00 da hesabınıza geçen tutar ve bu tutarı oluşturan işlem adedi.
                                </span>
                            </span>
                            <div className={styles.infobottom}>
                                <PriceLabel
                                    price={
                                        checkNullOrEmpty(
                                            paymentResponseListTotaltotalSuccessTransaction
                                        )
                                            ? paymentResponseListTotaltotalSuccessTransaction
                                            : 0
                                    }
                                    size="4"
                                />
                                <span className={styles.counttext}>
                                    {paymentListTotalCount + " adet işlem"}
                                </span>
                            </div>

                            <div>
                                <ListContainer>
                                    <List
                                        columnNames={["BAYİ İSMİ", "HESABA GEÇEN"]}
                                        data={currentItems}
                                    />
                                </ListContainer>
                            </div>
                        </div>

                    } /> : ""
            }
            {
                role == "jp" ? < ResizeableCard showContent={
                    // !isCollapsed && 
                    <>
                        <div className={styles.customerarea}>
                            <div className={styles.subheaders}>Müşteri Bilgisi</div>
                            <div className={styles.inputfieldresponsive}>
                                <AutoComplete
                                    // items={customerAndGroupsData}
                                    maxLength='70'
                                    height='3rem'
                                    name='customerFullName'
                                    placeholder='Müşteri ismi / Grup ismi'
                                    isLink={true}
                                    customerList={customerData}

                                />
                            </div>
                            <div className={styles.inputgroup}>
                                <div className={styles.groupitem}>
                                    <InputFlip
                                        placeholder='Telefon'
                                        buttonText='Telefon'
                                        closed={setPhoneFlipClosed}
                                        disabled={disableInputs}
                                        showInput={showPhone}
                                        name='phone'
                                        length='10'
                                    />
                                </div>
                                <div className={styles.groupitem}>
                                    <InputFlip
                                        placeholder='E-posta'
                                        buttonText='E-posta'
                                        closed={setMailFlipClosed}
                                        disabled={disableInputs}
                                        showInput={showMail}
                                        name='email'
                                    />
                                </div>
                            </div>
                            <div className={styles.subtitle}>Telefon ve E-postadan yanlızca biri zorunludur.</div>
                        </div>

                    </>
                }
                    setIsCollapsed={setIsCollapsed}
                /> : ""
            }
            <div className={styles.paymentcollapse}>
                <ResponsiveModal
                    isClosed={isClosed}
                    body={
                        <div className={styles.paymentmodal}>
                            <Payment />
                        </div>
                    }
                    callbackEvent={handleModalClick}

                />
            </div>
            {
                role == "smp" || role == "jp" || role == "bayis" || role == "altbayis" ? < div className={styles.infoarea}>
                    <div style={{ height: "20px" }}></div>
                    <span className={styles.header}>Başarılı İşlemler</span>
                    <div style={{ height: "15px" }}></div>
                    <div className={styles.cardbg}>

                        <div className={styles.infoheader}>
                            <span>İptal iade işlemi düşülerek hesaplanan tutarlardır.</span>
                            <span>
                                Bu işlemler firmanızın hesabına
                                <span className={styles.bolddate}>
                                    {" "}
                                    {willAccountedDateFormat(Date.now())}
                                </span>
                                'de gerçekleştirilecektir. Son güncelleme {" "}
                                {accountedForDate(Date.now())}
                            </span>
                        </div>

                        <div className={styles.infobottom}>
                            <PriceLabel
                                price={cardResponseData?.successfulTotalTransactionAmount}
                                size="4"
                            />
                            <span className={styles.counttext}>
                                {cardResponseData?.successfulTotalTransactionCount +
                                    " adet işlem"}
                            </span>
                        </div>
                    </div>
                </div> : ""
            }

            {
                role == "smp" || role == "bayis" || role == "altbayis" ? <Card
                    headText="Bekleyen İşlemler"
                    subText={"Bugün 00:00 itibariyle"}
                    price={cardResponseData?.waitingTotalTransactionAmount}
                    money="TL"
                    fontSize={"14px"}
                    count={
                        cardResponseData?.waitingTotalTransactionCount + " adet işlem"
                    }
                    color="#000d89"
                    style={{ margin: "15px" }}
                    href={"/transactions"}
                    state={{
                        paymentStatusId: 1,
                    }}
                ></Card> : ""
            }
            {
                role == "smp" || role == "bayis" || role == "altbayis" ? <Card
                    headText="İptal/İade İşlemleri"
                    subText={"Bugün 00:00 itibariyle"}
                    price={cardResponseData?.cancelAndRefundTotalTransactionAmount}
                    money="TL"
                    fontSize={"14px"}
                    count={
                        cardResponseData?.cancelAndRefundTotalTransactionCount +
                        " adet işlem"
                    }
                    color="#e99d36"
                    href={"/transactions"}
                    state={{
                        paymentStatusIdsArray: [5, 6],
                    }}
                    style={{ margin: "15px" }}
                /> : ""
            }
            {
                role == "jp" ?
                    <Card
                        headText="Bekleyen İşlemler"
                        subText={"Bugün 00:00 itibariyle"}
                        price={cardResponseData?.waitingTotalTransactionAmount}
                        money="TL"
                        fontSize={"14px"}
                        count={
                            cardResponseData?.waitingTotalTransactionCount + " adet işlem"
                        }
                        color="#000d89"
                        btnBackground="#f5f7f9"
                        style={{ margin: "15px" }}
                        href={"/transactions"}
                        state={{
                            paymentStatusId: 1,
                        }}
                    ></Card>
                    : ""
            }
            {
                role == "smp" || role == "jp" || role == "bayis" || role == "altbayis" ? <Card
                    headText="Başarısız İşlemler"
                    subText={"Bugün 00:00 itibariyle"}
                    price={cardResponseData?.unSuccessfulTotalTransactionAmount}
                    money="TL"
                    fontSize={"14px"}
                    count={
                        cardResponseData?.unSuccessfulTotalTransactionCount + " adet işlem"
                    }
                    color="#ff5c5c"
                    href={"/transactions"}
                    state={{
                        paymentStatusIdsArray: [3, 4],
                    }}
                    style={{ margin: "15px" }}
                /> : ""
            }
            {
                role == "smp" ? <Card
                    headText="Hesaba geçen toplam tutar"
                    subText="Bugün, 00.00 da hesabınıza geçen tutar ve bu tutarı oluşturan işlem adedi"
                    price={accountedForResponseData?.totalNetAccountAmountAndCurrency}
                    money="TL"
                    count={
                        accountedForResponseData?.paymentListTotalCount + " adet işlem"
                    }
                    color="#1ebcc8"
                    fontSize={"14px"}
                    href="/accountedFor"
                    state={{
                        date: accountedForDateFormat(new Date())
                    }}
                    style={{ margin: "15px" }}
                /> : ""
            }

            {
                role == "smp" || role == "bmp" ?
                    <Card
                        headText={"Hesaba geçecek toplam tutar"}

                        subText={
                            <span className={styles.normaldate}>
                                <br />
                                {accountedForDate(Date.now())}   00:00 itibariyle   {willAccountedDateFormat(Date.now())}  hesaba geçecek toplam tutar ve bu tutarı oluşturan işlem adedi.
                            </span>
                        }
                        color={"#000d89"}
                        style={{ margin: "15px" }}
                        price={willAccounted.totalSuccessTransactionAmountAndCurrency}
                        children={
                            <span span className={styles.counttext}>
                                {willAccounted?.paymentListTotalCount + " adet işlem"}
                            </span>
                        }
                    /> : ""
            }

            {
                role == "bmp" ? <Card
                    headText={"Bugünkü işlemler"}
                    subText={
                        <span className={styles.normaldate}>
                            <br />
                            Son güncelleme{" "}  {accountedForDate(Date.now())} tarihinde yapıldı.
                            <br />
                            <br />

                            {termDay} gün vadeli çalıştığınız için bu işlemler
                            <span className={styles.bolddate}>
                                {" "}
                                {willAccountedDateFormat()}
                            </span>
                            'de hesabınıza geçecek.<br /><br />
                        </span>
                    }
                    color={"#000d89"}
                    style={{ margin: "15px" }}
                    price={willAccounted.totalSuccessTransactionAmountAndCurrency}
                    children={
                        <span span className={styles.counttext}>
                            {willAccounted?.paymentListTotalCount + " adet işlem"}
                        </span>
                    }
                /> : ""
            }

            <div className={styles.bannerarea}>
                <BannerArea style={{ height: "50vh" }} />
            </div>

        </div >

    )
}

export default DashboardResponsive